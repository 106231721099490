import React, { useState } from "react";
import styles from "./EpisodeThumb.module.scss";
import { useViewportAnimation } from "../../logic/ViewportAnimation/useViewportAnimation";
import { useDimensions } from "../../logic/Dimentions";
import { Link } from "react-router-dom";
import { useSeasonal } from "../../logic/Seasonal";
import { updateEpisodeVideo } from "../../actions/episodeVideoAction";
import { useDispatch } from "react-redux";

const EpisodeThumb = ({ value }) => {
  const [playShown, setPlayShown] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const { width } = useDimensions();
  const { classes } = useSeasonal();
  const dispatch = useDispatch();

  useViewportAnimation();
  const onLoad = () => {
    setLoaded(true);
  };

  const handleClick = () => {
    dispatch(updateEpisodeVideo(value));
  };

  return (
    <div className={`${styles.EpisodeThumb} col-md-4 animation`}>
      <Link
        onClick={handleClick}
        to={`/episode/${value.url}`}
        activeClassName="active"
        className={` ${classes?.textDecororationNone}`}
      >
        <figure
          className={` ${styles.imgHoverAnim}    ${styles.imgWrap} ${styles.wide}`}
          onMouseEnter={() => setPlayShown(true)}
          onMouseLeave={() => setPlayShown(false)}
        >
          <img
            className={isLoaded ? "loaded" : null}
            src={value.image}
            onLoad={onLoad}
            alt=""
          />

          {playShown || width < 992 ? (
            <>
              <span
                className={`${styles.icon_play_video} ${classes?.playClass}`}
              ></span>
              <p className="episode-time">
                {/* <a className="IconPlaySeason-iconPlayP-0-1-138 IconPlaySeason-iconPlayP-0-1-140">
                        <i className="icon-watch-later"></i>Watch Later</a> */}

                <a
                  href={() => false}
                  className={`IconPlaySeason-iconPlayP-0-1-138 IconPlaySeason-iconPlayP-0-1-140 `}
                >
                  {value.duration}
                </a>
              </p>
            </>
          ) : null}
        </figure>

        <div className={styles.contentBox}>
          <h3 className={`${styles.title}  ${classes?.categoryList}`}>
            {value.title}
          </h3>

          <p
            className={styles.subtitle}
          >{`E${value.episode} - ${value.program}`}</p>
        </div>
      </Link>
    </div>
  );
};

export default EpisodeThumb;
