import React from "react";
import DollarADayLayout from '../components/Layouts/DollarADayLayout';
import DonateDollarADay from "../components/DonateDollarADay";
import { TitleComponent } from "../components/TitleComponent";
import { useSeo } from "../logic/Seo/useSeo";
import SocialMediaWrap from "../components/SocailMediaIcons";
import { useDimensions } from "../logic/Dimentions";
import { useSeasonal } from '../logic/Seasonal'

const DollarDayPage = () => {
  useSeo(); // Custom hook to handle SEO related tasks
  const { width } = useDimensions(); // Custom hook to get the dimensions of the viewport
  const { classes } = useSeasonal(); // Custom hook to handle seasonal logic

  return (
    <DollarADayLayout>
      {/* Component for setting the page title */}
      <TitleComponent title="Shalom world " />
      {/* Renders SocialMediaWrap component only if width is greater than or equal to 992 */}
      {/* Component for rendering the donation section DonateDollarADay*/}
      {width >= 992 && <SocialMediaWrap classes={classes} />}
      <DonateDollarADay />
    </DollarADayLayout>
  );

};
export default DollarDayPage;
