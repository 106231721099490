import React from "react";
import styles from "./DonateMore.module.scss";
import { Link } from "react-router-dom";
import { useSeasonal } from '../../../logic/Seasonal';

const DonateMore = ({ moreWaysGiveTitle, moreWaysGive }) => {
  const {seasonal,classes} = useSeasonal();

  return (
    <div className={styles.donate_more_wrap}>
      <section className={`${styles.donate_more} container`}>
        <h3 className={classes?.seasonalClass}>{moreWaysGiveTitle}</h3>
        <div className={`row ${styles.donate_grid}`}>
          {moreWaysGive.length > 0
            ? moreWaysGive.map((moreCards) => {
                return moreCards.readMoreUrl.includes("http") ? (
                  moreCards.type == "text" ? (
                    <div className={`col-md-6 col-lg-4 col-xs-6 ${styles.donate_col}`}>
                      <Link
                        target="_blank"
                        to={{
                          pathname: "" + moreCards.readMoreUrl + "",
                        }}
                      >
                        <div className={styles.donate_wrap}>
                          <div className={styles.img_wrap}>
                            <img src={moreCards.heading} />
                          </div>
                          <div className={styles.text_wrap}>
                            <div>
                              <h4>{moreCards.title}</h4>
                              <p>{moreCards.description}</p>
                            </div>
                          </div>
                          <span className={classes?.hoverColor}>{moreCards.readMoreText}</span>{" "}
                        </div>
                      </Link>
                    </div>
                  ) : (
                      <div className={`col-md-6 col-lg-4 col-xs-6 ${styles.donate_col}`}>
                          <a
                      href={"tel:" + moreCards.readMoreUrl}
                      className="full-link"
                    >
                        <div className={styles.donate_wrap}>
                          <div className={styles.img_wrap}>
                            <img src={moreCards.heading} />
                          </div>
                          <div className={styles.text_wrap}>
                            <div>
                              <h4>{moreCards.title}</h4>
                              <p>{moreCards.description}</p>
                            </div>
                          </div>
                          <span className={classes?.hoverColor}>{moreCards.readMoreText}</span>
                        </div>
                        </a>
                      </div>
                  )
                ) : moreCards.type == "text" ? (
                  
                    <div className={`col-md-6 col-lg-4 col-xs-6 ${styles.donate_col}`}>
                        <Link
                    target="_blank"
                    to={{
                      pathname: "/" + moreCards.readMoreUrl + "",
                    }}
                  >
                      <div className={styles.donate_wrap}>
                        <div className={styles.img_wrap}>
                          <img src={moreCards.heading} />
                        </div>
                        <div className={styles.text_wrap}>
                          <div>
                            <h4>{moreCards.title}</h4>
                            <p>{moreCards.description}</p>
                          </div>
                        </div>
                        <span className={classes?.hoverColor}>{moreCards.readMoreText}</span>
                      </div>
                  </Link>
                  </div>
                ) : (
                  
                    <div className={`col-md-6 col-lg-4 col-xs-6 ${styles.donate_col}`}>
                        <a
                    href={"tel:" + moreCards.readMoreUrl}
                    className="full-link"
                  >
                      <div className={styles.donate_wrap}>
                        <div className={styles.img_wrap}>
                          <img src={moreCards.heading} />
                        </div>
                        <div className={styles.text_wrap}>
                          <div>
                            <h4>{moreCards.title}</h4>
                            <p>{moreCards.description}</p>
                          </div>
                        </div>{" "}
                        <span className={classes?.hoverColor}>{moreCards.readMoreText}</span>
                      </div>
                  </a>
                  </div>
                );

                // </div>
                // </div>
              })
            : null}
        </div>
      </section>
    </div>
  );
};
export default DonateMore;
