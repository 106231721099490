import {
    FETCH_EVENTDETAILS,
    FETCH_EVENTRELATEDVIDEOS,
    FETCH_EVENTPASTVIDEOS,
    UPDATE_EVENTDETAILS,
    UPDATE_EVENTDETAILS_STATUS,
    CLEAR_THUMBNAILS
} from "../actions/types";

const INTIAL_STATE = {
    evnt: {
        banner: {
            logo: "",
            posterImage: "",
        },
        about: {
            sectionTitle: "",
            title: "",
            description: "",
        },
        news: {
            videos: [],
            sectionTitle: ``,
        },
        shortVideos: {
            videos: [],
            sectionTitle: ``,
        },
        pastVideos: {
            videos: [],
            sectionTitle: ``,
        },
        relatedVideos: {
            videos: [],
            sectionTitle: ``,
        },
        schedule: {
            scheduleAll: [],
            sectionTitle: ``,
        },
        upComingSchedule: {
            sectionTitle: "",
            schedule: [],
        },
        liveStreams: {
            status: "",
            posterImage: "",
            posterImageMobile: "",
            streams: []
        },
        status: "idle",
    },
};

// eslint-disable-next-line
export default (state = INTIAL_STATE, action) => {
    switch (action.type) {

        case FETCH_EVENTDETAILS:
            return {
                evnt: action.payload,
            };

        // eslint-disable-next-line no-duplicate-case
        case FETCH_EVENTRELATEDVIDEOS:
            return {
                ...state,
                evnt: {
                    ...state.evnt,
                    // Update relatedVideos with new data from payload
                    relatedVideos: {
                        ...state.relatedVideos,
                        videos: action.payload?.videos,
                        sectionTitle: action.payload?.sectionTitle,
                    },
                    // Update slug and title with new data from payload
                    slug: action.payload?.slug,
                    title: action.payload?.title,
                },
            };

        case FETCH_EVENTPASTVIDEOS:
            return {
                ...state,
                evnt: {
                    ...state.evnt,
                    // Update pastVideos with new data from payload
                    pastVideos: {
                        ...state.pastVideos,
                        videos: action.payload?.videos,
                        sectionTitle: action.payload?.sectionTitle,
                    },
                    // Update slug and title with new data from payload
                    slug: action.payload?.slug,
                    title: action.payload?.title,
                },
            };

        // Assuming "state.evnt.liveStreams.streams" is an array and "state.evnt.updatedSchedule" is an array
        case UPDATE_EVENTDETAILS:

            const { stream, payload, eventType } = action;

            // Update the stream at the specified index
            if (eventType === "resolution") {
                let updatedLiveStreamUrl = payload?.stage?.liveStreamUrl[0];
                let updatedSchedule = payload?.upComingSchedule;
                return {
                    ...state,
                    evnt: {
                        ...state.evnt,
                        liveStreams: {
                            ...state.evnt.liveStreams,
                            // eslint-disable-next-line no-useless-computed-key
                            [0]: {
                                ...state.evnt.liveStreams[0],
                                liveStreamUrl: [updatedLiveStreamUrl],
                            },
                        },
                        upComingSchedule: updatedSchedule, // Include the updated upComingSchedule here
                    },
                };
            } else {
                let updatedStreams = [...state.evnt.liveStreams.streams];
                updatedStreams[stream] = payload?.stage;
                // Update the "updatedSchedule" with the new array directly
                let updatedSchedule = payload?.upComingSchedule;
                return {
                    ...state,
                    evnt: {
                        ...state.evnt,
                        liveStreams: {
                            ...state.evnt.liveStreams,
                            streams: updatedStreams,
                        },
                        upComingSchedule: updatedSchedule,
                    },
                };
            }

        case UPDATE_EVENTDETAILS_STATUS:
            return {
                ...state,
                evnt: {
                    ...state.evnt,
                    status: 'success',
                },
            };
        case CLEAR_THUMBNAILS:
            return {
                ...state,
                evnt: {
                    banner: {
                        logo: "",
                        posterImage: "",
                    },
                    about: {
                        sectionTitle: "",
                        title: "",
                        description: "",
                    },
                    news: {
                        videos: [],
                        sectionTitle: ``,
                    },
                    shortVideos: {
                        videos: [],
                        sectionTitle: ``,
                    },
                    pastVideos: {
                        videos: [],
                        sectionTitle: ``,
                    },
                    relatedVideos: {
                        videos: [],
                        sectionTitle: ``,
                    },
                    schedule: {
                        scheduleAll: [],
                        sectionTitle: ``,
                    },
                    upComingSchedule: {
                        sectionTitle: "",
                        schedule: [],
                    },
                    liveStreams: {
                        status: "",
                        posterImage: "",
                        posterImageMobile: "",
                        streams: []
                    },
                    status: "idle",
                },
            };

        default:
            return state;
    }
};
