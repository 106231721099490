import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./PodcastPlaylist.module.scss";
import { useSeasonal } from '../../../logic/Seasonal'
import PodcastShareModal from "../../PodcastShareModal";


const PodcastPlaylist = ({ isPlaylist, setPlaylist, podCastPlayListData, handlePlayThisItem, handlePlay, play, handleSetActiveId, activeId, progressData }) => {

    let objectFound = podCastPlayListData.find((item) => item.active === true);
    const [showShareModal, setShareModal] = useState(false);
    const [shareContentModal, setShareContentModal] = useState(false);
    const [message, setMessage] = useState('');

    const handleSetMessage = (msg) => {
        setMessage(msg)
    };

    const handleClick = (e) => {
        e.preventDefault();
        return;
    };

    const handleHidePlaylist = () => {
        setPlaylist(false)
        return;
    };
    const scrollEnable = (e) => {
        document.querySelector("html").classList.remove("scroll_disable");
        document.body.classList.remove("scroll_disable");
        return;
    };

    const { classes } = useSeasonal();
    return (
        <>
            <div className={`${styles.podcastPlaylist} ${isPlaylist ? styles.show : ''} mob_playlist`}>
                <span className={styles.downArrow} onClick={() => { handleHidePlaylist();scrollEnable(); }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" ><g transform="translate(-174 -155)"><g transform="translate(174 155)" fill="#fff" stroke="#707070" strokeWidth="1" opacity="0"><rect width="28" height="28" stroke="none" /><rect x="0.5" y="0.5" width="27" height="27" fill="none" /></g><path d="M0,0,10.521,3.912,19.8,0" transform="translate(178.5 167.5)" fill="none" stroke="rgba(255,255,255,0.44)" strokeLinecap="round" strokeWidth="3" /></g></svg>
                </span>
                <div className={styles.podcastRewindWrapper}>
                    <p className={`podcastTitle`}>Playlist</p>

                    <p className={`podcastSubTitle`}>Now Playing</p>

                    <div className={`${styles.PodcastEpisodeThumb} ${styles.podcastActiveThumb}`}>
                        {/* Remove "podcastActiveThumb" for podcast that are not playing  */}

                        <Link onClick={() => {
                            handlePlay(!play);
                            // handlePlay(!play);
                            // handleSetActiveId(objectFound?.id);
                        }}
                            className="full_link"
                        >
                        </Link>
                        <div className={styles.episodeWrap}>

                            <div className={styles.audioPlay}>
                                <div className={`sound_bars ${classes.soundbarColor} ${play ? '' : 'd-none'}`}>
                                    <span className="bar"></span>
                                    <span className="bar"></span>
                                    <span className="bar"></span>
                                    <span className="bar"></span>
                                </div>
                                <span className={`play-icon xs ${play ? "d-none" : ""} ${classes.podcastPlayClass}`}>
                                    {play && activeId === objectFound.id ? 
                                        // play
                                        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="12" viewBox="0 0 9 12"><g transform="translate(-323 -1192)"><rect width="3" height="12" rx="1" transform="translate(323 1192)"/><rect width="3" height="12" rx="1" transform="translate(329 1192)"/></g></svg>
                                        : 
                                        // pause
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12" viewBox="0 0 10 12"><path d="M5.143,1.429a1,1,0,0,1,1.715,0l4.234,7.056A1,1,0,0,1,10.234,10H1.766A1,1,0,0,1,.909,8.486Z" transform="translate(10) rotate(90)" fill="#ebab0b"/></svg>
                                    }
                                </span>
                            </div>


                            <div className={styles.audioDtlWrap}>

                                <figure className="figImg">
                                    <img src={objectFound?.thumbnail?.small} alt="" />
                                </figure>

                                <div className={styles.episodeThumbContent}>
                                    <div className={styles.nameWrapper}>
                                        <p className={`${styles.episodename} ${classes.txtColor}`}>{objectFound?.title}</p>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.shareBtn} onClick={(e) => {
                                setShareModal(true);
                                setShareContentModal(objectFound?.id);
                                handleClick(e);
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18.661" height="20.574" viewBox="0 0 18.661 20.574"><g transform="translate(0.7 0.699)"><path d="M-38.177,330.228H-53.5a1.653,1.653,0,0,1-1.672-1.63V317.206h1.4V328.6a.254.254,0,0,0,.272.23h15.318a.253.253,0,0,0,.271-.23V317.206h1.4V328.6A1.653,1.653,0,0,1-38.177,330.228Z" transform="translate(54.467 -310.352)" fill="#fff" /><g transform="translate(4.888)"><path d="M-45.409,320.6h-1.4V308.676h1.4Z" transform="translate(49.821 -308.949)" fill="#fff" /><path d="M-42.738,313.483l-3.737-3.738-3.738,3.738-.99-.99,3.981-3.981a1.06,1.06,0,0,1,1.492,0l3.982,3.983Z" transform="translate(50.214 -308.903)" fill="#fff" /></g></g></svg>
                            </div>

                        </div>

                    </div>

                    <p className={`podcastSubTitle`}>Up Next</p>
                    <div className={styles.nextPodcastWrapper}>
                        {podCastPlayListData.map((item, index) => {
                            return (
                                <>
                                    <div className={` ${styles.PodcastEpisodeThumbs} ${play && activeId === item?.id ? styles.active : ""} `}
                                        key={index}>
                                        <Link className="full_link" onClick={(e) => {
                                            // handlePlay(!play);
                                            // // handleSetActiveId(item?.id);
                                            // handlePlayThisItem(item?.id);

                                            if (podCastPlayListData.filter(podCastPlayListItem => podCastPlayListItem.id === item?.id)) {

                                                if (play && activeId === item?.id) {
                                                    handlePlay(!play);
                                                } else {
                                                    handlePlayThisItem(item?.id);
                                                    handleClick(e);
                                                    handleSetActiveId(item?.id);
                                                }

                                            }
                                            else {
                                                handlePlayThisItem(item?.id);
                                                handleSetActiveId(item?.id);
                                                handlePlay(true);
                                            }

                                        }}></Link>
                                        <div className={styles.episodeWrap}>
                                            <div className={styles.indexNumber}>
                                                <p className={play && activeId === item?.id ? classes.txtColor : ""}>{index + 1}</p>
                                            </div>
                                            <div className={styles.audioDtlWrap}>
                                                <figure className="figImg">
                                                    <img src={item?.thumbnail?.small} alt="" />
                                                </figure>

                                                <div className={styles.episodeThumbContent}>
                                                    <div className={styles.nameWrapper}>
                                                        <p className={`${styles.episodename} ${play && activeId === item?.id ? classes.txtColor : ""}`}>{item?.title}</p>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className={styles.shareBtn} onClick={(e) => {
                                                setShareModal(true);
                                                setShareContentModal(item?.id);
                                                handleClick(e);
                                            }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18.661" height="20.574" viewBox="0 0 18.661 20.574"><g transform="translate(0.7 0.699)"><path d="M-38.177,330.228H-53.5a1.653,1.653,0,0,1-1.672-1.63V317.206h1.4V328.6a.254.254,0,0,0,.272.23h15.318a.253.253,0,0,0,.271-.23V317.206h1.4V328.6A1.653,1.653,0,0,1-38.177,330.228Z" transform="translate(54.467 -310.352)" fill="#fff" /><g transform="translate(4.888)"><path d="M-45.409,320.6h-1.4V308.676h1.4Z" transform="translate(49.821 -308.949)" fill="#fff" /><path d="M-42.738,313.483l-3.737-3.738-3.738,3.738-.99-.99,3.981-3.981a1.06,1.06,0,0,1,1.492,0l3.982,3.983Z" transform="translate(50.214 -308.903)" fill="#fff" /></g></g></svg>
                                            </div>
                                        </div>

                                    </div>
                                </>
                            );
                        })}
                    </div>
                </div>
            </div>
            <PodcastShareModal
                show={showShareModal}
                handleSetMessage={handleSetMessage}
                onHide={(e) => {
                    setShareModal(false);
                    setMessage();
                }}
                message={message}
                episode={podCastPlayListData.find((item) => item.id === shareContentModal)}
                podcastSlug={""}
                copyLinkText={
                    window.location.origin +
                    "/podcast/" +
                    podCastPlayListData.find((item) => item.id === shareContentModal)
                        ?.podcastSlug +
                    "/" +
                    podCastPlayListData.find((item) => item.id === shareContentModal)?.url
                }
            />
        </>

    );
};

export default PodcastPlaylist;
