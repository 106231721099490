/* eslint-disable */

import { FETCH_PROGRAMES, UPDATE_PROGRAMES, REMOVE_PROGRAMES } from "./types";
import salomapi from "../apis";
import BrowserPersistence from "../util/simplePersistance";

const storage = new BrowserPersistence();

export const fetchPrograms = (category, region) => async (dispatch) => {
    async function fetchFromServer() {
        try {
            const response = await salomapi.get(
                "/featured/programs?category=" + category
            );
            let data = {
                [category]: { page: 1, data: response.data.programs, totalPage: 1 },
            };
            dispatch({
                type: FETCH_PROGRAMES,
                payload: data,
                bgImage: response.data.bgImage,
            });
            await savePrograms(data);
        } catch (error) {
            dispatch({ type: FETCH_PROGRAMES, payload: error });
            throw error;
        }
    }
    const localHeaderData = await retrievePrograms();
    if (localHeaderData)
        dispatch({ type: FETCH_PROGRAMES, payload: localHeaderData });
    else await fetchFromServer();

    async function retrievePrograms() {
        return storage.getItem("programData");
    }
    async function savePrograms(data) {
        return storage.setItem("programData", data, 86400);
    }
};

export const fetchCategoryPrograms =
    (category, page, perPage) => async (dispach) => {
        const response = await salomapi.get("/category/program", {
            params: {
                region: storage.getItem("CountryCode"),
                category,
                page,
                perpage: perPage,
            },
        });

        if (response.status != "error") {
            if (page > 1) {
                dispach({
                    type: UPDATE_PROGRAMES,
                    payload: { [category]: response.data.programs, page, category },
                });
            } else {
                let data = {
                    [category]: {
                        page: page,
                        data: response.data.programs,
                        totalPage: response.data.pages,
                    },
                };
                dispach({
                    type: FETCH_PROGRAMES,
                    payload: data,
                    bgImage: response.data.bgImage,
                });
            }
        }
    };

export const fetchProgramDetails = (url, speakerId, categoryId) => async (dispatch) => {
    const params = {
        url,
        ...(speakerId && speakerId !== "all" && { speakerId }),
        ...(categoryId && categoryId !== "all" && { categoryId }),
    };

    const response = await salomapi.get("/program/single", { params });
    return response.data;
};

const buildQueryParamString = (speaker, topic) => {
    const queryParams = [];

    if (speaker?.ky && speaker?.ky !== 'All' && speaker?.ky !== 'all') {
        queryParams.push(`${encodeURIComponent('speaker')}=${encodeURIComponent(speaker.ky)}`);
    }

    if (topic?.ky && topic?.ky !== 'All' && topic?.ky !== 'all') {
        queryParams.push(`${encodeURIComponent('topic')}=${encodeURIComponent(topic.ky)}`);
    }

    return queryParams.join('&');
};

export const fetchLatestVideo = (id, zypeId, tags, page, limit, speaker, topic) => async (dispatch) => {
    const queryString = buildQueryParamString(speaker, topic);

    const zypeIdParam = zypeId !== undefined ? `zypeId=${zypeId}` : '';
    const idParam = id !== undefined ? `id=${id}` : '';

    const params = [zypeIdParam, idParam, queryString].filter(param => param !== '').join('&');

    let url = `/related/episodes?${params}`;
    if (page > 1) {
        url += `&page=${page}`;
    }
    const response = await salomapi.get(url);
    return response.data;
};

export const fetchRelatedVideo =
    (id, zypeId, tags, page) => async (dispach) => {
        const response = await salomapi.get(
            `/tags/episodes?zypeId=${zypeId}&tags=${tags}&page=${page}&perpage=9`
        );
        return response.data;
    };


export const removeProgammes = (categories) => async dispatch => {
    dispatch({ type: REMOVE_PROGRAMES, payload: categories });
}


