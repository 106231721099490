/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import { fetchToken, getSteamUri } from "../../actions/watchLiveAction";
import styles from "./WatchLiveVideo.module.scss";
import Container from "react-bootstrap/Container";
import UpcomingPrograms from "../UpcomingPrograms";
import WeekSchedule from "../WeekSchedule";
import { connect } from "react-redux";
import { useHomeLiveVideo } from "../../logic/HomeLiveVideo/useHomeLiveVideo";
import LiveVideoPlayer from "../LiveVideoPlayer";
import LiveVideoPlayerMobile from "../LiveVideoPlayerMobile";
import LiveVideoPlayerIos from "../LiveVideoPlayerIos";
import SecMenu from "../SecMenu";
import { useDimensions } from "../../logic/Dimentions";
import MobileMenu from "../MobileMenu";
import BrowserPersistence from "../../util/simplePersistance";
import { useLocation, useParams } from "react-router-dom";
import CommonLoader from "../Shimmers/CommonLoader";
import { useSeasonal } from "../../logic/Seasonal";

const storage = new BrowserPersistence();

const WatchLiveVideo = ({
  fetchToken,
  getSteamUri,
  fullscreen,
  setFullscreen,
  toggleScreen,
  setToggleScreen,
}) => {
  const location = useLocation();
  const { classes } = useSeasonal();
  const watchSheduleRef = useRef();
  const query = new URLSearchParams(location.search);
  const qRegion = query.get("region");

  const [upComing, setProgram] = useState(false);
  const [region, setRegion] = useState(storage.getItem("CountryCode"));
  const [playError, setPlayError] = useState(true);
  const [watchUrl, setWatchUrl] = useState("");
  const [play, setPlay] = useState(false);

  let { fullschedule } = useParams();
  const { width } = useDimensions();

  useEffect(() => {
    if (region != null) {
      setRegion(region);
    }
  }, [region]);

  useEffect(() => {
    setTimeout(() => {
      setProgram(true);
    }, 100);

  }, []);

  useEffect(() => {
    if (qRegion != null) {
      setRegion(qRegion);
    }
  }, [qRegion]);

  useEffect(() => {

    var timesRun = 0;
    var interval = setInterval(function () {
      timesRun += 1;
      if (timesRun === 20) {
        clearInterval(interval);
      }
      //do whatever here..
      if (storage.getItem("CountryCode") && region === null) {
        setRegion(storage.getItem("CountryCode"));
      }

      if (qRegion != null) {
        setRegion(qRegion);
      }
    }, 1000);
  }, []);

  useEffect(() => {
    if (watchSheduleRef.current && fullschedule) {
      setTimeout(() => {
        watchSheduleRef.current.scrollIntoView();
      }, 1000);
    }
  }, [fullschedule]);

  const logicProps = useHomeLiveVideo({
    fetchToken,
    getSteamUri,
    region,
    playError,
    setPlayError,
    watchUrl,
    setWatchUrl,
  });

  /**
   * Determine the mobile operating system.
   * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
   *
   * @returns {String}
   */
  function androidOrIOS() {
    return [
      "iPad Simulanavigator.platformtor",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      ? "mobile"
      : "desktop";
  }
  return (
    <>
      {!fullscreen ? (
        <div id="fullscreen-div">


          {watchUrl ? (
            width >= 992 && androidOrIOS() !== "mobile" ? (
              <LiveVideoPlayer
                url={watchUrl}
                fullscreen={fullscreen}
                setFullscreen={setFullscreen}
                playError={playError}
                setPlayError={setPlayError}
                live={true}
                toggleScreen={toggleScreen}
                setToggleScreen={setToggleScreen}
                play={play}
                setPlay={setPlay}
              />
            ) : (
              <div className={`mobile-video-player-wrapper reactplayerWrap ${navigator.userAgent === "shalom_world_app_mobile" ? 'mob-app-mar-0' : 'no-mob-app'}`}>
                <div
                  className={`${styles.reactplayerWrapMobFixed} mobFixedVideo`}
                >
                  {[
                    "iPad Simulanavigator.platformtor",
                    "iPhone Simulator",
                    "iPod Simulator",
                    "iPad",
                    "iPhone",
                    "iPod",
                  ].includes(navigator.platform) ||
                    // iPad on iOS 13 detection
                    (navigator.userAgent.includes("Mac") &&
                      "ontouchend" in document) ? (
                    <>
                      <LiveVideoPlayerIos
                        url={watchUrl}
                        fullscreen={fullscreen}
                        setFullscreen={setFullscreen}
                        playError={playError}
                        setPlayError={setPlayError}
                        live={true}
                        play={play}
                        setPlay={setPlay}
                        toggleScreen={toggleScreen}
                        setToggleScreen={setToggleScreen}
                      />
                    </>
                  ) : (
                    <>
                      <LiveVideoPlayerMobile
                        url={watchUrl}
                        fullscreen={fullscreen}
                        setFullscreen={setFullscreen}
                        playError={playError}
                        setPlayError={setPlayError}
                        live={true}
                        play={play}
                        setPlay={setPlay}
                        toggleScreen={toggleScreen}
                        setToggleScreen={setToggleScreen}
                      />
                    </>
                  )}
                </div>
              </div>
            )
          ) : (
            <div className="loaderWrp">
              <CommonLoader />
            </div>
          )}
          {width >= 992 ? (
            <Container>
              {/* <Navbar> */}
              {/* <header> */}
              <div className={styles.head_menu} ref={watchSheduleRef}>

                <ul className={styles.menu_links}>
                  <li>
                    <a
                      href={() => false}
                      data-toggle="tooltip"
                      title="latestVideo"
                      className={`${upComing ? styles.active : null} ${upComing && classes?.txtColor
                        } ${classes?.categoryList}`}
                      onClick={() => {
                        setProgram(true);
                      }}
                    >
                      Upcoming Programs
                    </a>
                  </li>
                  <li>
                    <a
                      href={() => false}
                      data-toggle="tooltip"
                      title="relatedVideo"
                      className={`${!upComing ? styles.active : null} ${!upComing && classes?.txtColor
                        } ${classes?.categoryList}`}
                      onClick={() => {
                        setProgram(false);
                      }}
                    >
                      Full Week Schedule
                    </a>
                  </li>
                </ul>
              </div>
              {/* </header> */}
              {/* </Navbar> */}
            </Container>
          ) : null}
          <div>

            {" "}
            {width >= 992 ? (
              upComing ? (
                <UpcomingPrograms
                  region={region}
                  setRegion={setRegion}
                  play={play}
                  setPlay={setPlay}
                />
              ) : (
                <WeekSchedule
                  region={region}
                  style={{ display: upComing ? "block" : "none" }}
                />
              )
            ) : null}
          </div>
        </div>
      ) : (
        <LiveVideoPlayer
          url={watchUrl}
          fullscreen={fullscreen}
          setFullscreen={setFullscreen}
          playError={playError}
          setPlayError={setPlayError}
          live={true}
          play={play}
          setPlay={setPlay}
          toggleScreen={toggleScreen}
          setToggleScreen={setToggleScreen}
        />
      )}
    </>
  );
};

export default connect(null, { fetchToken, getSteamUri })(WatchLiveVideo);
