import React, { useState, useCallback } from 'react'
import styles from './Endorsements.module.scss'
import Container from 'react-bootstrap/Container'
import { Navbar } from 'react-bootstrap'
import { fetchMoreEndorsements } from '../../actions/aboutusAction'
import Row from 'react-bootstrap/Row'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import InfiniteScroll from 'react-infinite-scroller'
import EndorsementItemInner from './EndorsementItemInner'
import LoadMoreLoader from '../Shimmers/LoadMoreLoader'
import { useSeasonal } from '../../logic/Seasonal'

const Endorsements = ({
  fetchMoreEndorsements,
  moreEndoresments,
  maxPage,
  currentPage,
  endorsments,
}) => {
  const [value, setValue] = useState(0)
  let loadPageAll,
    loadPageAmerica,
    loadPageAsia,
    loadPageAustralia,
    loadPageCanada,
    loadPageIre,
    loadPageUk
  loadPageAll = loadPageAmerica = loadPageAsia = loadPageAustralia = loadPageCanada = loadPageIre = loadPageUk = 1
  const { classes } = useSeasonal()

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const loadEndorsements = useCallback((endorseList) => {
    let page
    switch (endorseList.slug) {
      case 'all':
        loadPageAll++
        page = loadPageAll
        break
      case 'america':
        loadPageAmerica++
        page = loadPageAmerica
        break
      case 'asia':
        loadPageAsia++
        page = loadPageAsia
        break
      case 'australia':
        loadPageAustralia++
        page = loadPageAustralia
        break
      case 'canada':
        loadPageCanada++
        page = loadPageCanada
        break
      case 'ireland':
        loadPageIre++
        page = loadPageIre
        break
      case 'uk':
        loadPageUk++
        page = loadPageUk
        break
      default:
        break
    }
    fetchMoreEndorsements(endorseList.slug, page, endorseList.endorsement)
  }, [])

  function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    )
  }
  const loader = (
    <div className={styles.loader}>
      <LoadMoreLoader />
    </div>
  )
  const EndorsementItem = ({ item }) => {
    //const [play, setPlay] = useState(false)
    const [currentPlay, setCurrentPlay] = useState('')
    return item && item.length > 0
      ? item.map((endorse, index) => {
        return (
          <EndorsementItemInner
            classes={classes}
            endorse={endorse}
            currentPlay={currentPlay}
            key={index}
            setCurrentPlay={setCurrentPlay}
          />
        )
      })
      : null
  }
  return (
    <div>
      <Container>
        <h2 className={`${styles.is_title} ${classes?.seasonalClass}`}>
          Endorsements
        </h2>
        <Navbar className={styles.Menu}>
          <section className="endorsement-nav">
            <div className={styles.head_menu}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
                className={`${styles.tabContainer}  ${classes?.swtab}`}
              >
                <Tab
                  label={endorsments.all.title}
                  className={`${classes?.hoverClass}`}
                />
                <Tab
                  label={endorsments.america.title}
                  className={`${classes?.hoverClass}`}
                />
                <Tab
                  label={endorsments.asia.title}
                  className={` ${classes?.hoverClass}`}
                />
                <Tab
                  label={endorsments.australia.title}
                  className={` ${classes?.hoverClass}`}
                />
                <Tab
                  label={endorsments.canada.title}
                  className={` ${classes?.hoverClass}`}
                />
                <Tab
                  label={endorsments.ireland.title}
                  className={` ${classes?.hoverClass}`}
                />
                <Tab
                  label={endorsments.uk.title}
                  className={` ${classes?.hoverClass}`}
                />
              </Tabs>
              {/* <ul className={styles.menu_links}>

                            <li><a>All</a></li>
                            <li><a>America</a></li>
                            <li><a>Asia</a></li>
                            <li><a>Australia</a></li>
                            <li><a>Canada</a></li>
                        </ul> */}
            </div>
          </section>
        </Navbar>
        <TabPanel value={value} index={0}>
          <InfiniteScroll
            pageStart={1}
            loadMore={() => loadEndorsements(endorsments.all)}
            hasMore={currentPage < maxPage || maxPage === 1 ? true : false}
            loader={loader}
          >
            <Row className={styles.row_wrap}>
              {/* {moreEndoresments?.all?.length>0 ? (<EndorsementItem item={moreEndoresments.all} />):<EndorsementItem item={endorsments.all.endorsement} />} */}
              <EndorsementItem item={endorsments.all.endorsement} />
            </Row>
          </InfiniteScroll>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <InfiniteScroll
            pageStart={1}
            loadMore={() => loadEndorsements(endorsments.america)}
            hasMore={currentPage < maxPage || maxPage === 1 ? true : false}
            loader={loader}
          >
            <Row className={styles.row_wrap}>
              {/* {moreEndoresments.length>0 ? (<EndorsementItem item={moreEndoresments} />):<EndorsementItem item={endorsments.america.endorsement} />} */}
              <EndorsementItem item={endorsments.america.endorsement} />
            </Row>
          </InfiniteScroll>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <InfiniteScroll
            pageStart={1}
            loadMore={() => loadEndorsements(endorsments.asia)}
            hasMore={currentPage < maxPage || maxPage === 1 ? true : false}
            loader={loader}
          >
            <Row className={styles.row_wrap}>
              {/* {moreEndoresments.length>0 ? (<EndorsementItem item={moreEndoresments} />):<EndorsementItem item={endorsments.asia.endorsement} />} */}
              <EndorsementItem item={endorsments.asia.endorsement} />
            </Row>
          </InfiniteScroll>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <InfiniteScroll
            pageStart={1}
            loadMore={() => loadEndorsements(endorsments.australia)}
            hasMore={currentPage < maxPage || maxPage === 1 ? true : false}
            loader={loader}
          >
            <Row className={styles.row_wrap}>
              {/* {moreEndoresments.length>0 ? (<EndorsementItem item={moreEndoresments} />):<EndorsementItem item={endorsments.australia.endorsement} />} */}
              <EndorsementItem item={endorsments.australia.endorsement} />
            </Row>
          </InfiniteScroll>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <InfiniteScroll
            pageStart={1}
            loadMore={() => loadEndorsements(endorsments.canada)}
            hasMore={currentPage < maxPage || maxPage === 1 ? true : false}
            loader={loader}
          >
            <Row className={styles.row_wrap}>
              {/* {moreEndoresments.length>0 ? (<EndorsementItem item={moreEndoresments} />):<EndorsementItem item={endorsments.canada.endorsement} />} */}
              <EndorsementItem item={endorsments.canada.endorsement} />
            </Row>
          </InfiniteScroll>
        </TabPanel>
        <TabPanel value={value} index={5}>
          <InfiniteScroll
            pageStart={1}
            loadMore={() => loadEndorsements(endorsments.ireland)}
            hasMore={currentPage < maxPage || maxPage === 1 ? true : false}
            loader={loader}
          >
            <Row className={styles.row_wrap}>
              {/* {moreEndoresments.length>0 ? (<EndorsementItem item={moreEndoresments} />):<EndorsementItem item={endorsments.ireland.endorsement} />} */}
              <EndorsementItem item={endorsments.ireland.endorsement} />
            </Row>
          </InfiniteScroll>
        </TabPanel>
        <TabPanel value={value} index={6}>
          <InfiniteScroll
            pageStart={1}
            loadMore={() => loadEndorsements(endorsments.uk)}
            hasMore={currentPage < maxPage || maxPage === 1 ? true : false}
            loader={loader}
          >
            <Row className={styles.row_wrap}>
              {/* {moreEndoresments.length>0 ? (<EndorsementItem item={moreEndoresments} />):<EndorsementItem item={endorsments.uk.endorsement} />} */}
              <EndorsementItem item={endorsments.uk.endorsement} />
            </Row>
          </InfiniteScroll>
        </TabPanel>
      </Container>
    </div>
  )
}
const mapPropsToState = (state) => {
  return {
    moreEndoresments: state.aboutUs.moreEndoresments,
    maxPage: state.aboutUs.maxPage,
    currentPage: state.aboutUs.page,
  }
}
export default connect(mapPropsToState, { fetchMoreEndorsements })(Endorsements)
