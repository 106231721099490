/* eslint-disable */
import React, { useEffect } from "react";
import ReactPlayer from "react-player/lazy";
import styles from "./AboutUs.module.scss";
import Container from "react-bootstrap/Container";
import { fetchAboutus } from "../../actions/aboutusAction";
import { connect } from "react-redux";
import Endorsements from "../Endorsements";

import { useSeasonal } from "../../logic/Seasonal";
//import { useTransition } from '../../logic/PageTransition/useTransition'
//import BrowserPersistence from '../../util/simplePersistance'
// import { Transition } from 'react-transition-group'
// const storage = new BrowserPersistence()

const AboutUs = ({ fetchAboutus, aboutUs }) => {
  const { seasonal, classes } = useSeasonal();
  //const { duration, defaultStyle, transitionStyles } = useTransition()
  // const region = storage.getItem("CountryCode")  //   ? storage.getItem("CountryCode")  //   : "us";

  useEffect(() => {
    if (aboutUs.length === 0) fetchAboutus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchAboutus]);

  let bannerVideo = aboutUs?.bannerVideo?.video;

  const handlePlaying = () => {
    document.body.classList.add("isplaying");

  };

  const handleStop = () => {
    document.body.classList.remove("isplaying");
  };

  return (
    // <Transition in={aboutUs?.bannerVideo} timeout={duration}>
    // {state => (
    //   <>
    //   <div style={{
    //   ...defaultStyle,
    //   ...transitionStyles[state]
    // }}>
    <div className={`${styles.aboutUsBannerVideo} ${navigator.userAgent === "shalom_world_app_mobile" ? 'mob-app-pad-0' : 'no-mob-app'}`}>
      <div className={styles.video_align}>
        <div className="vdoWrap wide">
          {bannerVideo ? (
            <div
              className={`${styles.aboutReactPlayer} ${classes?.aboutPlayBtn}`}
            >
              <ReactPlayer
                url={bannerVideo.url}
                width="100%"
                height="100%"
                light={aboutUs.bannerImage}
                // muted={true}
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload noplaybackrate",
                      disablePictureInPicture: true,
                    },
                  },
                }}
                playing={true}
                controls={true}
                onStart={handlePlaying} //Only applicable for videos in Banner
                onPause={handleStop} //Only applicable for videos in Banner
                onPlay={handlePlaying} //Only applicable for videos in Banner
              />
            </div>
          ) : (
            <img src={aboutUs.bannerImage} alt="" />
          )}
        </div>
        <h2>{aboutUs.bannerText}</h2>
      </div>
      <Container>
        {aboutUs.aboutHeading ? (
          <h2 className={`${styles.is_title} ${classes?.seasonalClass}`}>
            {aboutUs.aboutHeading}
          </h2>
        ) : null}

        <p className={styles.para_align}>{aboutUs.Description}</p>
      </Container>
      {aboutUs.endorsement ? (
        <Endorsements endorsments={aboutUs.endorsement.endorsements} />
      ) : null}
    </div>
    // </div>
    //     </>
    //     )}
    // </Transition>
  );
};
const mapPropsToState = (state) => {
  return { aboutUs: state.aboutUs.aboutUs };
};
export default connect(mapPropsToState, { fetchAboutus })(AboutUs);
