/* eslint-disable react-hooks/exhaustive-deps */
import styles from "./Careers.module.scss";
import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import {
    fetchCareers,
    fetchMoreJobs,
    clearJobs,
} from "../../actions/careersAction";
import Container from "react-bootstrap/Container";
import { UseCareers } from "../../logic/Careers/useCareers";
import CareerSection from "../CareerSections";
import CurrentOpenings from "../CurrentOpenings";
import { useSeasonal } from "../../logic/Seasonal";
import { useDimensions } from "../../logic/Dimentions";
import SocialMediaWrap from "../SocailMediaIcons";

const CareersPage = ({
    fetchCareers,
    careers,
    moreJobs,
    fetchMoreJobs,
    clearJobs,
}) => {
    UseCareers({ fetchCareers, careers });
    const { width } = useDimensions();
    const { classes } = useSeasonal();

    let loadPage = 1;
    const loadMoreJobs = useCallback(() => {
        loadPage++;
        if (careers.jobs) {
            fetchMoreJobs(loadPage, careers.jobs.career);
        }
    }, [careers]);

    useEffect(() => {
        return () => {
            clearJobs();
        };
    }, []);

    return (
        <>
            <div className={`${styles.headingAlign} mob-app-pad-0 mob-app-mar-0`}>
                {width >= 992 && <SocialMediaWrap classes={classes} />}
                <div className={styles.career_banner}>
                    <img src={careers.banner} alt="" />
                </div>
                <Container>
                    <h1 className={classes?.seasonalClass}>Careers</h1>
                    <h3 className={styles.career_intro}>{careers.heading}</h3>
                    <p className={styles.career_des}>{careers.description}</p>
                </Container>
            </div>
            <CareerSection data={careers.sections} />
            <div className={styles.currentOpenings}>
                <div className="container">
                    <h1 className={classes?.seasonalClass}>Current Openings</h1>
                </div>
                {moreJobs.length > 0 ? (
                    <CurrentOpenings data={moreJobs} loadMoreJobs={loadMoreJobs} />
                ) : (
                    <CurrentOpenings
                        data={careers.jobs ? careers.jobs.career : null}
                        loadMoreJobs={loadMoreJobs}
                    />
                )}
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return { careers: state.careers.careers, moreJobs: state.careers.moreJobs };
};

export default connect(mapStateToProps, {
    fetchCareers,
    fetchMoreJobs,
    clearJobs,
})(CareersPage);
