

import React, { useState } from 'react'
import styles from "./PodcastShareModal.module.scss";
import { Modal, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { RiFacebookFill, RiTwitterFill, RiLinkedinFill, RiWhatsappFill } from "react-icons/ri";
import {
  FacebookShareButton, TwitterShareButton, WhatsappShareButton, LinkedinShareButton
} from 'react-share'
import Alert from 'react-bootstrap/Alert'
import { useSeasonal } from "../../logic/Seasonal";

const PodcastShareModal = (props) => {

  const [show, setShow] = useState(true);
  const { classes } = useSeasonal();

  const copyTextToClipboard = () => {
    var copyText = props.copyLinkText;

    // navigator.clipboard.writeText(copyText).then(() => {
    //   setShow(true)
    //   setTimeout(() => {
    //     setShow(false)
    //   }, 3000);
    //   props.handleSetMessage("Copied to clipboard");
    // });

    const el = document.createElement('textarea');
    el.value = copyText;
    el.style.opacity = 0;

    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    props.handleSetMessage("Copied to Clipboard");

    setShow(true)
    setTimeout(() => {
      setShow(false)
    }, 3000);


  };

  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`${styles.PodcastShareModal} podcast-modal`}
        {...props}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Share Podcast
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="audio-thumb">
            <figure >
              <img src={props?.episode?.thumbnail?.small} alt={props?.episode?.title} />
            </figure>
            <div className="audio-dtls">
              <strong>{props?.episode?.title}</strong>

              <p>
                {props?.episode?.description && props?.episode?.description
                  .replace(/(<([^>]+)>)/gi, "")
                  .substring(0, 300)}
              </p>

            </div>
          </div>
          <div className="share-via">
            <h5>Share Via</h5>
            <ul>
              <li>
                <Link>
                  <FacebookShareButton url={`${props.copyLinkText}`}
                  >
                    <i>
                      <RiFacebookFill />
                    </i>
                    <span>Facebook</span>
                  </FacebookShareButton>
                </Link>
              </li>

              <li>
                <Link>
                  <TwitterShareButton url={`${props.copyLinkText}`}
                  >
                    <i>
                      <RiTwitterFill />
                    </i>
                    <span>Twitter</span>
                  </TwitterShareButton>
                </Link>
              </li>

              <li>
                <Link>
                  <LinkedinShareButton url={`${props.copyLinkText}`}
                  >
                    <i>
                      <RiLinkedinFill />
                    </i>
                    <span>Linkedin</span>
                  </LinkedinShareButton>
                </Link>
              </li>

              <li>
                <Link>
                  <WhatsappShareButton url={`${props.copyLinkText}`}
                  >
                    <i>
                      <RiWhatsappFill />
                    </i>
                    <span>Whatsapp</span>
                  </WhatsappShareButton>
                </Link>
              </li>

            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-wrap">

            <Button className={`w-100 ${classes.bgBtnClass}`} onClick={(e) => {
              copyTextToClipboard(e);
            }}>Copy the Link</Button>
          </div>


          {show && props?.message?.length >= 1 &&

            <Alert variant="success" onClose={() => setShow(false)} dismissible>

              {props?.message}
            </Alert>
          }








        </Modal.Footer>
      </Modal>
    </>

  );
};



export default PodcastShareModal;
