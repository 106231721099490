import React from "react";
import DonationLayout from "../components/Layouts/DonationLayout";
import { useHome } from "../logic/Home/useHome";
import Donation from "../components/Donation";
import { connect } from "react-redux";
import { fetchOtherdetails } from "../actions/otherdetailsAction";
import { TitleComponent } from "../components/TitleComponent";
import { useSeo } from "../logic/Seo/useSeo";
import SocialMediaWrap from "../components/SocailMediaIcons";
import { useDimensions } from "../logic/Dimentions";
import { useSeasonal } from '../logic/Seasonal'

const DonationPage = ({ fetchOtherdetails, otherdetails }) => {
  useHome({ fetchOtherdetails, otherdetails });
  useSeo();
  const { width } = useDimensions();
  const { classes } = useSeasonal()

  return (
    <DonationLayout>
      <TitleComponent title="Shalom world " />
      {width >= 992 && <SocialMediaWrap classes={classes} />}
      <Donation />
    </DonationLayout>
  );
};
const mapStateToProps = (state) => {
  return { otherdetails: state.otherdetails.data };
};
export default connect(mapStateToProps, { fetchOtherdetails })(DonationPage);
