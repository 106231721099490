import React, { lazy } from "react";
import "./CommonStyle.scss";
import { useDimensions } from "../../logic/Dimentions";

const Menu = lazy(() => import("../Menu"));
// const Donate = lazy(() => import("../Donate"));
// const OtherSection = lazy(() => import("../OtherSection"));
const Donate = lazy(() => import("../FooterDonate"));
const ShalomTvApp = lazy(() => import("../ShalomTvApp"));
const Subscription = lazy(() => import("../Subscription"));
const Footer = lazy(() => import("../Footer"));
const MobileMenu = lazy(() => import("../MobileMenu"));
const BottomTab = lazy(() => import("../BottomTab"));

const CommonLayout = ({ children }) => {
    const { width } = useDimensions();

    return (
        <>
            {width >= 1025 ? <Menu /> : <MobileMenu />}
            <div>{children}</div>
            {width >= 1025 ? (
                <>
                    <ShalomTvApp />
                    {/* <OtherSection /> */}
                    <Donate />
                    <Subscription />
                    <Footer />
                </>
            ) : (
                <BottomTab />
            )}
        </>
    );
};

export default CommonLayout;
