import React from "react";
import { Link } from "react-router-dom";
import styles from "./PodcastMobileThumb.module.scss";
import { useSeasonal } from '../../../logic/Seasonal'

const PodcastMobileThumb = (
    { handlePlay,
        episode,
        handleUpdatePlaylist,
        activeId,
        progress,
        play,
        podCastPlayListData,
        handleClickUrl }
) => {

    const playToggleClass = (id) => {
        if (id === activeId) {
            handlePlay(!play);
            // setPlay(!play);
        } else {
            //Append eps to play list
            handleUpdatePlaylist(id);
        }
    };

    const handleClick = (e) => {
        e.preventDefault();
        return;
    };

    const {  classes } = useSeasonal();

    return (
        <div className={`${styles.PodcastEpisodeThumb} ${styles.podcastActiveThumb}`}>         {/* Add "podcastActiveThumb" for playing podcast */}

            <Link onClick={(e) => {
                handleClick(e);
                playToggleClass(episode.id);
            }}>
                <div className={styles.episodeWrap}>
                    <figure className="figImg">
                        <img src={episode?.thumbnail?.small} alt={episode?.title} />
                    </figure>
                    <div className={styles.episodeThumbContent}>
                        <div className={styles.nameWrapper}>
                            {play && activeId === episode?.id &&
                                <div className={`sound_bars ${classes.soundbarColor}`}>
                                    <span className="bar"></span>
                                    <span className="bar"></span>
                                    <span className="bar"></span>
                                    <span className="bar"></span>
                                </div>
                            }

                            <p className={styles.episodename}>{episode?.title}</p>
                        </div>
                        {/* <ul className={styles.episodtls}>
                            <li className={styles.author}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="13.264" height="14.182" viewBox="0 0 13.264 15.182"><g transform="translate(0.7 0.7)"><circle cx="3" cy="3" r="3" transform="translate(2.932)" fill="none" stroke="#B2B2B2" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4" /><path d="M-137.008,438.727h0a4.883,4.883,0,0,1,4.884-4.884h2.1a4.884,4.884,0,0,1,4.884,4.884h0" transform="translate(137.008 -424.945)" fill="none" stroke="#e0e0e0" strokeLinecap="square" strokeLinejoin="round" strokeWidth="1.4" /></g></svg>
                                Joshua Angrisand
                            </li>
                            <li className={styles.number}>Episode 236</li>
                        </ul> */}
                    </div>


                </div>
            </Link>

        </div>
    );
};

export default PodcastMobileThumb;
