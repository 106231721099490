/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDimensions } from "../../logic/Dimentions";

export const usePodCastEpisode = (props) => {
    const { fetchPodcastEpisode } = props;
    let { programId, episodeId } = useParams();
    const location = useLocation();
    const { width } = useDimensions();
    let history = useHistory();

    useEffect(() => {
        if (width >= 992) {
            fetchPodcastEpisode({ slug: episodeId });
        } else {
            history.push(`/podcast/${programId}#play`);
        }
    }, [location]);
};
