import React from "react";
import CommonLayout from "../components/Layouts/CommonLayout";
import { TitleComponent } from '../components/TitleComponent'
import PodcastEpisodeDetail from "../components/PodcastEpisodeDetail";
import SocialMediaWrap from '../components/SocailMediaIcons';
import { useDimensions } from '../logic/Dimentions';
import { useSeasonal } from '../logic/Seasonal'

const PodcastEpisodeDetailPage = () => {
    const { width } = useDimensions();
    const { classes } = useSeasonal()
    return (
        <CommonLayout>
            {width >= 992 && <SocialMediaWrap classes={classes} />}
            <TitleComponent title="Shalom world " />
            <PodcastEpisodeDetail />
        </CommonLayout>
    );
};

export default PodcastEpisodeDetailPage;
