import styles from './Feedback.module.scss'
import React, { useEffect, useState, useCallback, useRef } from 'react'
import {
  fetchCountryList,
  fetchStateFromCountry,
} from '../../actions/swprayerAction'
import { connect } from 'react-redux'
import { useFormik } from 'formik'
import { fetchContactus } from '../../actions/contactusAction'
import Container from 'react-bootstrap/Container'
import { UseContact } from '../../logic/ContactUs/useContact'
import classNames from 'classnames'
import Row from 'react-bootstrap/Row'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import GoogleRecaptcha from '../../components/Recaptcha'
import ContactAddress from '../ContactAddress'
import salomapi from '../../apis'
import { useSeasonal } from '../../logic/Seasonal'
import { useHistory } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'

// function onChange(value) {
//   }
const useStyles = makeStyles((theme) => ({
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      paddingLeft: 26,
      color: 'black',
    },
  },
}))
const Feedback = ({
  fetchContactus,
  contactUs,
  fetchCountryList,
  fetchStateFromCountry,
  countryList,
  stateList,
}) => {
  const [countryName, setCountryName] = useState('')
  const [countryInputName, setCountryInputName] = useState('')
  const [state, setState] = useState('')
  const [isFormSuccess, setFormSuccess] = useState(false)
  const [captchaReset, setCaptchaReset] = useState(false)
  const [loading, setLoading] = useState(false)
  const [captchaValid, isCaptchaValid] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [validUser, isValidUser] = useState(false)
  const seasonal = useSeasonal()
  let history = useHistory()

  UseContact({ fetchContactus, contactUs })
  useEffect(() => {
    if (countryList.length === 0) fetchCountryList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const inputClass = classNames(styles.input_fields)
  const divClass = classNames(styles.div_fields, 'col-sm-6')
  const classes = useStyles()
  const captcha = useRef(null)
  const validate = (values) => {
    const errors = {}
    if (!values.firstName) {
      errors.firstName = 'Required'
    } else if (values.firstName.length > 15) {
      errors.firstName = 'Must be 15 characters or less'
    }

    if (!values.lastName) {
      errors.lastName = 'Required'
    } else if (values.lastName.length > 20) {
      errors.lastName = 'Must be 20 characters or less'
    }

    if (!values.email) {
      errors.email = 'Required'
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = 'Invalid email address'
    }
    if (countryName === '') {
      errors.country = 'Required'
    }
    if (!values.description) {
      errors.metitionDesc = 'Required'
    }
    if (!values.city) {
      errors.metitionDesc = 'Required'
    }

    if (!captchaValid) {
      errors.captchaValid = 'Please valid the Captcha'
    }
    return errors
  }
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      city: '',
      description: '',
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      setLoading(true)
      let programId = ''
      let episodeId = ''
      if (history.location.state?.program) {
        programId = history.location.state.program
      }
      if (history.location.state?.episode) {
        episodeId = history.location.state.episode
      }
      const postData = {
        firstname: values.firstName,
        lastname: values.lastName,
        email: values.email,
        city: values.city,
        state: state,
        country: countryName,
        message: values.description,
        publish: 0,
        type: 'feedback',
        program: programId,
        episode: episodeId,
      }

      if (captchaValid) {
        salomapi.post('/send/testimony', postData).then((response) => {
          if (response.data.status === 'success') setFormSuccess(true)
          resetForm({})
          setCaptchaReset(true)
          isCaptchaValid(false)
          setState('')
          setCountryInputName('')
          setLoading(false)
          setTimeout(() => {
            setFormSuccess(false)
            setCaptchaReset(false)
          }, 4000)
        })
      } else {
        setLoading(false)
      }
      //recaptcha
      if (captcha.current.getValue()) {
        isValidUser(true)
        isCaptchaValid(true)
      } else {
        isValidUser(false)
        isCaptchaValid(false)
      }
      //alert(JSON.stringify(values, null, 2));
    },
  })
  const CountryChange = useCallback((event, newValue) => {
    if (newValue) {
      fetchStateFromCountry(newValue.id)
      setCountryName(newValue.country_name)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const CountryInputChange = useCallback((event, newInputValue) => {
    setCountryInputName(newInputValue)
  }, [])
  const StateChange = (event, newValue) => {
    setState(newValue)
    // setValue(newValue);
  }

  const handleOnChange = () => {
    isCaptchaValid(true)
  }
  return (
    <>
      {/* <div style={{background:`url("https://www.shalomworld.org/static/media/feedback-banner.9a087131.png")`}} className={styles.image_background}>
        </div> */}
      <div
        className="feedback-wrp"
        style={{
          background: `url("https://www.shalomworld.org/static/media/feedback-banner.9a087131.png")`,
        }}
      >
        <div className={`${styles.headingAlign} mob-app-pad-0 mob-app-mar-0`}>
          <Container>
            <h1 className={seasonal?.classes?.seasonalClass}>
              Give your feedback
            </h1>

            <form className={styles.form_align} onSubmit={formik.handleSubmit}>
              <Row className="col-lg-12">
                <div className={divClass}>
                  <input
                    className={inputClass}
                    onChange={formik.handleChange}
                    value={formik.values.firstName}
                    name="firstName"
                    type="text"
                    placeholder="First Name"
                  ></input>
                </div>
                <div className={divClass}>
                  <input
                    className={inputClass}
                    onChange={formik.handleChange}
                    value={formik.values.lastName}
                    name="lastName"
                    type="text"
                    placeholder="Last Name"
                  ></input>
                </div>
              </Row>
              <Row className="col-lg-12">
                <div className={divClass}>
                  <input
                    className={inputClass}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    name="email"
                    placeholder="Email"
                  ></input>
                </div>
                <div className={divClass}>
                  <Autocomplete
                    id="combo-box-demo"
                    classes={classes}
                    options={countryList}
                    getOptionLabel={(option) => option.country_name}
                    renderOption={(option) => (
                      <div style={{ color: 'white' }}>
                        {option.country_name}
                      </div>
                    )}
                    style={{ width: 404 }}
                    inputValue={countryInputName}
                    onInputChange={CountryInputChange}
                    // value={countryName}
                    onChange={CountryChange}
                    renderInput={(params) => (
                      <TextField
                        className={inputClass}
                        {...params}
                        placeholder="Select Country"
                        name="country"
                        variant="outlined"
                      />
                    )}
                  />
                  {/* <input className={inputClass} type="text" name="SelectCountry" placeholder="Select Country"></input> */}
                </div>
              </Row>
              <Row className="col-lg-12">
                <div className={divClass}>
                  <Autocomplete
                    id="combo-box-demo"
                    classes={classes}
                    options={stateList.length > 0 ? stateList : countryList}
                    getOptionLabel={(option) =>
                      stateList.length > 0 ? option.name : option.country_name
                    }
                    renderOption={(option) => (
                      <div style={{ color: 'white' }}>
                        {stateList.length > 0
                          ? option.name
                          : option.country_name}
                      </div>
                    )}
                    style={{ width: 404 }}
                    inputValue={state}
                    onInputChange={StateChange}
                    renderInput={(params) => (
                      <TextField
                        className={inputClass}
                        {...params}
                        placeholder="Select State"
                        variant="outlined"
                      />
                    )}
                  />
                  {/* <input className={inputClass} type="text" name="SelectState" placeholder="Select State"></input> */}
                </div>
                <div className={divClass}>
                  <input
                    className={inputClass}
                    onChange={formik.handleChange}
                    value={formik.values.city}
                    name="city"
                    type="text"
                    placeholder="City"
                  ></input>
                </div>
              </Row>
              <Row className={`col-lg-12 ${styles.div_fields}`}>
                <textarea
                  className={styles.textClass}
                  onChange={formik.handleChange}
                  name="description"
                  value={formik.values.description}
                  placeholder="Your Feedback ( max-limit : 300 Charachters )"
                ></textarea>
              </Row>
              <Row className={`col-lg-12 ${styles.div_fields}`}>
                <div className={divClass}>
                  <GoogleRecaptcha
                    onChange={handleOnChange}
                    isCaptchaReset={captchaReset}
                  />
                </div>
              </Row>
              {Object.keys(formik.errors).length != 0 && formik.submitCount ? (
                <div className="form-group col-sm-12">
                  <span className={styles.formtotNullfieldErr}>
                    Please fill all required fields.
                  </span>
                </div>
              ) : null}
              {isFormSuccess ? (
                <div className="form-group col-sm-12">
                  <span className={styles.resonseSuccessMsg}>
                    Your feedback send successfully!!!
                  </span>
                </div>
              ) : null}

              {/* { !formik.errors.captchaValid &&  Object.keys(formik.errors).length!=0 && formik.submitCount   && <p style={{color: 'red'}}>Please accept the validation </p>} */}
              <Row className={`col-lg-12 ${styles.div_fields}`}>
                <button
                  className={`${styles.web_btn} ${seasonal?.classes?.backgroundColr} ${seasonal?.classes?.borderOnly} ${styles.uploading}`}
                  type="submit"
                  disabled={!isValidUser}
                >
                  {loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : null}
                  SUBMIT
                </button>
              </Row>
            </form>
          </Container>
        </div>
        <div className={styles.contact_us_wrap}>
          <h2
            className={`${styles.is_title} ${seasonal?.classes?.seasonalClass}`}
          >
            Get in touch with us
          </h2>
          <ContactAddress data={contactUs.address ? contactUs.address : null} />
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    contactUs: state.contactUs,
    countryList: state.swprayer.countryList,
    stateList: state.swprayer.stateList,
  }
}

export default connect(mapStateToProps, {
  fetchContactus,
  fetchCountryList,
  fetchStateFromCountry,
})(Feedback)
