import { FETCH_DONATION } from "./types";
import salomapi from "../apis";

export const fetchDonation = () => async (dispatch) => {
  let time_now = new Date().getTime()

  const response = await salomapi.get("/causedonation?time="+time_now);

  dispatch({ type: FETCH_DONATION, payload: response.data });
};
