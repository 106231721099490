/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import styles from './ConnectedTVTab.module.scss'
import { Row } from 'react-bootstrap'
import Col from 'react-bootstrap/Col'
import ReactPlayer from 'react-player'
import { useDimensions } from '../../logic/Dimentions'
import { useParams } from 'react-router-dom'
import { useSeasonal } from '../../logic/Seasonal'

const ConnectedTvTab = ({ tabContent, tabType, activeIndex, setActiveIndex, activeName, setActiveName }) => {

  const pageRef = useRef([]);
  const [isDescTab, setDescTab] = useState(false)
  const [isVideo, setVideo] = useState(false)
  const [tabContents, setTabContents] = useState(0)


  const [itemDescData, setItemDescData] = useState({})
  const { width } = useDimensions()
  const { classes } = useSeasonal()
  let { platform } = useParams()

  useEffect(() => {
    let index = 0
    let item = tabContent && tabContent[0]
    if (platform) {
      index = tabContent && tabContent.findIndex((x) => x.name === platform)
      item = tabContent && tabContent.find((x) => x.name === platform)
    }

    setItemDescData(item)
    setActiveIndex(index)
    setDescTab(true)
  }, [platform])

  useEffect(() => {
    if (tabContent && tabContent.length > 0) {
      let addNo = Math.ceil(tabContent?.length / 4) * 4 - tabContent?.length;
      if (addNo !== 0) {
        let filledArray = Array.from({ length: addNo }, () => ({
          "name": "",
          "titleImage": "",
          "contentImage": "",
          "contentTitle": "",
          "description": "",
          "buttonImage": "",
          "buttonUrl": "",
          "contentVideo": ""
        }))
        setTabContents([...tabContent, ...filledArray])

      }
    }
    else {
      setTabContents(tabContent)
    }
  }, [tabContent])

  var blnVideo = 'block'
  function handleGetStarted() {
    setVideo(!isVideo)

    if (isVideo) {
      setActiveName('')
    } else {
      setActiveName('')
    }
  }
  const descSection = (item, index) => (event) => {
    event.preventDefault();
    setActiveIndex(index)
    setDescTab(true)
    setItemDescData(item)
    setActiveName(item.name)

    setTimeout(() => {
      var element = document.getElementById(`tab-li-${item.name}`);
      var headerOffset = -750;
      var elementPosition = element.offsetTop;
      var offsetPosition = elementPosition - headerOffset;
      document.documentElement.scrollTop = offsetPosition;
      document.body.scrollTop = offsetPosition; // For Safari
    }, 5);

  }

  const closeDescSection = () => {
    setDescTab(false)
  }



  const TabDetails = (index) => {
    return isDescTab && itemDescData ? (
      <div
        className={width >= 992 ? styles.hidden_layer : styles.mobileTabDes}
        style={{ display: 'block' }}
      >

        <div className={styles.hidden_content}>

          {!isVideo ? (
            <div className={styles.hidden_image}>
              <img
                alt=""
                src={
                  itemDescData?.contentImage && itemDescData?.contentImage?.url
                    ? itemDescData?.contentImage?.url
                    : itemDescData?.contentImage
                }
              ></img>
            </div>
          ) : !itemDescData.contentVideo == '' ? (
            <ReactPlayer
              url={itemDescData.contentVideo}
              className={styles.player}
              controls={true}
              style={{ display: !isVideo ? 'none' : 'block' }}
            />
          ) : (
            <p className={styles.no_preview}> No preview available</p>
          )}

          <div className={styles.text_wrap}>

            <h3>{itemDescData.contentTitle}</h3>
            <p>{itemDescData.description}</p>
            {!isVideo ? (
              <a
                href={() => false}
                className={`btn - outline - primary btn - lg ${classes?.fullSchduleBtn}  ${classes?.textDecororationNone}`}
                onClick={handleGetStarted}
              >
                Get Started
              </a>
            ) : (
              <a
                href={() => false}
                className={`${styles.web_btn_back} ${classes?.fullSchduleBtn}`}
                onClick={handleGetStarted}
                style={{ display: blnVideo }}
              >
                BACK
              </a>
            )}
            {width >= 992 ? (
              <span className={`${styles.close_btn} ${classes?.txtColor}`} onClick={closeDescSection}>
                ×
              </span>
            ) : null}
          </div>

        </div>
      </div>
    ) : null
  }

  const TabDetailsMobile = (item) => {
    return isDescTab ? (
      <>{item && item.titleImage && <div className={styles.mobileTabDes} style={{ display: 'block' }}>
        <div className={styles.hidden_content}>
          {!isVideo ? (
            <div className={styles.hidden_image}>
              <img
                alt=""
                src={
                  item.contentImage && item.contentImage.url
                    ? item.contentImage.url
                    : item.contentImage
                }
              ></img>
            </div>
          ) : (
            <div className="reactplayerWrap">
              {!item.contentVideo == '' ? (
                <ReactPlayer
                  url={item.contentVideo}
                  className={styles.player}
                  controls={true}
                  style={{ display: !isVideo ? 'none' : 'block' }}
                />
              ) : (
                <p
                  className={`${styles.no_preview} ${classes?.backgroundColr} ${classes?.borderOnly}`}
                >
                  {' '}
                  No preview available
                </p>
              )}
            </div>
          )}
          <div className={styles.text_wrap}>
            <h3>{item.contentTitle}</h3>
            <p>{item.description}</p>
            {!isVideo ? (
              <a
                href={() => false}
                className={`btn - outline - primary btn - lg ${classes?.fullSchduleBtn}`}
                onClick={handleGetStarted}
              >
                Get Started
              </a>
            ) : (
              <a
                href={() => false}
                className={`${styles.web_btn_back} ${classes?.fullSchduleBtn}`}
                onClick={handleGetStarted}
                style={{ display: blnVideo }}
              >
                BACK
              </a>
            )}
            {width >= 992 ? (
              <span className={`${styles.close_btn} ${classes?.txtColor}`} onClick={closeDescSection}>
                ×
              </span>
            ) : null}
          </div>
        </div>
      </div>}</>

    ) : null
  }

  return (
    <>
      <Row className={styles.outer_layer}>
        {tabContents && tabContents.length > 0
          ? tabContents.map((item, index) => {
            return (
              <>
                {width >= 992 ? (
                  <Col
                    key={item.contentImage.ID}
                    md={3}
                    className={`${styles.box_wrap} ${activeIndex === index &&
                      `${styles.active} ${classes?.seasonalClass2}`
                      } ${width >= 992 ? styles.colorChange : styles.mobile
                      } 
                      ${!item.titleImage ? "d-none" : null}
`}
                    id={`tab-li-${item.name}`}
                    ref={ref => pageRef.current.push(ref)}
                    onClick={descSection(item, index)}
                  >
                    <img src={item.titleImage} alt={index} />
                  </Col>
                ) : null}

                {width >= 992 ? (
                  <>
                    {width >= 992 && (index + 1) % 4 === 0 && activeIndex !== -1 ? (
                      <>
                        {(width >= 992 &&
                          parseInt(index / 4) === parseInt(activeIndex / 4)) ||
                          (width < 992 && index === activeIndex)
                          ? TabDetails(index)
                          : null}
                      </>
                    ) : null}
                  </>
                ) : (
                  TabDetailsMobile(item, index)
                )}
              </>
            )
          })
          : null}
      </Row>
    </>
  )
}

export default ConnectedTvTab
