import {FETCH_BLOG_CATEGORIES,FETCH_BLOG_LIST,FETCH_NEWS_DESCRIPTION,UPDATE_BLOG_LIST,REMOVE_NEWS_DESCRIPTION} from '../actions/types.js';

const INTIAL_STATE = {
  blogCategories :[],
  blogList :[]
};

export default (state = INTIAL_STATE,action) => {
    switch(action.type){
        case FETCH_BLOG_CATEGORIES:
            return {
                ...state,
                blogCategories :action.payload
            }
        case FETCH_BLOG_LIST:
            return {
                ...state,
                blogList :action.payload.blogs,
                blogTotalPages:action.payload.pages
            }
        case UPDATE_BLOG_LIST:
            return{
                ...state,
                blogList :state.blogList.concat(action.payload.blogs),
                blogTotalPages:action.payload.pages
            }
            case FETCH_NEWS_DESCRIPTION:
                return {
                    ...state,
                    newsDescription :action.payload
                }
            case REMOVE_NEWS_DESCRIPTION:
                return {...state,newsDescription:{}}
        default:
            return state;
    }
}