import { FETCH_SEASONAL } from './types';
import salomapi from '../apis';
import BrowserPersistence from '../util/simplePersistance';

const storage = new BrowserPersistence();
export const fetchSeasonal = () => async dispatch => {

    async function fetchFromServer() {
        try {
            const response = await salomapi.get('/seasonal');
            let default_values = {
                color: "#ffb600",
                isActive: true,
            };
            if (response?.data?.status !== 'error') {
                default_values = response.data;
            }
            await saveSeasonal(default_values);
            dispatch({ type: FETCH_SEASONAL, payload: default_values })
        } catch (error) {

            dispatch({
                type: FETCH_SEASONAL, payload: {
                    color: "#ffb600",
                    isActive: true,
                }
            });
            throw error;
        }
    }

    const localSeasonalData = await retrieveSeasonal();
    if (localSeasonalData) {
        dispatch({ type: FETCH_SEASONAL, payload: localSeasonalData })
    }
    else {
        await fetchFromServer();
    }

    async function retrieveSeasonal() {
        return storage.getItem('seasonList');
    }

    async function saveSeasonal(season) {
        return storage.setItem('seasonList', season, 172800);
    }
}
