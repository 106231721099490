import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { useLocation, useHistory } from "react-router-dom";
import { IoChevronBack } from "react-icons/io5";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import ReactPlayer from "react-player/lazy";
import styles from "./AllEventRelatedVideos.module.scss";
import { FreeMode, Navigation, Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/free-mode/free-mode.min.css";
import "swiper/modules/thumbs/thumbs.min.css";
import { useSeasonal } from "../../logic/Seasonal";
import { useSeo } from "../../logic/Seo/useSeo";
import { useEventRelatedVideos } from "../../logic/EventRelatedVideos/useEventRelatedVideos";
import { fetchEventRelatedVideos } from "../../actions/eventDetailsAction";
import EventVideoShare from "../EventVideoShare";

/**
 *  This component renders All-EventRelatedVideos
 */
const AllEventRelatedVideos = ({
  fetchEventRelatedVideos,
  relatedVideos,
  eventDetails,
}) => {
  const relatedRef = useRef();

  const swiperRefTwo = useRef();
  const swiperRefThree = useRef();

  const history = useHistory();
  const location = useLocation();
  const { classes } = useSeasonal();
  useSeo();
  useEventRelatedVideos({
    fetchEventRelatedVideos,
    relatedVideos,
  });
  const [contentType, setContentType] = useState("eventbanner");
  const [activeIndex, setActiveIndex] = useState(null);
  const [autoPlay, setAutoPlay] = useState(false);
  const [play, setPlay] = useState(false);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [modalActiveIndex, setModalActiveIndex] = useState(0);
  const [isLoading, setisLoading] = useState(0);

  const handlePlay = (index) => {
    setContentType("relatedvideo");
    setActiveIndex(index);
    setPlay(true);
    setAutoPlay(false);
  };

  const handleClose = (key) => {
    setContentType("eventbanner");
    setActiveIndex(0);
    setPlay(false);
    setModalActiveIndex(0);
    setThumbsSwiper(0)
    removeHash();   
  };

  useEffect(() => {
    // Extract the targetDiv identifier from the URL
    setTimeout(() => {
      const searchParams = new URLSearchParams(location.search);
      // Scroll to the targetDiv whenever the location changes
      if (
        relatedRef.current &&
        searchParams.get("related-videos") &&
        eventDetails?.relatedVideos?.videos?.length >= 1
      ) {
        const id = searchParams.get("related-videos");
        // eslint-disable-next-line eqeqeq
        const index = eventDetails?.relatedVideos?.videos.findIndex(
          (item) => item.slug == id
        );
        if (index >= 0) {
          setContentType("relatedvideo");
          setActiveIndex(index);
          setPlay(true);
          setAutoPlay(true);
          swiperRefTwo?.current?.swiper?.slideTo(index); // Go to the specified slide index
          swiperRefThree?.current?.swiper?.slideTo(index); // Go to the specified slide index
        }
        relatedRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    }, 200);
  }, [location, eventDetails?.relatedVideos]);

  const removeHash = () => {
    window.history.pushState("", document.title, window.location.pathname);
  };

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <>
      <div className={styles.event_carousel_wrap} ref={relatedRef}>
        <Container id="Section-past-vidoes">
          <div className="breadcrumb_back_wrap">
            {/* Go Back btn */}
            {eventDetails?.relatedVideos?.sectionTitle &&
              location?.state?.from === `/events/${eventDetails?.slug}` && (
                <a
                  href={() => false}
                  title="Back"
                  className={`${styles.btn_back} `}
                >
                  <IoChevronBack
                    onClick={handleGoBack}
                    className={`btn_back_icon`}
                  ></IoChevronBack>
                </a>
              )}

            {/* Breadcrumb */}
            {eventDetails?.title && (
              <nav aria-label="breadcrumb" className="container breadcrumb-nav">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={`/events/${eventDetails?.slug}`}>
                      {eventDetails?.title}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {eventDetails?.relatedVideos?.sectionTitle}
                  </li>
                </ol>
              </nav>
            )}
          </div>

          <div className={`${styles.event_carousel} carousel-slider`}>
            {eventDetails?.relatedVideos?.sectionTitle && (
              <h2 className={`is_title left ${classes?.seasonalClass}`}>
                {" "}
                {eventDetails?.relatedVideos?.sectionTitle}
              </h2>
            )}
            <Row>
              {eventDetails?.relatedVideos?.videos?.length > 0 &&
                eventDetails?.relatedVideos?.videos.map((dt, key) => {
                  return (
                    <Col md={6} lg={4} className="col_grid">
                      <div className="figImg v_thumb">
                        <img
                          src={dt?.thumbnail}
                          alt=""
                          onClick={() => {
                            setisLoading(true);
                            const timer = setTimeout(() => {
                              setisLoading(false);
                              handlePlay(key);
                              swiperRefTwo?.current?.swiper?.slideTo(key); // Go to the specified slide index
                              swiperRefThree?.current?.swiper?.slideTo(key); // Go to the specified slide index
                            }, 10);
                            return () => clearTimeout(timer);
                          }}
                        />
                        <span
                          onClick={() => {
                            setisLoading(true);
                            const timer = setTimeout(() => {
                              setisLoading(false);
                              handlePlay(key);
                              swiperRefTwo?.current?.swiper?.slideTo(key); // Go to the specified slide index
                              swiperRefThree?.current?.swiper?.slideTo(key); // Go to the specified slide index
                            }, 10);
                            return () => clearTimeout(timer);
                          }}
                          className={`icon-play_video ${classes?.playClass} ${classes?.seasonalAfterColorClass} `}
                        ></span>
                        <span className="v_overlay" onClick={() => {
                                setisLoading(true);
                                const timer = setTimeout(() => {
                                  setisLoading(false);
                                  handlePlay(key);
                                  swiperRefTwo?.current?.swiper?.slideTo(key); // Go to the specified slide index
                                  swiperRefThree?.current?.swiper?.slideTo(key); // Go to the specified slide index
                                }, 10);
                                return () => clearTimeout(timer);
                              }}></span>

                        <EventVideoShare
                          type={"related-videos"}
                          slug={dt?.slug}
                        />
                      </div>
                      <div
                        className={styles.event_dts}
                        onClick={() => {
                          setisLoading(true);
                          const timer = setTimeout(() => {
                            setisLoading(false);
                            handlePlay(key);
                            swiperRefTwo?.current?.swiper?.slideTo(key); // Go to the specified slide index
                            swiperRefThree?.current?.swiper?.slideTo(key); // Go to the specified slide index
                          }, 10);
                          return () => clearTimeout(timer);
                        }}
                      >
                        <h4 className={styles.title}>{dt?.title}</h4>
                        <div className={styles.info}>{dt?.description}</div>
                        <p className={styles.sub_info}>{dt?.date}</p>
                      </div>
                    </Col>
                  );
                })}
            </Row>
          </div>
        </Container>

        <Modal
          className={`video_modal ${styles.videoModal}`}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={play && contentType === "relatedvideo" ? true : false}
          onHide={() => {
            handleClose();
          }}
        >
          <Modal.Body>
            <span
              className={`modal-close ${classes?.videoModalClose}`}
              onClick={() => {
                handleClose();
              }}
            >
              &#x2715;
            </span>

            <div className={`${styles.videoSlider}`}>
              <EventVideoShare
                type={"related-videos"}
                slug={eventDetails?.relatedVideos?.videos?.[activeIndex]?.slug}
                elemType={"modal"}
              />
              <Swiper
                ref={swiperRefTwo}
                style={{
                  "--swiper-navigation-color": "#fff",
                  "--swiper-pagination-color": "#fff",
                }}
                loop={false}
                spaceBetween={10}
                navigation={true}
                initialSlide={activeIndex}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs]}
                className={styles.videoMainSlider}
                onSlideChange={(swiper) => {
                  setisLoading(true);
                  const timer = setTimeout(() => {
                    if (swiperRefThree?.current) {
                      swiperRefThree?.current?.swiper?.slideTo(
                        swiper.activeIndex
                      ); // Go to the specified slide index
                    }
                    setisLoading(false);
                    handlePlay(swiper.activeIndex);
                    setModalActiveIndex(swiper.activeIndex);
                  }, 100);
                  return () => clearTimeout(timer);
                }}
              >
                {eventDetails?.relatedVideos?.videos?.length > 0 &&
                  eventDetails?.relatedVideos?.videos.map((dt, key) => {
                    return (
                      <SwiperSlide>
                        <div
                          className={`${styles.video_wrapper} past_video video_wrap`}
                        >
                          {key === activeIndex && (
                            <ReactPlayer
                              url={
                                eventDetails?.relatedVideos?.videos?.[
                                  activeIndex
                                ]?.videoLink
                              }
                              controls={true}
                              className="react-player"
                              width="100%"
                              height="100%"
                              pip={false}
                              config={{
                                file: {
                                  attributes: {
                                    controlsList: "nodownload noplaybackrate",
                                    disablePictureInPicture: true,
                                    onContextMenu: (e) => e.preventDefault(),
                                  },
                                },
                              }}
                              playing={
                                play &&
                                activeIndex === key &&
                                modalActiveIndex === key
                              }
                              muted={autoPlay ? true : false} // Play the video muted
                            />
                          )}
                        </div>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>

              {eventDetails?.relatedVideos?.videos?.length > 1 && (
                <Swiper
                  ref={swiperRefThree}
                  onSwiper={setThumbsSwiper}
                  initialSlide={activeIndex}
                  loop={false}
                  spaceBetween={10}
                  slidesPerView={5}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className={styles.videoThumbSlider}
                >
                  {eventDetails?.relatedVideos?.videos?.length > 0 &&
                    eventDetails?.relatedVideos?.videos.map((dt, key) => {
                      return (
                        <SwiperSlide  onClick={(event) => {
                          setisLoading(true);
                          const timer = setTimeout(() => {
                            setisLoading(false);
                            handlePlay(key);
                            swiperRefTwo?.current?.swiper?.slideTo(key); // Go to the specified slide index
                          }, 10);
                          return () => clearTimeout(timer);
                        }}
                        className={
                          modalActiveIndex === key ? "slide-active" : ""
                        }>
                          <div className={`figImg v_thumb thumb-${key}`}>
                            <img
                              src={dt?.thumbnail}
                              alt="event"
                             
                            />
                            <span
                              className={`icon-play_video ${classes?.playClass} ${classes?.seasonalAfterColorClass} `}
                            ></span>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    eventDetails: state.eventDetails.evnt,
  };
};

export default connect(mapStateToProps, { fetchEventRelatedVideos })(
  AllEventRelatedVideos
);
