import React, { useEffect , useState} from 'react';
import { connect } from 'react-redux';
import styles from './StickyNewsHeader.module.scss';
import { fetchBlogCategories } from '../../actions/newsAction';
import NewsHeaderMobile from '../Mobile/NewsCategory';
import { isMobile } from 'react-device-detect';
import { useDimensions } from '../../logic/Dimentions';
import { Link } from 'react-router-dom';
import { useSeasonal } from '../../logic/Seasonal';


const StickyNewsHeader = ({ fetchBlogCategories, news,SetCatgeoryData,category }) => {
    const { height, width } = useDimensions();
    const {seasonal,classes} = useSeasonal();
  useEffect(() => {
    if (news && news.length == 0) fetchBlogCategories();
  }, []);


  const [stickyNewsMenu, setStickyNewsMenu] = useState(false);
  useEffect(()=>{
    window.addEventListener('scroll', handleScroll);
  },[]);

  const handleScroll = (e) => {
        
    if(window.pageYOffset>500){
     setStickyNewsMenu(true);
   }else{
    setStickyNewsMenu(false);
   }
}
  

    return (
        width >= 992 ? (<div className={`${
          stickyNewsMenu
                ? `${styles.margin_wrap} ${styles.stickyNewsMenu}`
                : styles.margin_wrap
          }`}> 
            <div className={styles.head_breadcrumbs}> <div className="container">
                <ul className={`${styles.program_tab} scroll_h`} > {
                    news && news.length > 0 ? (
                        news.map((newsCategory, index) => {
                            return (<li> <Link onClick={()=>SetCatgeoryData(newsCategory.slug)} className={`${category==newsCategory.slug&&styles.active} ${category==newsCategory.slug&&classes?.txtColor} ${classes?.categoryList}`} key={index}> {newsCategory.title} </Link>
                            </li>
                                )
                    })
                ) : null} </ul> 
            </div>
        </div> </div>) : (<NewsHeaderMobile value={news} SetCatgeoryData={SetCatgeoryData} category={category}/>)
            )
}
const mapStateToProps = (state) => {
    return ({news: state.news.blogCategories })
}
            export default connect(mapStateToProps, {fetchBlogCategories})(StickyNewsHeader);
