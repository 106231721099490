import React,{useEffect} from 'react';
import {connect} from 'react-redux';
import CommonLayout from "../../Layouts/CommonLayout";
import {fetchEmployeeMatching} from "../../../actions/employeeMatchingAction"
import Banner from '../Banner/Banner'
import ContentSection from "../ContentSection";
import styles from "./EmployeeMatching.module.scss";
import CountrySelectBox from "../CountrySelectBox/CountrySelectBox";
import DonateMore from "../DonateMore";
import StepsToShop from "../StepsToShop";
const EmployeeMatching = ({employeeMatching,fetchEmployeeMatching}) => {
    useEffect(()=>{
        // if(fellowship.length ==0)
        fetchEmployeeMatching()
        
    },[])
    const handleChangeCountry = (event) => {
        fetchEmployeeMatching(event);
      };
    return(
        <CommonLayout>
        <div>
           {Object.keys(employeeMatching).length > 0? (
            <>
           <div className="container"> 
           <Banner bannerDetails={employeeMatching.banner_details} />
           <CountrySelectBox
              handleChangeCountry={handleChangeCountry}
              countries={employeeMatching.countries}
              countrySelectionText={employeeMatching.country_selection_text}
              countrySelectionText1={employeeMatching.country_selection_text_1}
            />
            </div>
            <div>
              <section className={styles.content_section}>
              {employeeMatching.region === "others" && (
                <div className={`container ${styles.others}`}>
                <p>{employeeMatching.pageDescription}</p>
                </div>
              )}
               {employeeMatching.country_data && employeeMatching.region != "others" ? ( 
                   <>
                   <div className="container">
                     <ContentSection
                     heading={employeeMatching.country_data.pageHeading}
                     description={employeeMatching.country_data.pageDescription}
                    //  donationTxt={carDonation.country_data.link_title}
                    //  donationUrl={carDonation.country_data.link_url}
                    //  target={carDonation.country_data.link_target}
                   /> 
                   </div>
                    {employeeMatching.country_data.steps && (
                    <section className={styles.howtoshop}>
                      <div className="container">
                        <h3>{employeeMatching.country_data.howToShopTitle}</h3>
                      <StepsToShop
                        step="01"
                        description={employeeMatching.country_data.step_3_title}
                        // datas={employeeMatching.country_data.step_1_content}
                        // buttonTxt={amazonSmile.country_data.stepOneButtonText}
                        // buttonUrl={amazonSmile.country_data.stepOneButtonUrl}
                        // target="_blank"
                      />
                      <StepsToShop
                        step="02"
                        description={employeeMatching.country_data.step_2_title}
                        content = {employeeMatching.country_data.step_2_content}
                        // datas={employeeMatching.country_data.step_2_content}
                      />
                      {/* <StepsToShop
                        step="03"
                        description={amazonSmile.country_data.stepThreeContent}
                        buttonTxt={amazonSmile.country_data.stepOneButtonText}
                        buttonUrl={amazonSmile.country_data.stepOneButtonUrl}
                        target="_blank"
                      /> */}
                      </div>
                    </section>
                  )}
                   </>
                   ) : null}


                    {employeeMatching.country_data ? (
                <DonateMore
                  moreWaysGiveTitle={employeeMatching.country_data.moreWaysGiveTitle}
                  moreWaysGive={employeeMatching.country_data.moreWaysGive}
                />
              ) : null}
              </section>
           </div></> ):null}
        </div>
        </CommonLayout>
    )

}
const mapPropsToState = (state) => {
    return {employeeMatching:state.employeeMatchingData.employeeMatching}
}
export default connect(mapPropsToState,{fetchEmployeeMatching})(EmployeeMatching);