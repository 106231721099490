import React, { useEffect, useState, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import {
  fetchMediaGallery,
  emailVerify,
} from "../../actions/mediagalleryAction";
import { fetchCountryList } from "../../actions/swprayerAction";
import SwpluseBanner from "../NewSwPluseBanner";
import SwPluseFAQ from "../NewSwPluseFAQ";
import NewSwpluseFeature from "../NewSwPluseFeature";
import SwPluseSignUp from "../NewSwPluseSignUp";
import SwPluseSupport from "../NewSwPluseSupport";
import SwPluseTabs from "../NewSwPluseTabs";
import NewSwpluseKnowMore from "../NewSWpluseKnowMore";
import SocialMediaWrap from "../SocailMediaIcons";
import { useDimensions } from "../../logic/Dimentions";
import { useLocation } from "react-router-dom";
import { useSeasonal } from '../../logic/Seasonal'

function NewSwPluse() {
  const dispatch = useDispatch();
  const [ViewForm, SetForm] = useState(false);
  const [viewSupport, SetSupport] = useState(false);
  const [verifyMsg, setVerify] = useState({ isView: false, msg: "" });
  const [verifyError, setverifyError] = useState({ isView: false, msg: "" });
  const { width } = useDimensions();
  const [tabActive, SetTabActive] = useState(1);
  const { classes } = useSeasonal()

  useEffect(() => {
    dispatch(fetchMediaGallery());
    dispatch(fetchCountryList());
  }, []);

  const mediaGallery = useSelector((state) => state.mediaGallery.mediaGallery);
  const countryList = useSelector((state) => state.swprayer.countryList);
  const watchSheduleRef = useRef();
  const pathName = useLocation()?.pathname;
  const search = useLocation()?.search;

  const token = new URLSearchParams(search).get("token");
  const Id = new URLSearchParams(search).get("id");
  let data = { id: Id, token: token };

  useEffect(() => {
    if (pathName === "/swplus/forgotpassword") {
      SetTabActive(2);
    } else if (pathName === "/swplus/signin/") {
      dispatch(emailVerify(data)).then((response) => {
        if (response?.data?.status === "success") {
          setVerify({ isView: true, msg: response?.data?.msg });
        } else {
          setverifyError({ isView: true, msg: response?.data?.msg });
        }
      });
      SetTabActive(2);
      setTimeout(() => {
        setVerify({ isView: false, msg: "" });
        setverifyError({ isView: false, msg: "" });
      }, 10000);
    }
  }, []);

  useEffect(() => {
    if (watchSheduleRef.current && pathName === "/swplus/forgotpassword") {
      // watchSheduleRef.current.scrollIntoView();
      window.scrollTo({
        behavior: "smooth",
        left: 0,
        top: watchSheduleRef.current.offsetTop + 90,
      });
    } else if (watchSheduleRef.current && pathName === "/swplus/signin/") {
      // watchSheduleRef.current.scrollIntoView();
      window.scrollTo({
        behavior: "smooth",
        left: 0,
        top: watchSheduleRef.current.offsetTop + 90,
      });
    }
  }, [token]);

  const handleScrollToSign = () => watchSheduleRef.current.scrollIntoView()


  return (
    <div>
      <SwpluseBanner data={mediaGallery} />

      <SwPluseTabs data={mediaGallery} SetForm={SetForm} handleScrollToSign={handleScrollToSign} />
      {width >= 992 && <SocialMediaWrap classes={classes} />}
      {ViewForm && <NewSwpluseKnowMore SetForm={SetForm} />}

      <NewSwpluseFeature featureData={mediaGallery} />
      <div ref={watchSheduleRef}>
        <SwPluseSignUp
          data={mediaGallery}
          countryList={countryList}
          tabActive={tabActive}
          SetTabActive={SetTabActive}
          token={token}
          verifyMsg={verifyMsg}
          verifyError={verifyError}
          pathName={pathName}
        />
      </div>
      <SwPluseFAQ data={mediaGallery} />
      <SwPluseSupport
        data={mediaGallery}
        viewSupport={viewSupport}
        SetSupport={SetSupport}
      />
    </div>
  );
}

export default NewSwPluse;
