import styles from "./NewsDetails.module.scss";
import { fetchBlogComments } from "../../actions/newsCommentsAction";
import { connect } from "react-redux";
import moment from "moment";
import imgFeature from "../../assets/img/commentuser.2e7feaae.jpg";
import React,{useEffect} from 'react';

const BlogCommentList = ({ newsDetails, comments, fetchBlogComments, classes }) => {

  useEffect(()=>{
    if (newsDetails && newsDetails.blogId) {
      fetchBlogComments(newsDetails.blogId);
    }
},[fetchBlogComments, newsDetails])

  return (
    <ul className={`${styles.blogCommentList} mt-5`}>
      {comments.comments &&
        comments.comments.length >= 1 &&
        comments.comments.map((comment, index) => (
          <li key={index}>
            <div className={styles.avatarSec} bis_skin_checked="1">
              <span className={styles.avatar}>
                <img src={imgFeature} alt="" />
              </span>
            </div>

            <div className={`${styles.cmntBxwrap}`} bis_skin_checked="1">
              <span className={`${styles.avatarName} ${classes?.txtColor}`}>
              {comment.author_name}
              </span>
              <span className={styles.cmntDate}>
                {moment(comment.date).format("MMM DD, YYYY")}
              </span>
              <div className={styles.comment} bis_skin_checked="1">
                <div
                  dangerouslySetInnerHTML={{
                    __html: comment?.content?.rendered,
                  }}
                ></div>
              </div>
              <div className={styles.comment_ftr} bis_skin_checked="1"></div>
            </div>
          </li>
        ))}
    </ul>
  );
};

const mapStateToProps = (state) => {
  return { comments: state.comments };
};

export default connect(mapStateToProps, { fetchBlogComments })(BlogCommentList);
