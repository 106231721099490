import React, { useEffect } from "react";
import styles from "./MobPodcastInfo.module.scss";
import { Link } from "react-router-dom";
import { useSeasonal } from "../../../logic/Seasonal";
import { FiChevronLeft } from "react-icons/fi";
// import PodcastEpisodeCarousel from "../../PodcastEpisodeCarousel";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import {
  fetchPodcastEpisode, appendToPlaylist,
  updatePlaylist
} from "../../../actions/podCastPlayListAction";


const MobPodcastInfo = ({ episodeInfo, setEpisodeInfo, episode, podCastEpisode, fetchPodcastEpisode, handlePlay, play, activeId, handleSetActiveId, podCastPlayListData }) => {
  const { classes } = useSeasonal();

  useEffect(() => {
    fetchPodcastEpisode({ slug: episode?.url })
  }, [episode]);


  const handleUpdatePlaylist = (id) => {
    let nextObject = podCastPlayListData.find((item) => item.id === id);

    if (nextObject) {
      // Same item in the play list
      let indexFound = 0;
      podCastPlayListData?.forEach((element, index) => {
        if (id === element.id) {
          podCastPlayListData[index].active = true;
          handleSetActiveId(podCastPlayListData[index].id);
          indexFound = index;
        } else {
          podCastPlayListData[index].active = false;
        }
      });

      updatePlaylist(podCastPlayListData, indexFound);
    } else {
      //This does not exits in play list and its different podcast
      handleAddEpisodesToPlaylist(id)
    }


    const timer = setTimeout(() => {
      handlePlay(true);
    }, 60);
    return () => clearTimeout(timer);
  };

  const handleAddEpisodesToPlaylist = (id) => {
    let indexFound = 0;
    let podCastDataId = episodeInfo?.id;

    podCastEpisode?.otherEpisodes?.episodes.forEach((element, index) => {
      if (id === element.id) {
        podCastEpisode.otherEpisodes.episodes[index].active = true;
        handleSetActiveId(podCastEpisode.otherEpisodes.episodes[index].id);
        indexFound = index;
      } else {
        podCastEpisode.otherEpisodes.episodes[index].active = false;
      }
    });
    if (podCastEpisode?.otherEpisodes?.episodes) {
      appendToPlaylist(podCastEpisode.otherEpisodes.episodes, indexFound, podCastDataId);
    }

    const timer = setTimeout(() => {
      handlePlay(true);
    }, 60);

    return () => clearTimeout(timer);
  };





  return (
    <div
      className={`${styles.mobPodcastAbout} ${episodeInfo ? styles.show : ""}`}
    >
      <Container>
        <div className={styles.mobPodcastAboutHeader}>
          <Link
            onClick={() => {
              setEpisodeInfo(false);
            }}
          >
            <i>
              <FiChevronLeft />
            </i>
          </Link>
          <p>Show Notes</p>
        </div>
        <div className="scroll_wrap">
          <div className={styles.AboutPodcastContent}>
            {episode?.description && (
              <div className={styles.AboutDescriptionWrapper}>
                
                <h2 >{episode.title}</h2>
                <article
                  className={`editorTextArea ${classes?.contentLinkHoverColor}`}
                  dangerouslySetInnerHTML={{
                    __html: episode?.description,
                  }}
                ></article>
              </div>
            )}

            <div className={styles.AboutPodcast}>
              {episode?.host && episode?.host.length <= 1 && episode?.host[0] && episode?.host[0].name !== false && <h2>About Host</h2>}

              {episode?.host &&
                episode?.host.map((value, index) => {
                  return (
                    <div className={styles.host_guest_list} key={index}>
                      <div className={styles.profile}>
                        <figure>
                          <img src={value?.image} alt="Host" />
                        </figure>
                        <div className={styles.profile_dtls}>
                          {value?.name !== false && (
                            <>
                              {" "}
                              <strong>{value?.name} </strong>
                              <span>Host</span>
                            </>
                          )}
                        </div>
                      </div>
                      {value?.description !== false && (
                        <article
                          className={`editorTextArea ${classes?.contentLinkHoverColor}`}
                          dangerouslySetInnerHTML={{
                            __html: episode?.host?.[0]?.description,
                          }}
                        ></article>
                      )}
                    </div>
                  );
                })}

              {episode?.guest &&
                episode?.guest.map((value, index) => {
                  <div className={styles.host_guest_list} key={index}>
                    <div className={styles.profile}>
                      <figure>
                        <img src={value?.image} alt="Guest" />
                      </figure>
                      <div className={styles.profile_dtls}>
                        <strong> {value?.name}</strong>
                        <span>Guest</span>
                      </div>
                    </div>
                    <article
                      className={`editorTextArea ${classes?.contentLinkHoverColor}`}
                      dangerouslySetInnerHTML={{
                        __html: value?.description,
                      }}
                    ></article>
                  </div>;
                })}
            </div>
          </div>
          {/* <PodcastEpisodeCarousel
            data={podCastEpisode?.otherEpisodes}
            handleUpdatePlaylist={handleUpdatePlaylist}
            handlePlay={handlePlay}
            play={play}
            activeId={activeId}
            copyLinkText={
              window.location.origin +
              "/podcast/" +
              podCastEpisode?.podcastSlug
            }
            type="mobile"
          /> */}
        </div>

      </Container>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    podCastData: state.podCast,
    podCastEpisode: state.podCastEpisode,
    podCastPlayListData: state.podCastPlayList?.playLists,
    play: state.podCastPlayList?.play,
    activeId: state.podCastPlayList?.activeId,
    progress: state.podCastPlayList?.progress,
  };
};

export default connect(mapStateToProps, {
  fetchPodcastEpisode,

})(MobPodcastInfo);


