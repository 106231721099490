import React, { useState, useCallback, useEffect } from "react";
import { useFormik } from "formik";
import classNames from "classnames";
import Row from "react-bootstrap/Row";
import ReCAPTCHA from "react-google-recaptcha";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { salomapi3 } from "../../apis";
import styles from "./SwplusEditForm.module.scss";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { getProfile } from "../../actions/mediagalleryAction";
import BrowserPersistence from "../../util/simplePersistance";
import { useSeasonal } from '../../logic/Seasonal';
const storage = new BrowserPersistence();

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      paddingLeft: 26,
      color: "black",
    },
  },
}));

const SwplusEditForm = ({
  countryList,
  mediaGallery,
  getProfile,
  mediaUserReducer,
  mediaUserProfileReducer,
  themeClasses
}) => {
  const [countryName, setCountryName] = useState("");
  const [cmsName, setCmsName] = useState("");
  const [domains, setDomains] = useState([]);
  const [domainNew, setDomainNew] = useState("");
  const [isFormSuccess, setFormSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ message: "", domains: "" });
  const [profile, setProfile] = useState();
  const divClass = classNames(styles.div_fields, "col-sm-6");
  const inputClass = classNames(styles.input_fields);
  const classes = useStyles();
  //const { seasonal, themeclasses } = useSeasonal()
  let user1 = storage.getItem("mediauser");


  useEffect(() => {

    setProfile(mediaUserProfileReducer);
    formik.setFieldValue("firstName", mediaUserProfileReducer.firstName);
    formik.setFieldValue("lastName", mediaUserProfileReducer.lastName);
    formik.setFieldValue("email", mediaUserProfileReducer.email);
    formik.setFieldValue("domname", mediaUserProfileReducer.domains[0].domain);
    formik.setFieldValue("phone", mediaUserProfileReducer.phone);
    formik.setFieldValue("location", mediaUserProfileReducer.location);
    formik.setFieldValue("organization", mediaUserProfileReducer.organization);
    setDomains(mediaUserProfileReducer.domains);
    setCountryName(mediaUserProfileReducer.country);
    // setCmsName(mediaUserProfileReducer.user_cms)
    // })
  }, []);

  const validate = (values) => {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = "Required";
    } else if (values.firstName.length > 15) {
      errors.firstName = "Must be 15 characters or less";
    }

    if (!values.lastName) {
      errors.lastName = "Required";
    } else if (values.lastName.length > 20) {
      errors.lastName = "Must be 20 characters or less";
    }

    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }
    if (countryName == "") {
      errors.country = "Required";
    }
    // if (cmsName =="") {
    //   errors.cms = 'Required';
    // }
    // if (!values.domname) {
    //   errors.domname = 'Required';
    // }
    // if (!values.password) {
    //   errors.password = 'Required';
    // }
    // if (!values.cpassword) {
    //     errors.cpassword = 'Required';
    //   }
    if (!values.phone) {
      errors.phone = "Required";
    }
    if (!values.location) {
      errors.location = "Required";
    }
    if (!values.domname) {
      errors.domname = "Required";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      firstName: profile?.firstName,
      lastName: profile?.lastName,
      email: profile?.email,
    },
    validate,
    onSubmit: (values) => {
      const postData = {
        userId: mediaUserReducer?.userId,
        accessToken: mediaUserReducer?.accesstoken,
        timestamp: new Date().valueOf(),
        firstname: values.firstName,
        lastname: values.lastName,
        organization: values.organization,
        email: values.email,
        domains: domains.map(({ domain }) => domain).join(","),
        location: values.location,
        country: countryName,
        password: values.password,
        phone: values.phone,
        confirmPass: values.cpassword,
        // cms:cmsName
      };
      salomapi3
        .post("/media/updateprofile", postData)
        .then((response) => {
          if (response.data.code === "success") setFormSuccess(true);
        })
        .catch((e) => {
          if (e.response) {
            let err = {
              ...errorMsg,
              message: e.response.data.message,
              domains: e.response.data.message.split(" ")[0],
            };
            setErrorMsg(err);
          }
          // setErrorMsg('Invalid Domain')
        });

      //  alert(JSON.stringify(values, null, 2));
    },
  });
  const CountryChange = useCallback((event, newValue) => {
    if (newValue) setCountryName(newValue.country_name);
  }, []);

  const CountryCms = useCallback((event, newValue) => {
    if (newValue) setCmsName(newValue.value);
  }, []);

  const addDomian = (e) => {
    e.preventDefault();
    if (domainNew != "") {
      domains.push({ domain: domainNew });
      setDomainNew("");
    }
  };
  const removeDomain = (value) => {
    let data = domains.filter((item) => item.domain !== value.domain);

    let errorDomains = errorMsg.domains.split(",");
    let newErrorDomains = errorDomains.filter(
      (domain) => domain.length > 0 && domain !== value.domain
    );

    if (newErrorDomains.length > 0 && domains.length > 0) {
      setErrorMsg({
        message: newErrorDomains.join(",") + " Domain is Invalid",
        domains: newErrorDomains.join(","),
      });
    } else {
      setErrorMsg({ message: "", domains: "" });
    }

    setDomains(data);
  };

  return (
    <div className={styles.gallery_signup}>
      <h3>Get Started Now</h3>

      <form className={styles.form_align} onSubmit={formik.handleSubmit}>
        <Row className={styles.div_align}>
          <div className={divClass}>
            <input
              className={`${inputClass} ${formik.errors.firstName &&
                formik.submitCount &&
                styles.formerror
                }`}
              type="text"
              onChange={formik.handleChange}
              value={formik.values.firstName}
              name="firstName"
              placeholder="First Name"
            ></input>
          </div>
          <div className={divClass}>
            <input
              className={`${inputClass} ${formik.errors.lastName && formik.submitCount && styles.formerror
                }`}
              type="text"
              onChange={formik.handleChange}
              value={formik.values.lastName}
              name="lastName"
              placeholder="Last Name"
            ></input>
          </div>
        </Row>
        <Row className={styles.div_align}>
          <input
            className={`${styles.textClass} ${formik.errors.organization &&
              formik.submitCount &&
              styles.formerror
              }`}
            onChange={formik.handleChange}
            value={formik.values.organization}
            name="organization"
            placeholder="Organization Name"
          ></input>
        </Row>
        <Row className={styles.div_align}>
          <input
            className={`${styles.textClass} ${formik.errors.phone && formik.submitCount && styles.formerror
              }`}
            type="text"
            onChange={formik.handleChange}
            value={formik.values.phone}
            name="phone"
            placeholder="Phone"
          ></input>
        </Row>
        <Row className={styles.div_align}>
          <div className={divClass}>
            <input
              className={`${inputClass} ${formik.errors.password && formik.submitCount && styles.formerror
                }`}
              type="password"
              onChange={formik.handleChange}
              value={formik.values.password}
              name="password"
              placeholder="Password"
            ></input>
          </div>
          <div className={divClass}>
            <input
              className={`${inputClass} ${formik.errors.cpassword &&
                formik.submitCount &&
                styles.formerror
                }`}
              type="password"
              onChange={formik.handleChange}
              value={formik.values.cpassword}
              name="cpassword"
              placeholder="Confirm Password"
            ></input>
          </div>
        </Row>
        <Row className={styles.div_align}>
          <div className={divClass}>
            <Autocomplete
              id="combo-box-demo"
              classes={classes}
              options={countryList}
              getOptionLabel={(option) => option.country_name}
              renderOption={(option) => (
                <div style={{ color: "white" }}>{option.country_name}</div>
              )}
              style={{ width: 204 }}
              onChange={CountryChange}
              value={countryName}
              inputValue={countryName}
              renderInput={(params) => (
                <TextField
                  className={`${inputClass} ${formik.errors.country &&
                    formik.submitCount &&
                    styles.formerror
                    }`}
                  {...params}
                  placeholder="Select Country"
                  name="country"
                  variant="outlined"
                />
              )}
            />
          </div>
          <div className={divClass}>
            <input
              className={`${inputClass} ${formik.errors.location && formik.submitCount && styles.formerror
                }`}
              type="text"
              onChange={formik.handleChange}
              value={formik.values.location}
              name="location"
              placeholder="Enter Location"
            ></input>
          </div>
        </Row>
        {domains.map((value, index) => {
          return (
            <Row className={styles.div_align}>
              <div className={styles.addDomainWrap}>
                <input
                  className={`${styles.textClass} ${formik.errors.domname &&
                    formik.submitCount &&
                    styles.formerror
                    }`}
                  onChange={(e) => {
                    let dom = [...domains];
                    dom[index].domain = e.target.value;
                    setDomains(dom);
                  }}
                  value={value.domain}
                  name="domname"
                  placeholder="Domain Name : your-parish-website-url"
                ></input>
                <button
                  className={`${styles.btnDomainClose} ${styles.btnInputDomain} ${themeClasses?.backgroundColr}`}
                  onClick={() => {
                    removeDomain(value);
                  }}
                >
                  &times;
                </button>
              </div>
            </Row>
          );
        })}
        <Row className={styles.div_align}>
          <div className={styles.addDomainWrap}>
            <input
              className={`${styles.textClass} ${formik.errors.domname && formik.submitCount && styles.formerror
                }`}
              onChange={(e) => {
                setDomainNew(e.target.value);
              }}
              value={domainNew}
              name="domname"
              placeholder="Domain Name : your-parish-website-url"
            ></input>
            <button
              className={`${styles.btnDomainClose} ${styles.btnInputDomain} ${themeClasses?.backgroundColr}`}
              onClick={addDomian}
            >
              +
            </button>
          </div>
        </Row>
        {/* <Row className={styles.div_align}>

                        <Autocomplete
                            id="combo-box-demo"
                            className={styles.cmsClass}
                            options={mediaGallery.cms}
                            getOptionLabel={(option) => option.name}
                            renderOption={(option) => (
                            <div style={{ color: "white" }}>{option.name}</div>
                                    )}
                                style={{ width: 204 }}
                                onChange={CountryCms}
                                renderInput={(params) => <TextField className={`${(formik.errors.cms&&formik.submitCount)&&styles.formerror}`} {...params} placeholder="Select CMS*" name="cms" variant="outlined" />}
                            />

                    </Row> */}

        <button className={`${styles.signup_btn}  ${themeClasses?.borderOnly} ${themeClasses?.hoverBtnColor} `} type="submit">
          SUBMIT
        </button>
        {Object.keys(formik.errors).length != 0 && formik.submitCount ? (
          <div className="form-group col-sm-12">
            <span className={styles.formtotNullfieldErr}>
              Please fill all required fields.
            </span>
          </div>
        ) : null}

        {isFormSuccess ? (
          <div className="form-group col-sm-12">
            <span className={styles.resonseSuccessMsg}>
              Profile updated successfully
            </span>
          </div>
        ) : null}
        {errorMsg.message ? (
          <div className="form-group col-sm-12">
            <span className={styles.resonseFailedMsg}>{errorMsg.message}</span>
          </div>
        ) : null}
      </form>
    </div>
  );
};

const mapPropsToState = (state) => {
  return {
    mediaUserReducer: state.mediaUserReducer,
    mediaUserProfileReducer: state.mediaUserProfileReducer,
  };
};

export default connect(mapPropsToState, { getProfile })(SwplusEditForm);
