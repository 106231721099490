import React from "react";
import { connect } from "react-redux";
import {
    fetchPrograms,
    fetchCategoryPrograms,
} from "../../actions/programeAction";
import Row from "react-bootstrap/Row";
import ProgramThumb from "../ProgramThumb";
import { useProgramList } from "../../logic/ProgramList/useProgramList";
import { useParams, useHistory } from "react-router-dom";
import styles from "./ProgramList.module.scss";
import _ from "lodash";
import ShowListShimmer from "../Shimmers/ShowListShimmer/ShowListShimmer";

const ProgramList = ({
    fetchPrograms,
    programes,
    categories,
    fetchCategoryPrograms,
    categoryProgrames,
    isShimmer,
}) => {
    const { location } = useHistory();
    let path = location.pathname;
    let { category } = useParams();
    useProgramList({
        fetchPrograms,
        programes,
        category,
        categories,
        path,
        fetchCategoryPrograms,
    });

    if (path === "/") {
        category = "featured";
    } else if (path === "/shows") {
        category = "popular";
    }

    return (
        <div className={styles.ProgramListRow}>
            {isShimmer ? (
                <Row>
                    {_.times(path === "/" ? 10 : 15, () => (
                        <ShowListShimmer />
                    ))}
                </Row>
            ) : (
                <Row>
                    {programes &&
                        programes[category] &&
                        programes[category]?.data?.length > 0 ? (
                        programes[category]?.data?.map((value) => {
                            return (
                                <ProgramThumb value={value} key={value.url} id={value.id} />
                            );
                        })
                    ) : (
                        <>
                            {_.times(path === "/" ? 10 : 15, () => (
                                <ShowListShimmer />
                            ))}
                        </>
                    )}
                </Row>
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return { programes: state.programes, categories: state.categories };
};

export default connect(mapStateToProps, {
    fetchPrograms,
    fetchCategoryPrograms,
})(ProgramList);
