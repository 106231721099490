import React,{useState,useEffect,lazy, useCallback} from 'react';
import Publication from '../components/Publication';
import Testimonies from '../components/Testimonies';
import DailymassList from '../components/DailymassList';
import SwprayerLiveVideo from '../components/SwprayerLiveVideo';
import SwprayerTab from '../components/SwprayerTab';
import MassPetition from '../components/MassPetition';
import PrayerReqeust from '../components/PrayerReqeust';
import TestimonyRequest from '../components/TestimonyRequest';
import SwprayerAbout from '../components/SwprayerAbout';
import CommingUp from '../components/HomeBanner/CommingUp';
import {fetchOtherdetails} from '../actions/otherdetailsAction';
import {fetchCountryList,fetchStateFromCountry,fetchTimeFromDate} from '../actions/swprayerAction'
import {connect} from 'react-redux';
import { useHome } from '../logic/Home/useHome';
import {isMobile} from 'react-device-detect';
import {useDimensions} from '../logic/Dimentions';
import { TitleComponent } from '../components/TitleComponent';
import { useSeo } from '../logic/Seo/useSeo';
import { useHistory, useParams } from "react-router-dom";
import SocialMediaWrap from "../components/SocailMediaIcons";
import { useSeasonal } from '../logic/Seasonal';

const MainLayout = lazy(()=>import('../components/Layouts/MainLayout'));
const Donate = lazy(()=>import('../components/FooterDonate'));
const RelatedSites = lazy(()=>import('../components/RelatedSites'));
const ShalomTvApp = lazy(()=>import('../components/ShalomTvApp'));
const Subscription = lazy(()=>import('../components/Subscription'));
const Footer = lazy(()=>import('../components/Footer'));
const BottomTab = lazy(()=>import('../components/BottomTab'));
const LiveTitle = lazy(()=>import('../components/Mobile/HomeLIveWatch/LiveTitle'));
const FullSchedule = lazy(()=>import("../components/Mobile/FullSchedule"));


const Swprayer = ({fetchOtherdetails,fetchCountryList,fetchStateFromCountry,fetchTimeFromDate,otherdetails,countryList,stateList,weekTimeList}) => {
    useHome({fetchOtherdetails,otherdetails});
    useSeo();
    const { classes } = useSeasonal()
    let history = useHistory();
    let { type } = useParams()
    const [fullscreen, setFullscreen] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [countryName, setCountryName] = useState("");
    const [countryInputName, setCountryInputName] = useState("");
    const { height, width } = useDimensions();
    const [showFullSchedule,setShowFullSchedule] = useState(false)
    useEffect(()=>{
        // if(fellowship.length ==0)
        fetchCountryList()
    },[])
    const CountryChange = useCallback((event, newValue) => {

        if(newValue){
            fetchStateFromCountry(newValue.id)
        }

     },[])
     const CountryInputChange = useCallback((event, newInputValue) => {

            setCountryInputName(newInputValue);

     },[])
     const selectDate = (e) =>{
        setStartDate(e)
        fetchTimeFromDate(e)
      }
      const [value, setValue] = useState(0);
    useEffect(()=>{
        if(type=='todaymass'){
            setValue(0);
        }else if(type=='masspetition'){
            setValue(1);
        }else if(type=='prayerrequest'){
            setValue(2);
        }else if(type=='testimony'){
            setValue(3);
        }else if(type=='about'){
            setValue(4);
        }
    },[type])

    const renderTab = (value) => {

        switch (value) {
            case 0:
            return <DailymassList/>;
                break;
            case 1:
            return <MassPetition countryList={countryList} stateList={stateList} CountryChange={CountryChange} CountryInputChange={CountryInputChange} selectDate={selectDate} startDate={startDate} weekTimeList={weekTimeList} countryName={countryName} countryInputName={countryInputName}/>;
                break;
            case 2:
            return <PrayerReqeust countryList={countryList} stateList={stateList} CountryChange={CountryChange} CountryInputChange={CountryInputChange} countryName={countryName} countryInputName={countryInputName} />;
                break;
            case 3:
            return <TestimonyRequest countryList={countryList} stateList={stateList} CountryChange={CountryChange} CountryInputChange={CountryInputChange} countryName={countryName} countryInputName={countryInputName} />;
            break;
            case 4:
            return <SwprayerAbout/>;
            break;

            default:
            return <MassPetition/>;
                break;
        }
    }

    return (
       <>
       {!fullscreen ?  <>
        <TitleComponent title="Shalom world "/>
        {width>=992&&<SocialMediaWrap classes={classes}/>}
            <SwprayerLiveVideo fullscreen={fullscreen} setFullscreen={setFullscreen}/>
            {width>=992?<CommingUp position='inherit'/>:<><LiveTitle setShowFullSchedule={setShowFullSchedule}/>{showFullSchedule&&<FullSchedule setShowFullSchedule={setShowFullSchedule}/>}</>}
            <SwprayerTab value={value} setValue={setValue}/>
            {renderTab(value)}
            {width>=992?
            <>
            <Publication themeClasses={classes}/>
            <Testimonies/>
            <Donate/>
            <ShalomTvApp/>
            <RelatedSites/>
            <Subscription/>
            <Footer/>
            </>:<BottomTab/>}
        </> :   <SwprayerLiveVideo fullscreen={fullscreen} setFullscreen={setFullscreen}/> }
       </>


    )
}
const mapStateToProps = (state) => {
    return ({otherdetails:state.otherdetails.data,countryList:state.swprayer.countryList,stateList:state.swprayer.stateList,weekTimeList:state.swprayer.weekTimeList})
  }
export default connect(mapStateToProps,{fetchOtherdetails,fetchCountryList,fetchStateFromCountry,fetchTimeFromDate})(Swprayer);