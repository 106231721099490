import React from "react";
import "./CommonStyle.scss";

const DonateDollarLayout = ({ children }) => {
    return (
        <>
            <div>{children}</div>
        </>
    );
};

export default DonateDollarLayout;
