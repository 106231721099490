import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export const useEventRelatedVideos = props => {
    const { fetchEventRelatedVideos } = props

    let { eventId } = useParams();

    useEffect(() => {
        if (eventId) {
            fetchEventRelatedVideos(eventId)
        }
    }, [eventId, fetchEventRelatedVideos])
}

