import { FETCH_COMMON, FETCH_WEBSITES, FETCH_SOCIALLINK, FETCH_CURRENCY } from "./types";
import salomapi from "../apis";
import BrowserPersistence from "../util/simplePersistance";

const storage = new BrowserPersistence();

export const fetchCommon = () => async (dispatch) => {

  async function fetchFromServer() {
    try {
      const response = await salomapi.get("/common");
      await saveCookie(response.data);

      if (response.data.currencies) {
        dispatch({ type: FETCH_CURRENCY, payload: response.data.currencies.result });
        storage.setItem("currencyList", response.data.currencies.result, 31536000);
      }
      if (response.data.waffleMenu) {
        dispatch({ type: FETCH_WEBSITES, payload: response.data.waffleMenu });
        storage.setItem("websiteDetailsList", response.data.waffleMenu, 31536000);
      }

      if (response.data.socialLinks) {
        dispatch({ type: FETCH_SOCIALLINK, payload: response.data.socialLinks });
        storage.removeItem("socialLinkList");
        storage.setItem("socialLinksList", response.data.socialLinks, 31536000);
      }
      return;

    } catch (error) {
      dispatch({
        type: FETCH_COMMON,
        payload: error,
      });
      throw error;
    }
  }

  const localCommonData = await retrieveCookie();
  if (localCommonData) {
    dispatch({ type: FETCH_COMMON, payload: localCommonData });
  } else {
    await fetchFromServer();
  }

  async function retrieveCookie() {
    return storage.getItem("commonList");
  }

  async function saveCookie(season) {
    return storage.setItem("commonList", season, 31536000);
  }
};
