import {FETCH_PEACE_FELLOWSHIP,FETCH_MAIL_FORM_DETAILS,FETCH_DONATE} from './types';
import salomapi from '../apis';

export const fetchPeaceFellowship =  () => async dispatch => {
    const response = await salomapi.get('/peacefellowship');
    dispatch({type:FETCH_PEACE_FELLOWSHIP,payload:response.data})
}
export const fetchDonateData =  () => async dispatch => {
    
    const response = await salomapi.get('/donation');
    
    dispatch({type:FETCH_DONATE,payload:response.data})
}

export const fetchMailFormDetails =  (region) => async dispatch => {
    const response = await salomapi.get('/spf/mail?region='+region);
    dispatch({type:FETCH_MAIL_FORM_DETAILS,payload:response.data})
}