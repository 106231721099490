import React, { useEffect, useCallback, useState } from 'react'
import MainLayout from '../components/Layouts/MainLayout'
import StickyNewsHeader from '../components/StickyNewsHeader'
import BlogTitleBanner from '../components/BlogTitleBanner'
import BlogList from '../components/BlogList'
import { fetchBlogList } from '../actions/newsAction'
import { connect } from 'react-redux'
import styles from './News.module.scss'
import { Container } from 'react-bootstrap'
import Row from 'react-bootstrap/Row'
import { Transition } from 'react-transition-group'
import { TitleComponent } from '../components/TitleComponent'
import { useDimensions } from '../logic/Dimentions';
import SocialMediaWrap from '../components/SocailMediaIcons';
import { useSeasonal } from '../logic/Seasonal';
import { useSeo } from '../logic/Seo/useSeo'
import { useTransition } from '../logic/PageTransition/useTransition'

const News = ({ fetchBlogList, news, totalPages, location }) => {
  useSeo()
  const [loadPageData, setloadPage] = useState(1)
  const [category, setCatgeory] = useState('all')
  const { height, width } = useDimensions();
  const { classes } = useSeasonal()

  let blogs
  let loadPage = 1
  useEffect(() => {
    if (news.length === 0) fetchBlogList(1, category)
  }, [])

  const loadMorePage = useCallback(() => {
    loadPage++
    setloadPage(loadPage)
    fetchBlogList(loadPage, category)
  }, [category])

  const { duration, defaultStyle, transitionStyles } = useTransition()

  if (news.length > 0) blogs = news.slice(1, news.length)

  const SetCatgeoryData = useCallback(
    (category) => {
      setCatgeory(category)
      setloadPage(1)
      fetchBlogList(1, category)
    },
    [setCatgeory],
  )
  return (
    <MainLayout>
      {width >= 992 && <SocialMediaWrap classes={classes} />}
      <TitleComponent title="Shalom world " />


      {/* <Transition in={news.length > 0} timeout={duration}>
        {state => (
          <>
          <div style={{
          ...defaultStyle,
          ...transitionStyles[state]
        }}> */}
      <StickyNewsHeader
        SetCatgeoryData={SetCatgeoryData}
        category={category}
        className={styles.margin_wrap}
      />
      <div className={`${styles.blogpgwrap} ${navigator.userAgent === "shalom_world_app_mobile" ? 'mob-app-pad' : 'no-mob-app'}
`}>
        {news.length > 0 ? (
          <div className={styles.blog_wrapper}>
            <BlogTitleBanner newsHead={news[0]} />
            <BlogList
              loadMorePage={loadMorePage}
              blogList={blogs}
              totalPages={totalPages}
              currentPage={loadPageData}
            />
          </div>
        ) : null}
      </div>
      {/* </div>
        </>
        )}
        </Transition> */}
    </MainLayout>
  )
}
const mapStateToProps = (state) => {
  return { news: state.news.blogList, totalPages: state.news.blogTotalPages }
}
export default connect(mapStateToProps, { fetchBlogList })(News)
