import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import {
  FaPlay,
  FaPause,
  FaVolumeUp,
  FaVolumeMute,
  FaExpand,
  FaCompress,
  FaVolumeDown,
  FaClosedCaptioning,
  FaRegClosedCaptioning,
} from "react-icons/fa";
import { FiX } from "react-icons/fi";
import { AiTwotoneSetting } from "react-icons/ai";
import Slider from "@material-ui/core/Slider";
import styles from "./VideoPlayer.module.scss";
import { useDimensions } from "../../logic/Dimentions";
import useIsVisible from "../../logic/PipVIew";
import { useLocation } from "react-router-dom";
import { useSeasonal } from "../../logic/Seasonal";
import { Spinner } from "react-bootstrap";

const VideoPlayer = ({
  url,
  vidoes,
  durationData,
  fullscreen,
  setFullscreen,
  playError,
  setPlayError,
  live,
  play,
  setPlay,
  hideHeaderMenu,
  toggleScreen,
  setToggleScreen,
  showControls,
  setShowControls,
  preferredQuality,
  setPreferredQuality,
  selectedVideo,
  setSelectedVideo,
  allTracks,
  handleSelectSubtitle,
  setLanguage,
  language,
}) => {
  const { classes } = useSeasonal();
  const [volume, setVolume] = useState(1);
  const [totalDuration, setTotalDuration] = useState(0);
  const [currentDuration, setCurrentDuration] = useState(0);
  const [volumeDisplay, setVolumeDisplay] = useState({
    icon: false,
    slider: false,
  });
  const [selectedResolution, setSelectedResolution] = useState();
  const [settingDisplay, setSettingDisplay] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [settingCC, setSettingCC] = useState(false);

  const player = useRef();
  const videoplayer = useRef();
  const myRef = useRef(null);
  let newPlay = false;
  const location = useLocation();
  let path = location.pathname;

  const isVisible = useIsVisible(videoplayer, "-100px");
  const { width } = useDimensions();

  useEffect(() => {
    if (!isVisible) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [isVisible]);

  /**
   * @desc Hide Controls at every 12000 milliseconds with inactivity
   */

  useEffect(() => {
    let myTimeout = setInterval(() => {
      setShowControls(false);
      setSettingDisplay(false);
      setSettingCC(false);
    }, 12000);
    return () => {
      clearTimeout(myTimeout);
    };
  }, []);
  /**
   * @desc set last volume and play to first time
   */

  useEffect(() => {
    setPlay(false);

    if (localStorage.getItem("SHALOM_WORLD_BROWSER_volume") != null) {
      setIsLoading(true);
      let videoVolume = JSON.parse(
        localStorage.getItem("SHALOM_WORLD_BROWSER_volume")
      );

      setTimeout(() => {
        setVolume(0);
        setIsLoading(false);
        if (videoVolume?.volume) {
          setVolume(videoVolume.volume);
        }
      }, 2000);

      let videoPlayed = JSON.parse(
        localStorage.getItem("SHALOM_WORLD_BROWSER_videoPlayed")
      );
      if (videoPlayed?.path == path) {
        setTimeout(() => {
          player.current.seekTo(videoPlayed.duration, "seconds");
        }, 800);
      }
    }
  }, []);

  /**
   * @desc set last played time upon path and selected video
   */

  useEffect(() => {
    let videoPlayed = JSON.parse(
      localStorage.getItem("SHALOM_WORLD_BROWSER_videoPlayed")
    );
    if (videoPlayed?.path === path) {
      setIsLoading(true);
      setTimeout(() => {
        player.current.seekTo(videoPlayed.duration, "seconds");
        setIsLoading(false);
      }, 100);
    }
  }, [path, selectedVideo]);

  /**
   * @desc set  volume slider on change
   */

  const handleChangeVolume = (event, newValue) => {
    setVolume(newValue);

    localStorage.setItem(
      "SHALOM_WORLD_BROWSER_volume",
      JSON.stringify({
        creation: Math.round(new Date().getTime() / 1000),
        volume: newValue,
      })
    );
  };

  const handleVolumeControl = (event) => {
    setSettingDisplay(false);
    if (volume === 0) {
      let v = 1;
      if (localStorage.getItem("SHALOM_WORLD_BROWSER_volume")) {
        let vArr = JSON.parse(
          localStorage.getItem("SHALOM_WORLD_BROWSER_volume")
        );
        if (vArr?.volume && vArr.volume > 0) {
          v = vArr?.volume;
        }
      }
      setVolume(v);
    } else {
      setVolume(0);
    }
  };

  // const playErrorHandler = () => {
  //   setPlayError(true);
  // };

  const playHandle = () => {
    if (newPlay == true) {
      setPlay(false);
      newPlay = false;
    } else {
      setPlay(true);
      newPlay = true;
    }
  };

  const duration = (e) => {
    setTotalDuration(e);
  };

  function secondsToTime(e) {
    var h = Math.floor(e / 3600)
      .toString()
      .padStart(2, "0"),
      m = Math.floor((e % 3600) / 60)
        .toString()
        .padStart(2, "0"),
      s = Math.floor(e % 60)
        .toString()
        .padStart(2, "0");

    if (h === `00`) {
      return `${m}:${s}`;
    } else {
      return `${h}:${m}:${s}`;
    }
  }

  const progress = (e) => {
    setCurrentDuration(e.playedSeconds);
    localStorage.setItem(
      "SHALOM_WORLD_BROWSER_videoPlayed",
      JSON.stringify({ path: path, duration: e.playedSeconds })
    );
  };

  const seekHandler = (event, newValue) => {
    setCurrentDuration(newValue);
    localStorage.setItem(
      "SHALOM_WORLD_BROWSER_videoPlayed",
      JSON.stringify({ path: path, duration: newValue })
    );
    player.current.seekTo(newValue, "seconds");
  };

  /**
   * @desc keyboard events
   */
  document.addEventListener("keydown", (e) => EscKey(e));

  function EscKey(e) {
    if (e.code === "Escape") {
      fullscreen && setFullscreen(false);
    }
  }

  const handlePlayingToggle = () => {
    if (play) {
      document.body.classList.remove("isplaying");
      setPlay(false);
      setShowControls(true);
    } else {
      document.body.classList.add("isplaying");
      setPlay(true);
      setShowControls(true);
    }
  };

  const handlePlaying = () => {
    if (hideHeaderMenu) {
      document.body.classList.add("isplaying");
    }
  };

  const handleStop = () => {
    if (hideHeaderMenu) {
      document.body.classList.remove("isplaying");
    }
  };

  const handleOnError = () => {
  };

  const handleOnEnded = () => {
    setPlay(false);
  };



  /**
   * @desc set  Resolutions names
   */

  const getResolutionFromWidth = (width, height) => {
    let value = "";
    if (height === "4320") {
      value = "8K";
    } else if (height === "2160") {
      value = " 4K";
    } else if (height === "1440") {
      value = "QHD";
    } else if (height === "1080" && width === "2048") {
      value = "2K";
    } else if (height === "1080" && width === "1920") {
      value = "FHD";
    } else if (height === "720") {
      value = "HD";
    } else if (height === "480" || height === "540") {
      value = "SD";
    } else if (height === "360") {
      value = "Low";
    } else if (height === "240") {
      value = "Very Low";
    } else if (height === "") {
      value = "Auto";
    }
    return value;
  };

  /**
   * @desc Click a resolution
   */

  const handleSelectResolution = (width, height) => {
    let value = getResolutionFromWidth(width, height);
    setSelectedResolution(value);
  };
  /**
   * @desc Full screen events
   */
  const handleFullScreenChange = () => {
    let elem = document.getElementById("VideoPlayerWrap");
    setSettingDisplay(false);
    document.body.classList.remove("fullscreen");
    if (document.fullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      if (!elem.mozFullScreen && !elem.webkitFullScreen) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullScreen) {
          elem.webkitRequestFullScreen();
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  document.addEventListener("mousemove", (e) => {
    setShowControls(true);
  });

  function handleKeyDown(event) {

    if (fullscreen) {
      if (event.keyCode === 32) {
        setPlay(!play);
        setSettingDisplay(false);
        setSettingCC(false);
      }

      if (event.keyCode == 27) {
        setShowControls(true);
        setToggleScreen(false);
        setFullscreen(false);
        setSettingDisplay(false);
        setSettingCC(false);
      }
    }
  }

  if (document.addEventListener) {
    document.addEventListener("webkitfullscreenchange", exitHandler, false);
    document.addEventListener("mozfullscreenchange", exitHandler, false);
    document.addEventListener("fullscreenchange", exitHandler, false);
    document.addEventListener("MSFullscreenChange", exitHandler, false);
  }

  function exitHandler() {
    if (
      !document.webkitIsFullScreen &&
      !document.mozFullScreen &&
      !document.msFullscreenElement
    ) {
      setFullscreen(false);
      setToggleScreen(false);
    } else {
      if (videoplayer?.current) {
        videoplayer.current.focus();
      }
    }
  }

  var VideoPlayerRefernce = document.querySelector("#VideoPlayer video");

  if (VideoPlayerRefernce) {
    VideoPlayerRefernce.addEventListener(
      "webkitendfullscreen",
      function () {
        videoExitedFullscreen(VideoPlayerRefernce);
      },
      false
    );
  }

  function videoExitedFullscreen(videoElement) {
    //check if an element is currently full screen
    if (
      document.fullScreenElement ||
      document.webkitIsFullScreen == true ||
      document.mozFullScreen ||
      document.msFullscreenElement
    ) {
      setShowControls(false);
    } else {
      setShowControls(true);

      //do whatever you want on fullscreen close, like pause or mute
    }
  }
  const _toggleFullScreen = function _toggleFullScreen() {
    const vid = document.querySelector("#VideoPlayer video");
    if (vid.requestFullScreen) {
      vid.requestFullScreen();
    } else if (vid.webkitRequestFullScreen) {
      vid.webkitRequestFullScreen();
    } else if (vid.mozRequestFullScreen) {
      vid.mozRequestFullScreen();
    } else if (vid.msRequestFullscreen) {
      vid.msRequestFullscreen();
    } else if (vid.webkitEnterFullscreen) {
      vid.webkitEnterFullscreen(); //for iphone this code worked
    }
  };

  const executeScroll = () => myRef.current.scrollIntoView();

  return (
    <>
      <div ref={videoplayer}>
        <div
          className={`${styles.videoPlayer} ${navigator.userAgent === "shalom_world_app_mobile" ? 'mob-app-mar-0' : 'no-mob-app'} ${window.location.pathname.includes("episode") ? styles.videoBanner : ""} player1`} style={{ height: width >= 992 && "100vh" }} ref={myRef}
        >
          <div
            id="VideoPlayerWrap"
            className={`${styles.videoPlayerPos} ${!fullscreen && visible && width > 991 && !url
              ? styles.videoPip : "vdoPosAbsolute"}`}
          >

            {/* Play Icon on the center of the video player */}
            {!play && (
              <span
                className={`${styles.actionPlay} ${classes.playClass} ${classes.borderOnly} `}
                onClick={() => {
                  setPlay(true);
                  setSettingDisplay(false);
                }}
              ></span>
            )}

            {/* IF image THEN show image else Player here */}

            {vidoes?.length > 0 && vidoes[selectedVideo]?.url == "" ? (
              <img
                alt=""
                src="https://ark.shalomworld.org/wp-content/uploads/2020/08/1920X1080_Live3.jpg"
                width="100%"
                onClick={() => {
                  setPlayError(true);
                  setSettingDisplay(false);
                }}
              />
            ) : (
              <div className={styles.video_player_wrap}
                onClick={() => {
                  handlePlayingToggle();
                }}
              >
                <ReactPlayer
                  ref={player}
                  url={
                    !url
                      ? vidoes?.length > 0 &&
                      selectedVideo >= 0 &&
                      vidoes[selectedVideo].url
                      : url
                  }
                  className={`${styles.player} ${"shalom-video-" + selectedVideo + new Date().getTime()
                    }`}
                  id="VideoPlayer"
                  volume={volume}
                  width={"100%"}
                  playing={play}
                  height={width >= 992 ? "100%" : "100%"}
                  controls={false}
                  pip={false}
                  onError={handleOnError}
                  onEnded={handleOnEnded}
                  onClick={playHandle}
                  onDuration={duration}
                  onProgress={progress}
                  onStart={handlePlaying} //Only applicable for videos in Banner
                  onPause={handleStop} //Only applicable for videos in Banner
                  onPlay={handlePlaying} //Only applicable for videos in Banner
                  config={{
                    file: {
                      attributes: {
                        crossOrigin: "anonymous",
                        onContextMenu: (e) => e.preventDefault(),
                      },
                      tracks: allTracks ? allTracks : [],
                    },
                  }}
                />
              </div>
            )}
            {/* Spinner starts here */}

            {isLoading || totalDuration === 0 ? (
              <div className={`${styles.video_spinner} ${classes.loaderColor}`}>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </div>
            ) : null}

            {/* Unknonw starts here */}

            <div
              className={`${styles.closeMplayerBtn} ${classes.videoCloseClass}`}
              onClick={() => {
                setVisible(false);
                setSettingDisplay(false);
                setFullscreen(false);
                executeScroll();
              }}
            >
              <FiX />
            </div>
            {/* This Close Button is only applicable for PIP   */}
            {/* Contols starts here */}
            <div
              className={`${styles.control} controls`}
              style={{ opacity: showControls ? 1 : 0 }}
            >
              {/* <div style={{ color: 'red' }}> Desktop </div> */}
              <button
                className={styles.playIcon}
                onClick={() => {
                  if (vidoes?.length > 0 && vidoes[selectedVideo]?.url == "") {
                    setPlayError(true);
                    setSettingDisplay(false);
                  }
                  setPlay(!play);
                  setSettingDisplay(false);
                }}
              >
                {!play ? <FaPlay /> : <FaPause />}
              </button>
              {width >= 992 && (
                <div className={styles.duration}>
                  {secondsToTime(currentDuration)} /{" "}
                  {secondsToTime(totalDuration)}
                </div>
              )}
              {width >= 992 && (
                <div
                  className={`${styles.seekbar} ${classes.seekbarColor} seekbar-duration`}
                >
                  <Slider
                    value={currentDuration}
                    min={0}
                    step={0.1}
                    max={totalDuration}
                    scale={(x) => x ** 10}
                    valueLabelDisplay="off"
                    onChange={seekHandler}
                  />
                </div>
              )}

              <div className={styles.volumeWrap}>
                <div
                  className={styles.volume}
                  onMouseEnter={() => {
                    setVolumeDisplay({
                      ...volumeDisplay,
                      icon: true,
                      slider: false,
                    });
                  }}
                  onMouseLeave={() => {
                    setVolumeDisplay({
                      ...volumeDisplay,
                      icon: false,
                      slider: false,
                    });
                  }}
                >
                  <button onClick={handleVolumeControl}>
                    {volume === 1 ? (
                      <FaVolumeUp />
                    ) : volume > 0 ? (
                      <FaVolumeDown />
                    ) : (
                      <FaVolumeMute />
                    )}
                  </button>
                </div>

                <div
                  className={`${styles.slider} seekbar-volume`}
                  style={{
                    display:
                      volumeDisplay.icon || volumeDisplay.slider
                        ? "block"
                        : "none",
                  }}
                  onMouseEnter={() => {
                    setVolumeDisplay({
                      ...volumeDisplay,
                      slider: true,
                      icon: false,
                    });
                  }}
                  onMouseLeave={() => {
                    setVolumeDisplay({
                      ...volumeDisplay,
                      slider: false,
                      icon: false,
                    });
                  }}
                >
                  <Slider
                    value={volume}
                    min={0}
                    step={0.1}
                    max={1}
                    scale={(x) => x ** 10}
                    valueLabelDisplay="off"
                    onChange={handleChangeVolume}
                    orientation="vertical"
                  />
                </div>
              </div>
              {/* 
              <div style={{ color: 'red' }}>
                Desktop
              </div> */}

              {!url && (
                <button
                  className={styles.setting}
                  onClick={() => {
                    setSettingDisplay(!settingDisplay);
                  }}
                >
                  <ul style={{ display: settingDisplay ? "block" : "none" }}>
                    {vidoes?.length > 0 &&
                      vidoes.map((video, index) => {
                        return (
                          <>
                            {video.height !== "" && (
                              <li
                                className={`${selectedVideo === index ? styles.selected : ""
                                  }`}
                                onClick={() => {
                                  let cd = currentDuration;
                                  setSelectedVideo(index);
                                  handleSelectResolution(
                                    video.width,
                                    video.height
                                  );
                                  setPlay(true);
                                  player.current.seekTo(cd, "seconds");
                                }}
                              >
                                {getResolutionFromWidth(
                                  video.width,
                                  video.height
                                )}
                              </li>
                            )}
                          </>
                        );
                      })}

                    {vidoes?.length > 0 && (
                      <li
                        className={`${selectedVideo === 0 ? styles.selected : ""
                          }`}
                        onClick={() => {
                          let cd = currentDuration;
                          setSelectedVideo(0);
                          handleSelectResolution(
                            vidoes[0].width,
                            vidoes[0].height
                          );
                          setPlay(true);
                          player.current.seekTo(cd, "seconds");
                        }}
                      >
                        Auto{" "}
                      </li>
                    )}
                  </ul>
                  <AiTwotoneSetting />
                </button>
              )}

              {allTracks && allTracks?.length > 0 && (
                <>
                  <button
                    className={styles.setting}
                    onClick={() => {
                      setSettingCC(!settingCC);
                      setSettingDisplay(false);
                      //Work only if its English
                      if (allTracks?.length === 1) {
                        if (language != "en") {
                          handleSelectSubtitle("en");
                        } else {
                          handleSelectSubtitle("err");
                        }
                      }
                    }}
                  >
                    {language === "err" || language === "na" ? (
                      <FaRegClosedCaptioning title="Show subtitles" />
                    ) : (
                      <FaClosedCaptioning title="Hide subtitles" />
                    )}

                    {allTracks && allTracks?.length > 1 && (
                      <ul style={{ display: settingCC ? "block" : "none" }}>
                        {/* <li
                        className={`${language === 'err' ? styles.selected : ""
                          }`}
                        onClick={() => {
                          handleSelectSubtitle('err');
                        }}
                      >
                        {language === 'err' ? "OFF" : "ON"}
                      </li> */}
                        <li>OFF</li>
                        {allTracks?.length > 1 &&
                          allTracks.map((track, index) => {
                            return (
                              <>
                                <li
                                  className={`${language === track?.srcLang
                                    ? styles.selected
                                    : ""
                                    }`}
                                  onClick={() => {
                                    if (language !== track?.srcLang) {
                                      // alert('Set Langugae' + track?.srcLang)
                                      setLanguage(track?.srcLang);
                                      handleSelectSubtitle(track?.srcLang);
                                    } else {
                                      handleSelectSubtitle("err");
                                      // alert('You already selected same');
                                    }
                                  }}
                                >
                                  {track?.srcLang}
                                </li>
                              </>
                            );
                          })}
                      </ul>
                    )}
                  </button>
                </>
              )}

              {/* Do not show for Peace fellowship in iphone        */}

              {([
                "iPad Simulanavigator.platformtor",
                "iPhone Simulator",
                "iPod Simulator",
                "iPad",
                "iPhone",
                "iPod",
              ].includes(navigator.platform) ||
                // iPad on iOS 13 detection
                (navigator.userAgent.includes("Mac") &&
                  "ontouchend" in document)) &&
                path === "/peacefellowship" ? null : (
                <>
                  {!toggleScreen ? (
                    <button
                      className={styles.rightIcon}
                      onClick={() => {
                        setFullscreen(true);
                        setShowControls(false);
                        setToggleScreen(true);
                        handleFullScreenChange();
                        _toggleFullScreen();
                      }}
                    >
                      <FaExpand />
                    </button>
                  ) : (
                    <button
                      className={styles.rightIcon}
                      onClick={() => {
                        setFullscreen(false);
                        setShowControls(true);
                        setToggleScreen(false);
                        handleFullScreenChange();
                      }}
                    >
                      <FaCompress />
                    </button>
                  )}
                </>
              )}


              {!url && !fullscreen && (
                <button
                  className={styles.pipIcon}
                  onClick={() => {
                    setVisible(!visible);
                  }}
                >
                  <i className="icon-miniplayer"></i>
                </button>
              )}

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoPlayer;
