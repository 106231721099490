import React, { lazy, useEffect } from 'react'
import BlogList from '../components/BlogList'
import {
  fetchNewsDescription,
  removeNewsDescription,
} from '../actions/newsAction'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useDimensions } from '../logic/Dimentions'
import { useSeasonal } from '../logic/Seasonal'
import NewsDetails from '../components/NewsDetails'
import CommonLoader from '../components/Shimmers/CommonLoader'
import { TitleComponent } from '../components/TitleComponent'
import { useSeo } from '../logic/Seo/useSeo'
import SocialMediaWrap from '../components/SocailMediaIcons'

const Menu = lazy(() => import('../components/Menu'))
const Subscription = lazy(() => import('../components/Subscription'))
const Footer = lazy(() => import('../components/Footer'))
const MobileMenu = lazy(() => import('../components/MobileMenu'))
const BottomTab = lazy(() => import('../components/BottomTab'))

const NewsDetailPage = ({
  fetchNewsDescription,
  newsDescription,
  removeNewsDescription,
}) => {
  useSeo()
  let { newsId } = useParams()
  const { width } = useDimensions()
  const { classes } = useSeasonal()

  useEffect(() => {
    var url = newsId
    fetchNewsDescription(url)
    return () => {
      removeNewsDescription()
    }
  }, [newsId])

  return (
    <>
      {width >= 992 && <SocialMediaWrap classes={classes} />}
      <TitleComponent title="Shalom world " />
      {width >= 992 ? <Menu /> : <MobileMenu />}

      {width < 992 ? (
        newsDescription && newsDescription.blogTitle ? (
          <NewsDetails newsDetails={newsDescription} />
        ) : (
          <CommonLoader />
        )
      ) : (
        <NewsDetails newsDetails={newsDescription} />
      )}
      {newsDescription && newsDescription.relatedBlogs ? (
        <BlogList blogList={newsDescription.relatedBlogs} />
      ) : null}
      {/* </div>
            </>
            )}
        </Transition> */}
      {width >= 992 ? (
        <>
          <Subscription />
          <Footer />
        </>
      ) : (
        <BottomTab />
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return { newsDescription: state.news.newsDescription }
}
export default connect(mapStateToProps, {
  fetchNewsDescription,
  removeNewsDescription,
})(NewsDetailPage)
