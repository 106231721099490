import { salomapi2, salomapi3 } from '../apis';
import { FETCH_WATCH_LIVE_IMAGES } from './types';
import axios from 'axios';

export const fetchToken = () => async dispatch => {
    const response = await salomapi3.get('/live/token');
    return response.data;
}
export const getSteamUri = (data, region) => async dispatch => {
    const response = await salomapi3.get('/live/stream', { params: { token: data.secure_token, timestamp: data.timestamp, clientId: data.client_id, region: region } });
    return response.data;
}

export const getMassSteamUri = (data, region) => async dispatch => {
    const response = await salomapi3.get('/live/stream', { params: { token: data.secure_token, timestamp: data.timestamp, clientId: data.client_id, region: region } });
    return response.data;
}

export const fetchLiveImages = (region) => async dispatch => {
    let response = await salomapi2.get(`livethumb?region=${region}`);
    dispatch({ type: FETCH_WATCH_LIVE_IMAGES, payload: response.data.data })
}

export const fetchLiveUrl = (url) => async dispatch => {
    const response = await axios.get(url);
    return response;
}

