import styles from "./SignInTab.module.scss";
import React, { useState, useRef, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useFormik } from "formik";

import { salomapi3 } from "../../apis";
import { useSeasonal } from "../../logic/Seasonal";

const ForgotPassword = ({ setForgotClicked, setScreen }) => {
  const [isFormSuccess, setFormSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const { seasonal, classes } = useSeasonal();

  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Email Id is Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Please Enter a valid Email Id";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      const postData = {
        email: values.email,
      };

      salomapi3.post("/media/forgotpass", postData).then((res) => {

        if (res.data.status == "success") {
          setErrorMsg("");
          setFormSuccess(true);

          setSuccessMsg(res.data.msg);
          resetForm({});

          setTimeout(() => {
            setScreen("signIn");
          }, 3000);
        } else if (res.data.status == "error") {
          setErrorMsg(res.data.msg);
          setFormSuccess(false);
        }
      });
    },
  });

  return (
    <div className={`${styles.gallery_signup} ${styles.forgotPassword}`}>
      <h3>Reset Your Password</h3>
      <form className={styles.form_align} onSubmit={formik.handleSubmit}>
        <Row>
          <Col sm={12}>
            <div
              className={`${styles.textClass} ${formik.errors.email && formik.submitCount && styles.formerror
                }`}
            >
              <input
                type="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                name="email"
                placeholder="Email ID"
              ></input>
            </div>
          </Col>
        </Row>
        <div className={styles.btns_wrap}>
          <button
            type="submit"
            className={`${styles.signup_btn} ${classes?.bgBtnClass}`}
          >
            SUBMIT
          </button>
          <button
            className={`${styles.signup_btn} ${classes?.bgBtnClass}`}
            onClick={() => setScreen("signIn")}
          >
            CANCEL
          </button>
        </div>

        {formik.errors.email && formik.submitCount ? (
          <div className="form-group col-sm-12">
            <span className={styles.formtotNullfieldErr}>
              {formik.errors.email}
            </span>
          </div>
        ) : null}

        {isFormSuccess ? (
          <div className="form-group col-sm-12">
            <span className={styles.resonseSuccessMsg}> {successMsg}</span>
          </div>
        ) : null}
        {errorMsg ? (
          <div className="form-group col-sm-12">
            <span className={styles.formtotNullfieldErr}>{errorMsg}</span>
          </div>
        ) : null}
      </form>
    </div>
  );
};

export default ForgotPassword;
