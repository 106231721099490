/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { lazy, useState, useCallback, useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import {
  FaPlay,
  FaPause,
  FaVolumeUp,
  FaVolumeMute,
  FaFastForward,
  FaFastBackward,
  FaUndo,
  FaRedo,
  FaVolumeDown,
} from "react-icons/fa";

import Slider from "@material-ui/core/Slider";
import styles from "./PodCastPlayer.module.scss";
import { useDimensions } from "../../logic/Dimentions";
import useIsVisible from "../../logic/PipVIew";
import { useLocation } from "react-router-dom";
import { useSeasonal } from "../../logic/Seasonal";
import { Spinner } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { AiTwotoneSetting } from "react-icons/ai";
import { Container } from "react-bootstrap";
import imgThumb from "../../assets/img/episodeThumb.png";
import { FiX } from "react-icons/fi";
import Playlist from "./Playlist";
import PodcastShareModal from "../PodcastShareModal";

const PodCastPlayer = ({
  podCastPlayListData,
  play,
  handlePlay,
  handleSetActiveId,
  handleUpdatePlaylist,
  handleClearPlaylist,
  handleProgress,
  activeId,
  progressData,
}) => {
  let newPlay = false;
  const location = useLocation();
  let path = location.pathname;

  const { width } = useDimensions();
  const { classes } = useSeasonal();
  const [volume, setVolume] = useState(1);
  const [showControls, setShowControls] = useState(1);
  const [totalDuration, setTotalDuration] = useState(0);
  const [currentDuration, setCurrentDuration] = useState(0);
  const [volumeDisplay, setVolumeDisplay] = useState({
    icon: false,
    slider: false,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [playError, setPlayError] = useState(false);
  const [playBackRate, setPlayBackRate] = useState(1);
  const [settingDisplay, setSettingDisplay] = useState(false);
  const [playListDisplay, setPlayListDisplay] = useState(false);
  const [activeItem, setActiveItem] = useState(true);
  const [showShareModal, setShareModal] = useState(false);
  const [message, setMessage] = useState("");

  const playBackOptions = [0.7, 0.5, 1, 1.2, 1.5, 2];

  const player = useRef();
  const audioplayer = useRef();

  /**
  * @desc Hide Controls at every 12000 milliseconds with inactivity
  */

  useEffect(() => {
    let myTimeout = setInterval(() => {
      setShowControls(false);
      setSettingDisplay(false);
    }, 10000);
    return () => {
      clearTimeout(myTimeout);
    };
  }, []);


  useEffect(() => {
    if (localStorage.getItem("SHALOM_WORLD_BROWSER_volume") != null) {
      setIsLoading(true);
      let videoVolume = JSON.parse(
        localStorage.getItem("SHALOM_WORLD_BROWSER_volume")
      );

      setTimeout(() => {
        setVolume(0);
        setIsLoading(false);
        if (videoVolume?.volume) {
          setVolume(videoVolume.volume);
        }
      }, 1000);
    }
  }, []);

  /**
   * @desc hide controls after certain intervalhandleProgress
   */

  useEffect(() => {
    let myTimeout = setInterval(() => {
      setShowControls(false);
    }, 12000);
    return () => {
      clearTimeout(myTimeout);
    };
  }, []);

  const [titleAnim, setTilteAnimate] = useState(false);
  useEffect(() => {

    setTilteAnimate(false);
    const timer = setTimeout(() => {
      setTilteAnimate(true);
    }, 100);
    return () => clearTimeout(timer);


  }, [activeId]);

  const handleChangeVolume = (event, newValue) => {
    setVolume(newValue);

    localStorage.setItem(
      "SHALOM_WORLD_BROWSER_volume",
      JSON.stringify({
        creation: Math.round(new Date().getTime() / 1000),
        volume: newValue,
      })
    );
  };

  const playErrorHandler = () => {
    setPlayError(true);
  };

  const playHandle = () => {
    if (newPlay === true) {
      handlePlay(false);
      newPlay = false;
    } else {
      handlePlay(true);
      newPlay = true;
    }
  };

  const duration = (e) => {
    setTotalDuration(e);
  };

  /**
   * @desc set  volume mute/umute
   */
  const handleVolumeControl = (event) => {
    if (volume === 0) {
      let v = 1;
      if (localStorage.getItem("SHALOM_WORLD_BROWSER_volume")) {
        let vArr = JSON.parse(
          localStorage.getItem("SHALOM_WORLD_BROWSER_volume")
        );
        if (vArr?.volume && vArr.volume > 0) {
          v = vArr?.volume;
        }
      }
      setVolume(v);
    } else {
      setVolume(0);
    }
  };

  /**
   * @desc Convert time to hr 01 , min 20  sec 60
   */

  function secondsToTime(e) {
    var h = Math.floor(e / 3600)
      .toString()
      .padStart(2, "0"),
      m = Math.floor((e % 3600) / 60)
        .toString()
        .padStart(2, "0"),
      s = Math.floor(e % 60)
        .toString()
        .padStart(2, "0");

    if (h === `00`) {
      return `${m}:${s}`;
    } else {
      return `${h}:${m}:${s}`;
    }
  }

  /**
   * @desc Record Progress
   */

  const playClass = play ? "playClass" : "";

  const progress = (e) => {
    setCurrentDuration(e.playedSeconds);
    localStorage.setItem(
      "SHALOM_WORLD_BROWSER_audioPlayed",
      JSON.stringify({ path: path, duration: e.playedSeconds })
    );

    let timeRemaining = parseInt(totalDuration) - parseInt(e.playedSeconds);
    let hours = parseInt(timeRemaining / 3600);
    let minutes = Math.trunc(timeRemaining / 60);
    let seconds = Math.trunc(timeRemaining % 60);

    let hoursWithLeadingZero = hours < 10 ? "" : hours + " hr :";
    let minutesWithLeadingZero =
      minutes < 10 ? "0" + minutes + " min" : minutes + " min";
    let secondsWithLeadingZero =
      seconds < 10 ? "0" + seconds + " sec left" : seconds + " sec left";
    let percentage = Math.floor(
      (100 / parseInt(totalDuration)) * parseInt(e.playedSeconds)
    );

    handleProgress({
      percentage: percentage <= 10 ? 2 : percentage,
      remain:
        hoursWithLeadingZero +
        " " +
        minutesWithLeadingZero +
        " " +
        secondsWithLeadingZero,
    });
  };

  const seekHandler = (event, newValue) => {
    player.current.seekTo(newValue, "seconds");
    setCurrentDuration(newValue);
    localStorage.setItem(
      "SHALOM_WORLD_BROWSER_audioPlayed",
      JSON.stringify({ path: path, duration: newValue })
    );
  };

  document.addEventListener("keydown", (e) => EscKey(e));

  function EscKey(e) {
    if (e.code === "Escape") {
    }
  }

  const handlePlaying = () => { };

  const handleStop = () => { };

  const handleOnError = () => {
  };

  /**
   * @desc On Ended, Play next item on the list
   */

  const handleOnEnded = () => {
    let newPlayList = podCastPlayListData;
    let objectFound = podCastPlayListData.find((item) => item.active === true);
    let elemenPosition = podCastPlayListData.indexOf(objectFound);

    if (podCastPlayListData.length > 1) {
      if (elemenPosition - 1 < 0) {
        newPlayList[newPlayList.length - 1].active = true;
        newPlayList[elemenPosition].active = false;
        handleSetActiveId(newPlayList[newPlayList.length - 1].id);
        handlePlay(false)
        // handleUpdatePlaylist(newPlayList[newPlayList.length - 1].id);
      } else {
        newPlayList[elemenPosition - 1].active = true;
        newPlayList[elemenPosition].active = false;
        handleSetActiveId(newPlayList[elemenPosition - 1].id);
        // handleUpdatePlaylist(newPlayList[elemenPosition - 1].id);
      }
    } else {
      //Just Stop playing.
      handlePlay(false)
    }
  };
  const handleBuffer = () => {
    setIsLoading(true);
  };

  const handleBufferEnd = () => {
    setIsLoading(false);
  };

  const handlePlayPrev = () => {
    let newPlayList = podCastPlayListData;
    let objectFound = podCastPlayListData.find((item) => item.active === true);
    let elemenPosition = podCastPlayListData.indexOf(objectFound);

    if (elemenPosition === podCastPlayListData.length - 1) {
      newPlayList[0].active = true;
      newPlayList[elemenPosition].active = false;
      // handleUpdatePlaylist(newPlayList[0].id);
      handleSetActiveId(newPlayList[0].id);
    } else {
      newPlayList[elemenPosition + 1].active = true;
      newPlayList[elemenPosition].active = false;
      // handleUpdatePlaylist(newPlayList[elemenPosition + 1].id);
      handleSetActiveId(newPlayList[elemenPosition + 1].id);
    }
  };

  const handlePlayNext = () => {
    let newPlayList = podCastPlayListData;
    let objectFound = podCastPlayListData.find((item) => item.active === true);
    let elemenPosition = podCastPlayListData.indexOf(objectFound);

    if (elemenPosition - 1 < 0) {
      newPlayList[newPlayList.length - 1].active = true;
      newPlayList[elemenPosition].active = false;
      handleSetActiveId(newPlayList[newPlayList.length - 1].id);

      // handleUpdatePlaylist(newPlayList[newPlayList.length - 1].id);
    } else {
      newPlayList[elemenPosition - 1].active = true;
      newPlayList[elemenPosition].active = false;
      handleSetActiveId(newPlayList[elemenPosition - 1].id);
      // handleUpdatePlaylist(newPlayList[elemenPosition - 1].id);
    }
  };

  const handlePlayThisItem = (id) => {
    let newPlayList = podCastPlayListData;
    let objectFound = podCastPlayListData.find((item) => item.active === true);
    let elemenPosition = podCastPlayListData.indexOf(objectFound);

    let nextObject = podCastPlayListData.find((item) => item.id === id);
    let nextObjectPostion = podCastPlayListData.indexOf(nextObject);
    newPlayList[nextObjectPostion].active = true;
    newPlayList[elemenPosition].active = false;
    handleUpdatePlaylist(newPlayList[nextObjectPostion].id);
  };

  const handleSetMessage = (msg) => {
    setMessage(msg);
  };

  const countPlaces = (num) => {
    var sep = String(23.32).match(/\D/)[0];
    var b = String(num).split(sep);
    return b[1] ? b[1].length : 0;
  }

  const checkNextButton = (id) => {
    let objectFound = podCastPlayListData.find((item) => item.id === id);
    let elemenPosition = podCastPlayListData.indexOf(objectFound);
    if (elemenPosition === 0) {
      return true
    }
    else {
      return false
    }
  }

  const checkPrevButton = (id) => {
    let objectFound = podCastPlayListData.find((item) => item.id === id);
    let elemenPosition = podCastPlayListData.indexOf(objectFound);
    if (elemenPosition === podCastPlayListData.length - 1) {
      return true
    }
    else {
      return false
    }
  }

  return (
    <>
      <div className={`audio-player-wrapper ${styles.audio_player} ${titleAnim ? 'animTitle' : ''} 
      ${[
          "iPad Simulanavigator.platformtor",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform) ||
          // iPad on iOS 13 detection
          (navigator.userAgent.includes("Mac") &&
            "ontouchend" in document) ? "ios_device" : "android_device"}
`}>
        <div
          className="close"
          onClick={() => {
            handlePlay(false);
            handleClearPlaylist();
          }}
        >
          <span>Close</span>
          <FiX />
        </div>

        <Container>
          <ReactPlayer
            playbackRate={playBackRate}
            id="AudioPlayer"
            ref={player}
            // playIcon={true}
            url={
              podCastPlayListData && podCastPlayListData?.length >= 1
                ? podCastPlayListData.find((item) => item.active === true).audio
                : []
            }
            className={styles.player}
            volume={volume}
            width={"100%"}
            height={"auto"}
            playing={play}
            controls={false}
            pip={false}
            playsinline={true}
            playsInline={true}
            onError={handleOnError}
            onEnded={handleOnEnded}
            onBuffer={handleBuffer}
            onBufferEnd={handleBufferEnd}
            onClick={playHandle}
            onDuration={duration}
            onProgress={progress}
            onStart={handlePlaying}
            onPause={handleStop}
            onPlay={handlePlaying}
            config={{
              file: {
                attributes: {
                  onContextMenu: (e) => e.preventDefault(),
                },
              },
              disableDeferredLoading: true,
            }}
          />

          <div className="controls" style={{ opacity: showControls ? 1 : 1 }}>
            <div className="player-controls__left">
              <div className="audio-thumb">
                <figure>
                  <img
                    src={
                      podCastPlayListData.find((item) => item.active === true)
                        .thumbnail?.small
                    }
                    alt=""
                  />
                </figure>
                {/* <div className="audio-dtls">
                    <div className="podcastSlidingText">
                        <div>
                          <strong>
                            {
                              podCastPlayListData.find((item) => item.active === true)
                                .title
                            }
                          </strong>
                        </div>
                    </div>
                    
                  </div> */}
                <div className={`${playClass}`}>
                  <div className="audio-dtls">
                    <div className="podcastSlidingText">
                      <div className="slideText">
                        <strong>
                          {podCastPlayListData.find((item) => item.active === true)
                            .title
                          }
                        </strong>
                        <strong>
                          {podCastPlayListData.find((item) => item.active === true)
                            .title
                          }
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="player-controls__middle">
              <div className="play-controls">
                <div className="controls-left">
                  <button
                    disabled={checkPrevButton(activeId)}
                    className="prev_btn"
                    onClick={() => {
                      handlePlayPrev();
                    }}
                  >
                    {/* Prev */}
                    <span className="ctrls-tooltip">Prev</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15.658"
                      height="16.012"
                      viewBox="0 0 15.658 16.012"
                    >
                      <rect
                        width="3.342"
                        height="15.709"
                        rx="0.752"
                        transform="translate(0 0.035)"
                        fill="#fff"
                      />
                      <path
                        d="M-3428.673,1212.986l13.085-7.555a.45.45,0,0,1,.675.39v15.11a.45.45,0,0,1-.675.39l-13.085-7.555A.45.45,0,0,1-3428.673,1212.986Z"
                        transform="translate(3430.57 -1205.369)"
                        fill="#fff"
                      />
                    </svg>
                  </button>

                  <button
                    className="skip_15 backward"
                    onClick={() => {
                      player.current.seekTo(currentDuration - 15, "seconds");
                    }}
                  >
                    {/* skip backward 15 */}
                    <span className="ctrls-tooltip">Skip backward 15s</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19.209"
                      height="17.786"
                      viewBox="0 0 19.209 17.786"
                    >
                      <g transform="translate(0 0.003)">
                        <path
                          d="M-2208,2811.82h-4.443v-4.468"
                          transform="translate(2214.891 -2807.352)"
                          fill="none"
                          stroke="#fff"
                          strokeMiterlimit="10"
                          strokeWidth="1"
                        />
                        <g transform="translate(0 0.497)">
                          <path
                            d="M-2222.764,2811.808l.032-.051a8.721,8.721,0,0,1,7.32-3.971,8.73,8.73,0,0,1,8.73,8.729,8.863,8.863,0,0,1-3.535,7.017"
                            transform="translate(2225.391 -2807.786)"
                            fill="none"
                            stroke="#fff"
                            strokeMiterlimit="10"
                            strokeWidth="1"
                          />
                          <path
                            d="M.128-11.2v1.535H2.212v8.322H4.043V-11.2Zm9.1,3.816H8.281l.211-2.281h4.238V-11.2H6.943L6.436-5.835h2.38c2.042,0,2.647.591,2.647,1.535s-.774,1.507-2.084,1.507a4.667,4.667,0,0,1-2.9-.972L5.7-2.343A6.162,6.162,0,0,0,9.408-1.2c2.647,0,3.9-1.436,3.9-3.168S12.139-7.384,9.225-7.384Z"
                            transform="translate(-0.128 18.489)"
                            fill="#fff"
                          />
                        </g>
                      </g>
                    </svg>
                  </button>
                </div>
                <div className="play">
                  <button
                    className="play_btn"
                    onClick={() => {
                      handlePlay(!play);
                    }}
                  >
                    {!play ? (
                      // Play
                      <>
                        <span className="ctrls-tooltip">Play</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="38"
                          height="38"
                          viewBox="0 0 38 38"
                        >
                          <g fill="none">
                            <path
                              d="M19,0A19,19,0,1,1,0,19,19,19,0,0,1,19,0Z"
                              stroke="none"
                            />
                            <path
                              d="M 19 1 C 14.19202995300293 1 9.671829223632812 2.872329711914062 6.272079467773438 6.272079467773438 C 2.872329711914062 9.671829223632812 1 14.19202995300293 1 19 C 1 23.80797004699707 2.872329711914062 28.32817077636719 6.272079467773438 31.72792053222656 C 9.671829223632812 35.12767028808594 14.19202995300293 37 19 37 C 23.80797004699707 37 28.32817077636719 35.12767028808594 31.72792053222656 31.72792053222656 C 35.12767028808594 28.32817077636719 37 23.80797004699707 37 19 C 37 14.19202995300293 35.12767028808594 9.671829223632812 31.72792053222656 6.272079467773438 C 28.32817077636719 2.872329711914062 23.80797004699707 1 19 1 M 19 0 C 29.49341011047363 0 38 8.506589889526367 38 19 C 38 29.49341011047363 29.49341011047363 38 19 38 C 8.506589889526367 38 0 29.49341011047363 0 19 C 0 8.506589889526367 8.506589889526367 0 19 0 Z"
                              stroke="none"
                              fill="#fff"
                            />
                          </g>
                          <path
                            d="M5.143,1.429a1,1,0,0,1,1.715,0l4.234,7.056A1,1,0,0,1,10.234,10H1.766A1,1,0,0,1,.909,8.486Z"
                            transform="translate(25 13) rotate(90)"
                            fill="#fff"
                          />
                        </svg>
                      </>
                    ) : (
                      // Pause
                      <>
                        <span className="ctrls-tooltip">Pause</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="38"
                          height="38"
                          viewBox="0 0 38 38"
                        >
                          <path
                            d="M5.143,1.429a1,1,0,0,1,1.715,0l4.234,7.056A1,1,0,0,1,10.234,10H1.766A1,1,0,0,1,.909,8.486Z"
                            transform="translate(25 13) rotate(90)"
                            opacity="0"
                          />
                          <circle cx="19" cy="19" r="19" fill="#fff" />
                          <g transform="translate(-308.5 -1179)">
                            <rect
                              width="3"
                              height="12"
                              rx="1"
                              transform="translate(323 1192)"
                            />
                            <rect
                              width="3"
                              height="12"
                              rx="1"
                              transform="translate(329 1192)"
                            />
                          </g>
                        </svg>
                      </>
                    )}
                  </button>
                </div>
                <div className="controls-right">
                  <button
                    className="skip_15 forward"
                    onClick={() => {
                      player.current.seekTo(currentDuration + 15, "seconds");
                    }}
                  >
                    {/* skip forward 15 */}
                    <span className="ctrls-tooltip">Skip forward 15s</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19.21"
                      height="17.787"
                      viewBox="0 0 19.21 17.787"
                    >
                      <g transform="translate(0.5 0.002)">
                        <path
                          d="M-2212.443,2811.82H-2208v-4.468"
                          transform="translate(2224.262 -2807.352)"
                          fill="none"
                          stroke="#fff"
                          strokeMiterlimit="10"
                          strokeWidth="1"
                        />
                        <g transform="translate(0 0.498)">
                          <path
                            d="M-2206.682,2811.808l-.032-.051a8.721,8.721,0,0,0-7.32-3.971,8.73,8.73,0,0,0-8.73,8.729,8.725,8.725,0,0,0,4.188,7.457"
                            transform="translate(2222.764 -2807.786)"
                            fill="none"
                            stroke="#fff"
                            strokeMiterlimit="10"
                            strokeWidth="1"
                          />
                          <path
                            d="M.128-11.2v1.535H2.212v8.322H4.043V-11.2Zm9.1,3.816H8.281l.211-2.281h4.239V-11.2H6.944L6.437-5.835h2.38c2.042,0,2.647.591,2.647,1.535S10.689-2.793,9.38-2.793a4.667,4.667,0,0,1-2.9-.972L5.7-2.343A6.162,6.162,0,0,0,9.408-1.2c2.647,0,3.9-1.436,3.9-3.168S12.14-7.384,9.225-7.384Z"
                            transform="translate(5.401 18.489)"
                            fill="#fff"
                          />
                        </g>
                      </g>
                    </svg>
                  </button>

                  {/* 
                  <span style={{ color: 'red' }}>
                    activeId  {activeId}
                    {checkNextButton(activeId)}
                  </span> */}


                  <button
                    disabled={checkNextButton(activeId)}
                    className="next_btn"
                    onClick={() => {
                      handlePlayNext();
                    }}
                  >
                    {/* Next */}
                    <span className="ctrls-tooltip">Next</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15.657"
                      height="16.013"
                      viewBox="0 0 15.657 16.013"
                    >
                      <rect
                        width="3.342"
                        height="15.71"
                        rx="0.752"
                        transform="translate(12.315 0.035)"
                        fill="#fff"
                      />
                      <path
                        d="M-3415.137,1212.986l-13.086-7.555a.45.45,0,0,0-.675.39v15.11a.45.45,0,0,0,.675.39l13.086-7.555A.45.45,0,0,0-3415.137,1212.986Z"
                        transform="translate(3428.898 -1205.369)"
                        fill="#fff"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="seekbar">
                <div className="playback-position">
                  {" "}
                  {secondsToTime(currentDuration, "standard")}
                </div>
                <div className={`playback-slider ${classes.seekbarColor}`}>
                  <Slider
                    value={currentDuration}
                    min={0}
                    step={0.1}
                    max={totalDuration}
                    scale={(x) => x ** 10}
                    valueLabelDisplay="off"
                    onChange={seekHandler}
                  />
                </div>
                <div className="playback-duration">
                  {" "}
                  {secondsToTime(totalDuration, "standard")}
                </div>
              </div>
            </div>

            <div className="player-controls__right">
              <div className="playlist ">
                <button className={`${playListDisplay ? 'active' : ''} `}
                  onClick={() => {
                    setPlayListDisplay(!playListDisplay);
                  }}
                >
                  <span className="ctrls-tooltip">Playlist</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                  >
                    <g fill="#fff" stroke="#707070" strokeWidth="1" opacity="0">
                      <rect width="25" height="24" stroke="none" />
                      <rect
                        x="0.5"
                        y="0.5"
                        width="24"
                        height="23"
                        fill="none"
                      />
                    </g>
                    <g transform="translate(-1438 -916.189)">
                      <line
                        x2="18.804"
                        transform="translate(1438 918.189)"
                        fill="none"
                        stroke="#fff"
                        strokeWidth="1.2"
                      />
                      <line
                        x2="12.5"
                        transform="translate(1438 933.184)"
                        fill="none"
                        stroke="#fff"
                        strokeWidth="1.2"
                      />
                      <g transform="translate(1452.98 928.804)">
                        <line
                          x2="9.917"
                          transform="translate(0 4.647)"
                          fill="none"
                          stroke="#fff"
                          strokeWidth="1.2"
                        />
                        <line
                          x2="9.917"
                          transform="translate(4.958 0) rotate(90)"
                          fill="none"
                          stroke="#fff"
                          strokeWidth="1.2"
                        />
                      </g>
                      <line
                        x2="18.804"
                        transform="translate(1438 926.275)"
                        fill="none"
                        stroke="#fff"
                        strokeWidth="1.2"
                      />
                    </g>
                  </svg>
                </button>
              </div>

              <div className="speed">
                <button className="speed_btn" onClick={() => {
                  setSettingDisplay(!settingDisplay);
                }}>
                  <span className="ctrls-tooltip">Speed</span>
                  <div className="speed-control">
                    {countPlaces(playBackRate) === 0 ? <><div className="speed-val">{playBackRate}<em>x</em></div></> : <> <div className="speed-val"><div>{playBackRate}</div><div>x</div></div></>}
                  </div>
                </button>

                <ul className={`speed_list ${settingDisplay ? 'd-block' : 'd-none'}`}>
                  {playBackOptions.map((val, index) => {
                    return (
                      <li
                        key={index}
                        className={`${val === playBackRate ? "active" : ""}`}
                        onClick={() => {
                          handlePlay(true);
                          setPlayBackRate(val);
                          setSettingDisplay(false);
                        }}
                      >
                        {`${val}x`}
                      </li>
                    );
                  })}
                </ul>

              </div>



              <div className={`volume ${styles.volumeWrap}`}>
                <div
                  className="volume_hover"
                  onMouseEnter={() => {
                    setVolumeDisplay({
                      ...volumeDisplay,
                      icon: true,
                      slider: false,
                    });
                    setSettingDisplay(false);
                  }}
                  onMouseLeave={() => {
                    setVolumeDisplay({
                      ...volumeDisplay,
                      icon: false,
                      slider: false,
                    });
                  }}
                >
                  <button onClick={handleVolumeControl}>
                    {volume === 1 ? (
                      // Volume Up
                      <>
                        <span className="ctrls-tooltip">Volume Up</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                        >
                          <g
                            id="Volume_Increase_"
                            data-name="Volume Increase "
                            transform="translate(12289 9949)"
                          >
                            <g
                              id="Rectangle_23887"
                              data-name="Rectangle 23887"
                              transform="translate(-12289 -9949)"
                              fill="none"
                              stroke="#fff"
                              strokeWidth="1"
                              opacity="0"
                            >
                              <rect width="30" height="30" stroke="none" />
                              <rect
                                x="0.5"
                                y="0.5"
                                width="29"
                                height="29"
                                fill="none"
                              />
                            </g>
                            <g
                              id="Volume_Increase_2"
                              data-name="Volume Increase "
                            >
                              <path
                                id="Path_4383"
                                data-name="Path 4383"
                                d="M110.933,47.589l-.012-.008-9.341-6.5a.224.224,0,0,0-.1-.025h-3.9a.662.662,0,0,1-.663-.659V34.209a.661.661,0,0,1,.663-.658h3.92a.224.224,0,0,0,.116-.032l9.305-6.5a.666.666,0,0,1,.684-.019.651.651,0,0,1,.336.573v19.45a.65.65,0,0,1-.336.573A1.242,1.242,0,0,1,110.933,47.589Zm.234-.375a.221.221,0,0,0,.221,0,.213.213,0,0,0,.111-.188V27.577a.213.213,0,0,0-.111-.188.223.223,0,0,0-.226,0l-9.3,6.5a.674.674,0,0,1-.357.1h-3.92a.219.219,0,0,0-.221.217V40.4a.219.219,0,0,0,.221.217h3.9a.66.66,0,0,1,.345.1Z"
                                transform="translate(-12383.417 -9970.918)"
                                stroke="#fff"
                                strokeWidth="1"
                              />
                              <path
                                id="Path_4384"
                                data-name="Path 4384"
                                d="M500.661,191.1a.221.221,0,0,1,0-.312,5.088,5.088,0,0,0,0-7.187.221.221,0,0,1,.312-.312,5.53,5.53,0,0,1,0,7.812A.393.393,0,0,1,500.661,191.1Z"
                                transform="translate(-12768.337 -10120.804)"
                                stroke="#fff"
                                strokeWidth="1"
                              />
                              <path
                                id="Path_4385"
                                data-name="Path 4385"
                                d="M538.73,156.16a.221.221,0,0,1,0-.312,7.291,7.291,0,0,0,0-10.312.221.221,0,0,1,.312-.312,7.733,7.733,0,0,1,0,10.937A.393.393,0,0,1,538.73,156.16Z"
                                transform="translate(-12802.248 -10084.307)"
                                stroke="#fff"
                                strokeWidth="1"
                              />
                            </g>
                          </g>
                        </svg>
                      </>
                    ) : volume > 0 ? (
                      // Volume Down
                      <>
                        <span className="ctrls-tooltip">Volume Down</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                        >
                          <g
                            id="Volume_Decrease_"
                            data-name="Volume Decrease "
                            transform="translate(12289 9903)"
                          >
                            <g
                              id="Volume_Decrease_2"
                              data-name="Volume Decrease "
                              transform="translate(0 46)"
                            >
                              <path
                                id="Path_4383"
                                data-name="Path 4383"
                                d="M110.933,47.589l-.012-.008-9.341-6.5a.224.224,0,0,0-.1-.025h-3.9a.662.662,0,0,1-.663-.659V34.209a.661.661,0,0,1,.663-.658h3.92a.224.224,0,0,0,.116-.032l9.305-6.5a.666.666,0,0,1,.684-.019.651.651,0,0,1,.336.573v19.45a.65.65,0,0,1-.336.573A1.242,1.242,0,0,1,110.933,47.589Zm.234-.375a.221.221,0,0,0,.221,0,.213.213,0,0,0,.111-.188V27.577a.213.213,0,0,0-.111-.188.223.223,0,0,0-.226,0l-9.3,6.5a.674.674,0,0,1-.357.1h-3.92a.219.219,0,0,0-.221.217V40.4a.219.219,0,0,0,.221.217h3.9a.66.66,0,0,1,.345.1Z"
                                transform="translate(-12383.417 -9970.918)"
                                stroke="#fff"
                                strokeWidth="1"
                              />
                              <path
                                id="Path_4384"
                                data-name="Path 4384"
                                d="M500.661,191.1a.221.221,0,0,1,0-.312,5.088,5.088,0,0,0,0-7.187.221.221,0,0,1,.312-.312,5.53,5.53,0,0,1,0,7.812A.393.393,0,0,1,500.661,191.1Z"
                                transform="translate(-12768.337 -10120.804)"
                                stroke="#fff"
                                strokeWidth="1"
                              />
                            </g>
                            <g
                              id="Rectangle_23888"
                              data-name="Rectangle 23888"
                              transform="translate(-12289 -9903)"
                              fill="none"
                              stroke="#fff"
                              strokeWidth="1"
                              opacity="0"
                            >
                              <rect width="30" height="30" stroke="none" />
                              <rect
                                x="0.5"
                                y="0.5"
                                width="29"
                                height="29"
                                fill="none"
                              />
                            </g>
                          </g>
                        </svg>
                      </>
                    ) : (
                      // Mute
                      <>
                        <span className="ctrls-tooltip">Mute</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                        >
                          <defs>
                            <clipPath id="clip-path">
                              <rect
                                id="Rectangle_23886"
                                data-name="Rectangle 23886"
                                width="18.99"
                                height="21.728"
                                transform="translate(0 0)"
                                fill="none"
                              />
                            </clipPath>
                          </defs>
                          <g id="Mute" transform="translate(12289 9860)">
                            <g
                              id="Group_126253"
                              data-name="Group 126253"
                              transform="translate(-12286.5 -9855.864)"
                            >
                              <g
                                id="Group_126252"
                                data-name="Group 126252"
                                clip-path="url(#clip-path)"
                              >
                                <path
                                  id="Path_76611"
                                  data-name="Path 76611"
                                  d="M15.3,3.888a.72.72,0,0,0,.72-.72V1.158A1.154,1.154,0,0,0,14.22.2L4.99,6.638H1.16A1.16,1.16,0,0,0,0,7.8v6.19a1.15,1.15,0,0,0,1.15,1.15H4.97l9.24,6.44.16.08a2.07,2.07,0,0,0,.49.07,1.759,1.759,0,0,0,.46-.06l.11-.05a1.121,1.121,0,0,0,.59-1.01v-2a.72.72,0,0,0-1.44,0v1.47l-8.9-6.2a1.221,1.221,0,0,0-.61-.17H1.44V8.078H5.09a1.188,1.188,0,0,0,.64-.19L14.58,1.7v1.47A.72.72,0,0,0,15.3,3.888Z"
                                  fill="#fff"
                                />
                                <line
                                  id="Line_2224"
                                  data-name="Line 2224"
                                  x2="6.032"
                                  y2="6.373"
                                  transform="translate(12.208 7.678)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeWidth="1.5"
                                />
                                <line
                                  id="Line_2225"
                                  data-name="Line 2225"
                                  x1="6.032"
                                  y2="6.373"
                                  transform="translate(12.208 7.678)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeWidth="1.5"
                                />
                              </g>
                            </g>
                            <g
                              id="Rectangle_23889"
                              data-name="Rectangle 23889"
                              transform="translate(-12289 -9860)"
                              fill="none"
                              stroke="#fff"
                              strokeWidth="1"
                              opacity="0"
                            >
                              <rect width="30" height="30" stroke="none" />
                              <rect
                                x="0.5"
                                y="0.5"
                                width="29"
                                height="29"
                                fill="none"
                              />
                            </g>
                          </g>
                        </svg>
                      </>
                    )}
                  </button>
                </div>

                {/* Volume Slider */}
                <div
                  className={`slider ${classes.seekbarColor}`}
                  style={{
                    display:
                      volumeDisplay.icon || volumeDisplay.slider
                        ? "block"
                        : "none",
                  }}
                  onMouseEnter={() => {
                    setVolumeDisplay({
                      ...volumeDisplay,
                      slider: true,
                      icon: false,
                    });
                  }}
                  onMouseLeave={() => {
                    setVolumeDisplay({
                      ...volumeDisplay,
                      slider: false,
                      icon: false,
                    });
                  }}
                >
                  <Slider
                    value={volume}
                    min={0}
                    step={0.1}
                    max={1}
                    scale={(x) => x ** 10}
                    valueLabelDisplay="off"
                    onChange={handleChangeVolume}
                    orientation="vertical"
                  />
                </div>
              </div>
              <div className="share">
                <button
                  className="share_btn"
                  onClick={() => {
                    setShareModal(true);
                  }}
                >
                  <span className="ctrls-tooltip">Share</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20.49"
                    height="22.673"
                    viewBox="0 0 20.49 22.673"
                  >
                    <g transform="translate(0.7 0.765)">
                      <path
                        d="M-54.467,317.206v12.6a1.053,1.053,0,0,0,1.075,1.029H-36.45a1.052,1.052,0,0,0,1.074-1.029v-12.6"
                        transform="translate(54.467 -309.626)"
                        fill="none"
                        stroke="#fff"
                        strokeWidth="1.4"
                      />
                      <g transform="translate(5.406)">
                        <path
                          d="M-46.109,321.01V309.376"
                          transform="translate(50.214 -308.903)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="square"
                          strokeWidth="1.4"
                        />
                        <path
                          d="M-50.214,312.874l3.856-3.856a.4.4,0,0,1,.557,0l3.855,3.856"
                          transform="translate(50.214 -308.903)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="square"
                          strokeWidth="1.4"
                        />
                      </g>
                    </g>
                  </svg>
                </button>
              </div>
            </div>
          </div>

          {playListDisplay && (
            <Playlist
              playListDisplay={playListDisplay}
              podCastPlayListData={podCastPlayListData}
              setPlayListDisplay={setPlayListDisplay}
              handlePlayThisItem={handlePlayThisItem}
              handlePlay={handlePlay}
              play={play}
              handleSetActiveId={handleSetActiveId}
              activeId={activeId}
              progressData={progressData}
            />
          )}
        </Container>
      </div>

      <PodcastShareModal
        show={showShareModal}
        handleSetMessage={handleSetMessage}
        onHide={(e) => {
          setShareModal(false);
          setMessage();
        }}
        message={message}
        episode={podCastPlayListData.find((item) => item.active === true)}
        podcastSlug={""}
        copyLinkText={
          window.location.origin +
          "/podcast/" +
          podCastPlayListData.find((item) => item.active === true)
            ?.podcastSlug +
          "/" +
          podCastPlayListData.find((item) => item.active === true)?.url
        }
      />
    </>
  );
};

export default PodCastPlayer;
