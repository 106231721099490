import { useEffect } from "react";
import { fetchSeo } from "../../actions/seoAction";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export const useSeo = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  let path = location.pathname;
  let page = path;
  let type = "page";

  if (path.includes("show") && path != "/shows") {
    const arr = path.split("/");
    page = arr[arr.length - 1];
    type = "programs";
  }

  if (path.includes("episode")) {
    const arr = path.split("/");
    page = arr[arr.length - 1];
    type = "episodes";
  }
  if (path.includes("news")) {
    const arr = path.split("/");
    page = arr[arr.length - 1];
  }

  if (path.includes("podcast")) {
    //Check if podcast detail or  podcast episode detail page
    let urlParams = path.split("/");

    if (urlParams.length === 3) {
      page = urlParams[2];
      type = "podcasts";
    } else if (urlParams.length === 4) {
      page = urlParams[3];
      type = "podcastepisodes";
    } else {
      type = "page";
    }
  }
  if (path.includes("promotion")) {
    const arr = path.split("/");
    page = arr[arr.length - 1];
    type = "promotion";
  }

  if (path.includes("events")) {
    const arr = path.split("/");
    page = arr[arr.length - 1];
    type = "event";
  }

  const seoData = useSelector((state) => state.seo);
  useEffect(() => {
    if (path === "/") {
      page = "home";
    }

    let meta = seoData.find((o) => o.page === page);
    if (!meta) {
      dispatch(fetchSeo(page, type));
    }
  }, []);
};
