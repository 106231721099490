import React, { useState, useEffect, useRef } from "react";
import styles from "./PodcastDetailMob.module.scss";
import { Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import PodcastMobileThumb from "../PodcastMobileThumb";
import PodcastAbout from "../PodcastAbout";
import PodcastListenOn from "../PodcastListenOn";
import { useSeasonal } from "../../../logic/Seasonal";
import { useLocation } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import LoadMoreLoader from "../../../components/Shimmers/LoadMoreLoader"

// import PodcastAllEpisodeShimmer from "../../Shimmers/PodcastAllEpisodeShimmer/PodcastAllEpisodeShimmer";

const PodcastDetailMob = ({
    handlePlay,
    data,
    handleUpdatePlaylist,
    handleAddToPlaylist,
    activeId,
    progress,
    podCastPlayListData,
    play,
    handleClickUrl,
    handleSupportPodcast,
    setSeasonId,
    seasonId,
    handleSeasonChange,
    loadMoreEpisodes
}) => {
    const { hash } = useLocation();
    const scrollerRef = useRef();

    const [readmore, setReadMore] = useState(false);
    const [listenOn, setListenOn] = useState(false);
    const { classes } = useSeasonal();

    useEffect(() => {
        if (hash && hash === "#play" && data?.episodes?.length >= 1) {
            handleAddToPlaylist();
            const timer = setTimeout(() => {
                handlePlay(false);
            }, 500);
            return () => clearTimeout(timer);
        }
    }, [data?.episodes]);

    return (
        <>
            <section className={styles.PodcastBannerMob}>
                <figure className="figImg">
                    <img src={data?.bannerImgMobile} alt={data?.title} />
                </figure>
                <div className={`${styles.bannerContent} container`}>
                    <div className={styles.episode_total_time}>
                        {data?.latestEpisode && (
                            <strong>{data.latestEpisode[seasonId]} Episodes</strong>
                        )}
                        {data?.totalDuration && <strong>{data?.totalDuration[seasonId]} </strong>}
                    </div>
                    <h1>{data?.title}</h1>

                    {data?.title && (
                        <article>
                            Hosted by
                            <span>
                                {data?.host &&
                                    data?.host?.[0]?.name &&
                                    data?.host &&
                                    data?.host?.[0]?.name}
                            </span>
                            {data?.guest && data?.guest?.[0]?.name && data?.guest && (
                                <>
                                    and
                                    <span>{data?.guest?.[0]?.name}</span>
                                </>
                            )}
                            , about the podc…{" "}
                            <Link
                                onClick={() => {
                                    setReadMore(true);
                                }}
                                className={classes.txtColor}
                            >
                                Readmore
                            </Link>
                        </article>
                    )}
                </div>
            </section>
            <Container>
                {data?.title && (
                    <div className={styles.btn_wrap}>
                        <Button
                            className={`web_btn btn btn-primary ${classes?.bgBtnClass}`}
                            onClick={() => {
                                setListenOn(true);
                            }}
                        >
                            Listen On
                        </Button>
                        <Button
                            className={`web_btn btn btn-outline-primary ${classes?.fullSchduleBtn}`}
                            onClick={handleSupportPodcast}
                        >
                            Support Podcasts
                        </Button>
                    </div>
                )}

                <div className={styles.episodesWrapper}>
                    {data?.title && (
                        <div className={styles.episodes_seasons}>
                            <h4 className={styles.episodesTitle}>All Episodes</h4>

                            {data && data?.seasons && data?.seasons.length > 1 && (
                                <div className={`${styles.ddlSeason}`}>
                                    <select
                                        onChange={(e) => {
                                            setSeasonId(e.target.value);
                                            handleSeasonChange(e.target.value)
                                        }}
                                    >
                                        {data?.seasons.map((season, index) => (
                                            <option value={index} key={index}>
                                                {season?.title}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}
                        </div>
                    )}

                    <div className={styles.episodeListWrap} ref={scrollerRef} >
                        {/* <span style={{ color: 'red' }}> # {parseInt(data?.seasons[seasonId].page) + 1}  <br /></span>
                        <span style={{ color: 'red' }}> # {data?.seasons[seasonId].pages} <br /> </span>
                        <span style={{ color: 'red' }}> # {parseInt(data.seasons[seasonId].page) + 1 >= data?.seasons[seasonId].pages ? 'STOPPED' : 'PAGINATE'} <br /> </span>*/}

                        <InfiniteScroll
                            pageStart={0}
                            loadMore={() => loadMoreEpisodes(parseInt(data?.seasons[seasonId].page) + 1)}
                            hasMore={parseInt(data.seasons[seasonId].page) >= data?.seasons[seasonId].pages ? false : true}
                            loader={
                                <div className="loader" key={0} >
                                    <LoadMoreLoader />
                                </div>
                            }
                            useWindow={false}
                            getScrollParent={() => scrollerRef.current}
                        >
                            {data && data?.seasons[seasonId]?.episodes &&
                                data?.seasons[seasonId]?.episodes.length >= 1 &&
                                data?.seasons[seasonId]?.episodes.map((value, index) => {
                                    return (
                                        <PodcastMobileThumb
                                            episode={value}
                                            handleUpdatePlaylist={handleUpdatePlaylist}
                                            activeId={activeId}
                                            progress={progress}
                                            play={play}
                                            podCastPlayListData={podCastPlayListData}
                                            handlePlay={handlePlay}
                                            handleClickUrl={handleClickUrl}
                                            key={index}
                                        />
                                    );
                                })}
                        </InfiniteScroll>
                    </div>
                    {/* <PodcastAllEpisodeShimmer></PodcastAllEpisodeShimmer> */}
                </div>
            </Container>

            <PodcastAbout readmore={readmore} setReadMore={setReadMore} data={data} />
            <PodcastListenOn
                listenOn={listenOn}
                setListenOn={setListenOn}
                data={data}
            />

        </>
    );
};

export default PodcastDetailMob;
