import React, { useState, useRef, useEffect } from "react";
import { Container } from "react-bootstrap";
import { FiChevronLeft } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";
import ReactPlayer from "react-player";
import PodcastPlaylist from "../PodcastPlaylist";
import MobPodcastInfo from "../MobPodcastInfo";
import styles from "./PodcastPlayScreen.module.scss";
import PodcastShareModal from "../../PodcastShareModal";
import PodcastAudioSpeed from "../PodcastAudioSpeed";
import Slider from "@material-ui/core/Slider";
import { useSeasonal } from "../../../logic/Seasonal";
// import rewindPlayImg from "../../../assets/img/rewind-play-img.png";

const PodcastPlayScreen = ({
    audioDtls,
    setAudioDtls,
    episode,
    activeId,
    progressData,
    play,
    handlePlay,
    podCastPlayListData,
    handleSetActiveId,
    handleUpdatePlaylist,
    pageType,
    setEpisodeInfo,
    episodeInfo,
    isPlaylist,
    setPlaylist,
}) => {
    const location = useLocation();
    let path = location.pathname;

    let newPlay = false;
    const player = useRef();
    const playBackOptions = [0.7, 0.5, 1, 1.2, 1.5, 2];

    const [speedModal, setSpeedModal] = useState(false);
    const [shareModal, setShareModal] = useState(false);
    const [message, setMessage] = useState("");
    const [playBackRate, setPlayBackRate] = useState(1);
    const [totalDuration, setTotalDuration] = useState(0);
    const [currentDuration, setCurrentDuration] = useState(0);
    const { classes } = useSeasonal();
    const [volume, setVolume] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [slideNext, setSlideNext] = useState(false);
    const [slidePrev, setSlidePrev] = useState(false);
    const [titleAnim, setTilteAnimate] = useState(false);
    // const [checkSafari, setCheckSafari] = useState(false);
    const [imageAnimation, setImageAnimation] = useState(false);

    /**
      * @desc Hide Controls at every 12000 milliseconds with inactivity
    */

    useEffect(() => {
        let myTimeout = setInterval(() => {
            setSpeedModal(false);
        }, 10000);
        return () => {
            clearTimeout(myTimeout);
        };
    }, []);

    /**
      * @desc 
    */

    useEffect(() => {
        setImageAnimation(true)

    }, [activeId]);

    const scrollEnable = (e) => {
        document.querySelector("html").classList.remove("scroll_disable");
        document.body.classList.remove("scroll_disable");
        return;
    };

    if (window.matchMedia("(orientation: portrait)").matches) {
        // you're in PORTRAIT mode
        document.body.classList.remove("landscape");
        document.querySelector("html").classList.remove("l_scroll");
        document.body.classList.remove("l_scroll");
    }

    if (window.matchMedia("(orientation: landscape)").matches) {
        // you're in LANDSCAPE mode
        document.querySelector("html").classList.add("l_scroll");
        document.body.classList.add("l_scroll");
        document.body.classList.add("landscape");
    }

    const handleSetMessage = (msg) => {
        setMessage(msg);
    };

    const handleOnError = () => {
    };

    /**
     * @desc On Ended, Play next item on the list
     */

    const handleOnEnded = () => {
        let newPlayList = podCastPlayListData;
        let objectFound = podCastPlayListData.find((item) => item.active === true);
        let elemenPosition = podCastPlayListData.indexOf(objectFound);
        if (podCastPlayListData.length > 1) {
            if (elemenPosition - 1 < 0) {
                newPlayList[newPlayList.length - 1].active = true;
                newPlayList[elemenPosition].active = false;
                handleSetActiveId(newPlayList[newPlayList.length - 1].id);
                handlePlay(false)

                // handleUpdatePlaylist(newPlayList[newPlayList.length - 1].id);
            } else {
                newPlayList[elemenPosition - 1].active = true;
                newPlayList[elemenPosition].active = false;
                handleSetActiveId(newPlayList[elemenPosition - 1].id);
                // handleUpdatePlaylist(newPlayList[elemenPosition - 1].id);
            }
        }
        else {
            //Just Stop playing.
            handlePlay(false)
        }
    };
    const handleBuffer = () => {
        setIsLoading(true);
    };

    const handleBufferEnd = () => {
        setIsLoading(false);
    };

    const playHandle = () => {
        if (newPlay === true) {
            handlePlay(false);
            newPlay = false;
        } else {
            handlePlay(true);
            newPlay = true;
        }
    };

    const duration = (e) => {
        setTotalDuration(e);
    };

    const progress = (e) => {
        setCurrentDuration(e.playedSeconds);
    };

    const seekHandler = (event, newValue) => {
        player.current.seekTo(newValue, "seconds");
        setCurrentDuration(newValue);
        localStorage.setItem(
            "SHALOM_WORLD_BROWSER_audioPlayed",
            JSON.stringify({ path: path, duration: newValue })
        );
    };

    const handlePlaying = () => { };

    const handleStop = () => { };

    const handlePlayNext = () => {
        let newPlayList = podCastPlayListData;
        let objectFound = podCastPlayListData.find((item) => item.active === true);
        let elemenPosition = podCastPlayListData.indexOf(objectFound);

        if (elemenPosition === 0) {
            newPlayList[0].active = false;
            newPlayList[newPlayList?.length - 1].active = true;
            handleSetActiveId(newPlayList[newPlayList?.length - 1].id);
        } else {
            newPlayList[elemenPosition - 1].active = true;
            newPlayList[elemenPosition].active = false;
            // handleUpdatePlaylist(newPlayList[elemenPosition + 1].id);
            handleSetActiveId(newPlayList[elemenPosition - 1].id);
        }
    };

    const handlePlayPrev = () => {
        let newPlayList = podCastPlayListData;
        let objectFound = podCastPlayListData.find((item) => item.active === true);
        let elemenPosition = podCastPlayListData.indexOf(objectFound);

        if (elemenPosition === podCastPlayListData.length - 1) {
            newPlayList[0].active = true;
            newPlayList[elemenPosition].active = false;
            // handleUpdatePlaylist(newPlayList[0].id);
            handleSetActiveId(newPlayList[0].id);
        } else {
            newPlayList[elemenPosition + 1].active = true;
            newPlayList[elemenPosition].active = false;
            // handleUpdatePlaylist(newPlayList[elemenPosition + 1].id);
            handleSetActiveId(newPlayList[elemenPosition + 1].id);
        }
    };

    const handlePlayThisItem = (id) => {
        let newPlayList = podCastPlayListData;
        let objectFound = podCastPlayListData.find((item) => item.active === true);
        let elemenPosition = podCastPlayListData.indexOf(objectFound);

        let nextObject = podCastPlayListData.find((item) => item.id === id);
        let nextObjectPostion = podCastPlayListData.indexOf(nextObject);
        newPlayList[nextObjectPostion].active = true;
        newPlayList[elemenPosition].active = false;
        handleUpdatePlaylist(newPlayList[nextObjectPostion].id);
    };

    /**
     * @desc Convert time to hr 01 , min 20  sec 60
     */

    function secondsToTime(e) {
        var h = Math.floor(e / 3600)
            .toString()
            .padStart(2, "0"),
            m = Math.floor((e % 3600) / 60)
                .toString()
                .padStart(2, "0"),
            s = Math.floor(e % 60)
                .toString()
                .padStart(2, "0");

        if (h === `00`) {
            return `${m}:${s}`;
        } else {
            return `${h}:${m}:${s}`;
        }
    }

    const handleShowPlaylist = () => {
        setPlaylist(true);
        return;
    };
    const handleNext = () => {
        setSlideNext(false);
        setSlidePrev(false);
        const timer = setTimeout(() => {
            setSlideNext(true);
        }, 100);
        return () => clearTimeout(timer);
    };

    const handlePrev = () => {
        setSlidePrev(false);
        setSlideNext(false);
        const time = setTimeout(() => {
            setSlidePrev(true);
        }, 100);
        return () => clearTimeout(time);
    };

    const animTitle = () => {
        var audioTitle = document.getElementById("audioTitle");
        audioTitle.classList.remove("animTitle");
        setTilteAnimate(false);
        const timer = setTimeout(() => {
            setTilteAnimate(true);
        }, 500);
        return () => clearTimeout(timer);
    };


    const checkNextButton = (id) => {
        let objectFound = podCastPlayListData.find((item) => item.id === id);
        let elemenPosition = podCastPlayListData.indexOf(objectFound);
        if (elemenPosition === 0) {
            return true
        }
        else {
            return false
        }
    }

    const checkPrevButton = (id) => {
        let objectFound = podCastPlayListData.find((item) => item.id === id);
        let elemenPosition = podCastPlayListData.indexOf(objectFound);
        if (elemenPosition === podCastPlayListData.length - 1) {
            return true
        }
        else {
            return false
        }
    }
    // const [isLoaded, setLoaded] = useState(false);
    // const onLoad = () => {
    //     setLoaded(true);
    //   };
    return (
        <>
            <ReactPlayer
                playbackRate={playBackRate}
                id="AudioPlayer"
                ref={player}
                url={episode.audio}
                className={styles.player}
                volume={volume}
                width={"100%"}
                height={"auto"}
                playing={play}
                controls={false}
                pip={false}
                playsinline={true}
                playsInline={true}
                onError={handleOnError}
                onEnded={handleOnEnded}
                onBuffer={handleBuffer}
                onBufferEnd={handleBufferEnd}
                onClick={playHandle}
                onDuration={duration}
                onProgress={progress}
                onStart={handlePlaying}
                onPause={handleStop}
                onPlay={handlePlaying}
                config={{
                    file: {
                        attributes: {
                            onContextMenu: (e) => e.preventDefault(),
                        },
                    },
                    disableDeferredLoading: true,
                }}
            />
            <div id="audioPlayScreen" className={`${styles.rewindPlayScreen} ${slideNext ? styles.nextSlide : ""} ${slidePrev ? styles.prevSlide : ""} ${audioDtls ? styles.show : ""} play_screen ${[
                "iPad Simulanavigator.platformtor",
                "iPhone Simulator",
                "iPod Simulator",
                "iPad",
                "iPhone",
                "iPod",
            ].includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document) ? styles.ios_device : styles.android_device}                      
                `} >
                <Container>
                    <div className={`${styles.rewindScreenContainer} `} >
                        <div className={styles.rewindPlayHeader}>


                            {/* Shows Go back btn */}
                            <Link
                                onClick={() => {
                                    setAudioDtls(false);
                                    scrollEnable();
                                    setSpeedModal(false)
                                    setPlaylist(false)
                                }}
                            >
                                <i>
                                    <FiChevronLeft />
                                </i>
                            </Link>

                            {/* Shows Title */}
                            <p className="podcastTitle">{episode?.podcastTitle}</p>

                            {/*  Shows i btn for - Podcast description, Podcast Host, Guest */}
                            <div
                                className={styles.epInfo}
                                onClick={() => {
                                    setEpisodeInfo(true);
                                }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="19.698"
                                    height="19.698"
                                    viewBox="0 0 19.698 19.698"
                                >
                                    <g
                                        id="Group_2"
                                        data-name="Group 2"
                                        transform="translate(-335.068 -59.234)"
                                    >
                                        <g
                                            id="Group_1"
                                            data-name="Group 1"
                                            transform="translate(17 -7)"
                                        >
                                            <path
                                                id="Path_76602"
                                                data-name="Path 76602"
                                                d="M21.2,12.1A9.1,9.1,0,1,1,12.1,3a9.1,9.1,0,0,1,9.1,9.1Z"
                                                transform="translate(315.818 63.984)"
                                                fill="none"
                                                stroke="#fff"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="1.5"
                                            />{" "}
                                            <path
                                                id="Path_76603"
                                                data-name="Path 76603"
                                                d="M18,21.64V18"
                                                transform="translate(309.917 58.083)"
                                                fill="none"
                                                stroke="#fff"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="1.5"
                                            />
                                            <path
                                                id="Path_76604"
                                                data-name="Path 76604"
                                                d="M18,12h0"
                                                transform="translate(309.917 60.443)"
                                                fill="none"
                                                stroke="#fff"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="1.8"
                                            />
                                        </g>
                                    </g>
                                </svg>
                            </div>

                        </div>

                        {/* Thumbnail  */}
                        <div className={styles.podcastThumb} onClick={() => {
                            setSpeedModal(false)
                            setPlaylist(false)
                        }}>
                            <figure className={`figImg`}>
                                <img src={episode?.thumbnail?.large} alt={episode?.title} />
                            </figure>
                        </div>
                        {/* Slide text contains Podcast Title, Episode Title  */}
                        <div className={styles.audioDetails}>
                            <div className={styles.audioDtls}>
                                <div id="audioTitle" className={`${styles.audioData} ${titleAnim ? 'animTitle' : ''} animTitle `}>
                                    <div className="podcastSlidingText">
                                        <div className="slideText">
                                            <span>{episode?.title}</span>
                                            <span>{episode?.title}</span>
                                        </div>
                                    </div>
                                    <ul>
                                        <li>{episode?.podcastTitle} </li>

                                        {Array.isArray(episode?.host) && !episode?.host.length && (
                                            <>
                                                {episode?.host[0] ? (
                                                    <li>{episode?.host[0]?.name + "..."} </li>
                                                ) : null}
                                            </>
                                        )}
                                    </ul>
                                </div>

                                <div
                                    className={styles.audioShare}
                                    onClick={() => {
                                        setShareModal(true);
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18.661"
                                        height="20.574"
                                        viewBox="0 0 18.661 20.574"
                                    >
                                        <g transform="translate(0.7 0.699)">
                                            <path
                                                d="M-38.177,330.228H-53.5a1.653,1.653,0,0,1-1.672-1.63V317.206h1.4V328.6a.254.254,0,0,0,.272.23h15.318a.253.253,0,0,0,.271-.23V317.206h1.4V328.6A1.653,1.653,0,0,1-38.177,330.228Z"
                                                transform="translate(54.467 -310.352)"
                                                fill="#fff"
                                            />
                                            <g transform="translate(4.888)">
                                                <path
                                                    d="M-45.409,320.6h-1.4V308.676h1.4Z"
                                                    transform="translate(49.821 -308.949)"
                                                    fill="#fff"
                                                />
                                                <path
                                                    d="M-42.738,313.483l-3.737-3.738-3.738,3.738-.99-.99,3.981-3.981a1.06,1.06,0,0,1,1.492,0l3.982,3.983Z"
                                                    transform="translate(50.214 -308.903)"
                                                    fill="#fff"
                                                />
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            {/* Adjust value of width acoording to podcast progress*/}
                            {/* <div style={{ color: 'red' }}>
                                ActiveId:  {activeId} <br />
                                EpisodeID:  {episode.id} <br />
                                ProgressData:  {JSON.stringify(progressData)} <br />
                            </div> */}

                            <div className={styles.mediaPlayer}>
                                {/* <div
                                    className={styles.audioProgress}
                                    style={{ width: activeId === episode.id ? progressData.percentage + "90%" : "100%" }}
                                ></div> */}

                                <div
                                    className={`playback-slider ${styles.seekbar} ${classes.seekbarColor}`}
                                >
                                    <Slider
                                        value={currentDuration}
                                        min={0}
                                        step={0.1}
                                        max={totalDuration}
                                        scale={(x) => x ** 10}
                                        valueLabelDisplay="off"
                                        onChange={seekHandler}
                                    />
                                </div>
                            </div>
                            <div className={styles.mediaPlayTime}>
                                <p className={styles.currentPlayTime}>
                                    {" "}
                                    {secondsToTime(currentDuration, "standard")}
                                </p>
                                <p className={styles.totalPlayTime}>
                                    {" "}
                                    {secondsToTime(totalDuration, "standard")}
                                </p>
                            </div>

                            <div className={styles.mediaPlayAction}>
                                {/* Skip 15 sec backwards */}
                                <div className={"blinkBtn"}
                                    onClick={() => {
                                        player.current.seekTo(currentDuration - 15, "seconds");
                                    }}

                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="19.209"
                                        height="17.786"
                                        viewBox="0 0 19.209 17.786"
                                    >
                                        <g transform="translate(-178.92 -613.607)">
                                            <path
                                                d="M-2208,2812.32h-4.943v-4.968h1v3.968H-2208Z"
                                                transform="translate(2393.811 -2193.741)"
                                                fill="#fff"
                                            />
                                            <g transform="translate(178.92 614.107)">
                                                <path
                                                    d="M-2209.906,2823.924l-.623-.783a8.4,8.4,0,0,0,3.346-6.625,8.239,8.239,0,0,0-8.23-8.229,8.209,8.209,0,0,0-6.9,3.741l-.031.048-.844-.536.035-.056a9.206,9.206,0,0,1,7.739-4.2,9.24,9.24,0,0,1,9.23,9.229A9.377,9.377,0,0,1-2209.906,2823.924Z"
                                                    transform="translate(2225.391 -2807.786)"
                                                    fill="#fff"
                                                />
                                                <path
                                                    d="M.128-11.2v1.535H2.212v8.322H4.043V-11.2Zm9.1,3.816H8.281l.211-2.281h4.238V-11.2H6.943L6.436-5.835h2.38c2.042,0,2.647.591,2.647,1.535s-.774,1.507-2.084,1.507a4.667,4.667,0,0,1-2.9-.972L5.7-2.343A6.162,6.162,0,0,0,9.408-1.2c2.647,0,3.9-1.436,3.9-3.168S12.139-7.384,9.225-7.384Z"
                                                    transform="translate(-0.128 18.489)"
                                                    fill="#fff"
                                                />
                                            </g>
                                        </g>
                                    </svg>
                                </div>

                                {/* Move to previous audio */}

                                <div className={`blinkBtn ${checkPrevButton(activeId) ? 'disabled' : ''}`}
                                    onClick={() => {
                                        handlePlayPrev();
                                        handlePrev();
                                        animTitle();
                                        // onLoad();
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="28"
                                        height="28"
                                        viewBox="0 0 28 28"
                                    >
                                        <g transform="translate(-92 -655)">
                                            <g opacity="0">
                                                <rect
                                                    width="28"
                                                    height="28"
                                                    transform="translate(92 655)"
                                                    fill="#fff"
                                                />
                                                <path
                                                    d="M1,1V27H27V1H1M0,0H28V28H0Z"
                                                    transform="translate(92 655)"
                                                    fill="#707070"
                                                />
                                            </g>
                                            <g transform="translate(96.242 658.835)">
                                                <rect
                                                    width="4.081"
                                                    height="19.187"
                                                    rx="0.752"
                                                    transform="translate(0 0.043)"
                                                    fill="#fff"
                                                />
                                                <path
                                                    d="M-3428.623,1214.672l15.982-9.228a.549.549,0,0,1,.825.476v18.454a.55.55,0,0,1-.825.476l-15.982-9.227A.55.55,0,0,1-3428.623,1214.672Z"
                                                    transform="translate(3430.939 -1205.369)"
                                                    fill="#fff"
                                                />
                                            </g>
                                        </g>
                                    </svg>
                                </div>

                                {/* Play/Pause button */}
                                <div className={styles.playOrPause}>
                                    <span
                                        className={`play-icon ${play ? "pause" : ""}`}
                                        onClick={(e) => {
                                            // playToggleClass();
                                            handlePlay(!play);
                                        }}
                                    >
                                        {play && activeId === episode.id ?
                                            // play
                                            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="12" viewBox="0 0 9 12"><g transform="translate(-323 -1192)"><rect width="3" height="12" rx="1" transform="translate(323 1192)" /><rect width="3" height="12" rx="1" transform="translate(329 1192)" /></g></svg>
                                            :
                                            // pause
                                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12" viewBox="0 0 10 12"><path d="M5.143,1.429a1,1,0,0,1,1.715,0l4.234,7.056A1,1,0,0,1,10.234,10H1.766A1,1,0,0,1,.909,8.486Z" transform="translate(10) rotate(90)" fill="#ebab0b" /></svg>

                                        }
                                    </span>
                                </div>

                                {/* Move to next audio */}

                                <div className={`blinkBtn ${checkNextButton(activeId) ? 'disabled' : ''}`}
                                    onClick={() => {
                                        handlePlayNext();
                                        handleNext();
                                        animTitle();
                                        // onLoad();
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="28"
                                        height="28"
                                        viewBox="0 0 28 28"
                                    >
                                        <g opacity="0">
                                            <rect width="28" height="28" fill="#fff" />
                                            <path d="M1,1V27H27V1H1M0,0H28V28H0Z" fill="#707070" />
                                        </g>
                                        <g transform="translate(4.635 3.835)">
                                            <rect
                                                width="4.081"
                                                height="19.187"
                                                rx="0.752"
                                                transform="translate(15.041 0.043)"
                                                fill="#fff"
                                            />
                                            <path
                                                d="M-3412.092,1214.672l-15.982-9.228a.549.549,0,0,0-.825.476v18.454a.55.55,0,0,0,.825.476l15.982-9.227A.55.55,0,0,0-3412.092,1214.672Z"
                                                transform="translate(3428.898 -1205.369)"
                                                fill="#fff"
                                            />
                                        </g>
                                    </svg>
                                </div>

                                {/* Skip 15 sec forward */}
                                <div className={"blinkBtn"}
                                    onClick={() => {
                                        player.current.seekTo(currentDuration + 15, "seconds");
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="19.21"
                                        height="17.787"
                                        viewBox="0 0 19.21 17.787"
                                    >
                                        <g transform="translate(-178.42 -579.607)">
                                            <path
                                                d="M-2207.5,2812.32h-4.943v-1h3.943v-3.968h1Z"
                                                transform="translate(2403.182 -2227.742)"
                                                fill="#fff"
                                            />
                                            <g transform="translate(178.92 580.107)">
                                                <path
                                                    d="M-2218.837,2824.4a9.291,9.291,0,0,1-3.209-3.3,9.23,9.23,0,0,1-1.219-4.586,9.24,9.24,0,0,1,9.23-9.229,9.206,9.206,0,0,1,7.739,4.2l0,.005.032.051-.844.536-.031-.048a8.209,8.209,0,0,0-6.9-3.741,8.239,8.239,0,0,0-8.23,8.229,8.181,8.181,0,0,0,3.948,7.03Z"
                                                    transform="translate(2222.764 -2807.786)"
                                                    fill="#fff"
                                                />
                                                <path
                                                    d="M.128-11.2v1.535H2.212v8.322H4.043V-11.2Zm9.1,3.816H8.281l.211-2.281h4.239V-11.2H6.944L6.437-5.835h2.38c2.042,0,2.647.591,2.647,1.535S10.689-2.793,9.38-2.793a4.667,4.667,0,0,1-2.9-.972L5.7-2.343A6.162,6.162,0,0,0,9.408-1.2c2.647,0,3.9-1.436,3.9-3.168S12.14-7.384,9.225-7.384Z"
                                                    transform="translate(5.401 18.489)"
                                                    fill="#fff"
                                                />
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <div className={styles.mediaPlayFooter}>
                                <div
                                    className={`${styles.mediaPlaySpeed}`}
                                    onClick={() => {
                                        setSpeedModal(true);
                                    }}
                                >
                                    {`${playBackRate}x`}
                                </div>
                                <div className={`${styles.mediaPlayOptions}`}>
                                    <Link
                                        onClick={() => {
                                            handleShowPlaylist();
                                        }}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="23.333"
                                            height="21"
                                            viewBox="0 0 23.333 21"
                                        >
                                            <g transform="translate(-14.94 -700)">
                                                <g opacity="0">
                                                    <rect
                                                        width="23.166"
                                                        height="21"
                                                        transform="translate(14.94 700)"
                                                        fill="#fff"
                                                    />
                                                    <path
                                                        d="M1,1V20H22.166V1H1M0,0H23.166V21H0Z"
                                                        transform="translate(14.94 700)"
                                                        fill="#707070"
                                                    />
                                                </g>
                                                <g transform="translate(15.01 701.788)">
                                                    <path d="M16.814.6H0V-.6H16.814Z" fill="#fff" />
                                                    <path
                                                        d="M11.178.6H0V-.6H11.178Z"
                                                        transform="translate(0 13.408)"
                                                        fill="#fff"
                                                    />
                                                    <g transform="translate(14.395 9.491)">
                                                        <path
                                                            d="M8.868.6H0V-.6H8.868Z"
                                                            transform="translate(0 4.156)"
                                                            fill="#fff"
                                                        />
                                                        <path
                                                            d="M8.868.6H0V-.6H8.868Z"
                                                            transform="translate(4.434 0) rotate(90)"
                                                            fill="#fff"
                                                        />
                                                    </g>
                                                    <path
                                                        d="M16.814.6H0V-.6H16.814Z"
                                                        transform="translate(0 7.23)"
                                                        fill="#fff"
                                                    />
                                                </g>
                                            </g>
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>

                    </div>
                </Container>

                <div className={styles.backgroundImg}>
                    <figure className="figImg">
                        <img src={episode?.thumbnail?.large} alt={episode?.title} />
                    </figure>
                </div>

            </div>

            <PodcastPlaylist
                isPlaylist={isPlaylist}
                setPlaylist={setPlaylist}
                podCastPlayListData={podCastPlayListData}
                handlePlayThisItem={handlePlayThisItem}
                handlePlay={handlePlay}
                play={play}
                handleSetActiveId={handleSetActiveId}
                activeId={activeId}
                progressData={progressData}
            />

            <MobPodcastInfo
                episodeInfo={episodeInfo}
                setEpisodeInfo={setEpisodeInfo}
                episode={episode}
                handlePlay={handlePlay}
                play={play}
                handleSetActiveId={handleSetActiveId}
                activeId={activeId}

            />

            <PodcastAudioSpeed
                speedModal={speedModal}
                setSpeedModal={setSpeedModal}
                playBackOptions={playBackOptions}
                setPlayBackRate={setPlayBackRate}
                playBackRate={playBackRate}
                handlePlay={handlePlay}
            />

            <PodcastShareModal
                shareModal={shareModal}
                setShareModal={setShareModal}
                show={shareModal}
                handleSetMessage={handleSetMessage}
                onHide={(e) => {
                    setShareModal(false);
                    setMessage();
                }}
                message={message}
                episode={episode}
                podcastSlug={""}
                copyLinkText={
                    window.location.origin +
                    "/podcast/" +
                    episode?.podcastSlug +
                    "/" +
                    episode?.url
                }
            />
        </>
    );
};

export default PodcastPlayScreen;
