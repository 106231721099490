/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";

import ReactPlayer from "react-player/lazy";
import {
  FaPlay,
  FaPause,
  FaVolumeUp,
  FaVolumeMute,
  FaExpand,
  FaVolumeDown,
  FaCompress,
} from "react-icons/fa";
import { AiTwotoneSetting } from "react-icons/ai";
import Slider from "@material-ui/core/Slider";
import styles from "./LiveVideoPlayerIos.module.scss";
import { useDispatch } from "react-redux";
import { fetchLiveUrl } from "../../actions/watchLiveAction";
import imgPreloader from "../../assets/img/preloader.gif";
import { useLocation } from "react-router-dom";
import { Spinner } from "react-bootstrap";

const LiveVideoPlayerIos = ({
  url,
  fullscreen,
  setFullscreen,
  playError,
  setPlayError,
  live,
  play,
  setPlay,
  config,
}) => {
  const [videos, setVideos] = useState({});
  const [currentDuration, setCurrentDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [selectedVideo, setSelectedVideo] = useState(0);
  const [settingDisplay, setSettingDisplay] = useState(false);
  const [toggleScreen, setToggleScreen] = useState(false);
  const [muted, setMuted] = useState(false);
  const [selectedResolution, setSelectedResolution] = useState(0);
  const [showControls, setShowControls] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  let path = location.pathname;
  const player = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    setPlayError(true);
    if (url) {
      dispatch(fetchLiveUrl(url)).then((res) => {
        let a = res.data.split(",");
        let arr = a.filter((item) => item.includes("RESOLUTION"));
        let obj = {};
        arr.map((item, index) => {
          if (index === 0) {
            obj["0"] = url; //Auto url for m3u8
          }
          const key = item.search("https");
          const urlEnd = item.search("#");
          const resolution = item.slice(0, key).split("x");

          obj[parseFloat(resolution[0].slice(11))] = item.slice(key, urlEnd);
        });
        setVideos(obj);
      });
    }
  }, [dispatch, setPlayError, url]);

  const handleChangeVolume = (event, newValue) => {
    setVolume(newValue);

    localStorage.setItem(
      "SHALOM_WORLD_BROWSER_volume",
      JSON.stringify({
        creation: Math.round(new Date().getTime() / 1000),
        volume: newValue,
      })
    );
  };

  // Close events

  if (document.addEventListener) {
    document.addEventListener("webkitfullscreenchange", exitHandler, false);
    document.addEventListener("mozfullscreenchange", exitHandler, false);
    document.addEventListener("fullscreenchange", exitHandler, false);
    document.addEventListener("MSFullscreenChange", exitHandler, false);
  }

  document.addEventListener("keydown", (e) => EscKey(e));

  function EscKey(e) {
    if (e.code === "Escape") {
      fullscreen && setFullscreen(false);
      setToggleScreen(false);
      root.classList.remove("h_overflow");
      document.body.classList.remove("full-screen");
      document.body.classList.remove("full-screen-mac");
    }
  }

  function exitHandler() {
    if (
      !document.webkitIsFullScreen &&
      !document.mozFullScreen &&
      !document.msFullscreenElement
    ) {
      fullscreen && setFullscreen(false);
      setToggleScreen(false);
      root.classList.remove("h_overflow");
      document.body.classList.remove("full-screen");
      document.body.classList.remove("full-screen-mac");
    }
  }
  var root = document.getElementsByTagName("html")[0];
  //

  const getResolutionFromWidth = (res) => {
    if (res > 1500) return "Full HD";
    else if (res > 1100) return "HD";
    else if (res > 800) return "SD";
    else if (res >= 640) return "Low";
    else if (res === "0") return "Auto";
  };

  const handleSelectResolution = (res) => {
    setShowControls(true);
    let value = getResolutionFromWidth(res);
    setSelectedResolution(value);
  };

  /**
   * @desc hide controls after certain interval
   */

  useEffect(() => {
    let myTimeout = setInterval(() => {
      setShowControls(false);
      setSettingDisplay(false);
    }, 12000);
    return () => {
      clearTimeout(myTimeout);
    };
  }, []);

  document.addEventListener("mousemove", (e) => {
    setShowControls(true);
  });

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  document.addEventListener("mousemove", (e) => {
    setShowControls(true);
  });
  /**
   * @desc set last volume and play to first time
   */
  function handleKeyDown(event) {
    if (fullscreen) {
      if (event.keyCode === 32) {
        setPlay(!play);
        setSettingDisplay(false);
      }

      if (event.keyCode === 27) {
        setShowControls(true);
        setToggleScreen(false);
        setFullscreen(false);
        setSettingDisplay(false);
      }
    }
  }

  useEffect(() => {
    setPlay(false);
    if (localStorage.getItem("SHALOM_WORLD_BROWSER_volume") != null) {
      setIsLoading(true);
      let videoVolume = JSON.parse(
        localStorage.getItem("SHALOM_WORLD_BROWSER_volume")
      );

      setTimeout(() => {
        setVolume(0);
        setIsLoading(false);
        if (videoVolume?.volume) {
          setVolume(videoVolume.volume);
        }
      }, 2000);
    }
  }, []);

  var VideoPlayerRefernce = document.querySelector("#VideoPlayer video");

  if (VideoPlayerRefernce) {
    VideoPlayerRefernce.addEventListener(
      "webkitendfullscreen",
      function () {
        videoExitedFullscreen(VideoPlayerRefernce);
      },
      false
    );
  }

  function videoExitedFullscreen(videoElement) {
    //check if an element is currently full screen
    if (
      document.fullScreenElement ||
      document.webkitIsFullScreen == true ||
      document.mozFullScreen ||
      document.msFullscreenElement
    ) {
      //do whatever here if the video is now (just became) full screen
      videoElement.removeAttribute("controls");
      setFullscreen(true);
      setShowControls(false);
      setPlay(true);
    } else {
      setFullscreen(false);
      videoElement.removeAttribute("controls");
      setShowControls(true);
      setPlay(false);

      //do whatever you want on fullscreen close, like pause or mute
    }
  }

  const _toggleFullScreen = function _toggleFullScreen() {
    const vid = document.querySelector("#VideoPlayer video");

    setPlay(true);
    if (vid.requestFullScreen) {
      vid.requestFullScreen();
    } else if (vid.webkitRequestFullScreen) {
      vid.webkitRequestFullScreen();
    } else if (vid.mozRequestFullScreen) {
      vid.mozRequestFullScreen();
    } else if (vid.msRequestFullscreen) {
      vid.msRequestFullscreen();
    } else if (vid.webkitEnterFullscreen) {
      // vid.webkitEnterFullscreen(); //for iphone this code worked
      vid.play().then(() => vid.webkitEnterFullscreen());
    }
  };

  return (
    <>
      <div
        id="VideoPlayerWrap"
        className={`${styles.videoPlayer
          } player1 mobile-videoplayer mobile-player-ios ${"volume-" + volume}`}
      >
        {!play && (
          <span
            className={`${styles.actionPlay} ${styles.playClass} ${styles.borderOnly} `}
            onClick={() => {
              setPlay(true);
              setSettingDisplay(false);
            }}
          ></span>
        )}

        {url === "" ? (
          <div className={styles.preloader}>
            <img src={imgPreloader} alt="preloader" />
          </div>
        ) : (
          <div
            className={`${styles.custom_live_player} custom_live_player`}
            onClick={() => setPlay(true)}
          >
            <ReactPlayer
              ref={player}
              url={videos[selectedVideo] || url}
              id="VideoPlayer"
              className={styles.player}
              volume={volume}
              width={"100%"}
              config={{
                file: {
                  hlsOptions: config,
                },
              }}
              playing={play}
              height={path === "/" ? "100%" : "100%"}
              controls={false}
              onContextMenu={(e) => e.preventDefault()}
              muted={muted}
              playsInline
            />
          </div>
        )}

        {isLoading ? (
          <div className={styles.video_spinner}>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="light"
            />
          </div>
        ) : null}

        <div
          className={`${styles.control} custom-controls`}
          style={{ opacity: showControls ? 1 : 0 }}
        >
          <button
            onClick={() => {
              setToggleScreen(true);
              setPlay(false);
              const timer = setTimeout(() => {
                setPlay(!play);
              }, 100);
              return () => clearTimeout(timer);
            }}
          >
            {!play ? <FaPlay /> : <FaPause />}
          </button>

          <button
            onClick={() => {
              setVolume(volume == 1 ? 0 : 1);
            }}
          >
            {volume == 1 ? (
              <FaVolumeUp />
            ) : volume > 0 ? (
              <FaVolumeDown />
            ) : (
              <FaVolumeMute />
            )}
          </button>
          <div className={styles.slider}>
            <Slider
              value={volume}
              min={0}
              step={0.1}
              max={1}
              scale={(x) => x ** 10}
              valueLabelDisplay="off"
              onChange={handleChangeVolume}
            />
          </div>

          <div className={styles.liveText}>LIVE</div>

          <button
            className={styles.rightIcon}
            onClick={() => {
              setToggleScreen(true);
              _toggleFullScreen();
              setPlay(false);
              const timer = setTimeout(() => {
                setPlay(true);
              }, 100);
              return () => clearTimeout(timer);
            }}
          >
            <FaExpand />
          </button>
          {/* <div style={{ color: 'blue' }}>IOS</div> */}

          <button
            className={styles.setting}
            onClick={() => {
              setSettingDisplay(!settingDisplay);
            }}
          >
            <ul style={{ display: settingDisplay ? "block" : "none" }}>
              {Object.keys(videos)?.length > 0 &&
                Object.keys(videos)
                  .reverse()
                  .map((video, index) => {
                    return (
                      <>
                        <li
                          // eslint-disable-next-line eqeqeq
                          className={`${selectedVideo == video ? styles.selected : ""
                            }`}
                          onClick={() => {
                            let cd = currentDuration;
                            setSelectedVideo(video);
                            handleSelectResolution(video);
                            setPlay(true);
                            player.current.seekTo(cd, "seconds");
                          }}
                        >
                          {getResolutionFromWidth(video)}
                        </li>
                      </>
                    );
                  })}
            </ul>
            <AiTwotoneSetting />
          </button>
        </div>
      </div>
    </>
  );
};

export default LiveVideoPlayerIos;
