import React from "react";
import styles from "./VechcleTypes.module.scss";
// import car from "../../../assets/img/car.png";
// import pickup from "../../../assets/img/pickup.png";
// import caravan from "../../../assets/img/caravan.png";
// import bike from "../../../assets/img/bike.png";
// import boats from "../../../assets/img/boat.png";
// import plane from "../../../assets/img/plane.png";
import apartment from "../../../assets/img/apartment.png";
import house from "../../../assets/img/house.png";
import people from "../../../assets/img/people.png";
import plot from "../../../assets/img/plot.png";
import storefront from "../../../assets/img/storefront.png";

import { useSeasonal } from "../../../logic/Seasonal";

const VechcleTypes = () => {
    const { seasonal, classes } = useSeasonal();

    return (
        <div className={styles.accept_vechiles_wrap}>
            <h2 className={classes?.seasonalClass}>
                All Types of Vehicles are Considered
            </h2>
            <div className={styles.accept_vechiles}>

                <ul>
                    <li>
                        <figure>
                            {/* <img src={car} alt="data" />
                            <figcaption>Car</figcaption> */}
                            <img src={apartment} alt="data" />
                            <figcaption>Apartment</figcaption>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            {/* <img src={pickup} alt="data" />
                            <figcaption>Pick up</figcaption> */}
                            <img src={house} alt="data" />
                            <figcaption>House</figcaption>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            {/* <img src={caravan} alt="data" />
                            <figcaption>Caravan</figcaption> */}

                            <img src={people} alt="data" />
                            <figcaption>People</figcaption>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            {/* <img src={plane} alt="data" />
                            <figcaption>Plane</figcaption> */}

                            <img src={plot} alt="data" />
                            <figcaption>Plot</figcaption>
                        </figure>
                    </li>
                    <li>
                        <figure>
                            {/* <img src={bike} alt="data" />
                            <figcaption>Two Wheeler</figcaption> */}

                            <img src={storefront} alt="data" />
                            <figcaption>Store font</figcaption>
                        </figure>
                    </li>
                    {/* <li>
                        <figure>
                            <img src={boats} alt="data" />
                            // <figcaption>Boats</figcaption>
                        </figure>
                    </li> */}
                </ul>
            </div>
        </div>
    );
};
export default VechcleTypes;
