import React, { useState, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import styles from "./Podcasts.module.scss";
import PodcastList from "../PodcastList";
import { TitleComponent } from "../TitleComponent";
import SocialMediaWrap from "../SocailMediaIcons";
import { useSeasonal } from "../../logic/Seasonal";
import { useDimensions } from "../../logic/Dimentions";
import { useSeo } from "../../logic/Seo/useSeo";
import { fetchPodcasts, updatePodcast } from "../../actions/podCastsAction";
import { usePodCasts } from "../../logic/PodCastsList/usePodCasts";
import PodcastListShimmer from "../Shimmers/PodcastListShimmer/PodcastListShimmer";
import MobPodcastListShimmer from "../Shimmers/MobPodcastListShimmer/MobPodcastListShimmer";

/**
 * This component renders a list of podcasts.
 *
 * @param {object} podCastsData The podcast data.
 * @param {function} updatePodcast A function that is called when a podcast is updated.
 * @param {function} fetchPodcasts A function that is called to fetch more podcasts.
 * @param {number} activeId The ID of the currently active podcast.
 * @param {object} podCasts The Redux store state for podcasts.
 * @returns {ReactElement} The rendered podcast list component.
 */
const Podcasts = ({
  podCastsData,
  updatePodcast,
  fetchPodcasts,
  activeId,
  podCasts,

}) => {
  /**
   * Tracks the scroll position of the parent container.
   */
  const scrollParentRef = useRef(null);

  /**
   * Tracks the current page number.
   */
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  /**
   * Gets the screen width.
   */
  const { width } = useDimensions();

  /**
   * Gets the seasonal classes.
   */
  const { classes } = useSeasonal();

  /**
   * Updates the Redux store with the latest podcast data.
   */
  usePodCasts({
    podCastsData,
    updatePodcast,
    fetchPodcasts,
    page,
    setPage,
  });
  useSeo();

  /**
   * Renders a loader component when there is no data.
   */
  const loader = (
    <div className={`${styles.PodcastLoader} mt-12`}>
      {width >= 992 ? (
        <>
          <Row>
            {/* <PodcastListShimmer />
            <PodcastListShimmer />
            <PodcastListShimmer />
            <PodcastListShimmer /> */}
          </Row>
        </>
      ) : (
        <Row>
          <Col>
            <Row>
              {" "}
              <MobPodcastListShimmer />{" "}
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );

  /**
  * Loads more podcasts when the user scrolls to the bottom of the list.
  */
  const loadItems = () => {
    if (loading) {
      return
    }

    if (page <= podCastsData?.totalPages) {
      setLoading(true)
      let pageNo = page + 1;
      setPage(pageNo); // <-- Change this line!
      fetchPodcasts(
        pageNo
      ).then((response) => {
        const timer = setTimeout(() => {
          setLoading(false)
        }, 1000);
        return () => clearTimeout(timer);
      });
    }
  };

  /*Update Redux before API hits*. Rather than waiting for redux update after API response. Pass title, description, image earlier */
  const handleClick = (item) => {
    updatePodcast(item);
  };

  return (
    <>
      <div className={styles.bgImageWrap_new}>
        <img
          src={podCastsData?.banner ? podCastsData.banner : ""}
          className="realImg"
          alt=""
        />
      </div>
      <div className={`${styles.headingAlign} mob-app-pad-0 mob-app-mar-0 `}>
        {width >= 992 && <SocialMediaWrap classes={classes} />}
        <TitleComponent title="Shalom world " />

        <Container>
          <h1 className={`${styles.ttlHome} ${classes?.seasonalClass}`}>
            Our Podcasts
          </h1>

          {/* <div
            className={`${styles.procastListsWrapper} ${activeId ? styles.audioThumbShow : ""
              }`}
          > */}
          <div
            ref={scrollParentRef}
            className={`${styles.procastListsWrapper} ${activeId ? styles.audioThumbShow : ""
              }`}

          >
            <InfiniteScroll
              pageStart={1}
              loadMore={loadItems}
              hasMore={page !== podCastsData?.totalPages}
              loader={loader}
              getScrollParent={() => scrollParentRef}
            >
              <PodcastList
                data={podCastsData?.data}
                handleClick={handleClick}
                key={""}
              />
            </InfiniteScroll>

          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    podCasts: state.podCasts,
    podCastsData: state.podCasts?.programs,
    activeId: state.podCastPlayList?.activeId,
  };
};

export default connect(mapStateToProps, { fetchPodcasts, updatePodcast })(
  Podcasts
);
