import React from 'react';
import styles from './Banner.module.scss';
const Banner = ({bannerDetails}) => {
    return (
        <div className={styles.videoImgBanner}>
            <div className={styles.vi_video_banner}>
                <img src={bannerDetails.image} width="100px" />
                <div className={styles.vi_banner_content}>
                    <h6>{bannerDetails.banner_content}</h6>
                    <h4>{bannerDetails.banner_title}</h4>
                </div>
            </div>
            
        </div>
    )
}

export default Banner;