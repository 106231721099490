/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { fetchWatchOnDetails } from "../../actions/watchonAction";
import styles from "./WatchOnVideo.module.scss";
import Container from "react-bootstrap/Container";
import WatchOnSection from "../WatchOnSection";
import { connect } from "react-redux";
import { useDimensions } from "../../logic/Dimentions";
import VideoPlayer from "../VideoPlayer";

const WatchOnVideo = ({ fetchWatchOnDetails, watchonDetails, classes }) => {
    const { width } = useDimensions();
    const [fullscreen, setFullscreen] = useState(false);
    const [play, setPlay] = useState(true);
    const [showControls, setShowControls] = useState(0);
    const [toggleScreen, setToggleScreen] = useState(false);

    useEffect(() => {
        fetchWatchOnDetails();
    }, []);

    const handlePlaying = () => {
        document.body.classList.add("isplaying");
    };

    const handleStop = () => {
        document.body.classList.remove("isplaying");
    };

    return (
        <div>
            {width >= 992 ? (
                <Container
                    className={`${styles.video_align} ${classes?.aboutVideoPlayCircleClass}`}
                >
                    {watchonDetails.bannerVideo ? (
                        <VideoPlayer
                            url={watchonDetails.bannerVideo}
                            fullscreen={fullscreen}
                            setFullscreen={setFullscreen}
                            play={play}
                            setPlay={setPlay}
                            hideHeaderMenu={true}
                            showControls={showControls}
                            setShowControls={setShowControls}
                            toggleScreen={toggleScreen}
                            setToggleScreen={setToggleScreen}
                        />
                    ) : null}


                </Container>
            ) : null}

            <WatchOnSection watchonDetails={watchonDetails} classes={classes} />
        </div>
    );
};
const mapPropsToState = (state) => {
    return { watchonDetails: state.watchon.watchonDetails };
};
export default connect(mapPropsToState, { fetchWatchOnDetails })(WatchOnVideo);
