import { FETCH_ADS } from '../actions/types.js';

const INTIAL_STATE = {
    ads: [],
};

export default (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_ADS:
            return {
                ...state,
                ads: action.payload
            }

        default:
            return state;
    }
}

