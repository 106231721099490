import React from 'react';
import styles from './NewsCategory.module.scss';
import { useSeasonal } from '../../../logic/Seasonal';

const NewsCategory = ({value,SetCatgeoryData,category}) => {
    const {seasonal,classes} = useSeasonal();

    return (
        <div className="news-tab-wrp">
        <ul className={styles.newsCategory}>
            {value.map((cat)=>{
                return (
                    <li onClick={()=>{SetCatgeoryData(cat.slug)}} className={category==cat.slug&&`${styles.active} ${classes?.txtColor}`}>{cat.title}</li>
                )
            })}
        </ul>
        </div>
    )
}

export default NewsCategory;