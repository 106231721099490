import styles from './MailPage.module.scss';
import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ReCAPTCHA from "react-google-recaptcha";
import StickyNewsHeader from '../StickyNewsHeader';
import { connect } from 'react-redux';
import { fetchMailFormDetails } from "../../actions/fellowshipAction"
import BrowserPersistence from '../../util/simplePersistance';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import MonthlyPledge from '../MonthlyPledge';
import SingleGift from '../SingleGift';
import { useSeasonal } from '../../logic/Seasonal';

const storage = new BrowserPersistence();
const MailPage = ({ fetchMailFormDetails, mailFormDetails }) => {
  const {classes, seasonal} = useSeasonal();
  const [value, setValue] = useState(0);
  useEffect(() => {
    fetchMailFormDetails(storage.getItem('CountryCode'))
  }, [])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  return (
    <div className={`${styles.spfmail_intro} mail_tab ${classes?.tabColor}`}>
      <Container>
        <h3>{mailFormDetails.pageHeading}</h3>
        <p>{mailFormDetails.pageDescription}</p>

        <Tabs aria-label="simple tabs example" onChange={handleChange} value={value} className={`${styles.spfmail_tabs} ${classes?.tabColor}`}>
          <Tab label={
            <a href={() => false}>
              <h3 className={`${classes?.seasonalClass}`}>Monthly Pledge</h3>
            </a>
          }
          className={` ${styles.periodic_tab} ${classes?.tabColor} ${value === 0 ? classes.borderBtmOnly: ""}` }>
          </Tab>

          <Tab label={
            <a href={() => false}>
              <h3 className={`${classes?.seasonalClass}`}>Single Gift</h3>
            </a>
          }
            className={styles.periodic_tab} />
        </Tabs>

        <TabPanel index={0} value={value} className={styles.tab_panel_mail}>
          <MonthlyPledge MonthlyDescription={mailFormDetails.MonthlyDescription} clubLevels={mailFormDetails.clubLevels} classes={classes} />
        </TabPanel>
        <TabPanel index={1} value={value} className={styles.tab_panel_mail}>
          <SingleGift classes={classes}/>
        </TabPanel>
      </Container>

    </div>
  )

}

const mapPropsToState = (state) => {
  return { mailFormDetails: state.fellowshipData.mailFormDetails }
}
export default connect(mapPropsToState, { fetchMailFormDetails })(MailPage);