/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSeasonal } from "../../actions/seasonAction";
import { fetchRegion } from "../../actions/regionAction";
import { fetchCommon } from "../../actions/commonAction";

const SeasoanlLayout = ({ children }) => {

  const dispatch = useDispatch();
  const seasonal = useSelector((state) => state.seasonal);

  useEffect(() => {
    if (seasonal.status === 'idle') {      
      if(navigator.userAgent === "shalom_world_app_mobile"){
        //This is Shalom World APP
        document.body.classList.add('mob-app');
      }
      dispatch(fetchSeasonal());
    }
  }, []);

  useEffect(() => {
    dispatch(fetchCommon());
    dispatch(fetchRegion());
  }, []);

  return (<>{seasonal.status !== 'idle' ? children : null}</>);
}

export default SeasoanlLayout