import React, { useState } from "react";
import styles from "./PodcastEpisodeBanner.module.scss";
import { Link } from "react-router-dom";
import PodcastShareModal from "../PodcastShareModal";
import { useSeasonal } from '../../logic/Seasonal'

const PodcastEpisodeBanner = ({
  data,
  handlePlay,
  play,
  activeId,
  handleUpdatePlaylist,
  handleClick,
  progress,
}) => {
  const [isLoaded, setLoaded] = useState(false);
  const [showShareModal, setShareModal] = useState(false);
  const [message, setMessage] = useState("");
  const { classes } = useSeasonal()

  const playToggleClass = (id) => {
    if (id === activeId) {
      handlePlay(!play);
      // setPlay(!play);
    } else {
      //Append eps to play list
      handleUpdatePlaylist(id);
    }
  };
  const onLoad = () => {
    setLoaded(true);
  };
  const handlePrevent = (e) => {
    e.preventDefault();
    return;
  };

  const handleSetMessage = (msg) => {
    setMessage(msg);
  };

  const formatTime = (duration) => {
    if (duration) {
      let arr = duration.split(":"); // splitting the string by colon
      arr[0] = arr[0] !== "" ? `${arr[0]} hr` : "";
      arr[1] = arr[1] !== "" ? `${arr[1]} min` : "";
      arr[2] = arr[2] !== "" ? `${arr[2]} Sec` : "";
      let newVal = arr.join(" ");
      return newVal.replace("00 hr", "");
    }
  };

  return (
    <>
      <section className={`${styles.PodcastEpisodeBanner} animation`}>
        <figure
          className={`figImg blurBanner ${isLoaded ? "loaded" : null}`}
          onLoad={onLoad}
        >
          <img src={data?.thumbnail?.small} alt="podcast episode" />
        </figure>
        <div className={`${styles.bannerContent} container `}>
          <div
            className={`${styles.episodeWrap} ${isLoaded ? "loaded" : null}`}
            onLoad={onLoad}
          >
            <figure className="figImg">
              <img src={data?.thumbnail?.small} alt="" />
            </figure>

            <div className={styles.episodeThumbContent}>
              {/* <span className={styles.ep_number}>Episode {data?.episode}</span> */}
              <h2>{data?.title}</h2>
              <ul className={styles.episdodedtls}>

                {data?.host &&
                  data?.host?.[0]?.name &&
                  data?.host?.[0]?.name && (
                    <li className={styles.author}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13.264"
                        height="15.182"
                        viewBox="0 0 13.264 15.182"
                      >
                        <g transform="translate(0.7 0.7)">
                          <circle
                            cx="3"
                            cy="3"
                            r="3"
                            transform="translate(2.932)"
                            fill="none"
                            stroke="#e0e0e0"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.4"
                          />
                          <path
                            d="M-137.008,438.727h0a4.883,4.883,0,0,1,4.884-4.884h2.1a4.884,4.884,0,0,1,4.884,4.884h0"
                            transform="translate(137.008 -424.945)"
                            fill="none"
                            stroke="#e0e0e0"
                            strokeLinecap="square"
                            strokeLinejoin="round"
                            strokeWidth="1.4"
                          />
                        </g>
                      </svg>
                      {data?.host && data?.host?.[0]?.name}
                    </li>
                  )}
                <li className={styles.podcastname}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15.325"
                    height="12.807"
                    viewBox="0 0 15.325 12.807"
                  >
                    <g transform="translate(0.7 0.7)">
                      <path
                        d="M-658.772,459.682l-5.834-3.368v6.736Z"
                        transform="translate(664.606 -452.155)"
                        fill="none"
                        stroke="#e0e0e0"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        strokeWidth="1.4"
                      />
                      <line
                        x2="14.625"
                        fill="none"
                        stroke="#e0e0e0"
                        strokeMiterlimit="10"
                        strokeWidth="1.4"
                      />
                      <line
                        x2="8.791"
                        transform="translate(5.834 3.893)"
                        fill="none"
                        stroke="#e0e0e0"
                        strokeMiterlimit="10"
                        strokeWidth="1.4"
                      />
                      <line
                        x2="4.66"
                        transform="translate(9.965 7.787)"
                        fill="none"
                        stroke="#e0e0e0"
                        strokeMiterlimit="10"
                        strokeWidth="1.4"
                      />
                    </g>
                  </svg>
                  <Link to={`/podcast/${data?.podcastSlug}`} onClick={(e) => {
                    // playToggleClass(data.id);
                    handleClick(data)
                  }}>
                    {" "}
                    {data?.podcastTitle}{" "}
                  </Link>
                </li>
              </ul>

              <div className={styles.episodeAudioStatus}>
                <div className={styles.audioDtls}>
                  <span
                    className={`play-icon ${data?.otherEpisodes?.episodes?.length >= 1 ? '' : 'opacity-none'} ${play && activeId === data.id ? "pause" : "play"
                      } `}
                    onClick={(e) => {
                      handlePrevent(e);
                      playToggleClass(data.id);
                    }}
                  >
                    {play && activeId === data.id ?
                      // play
                      <svg xmlns="http://www.w3.org/2000/svg" width="9" height="12" viewBox="0 0 9 12"><g transform="translate(-323 -1192)"><rect width="3" height="12" rx="1" transform="translate(323 1192)" /><rect width="3" height="12" rx="1" transform="translate(329 1192)" /></g></svg>
                      :
                      // pause
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12" viewBox="0 0 10 12"><path d="M5.143,1.429a1,1,0,0,1,1.715,0l4.234,7.056A1,1,0,0,1,10.234,10H1.766A1,1,0,0,1,.909,8.486Z" transform="translate(10) rotate(90)" fill="#ebab0b" /></svg>

                    }
                  </span>


                  {/* <span className={`play-icon ${isPlayActive ? "pause" : ""}`} onClick={(e) => { handlePrevent(e); playToggleClass(); }}></span> */}
                  <span className={styles.date}> {data?.date} </span>
                  <span className={styles.time}>
                    {" "}
                    {activeId === data.id
                      ? progress?.remain
                      : formatTime(data?.duration)}
                  </span>
                  {activeId === data.id &&
                    <div className="progress-bar">
                      <div
                        className={`progress ${classes.seasonalClass}`}
                        style={{
                          width:
                            activeId === data.id ? progress?.percentage + '%' : "0%",
                        }}
                      ></div>
                    </div>}
                </div>

                <div className={styles.episodeShare}>
                  <div
                    className="share_btn share-text"
                    onClick={(e) => {
                      handlePrevent(e);
                      setShareModal(true);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20.49"
                      height="22.673"
                      viewBox="0 0 20.49 22.673"
                    >
                      <g
                        id="Component_54_1"
                        data-name="Component 54 – 1"
                        transform="translate(0.7 0.765)"
                      >
                        <g id="Group_4346" data-name="Group 4346">
                          <g id="Group_4331" data-name="Group 4331">
                            <path
                              id="Path_4380"
                              data-name="Path 4380"
                              d="M-54.467,317.206v12.6a1.053,1.053,0,0,0,1.075,1.029H-36.45a1.052,1.052,0,0,0,1.074-1.029v-12.6"
                              transform="translate(54.467 -309.626)"
                              fill="none"
                              stroke="#fff"
                              strokeWidth="1.4"
                            />
                            <g
                              id="Group_4331-2"
                              data-name="Group 4331-2"
                              transform="translate(5.406)"
                            >
                              <path
                                id="Path_4381"
                                data-name="Path 4381"
                                d="M-46.109,321.01V309.376"
                                transform="translate(50.214 -308.903)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="square"
                                strokeWidth="1.4"
                              />
                              <path
                                id="Path_4382"
                                data-name="Path 4382"
                                d="M-50.214,312.874l3.856-3.856a.4.4,0,0,1,.557,0l3.855,3.856"
                                transform="translate(50.214 -308.903)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="square"
                                strokeWidth="1.4"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>{" "}
                    Share
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <PodcastShareModal
        show={showShareModal}
        handleSetMessage={handleSetMessage}
        onHide={(e) => {
          setShareModal(false);
          setMessage();
        }}
        message={message}
        episode={data}
        podcastSlug={""}
        copyLinkText={
          window.location.origin +
          "/podcast/" +
          data?.podcastSlug +
          "/" +
          data?.url
        }
      />
    </>
  );
};

export default PodcastEpisodeBanner;
