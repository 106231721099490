import {FETCH_MEDIA_USER,RESET_MEDIA_USER} from '../actions/types.js';

const INTIAL_STATE = {};

export default (state = INTIAL_STATE,action) => {
    switch(action.type){
        case FETCH_MEDIA_USER:
            return action.payload;
        case RESET_MEDIA_USER:
            return INTIAL_STATE;
        default:
            return state;
    }
}