import { SHORT_FILM_CONTEST } from "./types";
import salomapi from "../apis";

export const fetchShortFilmData = (region) => async (dispatch) => {
  try {
    const response = await salomapi.get("/shortfilmpage");

    dispatch({ type: SHORT_FILM_CONTEST, payload: response.data });
  } catch (error) {}
};
