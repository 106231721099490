
import React, { useState, useEffect } from "react";
import Pagination from "react-bootstrap/Pagination";
import styles from "./Paginate.module.scss";
import { useSeasonal } from "../../logic/Seasonal";

const Paginate = ({
  currentPage,
  onPagination = () => { },
  totalPages,
  pageNumberLimit,
  check
}) => {
  const { classes } = useSeasonal();
  const [maxPageLimit, setMaxPageLimit] = useState(pageNumberLimit);
  const [minPageLimit, setMinPageLimit] = useState(0);

  useEffect(() => {
    setMaxPageLimit(pageNumberLimit)
    setMinPageLimit(0)
  }, [check]);

  const pages = [];
  for (let i = 1; i <= totalPages; i++) {
    pages.push(i);
  }

  const pageNumbers = pages.map((page, index) => {
    if (minPageLimit < 0) {
      setMinPageLimit(0);
      setMaxPageLimit(pageNumberLimit);
    }

    if (page <= maxPageLimit && page > minPageLimit) {
      return (
        <Pagination.Item
          key={index}
          id={page}
          onClick={() => handlePaginate(page)}
          className={
            currentPage === page ? "active " + classes.paginateActive : ""
          }
        >
          {page}
        </Pagination.Item>
      );
    }
  });


  const handlePaginate = (value) => {
    onPagination(value);
  };

  const nextPage = () => {
    if (currentPage + 1 > maxPageLimit) {
      setMaxPageLimit(maxPageLimit + pageNumberLimit);
      setMinPageLimit(minPageLimit + pageNumberLimit);
    }
    if (currentPage < totalPages) {
      handlePaginate(currentPage + 1);
    }
  };

  const prevPage = () => {
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setMaxPageLimit(currentPage - 1);
      setMinPageLimit((currentPage - 1) - pageNumberLimit);
    }
    if (currentPage > 1) {
      handlePaginate(currentPage - 1);
    }
  };

  const prevSkip = () => {
    setMaxPageLimit(maxPageLimit - pageNumberLimit);
    setMinPageLimit(minPageLimit - pageNumberLimit);

    if (currentPage > 1) {
      handlePaginate(minPageLimit);
    }
  };

  const forwardSkip = () => {
    setMaxPageLimit(maxPageLimit + pageNumberLimit);
    setMinPageLimit(minPageLimit + pageNumberLimit);

    if (currentPage < totalPages) {
      handlePaginate(maxPageLimit + 1);
    }
  };

  const firstPage = () => {
    setMaxPageLimit(pageNumberLimit);
    setMinPageLimit(0);
    handlePaginate(1);
  };

  const lastPage = () => {
    setMaxPageLimit(totalPages);
    setMinPageLimit(totalPages - pageNumberLimit);
    handlePaginate(totalPages);
  };

  let pageIncrementEllipses = null;
  let pageDecremenEllipses = null;
  if (pages.length > maxPageLimit) {
    pageDecremenEllipses = (
      <Pagination.Ellipsis className={"backward "} onClick={forwardSkip} />
    );
  }
  if (minPageLimit >= 1) {
    pageIncrementEllipses = (
      <Pagination.Ellipsis className={"forward "} onClick={prevSkip} />
    );
  }

  return (
    <div className={styles.pagination_wrap}>

      {/* <div style={{ color: 'red' }}>
        <p>minPageLimit : {minPageLimit}</p>
        <p>maxPageLimit : {maxPageLimit}</p>
        <p>currentPage : {currentPage}</p>
        <p>times : {check ? "True" : "False"}</p>
        <p>totalPages : {totalPages}</p>
      </div> */}


      {totalPages > 1 && (
        <Pagination>
          <Pagination.First
            onClick={firstPage}
            disabled={currentPage > 1 ? false : true}
          />
          <Pagination.Prev
            onClick={prevPage}
            disabled={currentPage > 1 ? false : true}
          />
          {pageIncrementEllipses}
          {pageNumbers}
          {pageDecremenEllipses}

          <Pagination.Next
            onClick={nextPage}
            disabled={currentPage >= totalPages ? true : false}
          />

          <Pagination.Last
            onClick={lastPage}
            disabled={currentPage >= totalPages ? true : false}
          />
        </Pagination>
      )}
    </div>
  );
};

export default Paginate;
