/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import styles from './CommingUp.module.scss'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  // fetchLiveSchedule,
  fetchUpcomingmassSchedule,
} from '../../actions/LiveScheduleAction'
import { useSeasonal } from '../../logic/Seasonal'

const CommingUp = ({
  liveSchedule,
  fetchUpcomingmassSchedule,
  upcomingSchedule,
}) => {
  const { seasonal, } = useSeasonal()
  const [regionData, setRegionData] = useState()

  const { location } = useHistory()
  let path = location.pathname

  useEffect(() => {
    if (Object.values(liveSchedule)[0]) {
      setRegionData(Object.values(liveSchedule)[0])
    }
  }, [liveSchedule])


  return (
    <div
      className={path === '/' ? styles.commingUpHome : styles.commingSwprayer}
    >
      <div className={styles.containerComingUp}>


        {regionData?.schedules?.length > 0 && (
          <div className={`row ${styles.container1}`}>
            <div className="col-sm-3 comingUpCol">COMING UP </div>
            <div className="col-sm-3">
              <p>
                {regionData?.schedules[1]?.start
                  ? regionData.schedules[1].start
                  : null}
              </p>
              <h4
                style={
                  seasonal?.color !== undefined && { color: seasonal?.color }
                }
              >
                {regionData?.schedules[1]?.name
                  ? regionData.schedules[1].name
                  : null}
              </h4>
            </div>

            <div className="col-sm-3">
              <p>
                {regionData?.schedules[2]?.start
                  ? regionData.schedules[2].start
                  : null}
              </p>
              <h4
                style={
                  seasonal?.color !== undefined && { color: seasonal?.color }
                }
              >
                {regionData?.schedules[2]?.name
                  ? regionData.schedules[2].name
                  : null}
              </h4>
            </div>

            <div className="col-sm-3">
              <p>
                {regionData?.schedules[3]?.start
                  ? regionData.schedules[3].start
                  : null}
              </p>
              <h4
                style={
                  seasonal?.color !== undefined && { color: seasonal?.color }
                }
              >
                {regionData?.schedules[3]?.name
                  ? regionData.schedules[3].name
                  : null}
              </h4>
            </div>
          </div>
        )}

      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    // liveSchedule: state.liveSchedule,
    upcomingSchedule: state.upcomingSchedule,
  }
}

export default connect(mapStateToProps, {
  fetchUpcomingmassSchedule,
})(CommingUp)
