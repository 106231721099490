import React from "react";
import { Link } from "react-router-dom";
import styles from "./PodcastAudioSpeed.module.scss";

const PodcastAudioSpeed = ({
    speedModal,
    setSpeedModal,
    playBackOptions,
    setPlayBackRate,
    playBackRate,
    handlePlay,
}) => {
    return (
        <div
            className={`${styles.PodcastAudioSpeed} ${speedModal ? styles.show : ""} mob_audio_speed`}
        >
            <div className={styles.PodcastAudioSpeedWrapper}>
                <div className={styles.audioSpeedHeaderWrapper}>
                    <span
                        className={styles.downArrow}
                        onClick={() => {
                            setSpeedModal(false);
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="28"
                            height="28"
                            viewBox="0 0 28 28"
                        >
                            <g transform="translate(-174 -155)">
                                <g
                                    transform="translate(174 155)"
                                    fill="#fff"
                                    stroke="#707070"
                                    strokeWidth="1"
                                    opacity="0"
                                >
                                    <rect width="28" height="28" stroke="none" />
                                    <rect x="0.5" y="0.5" width="27" height="27" fill="none" />
                                </g>
                                <path
                                    d="M0,0,10.521,3.912,19.8,0"
                                    transform="translate(178.5 167.5)"
                                    fill="none"
                                    stroke="rgba(255,255,255,0.44)"
                                    strokeLinecap="round"
                                    strokeWidth="3"
                                />
                            </g>
                        </svg>
                    </span>
                    <div className={styles.audioSpeedHeader}>
                        <p className={`podcastTitle`}>Audio Speed</p>
                        <Link>
                            <span className={`${styles.showAllBtn} podcastBtn`} onClick={() => {
                                handlePlay(true);
                                setPlayBackRate(1);
                            }}>Reset</span>
                        </Link>
                    </div>
                </div>
                <div className={styles.audioSpeedValues}>
                    {playBackOptions.map((val, index) => {
                        return (
                            <div
                                className={`${styles.audioSpeedValuesItem} ${val === playBackRate ? "active" : ""}`}
                                onClick={() => {
                                    handlePlay(true);
                                    setPlayBackRate(val);
                                    setTimeout(() => {
                                        setSpeedModal(false);
                                      }, 500);
                                }}
                            >
                                <p className={styles.speedValue}>{`${val}x`}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default PodcastAudioSpeed;
