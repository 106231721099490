import logos from '../Assets/logo.svg';
import logo_media from '../Assets/logo_media.svg';
import logo_tidings from '../Assets/logo_tidings.svg';
import logo_store from '../Assets/logo_store.svg';
import mediagal1 from '../../assets/img/mediagal_pos1.jpg';
import mediagal2 from '../../assets/img/mediagal_pos2.jpg';
import mediagal3 from '../../assets/img/mediagal_pos3.jpg';

const Assets = {
    logos,
    logo_media,
    logo_tidings,
    logo_store,
    mediagal1,
    mediagal2,
    mediagal3
}

export default Assets;