export const FETCH_BANNER = "FETCH_BANNER";
export const FETCH_MOBBANNER = "FETCH_MOBBANNER";
export const FETCH_PROGRAMES = "FETCH_PROGRAMES";
export const UPDATE_PROGRAMES = "UPDATE_PROGRAMES";
export const REMOVE_PROGRAMES = "REMOVE_PROGRAMES";

export const FETCH_OTHER_DETAILS = "FETCH_OTHER_DETAILS";
export const FETCH_LATEST_EPISODES_START = "FETCH_LATEST_EPISODES_START";
export const FETCH_LATEST_EPISODES = "FETCH_LATEST_EPISODES";
export const UPDATE_LATEST_EPISODES = "UPDATE_LATEST_EPISODES";
export const RESET_LATEST_EPISODES = "RESET_LATEST_EPISODES";
export const FETCH_CURRENCY = "FETCH_CURRENCY";
export const FETCH_FOOTER = "FETCH_FOOTER";
export const FETCH_CATEGORIES = "FETCH_CATEGORIES";
export const FETCH_VIDEO = "FETCH_VIDEO";
export const FETCH_CAREERS = "FETCH_CAREERS";
export const CLEAR_CAREERS = "CLEAR_CAREERS";
export const FETCH_CONTACTUS = "FETCH_CONTACTUS";
export const FETCH_BLOG_CATEGORIES = "FETCH_BLOG_CATEGORIES";
export const FETCH_BLOG_LIST = "FETCH_BLOG_LIST";
export const UPDATE_BLOG_LIST = "UPDATE_BLOG_LIST";
export const FETCH_MENU_LIST = "FETCH_MENU_LIST";
export const FETCH_CATEGORY_EPISODES = "FETCH_CATEGORY_EPISODES";
export const FETCH_LATEST_VIDEOS = "FETCH_LATEST_VIDEOS";
export const FETCH_RELATED_VIDEOS = "FETCH_RELATED_VIDEOS";
export const REMOVE_LATEST_RELATED_VIDEOS = "REMOVE_LATEST_RELATED_VIDEOS";
export const FETCH_ABOUTUS = "FETCH_ABOUTUS";
export const FETCH_MORE_ENDORSEMENTS = "FETCH_MORE_ENDORSEMENTS";
export const FETCH_MORE_JOBS = "FETCH_MORE_JOBS";
export const FETCH_LIVE_SCHEDULE = "FETCH_LIVE_SCHEDULE";
export const FETCH_SWPRAYERDETAILS = "FETCH_SWPRAYERDETAILS";
export const FETCH_TESTIMONIES = "FETCH_TESTIMONIES";
export const FETCH_DAILYMASS = "FETCH_DAILYMASS";
export const FETCH_SWPRAYER = "FETCH_SWPRAYER";
export const FETCH_SEC_MENU_LIST = "FETCH_SEC_MENU_LIST";
export const FETCH_PEACE_FELLOWSHIP = "FETCH_PEACE_FELLOWSHIP";
export const FETCH_MEDIA_GALLERY = "FETCH_MEDIA_GALLERY";
export const FETCH_COUNTRY_LIST = "FETCH_COUNTRY_LIST";
export const FETCH_STATE_FROM_COUNTRY = "FETCH_STATE_FROM_COUNTRY";
export const FETCH_TIME_FROM_DATE = "FETCH_TIME_FROM_DATE";
export const FETCH_WATCHON_DETAILS = "FETCH_WATCHON_DETAILS";
export const FETCH_NEWS_DESCRIPTION = "FETCH_NEWS_DESCRIPTION";
export const REMOVE_NEWS_DESCRIPTION = "REMOVE_NEWS_DESCRIPTION";
export const FETCH_NEWS_COMMENTS = "FETCH_NEWS_COMMENTS";
export const FETCH_MAIL_FORM_DETAILS = "FETCH_MAIL_FORM_DETAILS";
export const FETCH_CAREER_DETAILS = "FETCH_CAREER_DETAILS";
export const FETCH_FULL_SCHEDULE = "FETCH_FULL_SCHEDULE";
export const FETCH_UPCOMING_SCHEDULE = "FETCH_UPCOMING_SCHEDULE";
export const FETCH_LIVE_SCHEDULE2 = "FETCH_LIVE_SCHEDULE2";
export const FETCH_COMMON_SEARCH = "FETCH_COMMON_SEARCH";
export const FETCH_WATCH_LIVE_IMAGES = "FETCH_WATCH_LIVE_IMAGES";
export const FETCH_SOCIALLINK = "FETCH_SOCIALLINK";
export const REMOVE_VIDEO = "REMOVE_VIDEO";
export const FETCH_CAR_DONATION = "FETCH_CAR_DONATION";
export const FETCH_REAL_ESTATE_DONATION = "FETCH_REAL_ESTATE_DONATION";
export const FETCH_EMPLOYEE_MATCHING = "FETCH_EMPLOYEE_MATCHING";
export const FETCH_AMAZON_SMILE = "FETCH_AMAZON_SMILE";
export const POST_MEDIASIGN = "POST_MEDIASIGN";
export const UPDATE_LATEST_VIDEO = "UPDATE_LATEST_VIDEO";
export const UPDATE_RELATED_VIDEO = "UPDATE_RELATED_VIDEO";
export const REMOVE_VIDEOS = "REMOVE_VIDEOS";
export const TERMS_CONDITIONS = "TERMS_CONDITIONS";
export const PRIVACY_STATEMENTS = "PRIVACY_STATEMENTS";
export const FETCH_WEBSITES = "FETCH_WEBSITES";
export const UPDATE_COMPLETE_SEARCH = "UPDATE_COMPLETE_SEARCH";
export const FETCH_MEDIA_USER = "FETCH_MEDIA_USER";
export const RESET_MEDIA_USER = "RESET_MEDIA_USER";
export const FETCH_DONATE = "FETCH_DONATE";
export const FETCH_COOKIE = "FETCH_COOKIE";
export const FETCH_MOBMENU = "FETCH_MOBMENU";
export const FETCH_SEO = "FETCH_SEO";
export const FETCH_SEASONAL = "FETCH_SEASONAL";
export const FETCH_XMAS = "FETCH_XMAS";
export const FETCH_TWITTER = "FETCH_TWITTER";
export const FETCH_DONATION = "FETCH_DONATION";
export const FETCH_REGION = "FETCH_REGION";
export const FETCH_FOOTEREIN = "FETCH_FOOTEREIN";
export const SHORT_FILM_CONTEST = "SHORT_FILM_CONTEST";
export const FETCH_ADS = "FETCH_ADS";
export const FETCH_COMMON = "FETCH_COMMON";
export const UPDATE_VIDEO = "UPDATE_VIDEO";
export const FETCH_DONATE_URLS = "FETCH_DONATE_URLS";

// Action types for updating video, fetching podcasts, fetching more podcasts, fetching podcast, updating podcast, fetching more podcast, fetching podcast episodes
export const FETCH_PODCASTS = "FETCH_PODCASTS";         //Podcasts listing screen
export const FETCH_MOREPODCASTS = "FETCH_MOREPODCASTS"; //Podcasts listing screen
export const FETCH_PODCAST = "FETCH_PODCAST";           //Podcast Detail screen
export const UPDATE_PODCAST = "UPDATE_PODCAST";         //Podcast Detail screen, Update before route changes
export const FETCH_MORE_PODCAST = "FETCH_MORE_PODCAST"; //Podcast Detail Episode pagination screen
export const FETCH_PODCAST_EPISODES = "FETCH_PODCAST_EPISODES"; //Podcast Detail Episode pagination screen
export const ADD_TOPLAYLIST = "ADD_TOPLAYLIST";         //Add To Playlist
export const APPEND_TOPLAYLIST = "APPEND_TOPLAYLIST";   //Append an item To Playlist
export const UPDATE_PLAYLIST = "UPDATE_PLAYLIST";       //Update Playlist
export const PURGE_TOPLAYLIST = "PURGE_TOPLAYLIST";     //Purge
export const TRIGGER_PLAY = "TRIGGER_PLAY";             //Play: true, false
export const TRIGGER_PROGRESS = "TRIGGER_PROGRESS";     //In progress
export const TRIGGER_ACTIVEID = "TRIGGER_ACTIVEID";     //Active Episdoe Id
export const FETCH_PODCASTEPISODE = "FETCH_PODCASTEPISODE";   // Podcast Episode detail screen
export const UPDATE_PODCASTEPISODE = "UPDATE_PODCASTEPISODE"; // Podcast Episode detail screen, Update before route changes
export const FETCH_PODCAST_EPISODESPLAY = "FETCH_PODCAST_EPISODESPLAY";

// Action types for Fetching Awards, Promitions
export const FETCH_PROMOTION = "FETCH_PROMOTION";
export const FETCH_AWARDS = "FETCH_AWARDS";

// Action types for Website Searching
export const FETCH_COMPLETE_SEARCH_START = "FETCH_COMPLETE_SEARCH_START";
export const FETCH_COMPLETE_SEARCH_SUCCESS = "FETCH_COMPLETE_SEARCH_SUCCESS";

// Action types for EventDetails
export const FETCH_EVENTDETAILS = "FETCH_EVENTDETAILS";
export const FETCH_EVENTRELATEDVIDEOS = "FETCH_EVENTRELATEDVIDEOS";
export const FETCH_EVENTPASTVIDEOS = "FETCH_EVENTPASTVIDEOS";
export const UPDATE_EVENTDETAILS = "UPDATE_EVENTDETAILS";
export const UPDATE_EVENTDETAILS_STATUS = "UPDATE_EVENTDETAILS_STATUS";
export const CLEAR_THUMBNAILS = "CLEAR_THUMBNAILS";
