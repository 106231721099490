import React, { useEffect } from 'react';
import styles from './SwplusConfiguration.module.scss';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Assets from '../Layouts/Assets';
import useClipboard from "react-use-clipboard";
import { useSeasonal } from '../../logic/Seasonal'
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom';

const SwplusConfiguration = (token) => {
    const [value, setValue] = React.useState('bright');
    const history = useHistory();
    const location = useLocation();

    // const [text, setText]= useState('');
    // const [copied, setCopied] = useState(false);
    const { classes } = useSeasonal()
    const [isCopied, setCopied] = useClipboard(`<script>
    (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];if (d.getElementById(id)) return;js = d.createElement(s); js.id = id;
        js.src = "//shalomworld.org/media-gallery/parish-mission.min.js#appID=${token.token}&elementID=pm_mg-trigger";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, "script", "pmission-media_gallery"));
</script>`);

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    useEffect(() => {

        if (!token.token && !location.state?.profile) {
            history.push(`/swplus`);
        }
    }, [token]);

    return (
        <div className={styles.swConfiguration}>
            <div className={styles.comSteps}>
                Complete following steps
            </div>
            <div className="row">
                <div className={`col-lg-6 ${styles.step1} `}>
                    <div className={`${classes?.seasonalClass} ${styles.title}`}>
                        step 1
                    </div>
                    <p>Select the style you would like to use in your website.</p>
                    <div className={classes.radioBtnColor}>
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                                <FormControlLabel value="bright" control={<Radio />} label="Floating Icon Bottom Right" />
                                <FormControlLabel value="left" control={<Radio />} label="Floating Icon Bottom Left" />
                                <FormControlLabel value="right" control={<Radio />} label="Floating Icon Right" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                    {value == 'bright' && <img src={Assets.mediagal1} />}
                    {value == 'left' && <img src={Assets.mediagal2} />}
                    {value == 'right' && <img src={Assets.mediagal3} />}
                </div>
                <div className={`col-lg-6 mt-5 mt-lg-0 ${styles.step1}`}>
                    <div className={`${classes?.seasonalClass} ${styles.title}`}>
                        step 2
                    </div>
                    <p>Copy and paste the following script in header or footer.</p>
                    <button className={`${classes?.backgroundColr} ${styles.btn_copyscript}`} onClick={setCopied}>Copy to clipboard </button>
                    <h6>Was it copied? {isCopied ? "Yes! 👍" : "Nope! 👎"}</h6>
                    <pre className={styles.tocopy}>
                        <code>

                            {`<script>
                        (function(d, s, id) {
                            var js, fjs = d.getElementsByTagName(s)[0];if (d.getElementById(id)) return;js = d.createElement(s); js.id = id;
                            js.src = "//shalomworld.org/media-gallery/parish-mission.min.js#${token.token}&elementID=pm_mg-trigger";
                            fjs.parentNode.insertBefore(js, fjs);
                        }(document, "script", "pmission-media_gallery"));
                    </script>`}
                        </code>
                    </pre>
                </div>
            </div>
        </div>
    )
}

export default SwplusConfiguration;