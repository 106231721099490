import React from "react";
import FeedbackLayout from "../components/Layouts/FeedbackLayout";
import { useHome } from "../logic/Home/useHome";
import Feedback from "../components/Feedback";
import { connect } from "react-redux";
import { fetchOtherdetails } from "../actions/otherdetailsAction";
import { TitleComponent } from "../components/TitleComponent";
import { useSeo } from "../logic/Seo/useSeo";
import SocialMediaWrap from "../components/SocailMediaIcons";
import { useDimensions } from "../logic/Dimentions";
import { useSeasonal } from "../logic/Seasonal";

const FeedbackPage = ({ fetchOtherdetails, otherdetails }) => {
  useHome({ fetchOtherdetails, otherdetails });
  useSeo();
  const { width } = useDimensions();
  const { classes } = useSeasonal();

  return (
    <FeedbackLayout>
      <TitleComponent title="Shalom world " />
      {width >= 992 && <SocialMediaWrap classes={classes} />}
      <Feedback />
    </FeedbackLayout>
  );
};
const mapStateToProps = (state) => {
  return { otherdetails: state.otherdetails.data };
};
export default connect(mapStateToProps, { fetchOtherdetails })(FeedbackPage);
