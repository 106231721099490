import React from 'react';
import CommonLayout from '../components/Layouts/CommonLayout';
import ShowDetails from '../components/ShowDetails';
import MobileShowDetails from '../components/Mobile/ShowDetails';
import { TitleComponent } from '../components/TitleComponent';
import SocialMediaWrap from '../components/SocailMediaIcons';
import { useSeasonal } from '../logic/Seasonal'
import { useDimensions } from '../logic/Dimentions';

const ShowDetailsPage = () => {
    const { width } = useDimensions();
    const { classes } = useSeasonal()

    return (
        <CommonLayout>
            {width >= 992 && <SocialMediaWrap classes={classes} />}
            <TitleComponent title="Shalom world " />
            {width >= 992 ? <ShowDetails /> : <MobileShowDetails />}
        </CommonLayout>
    )
}

export default ShowDetailsPage;