import React, { useEffect, useState, useCallback, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import style from "./EpisodeVideo.module.scss";
import Episodethumb from "../EpisodeThumb";
import {
    fetchLatestEpisodes,
    fetchRelatedVideo,
    removeLatestRelatedVideo,
} from "../../actions/episodesAction";
import { connect } from "react-redux";
import CountryDropDown from "../CountryDropdown";
import { useSeasonal } from "../../logic/Seasonal";
import Paginate from "../../util/Paginate";
import EpisodeListShimmer from "../Shimmers/EpisodeListShimmer/EpisodeListShimmer";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import { useDimensions } from "../../logic/Dimentions";

const EpiosdeRelatedVideo = ({
    episodeVideo,
    fetchLatestEpisodes,
    fetchRelatedVideo,
    episodeVideos,
    removeLatestRelatedVideo,
}) => {
    const { classes } = useSeasonal();
    const listingRef = useRef(null);
    const location = useLocation();
    const { width } = useDimensions();

    const [country, setCountry] = useState("all");
    const [tabActive, SetTabActive] = useState(1);
    const [pagination, setPagination] = useState({
        page: 0,
        limit: 9,
        perPage: 9,
    });
    const [isShimmer, setIsShimmer] = useState(false);

    useEffect(() => {
        if (pagination && pagination.page >= 1) {
            loadItems();
        }
    }, [pagination]);

    const onPagination = useCallback(
        (pageNumber) => {
            setPagination({
                ...pagination,
                page: pageNumber,
            });
        },
        [pagination]
    );

    const onShowShimmer = () => {
        setIsShimmer(true);
    };

    const onHideShimmer = () => {
        setIsShimmer(false);
    };

    const loadItems = (e) => {
        if (tabActive === 1) {
            let pageNo = pagination.page;
            onShowShimmer();
            fetchLatestEpisodes(
                episodeVideo.programId,
                episodeVideo.zypeId,
                pageNo,
                episodeVideo.id,
                country
            ).then((response) => {
                const timer = setTimeout(() => {
                    onHideShimmer();
                    executeScroll();
                }, 1000);
                return () => clearTimeout(timer);
            });
        }
        if (tabActive === 2) {
            let pageNo = pagination.page;
            onShowShimmer();
            fetchRelatedVideo(
                episodeVideo.zypeId,
                episodeVideo.tagsIds,
                pageNo,
                episodeVideo.id,
                country
            ).then((response) => {
                const timer = setTimeout(() => {
                    onHideShimmer();
                    executeScroll();
                }, 1000);
                return () => clearTimeout(timer);
            });
        }
    };

    const executeScroll = () =>
        listingRef.current.scrollIntoView({ behavior: "smooth", block: "start" });

    //   Reset the count if Url location changed
    useEffect(() => {
        setPagination({
            page: 0,
            limit: 9,
            perPage: 9,
        });
    }, [location]);

    return (
        <div className={style.episodeRelated} ref={listingRef}>
            <Container>
                <CountryDropDown selectCountry={setCountry} country={country} />

                <ul className={style.tabs}>
                    <li
                        className={`${tabActive === 1 ? style.active + " " + classes?.txtColor : null
                            } `}
                        onClick={() => {
                            SetTabActive(1);
                            setPagination({
                                page: 1,
                                limit: 9,
                                perPage: 9,
                            });
                        }}
                    >
                        Latest Video
                    </li>
                    <li
                        className={
                            tabActive === 2 ? style.active + " " + classes?.txtColor : null
                        }
                        onClick={() => {
                            SetTabActive(2);
                            setPagination({
                                page: 1,
                                limit: 9,
                                perPage: 9,
                            });
                        }}
                    >
                        Related Video
                    </li>
                </ul>

                {tabActive === 1 && (
                    <>
                        {episodeVideo.id &&
                            episodeVideo.latestEpisodes &&
                            episodeVideo.latestEpisodes.length !== 0 ? (
                            <Row>
                                {isShimmer ? (
                                    <>
                                        {_.times(9, (i) => (
                                            <EpisodeListShimmer key={i} />
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        {episodeVideo.latestEpisodes.map((value) => {
                                            return <Episodethumb value={value} />;
                                        })}
                                    </>
                                )}

                                <Paginate
                                    currentPage={pagination.page ? pagination.page : 1}
                                    onPagination={onPagination}
                                    totalPages={episodeVideo.latestPages}
                                    pageNumberLimit={width >= 992 ? 5 : 3}
                                />
                            </Row>
                        ) : (
                            <Row>
                                {episodeVideo.id && episodeVideo.latestEpisodes === null ? (
                                    <>
                                        <Col className={style.notavaildata}>
                                            <p className={style.notaviliable}>
                                                No latest episode is available
                                            </p>
                                        </Col>
                                    </>
                                ) : (
                                    <>
                                        {" "}
                                        {_.times(9, (i) => (
                                            <EpisodeListShimmer key={i} />
                                        ))}
                                    </>
                                )}
                            </Row>
                        )}
                    </>
                )}

                {tabActive === 2 && (
                    <>
                        {episodeVideo.relatedEpisodes &&
                            episodeVideo.relatedEpisodes.length !== 0 ? (
                            <Row>
                                {isShimmer ? (
                                    <>
                                        {_.times(9, (i) => (
                                            <EpisodeListShimmer key={i} />
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        {episodeVideo.relatedEpisodes.map((value) => {
                                            return <Episodethumb value={value} />;
                                        })}
                                    </>
                                )}

                                <Paginate
                                    currentPage={pagination.page ? pagination.page : 1}
                                    onPagination={onPagination}
                                    totalPages={episodeVideo.relatedPages}
                                    pageNumberLimit={width >= 992 ? 5 : 3}
                                />
                            </Row>
                        ) : (
                            <Row>
                                {!episodeVideo.id ? (
                                    <>
                                        {_.times(9, (i) => (
                                            <EpisodeListShimmer key={i} />
                                        ))}
                                    </>
                                ) : (
                                    <Col className={style.notavaildata}>
                                        <p className={style.notaviliable}>
                                            No related episode is available
                                        </p>
                                    </Col>
                                )}
                            </Row>
                        )}
                    </>
                )}
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return { episodeVideos: state.episodeVideo };
};

export default connect(mapStateToProps, {
    fetchLatestEpisodes,
    fetchRelatedVideo,
    removeLatestRelatedVideo,
})(EpiosdeRelatedVideo);
