import {FETCH_XMAS , FETCH_TWITTER} from './types';
import salomapi from '../apis';

export const fetchXmas =  () => async dispatch => {
    const response = await salomapi.get('/christmaspage');
    dispatch({type:FETCH_XMAS,payload:response.data})
}
export const fetchTwitter =  () => async dispatch => {
    const response = await salomapi.get('/twitterfeed');
    dispatch({type:FETCH_TWITTER,payload:response.data})
}