import React from 'react'
import CommonLayout from '../components/Layouts/CommonLayout'
import { useHome } from '../logic/Home/useHome'
import ContactUs from '../components/ContactUs'
import { connect } from 'react-redux'
import { fetchOtherdetails } from '../actions/otherdetailsAction'
import SocialMediaWrap from "../components/SocailMediaIcons";
import { useDimensions } from "../logic/Dimentions";
import { useSeasonal } from '../logic/Seasonal'

const Contact = ({ fetchOtherdetails, otherdetails }) => {
  useHome({ fetchOtherdetails, otherdetails })
  const {  width } = useDimensions();
  const { classes } = useSeasonal()


  return (
    <CommonLayout>
       {width>=992&&<SocialMediaWrap classes={classes}/>}
      <ContactUs />
    </CommonLayout>
  )
}
const mapStateToProps = (state) => {
  return { otherdetails: state.otherdetails.data }
}
export default connect(mapStateToProps, { fetchOtherdetails })(Contact)
