/* eslint-disable import/no-anonymous-default-export */
import {
    FETCH_LATEST_EPISODES_START,
    FETCH_LATEST_EPISODES,
    UPDATE_LATEST_EPISODES,
    RESET_LATEST_EPISODES,
} from "../actions/types.js";

const INTIAL_STATE = {
    status: 'idle'
};

export default (state = INTIAL_STATE, action) => {
    switch (action.type) {

        case FETCH_LATEST_EPISODES_START:
            let status = { status: "loading" };
            return { ...state, ...status };


        case FETCH_LATEST_EPISODES:
            let data = { ...action.payload, status: "completed" };
            return { ...state, ...data };

        // case UPDATE_LATEST_EPISODES:
        //     return {
        //         ...state,
        //         [action.payload.category]:
        //             { ...state[action.payload.category], page: action.payload.page, totalPage: state[action.payload.category].totalPage, data: [...state[action.payload.category].data, ...action.payload.episodeData[action.payload.category]] }
        //     }
        // case UPDATE_LATEST_EPISODES:

        case UPDATE_LATEST_EPISODES:
            return {
                ...state,
                [action.payload.category]: {
                    ...state[action.payload.category],
                    page: action.payload.page,
                    data: action.payload.episodeData[action.payload.category],
                    totalPage: action.payload.episodeData.totalPage,
                },
                status: "completed"
            };

        case RESET_LATEST_EPISODES:
            return INTIAL_STATE;
        default:
            return state;
    }
};
