import React from "react";
import styles from "./AboutPodcast.module.scss";
import { useSeasonal } from "../../logic/Seasonal";

const AboutPodcast = ({ data, showDesc }) => {
  const { classes } = useSeasonal();
  return (
    <>
      <div className={styles.AboutPodcast}>
        <div className={styles.titleWrap}>
          <h2>About the podcast</h2>
          <div className={styles.podcastStatus}>
            <strong>{data?.podcastTitle}</strong>
            <span className="d-none">
              {data?.latestEpisode} episodes
            </span>
          </div>

          {data?.podcastDescription !== false &&
            <article className={`editorTextArea ${classes?.contentLinkHoverColor}`} dangerouslySetInnerHTML={{ __html: data?.podcastDescription }}>
            </article>}

        </div>
        <div className={styles.host_guest_wrap}>

          {data?.host && data?.host.map((value, index) => {
            return (
              <div className={styles.host_guest_list} key={index}>
                <div className={styles.profile}>

                  <figure>
                    <img src={value?.image} alt="Host" />
                  </figure>

                  {value?.name !== false && <div className={styles.profile_dtls}>
                    <strong> {value?.name} </strong><span>Host</span>
                  </div>}
                </div>

                {value?.description !== false && <article className={`editorTextArea ${classes?.contentLinkHoverColor}`} dangerouslySetInnerHTML={{ __html: value?.description }}>
                </article>}

              </div>
            )
          })}
          {data?.guest && data?.guest.map((value, index) => {
            return (
              <>
                <div className={styles.host_guest_list} key={index}>
                  <div className={styles.profile}>
                    <figure>
                      <img src={value?.image} alt="Host" />
                    </figure>
                    <div className={styles.profile_dtls}>
                      <strong> {value?.name} </strong><span>Guest</span>
                    </div>
                  </div>
                  <article className={`editorTextArea ${classes?.contentLinkHoverColor}`} dangerouslySetInnerHTML={{ __html: value?.description }}>

                  </article>
                </div>
              </>
            )
          })}

        </div>
      </div>
    </>
  );
};

export default AboutPodcast;
