/* eslint-disable */

import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import styles from './ChannelFinderTab.module.scss';
import WatchonFormMail from "../WatchonFormMail"
import WatchonFormRequest from '../WatchonFormRequest';
import { useSeasonal } from '../../logic/Seasonal';


const ChannelFinderAreaList = ({ output, zipCode }) => {

    const [sendEmail, setSendEmail] = useState(false);
    const [selectedChannel, setSelectedChannel] = useState(false);
    const { seasonal, classes } = useSeasonal();

    const tweeturl = "https://twitter.com/share?text=Please%20bring%20@ShalomWorldTV%20to%20our area.%20%23getShalomWorldTV+&amp;url=http://www.shalomworld.org"
    return (
        output?.status == 'error' ?
            <div className={styles.nochannel}>
                <p className={styles.message}>{output.msg}</p>
                <h2 className={`${classes?.seasonalClass} ${'styles.ttlhome'}`}>Request The Shalom World TV Network today!</h2>
                <div className={`row ${styles.buttonContainer}`}>
                    <div className={`col-md-3 ${styles.buttonInner}`}>
                        <Button variant="outline-primary" className={classes?.fullSchduleBtn} size="lg" onClick={() => { setSendEmail(true) }}>SEND EMAIL</Button>
                    </div>
                    <div className={`col-md-3 ${styles.buttonInner}`}>
                        <Button variant="outline-primary" size="lg" className={classes?.fullSchduleBtn} ><a target="_blank" href={tweeturl}>SEND TWEET</a></Button>
                    </div>
                </div>
                {sendEmail && <WatchonFormMail />}
            </div> : <div className={styles.channelList}>
                {output?.channels.map((value) => {
                    <h2 className={`${classes?.seasonalClass} ${'styles.ttlhome'}`}>Request Shalom World from your provider.</h2>
                    return (
                        <div className={`${styles.listContainer}`}>
                            <div className={`${styles.channelName}`}>{value.provider}</div>
                            <div className={styles.channelBtn}>
                                <div className={` ${styles.buttonInner}`}>
                                    <Button variant="outline-primary" size="lg" className={classes?.fullSchduleBtn} onClick={() => setSelectedChannel(value)}>REQUEST</Button>
                                </div>
                                <div className={`${styles.buttonInner}`}>
                                    <Button variant="outline-primary" size="lg" className={classes?.fullSchduleBtn} ><a target="_blank" href={tweeturl}>SEND TWEET</a></Button>
                                </div>
                            </div>
                        </div>
                    )
                })}
                {selectedChannel != false && <WatchonFormRequest zipCode={zipCode} channel={selectedChannel} />}
            </div>
    )
}



export default ChannelFinderAreaList;