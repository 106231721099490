import {FETCH_CAR_DONATION} from '../actions/types.js';

const INTIAL_STATE = {
    carDonation:{},
};
export default (state = INTIAL_STATE,action) => {
    switch(action.type){ 
        case FETCH_CAR_DONATION: 
        return {
            ...state,
            carDonation:action.payload
        }
        default:
            return state;
    }
}