/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import styles from "./DonateDollarADayForm.module.scss";

/**
 * This component renders a donation form for the specified region..
 * The possible values are `/dollar-a-day-ca`, `/dollar-a-day-aus`, `/one-pound-a-day`, `/euro-a-day`, and `/dollar-a-day-us`.
 * @param {pathName} The `pathName` prop specifies the region for the donation form.
 * @param {setPlaying} The `setPlaying` prop is used to stop the video player.
 * The `ref` prop is used to get a reference to the donation form.
 * Form ids used here are:  XYJRQAGB =>CA && XHKAJKLB => Row (US)} 
 */

const DonateDollarADayForm = ({ pathName, setPlaying, ref }) => {
  return (
    <>

      {/* Show FundRaiser form for CA */}
      {pathName === "/dollar-a-day-ca" ? (
        <>
          <div ref={ref} className={`${styles.donationEmbededForm} section-region-ca`}
            onClick={() => {
              // Stop the video player.
              setPlaying(false);
            }}>
            <a className="contentForm" href="#XYJRQAGB" ></a>

          </div>
        </>
      ) : pathName === "/dollar-a-day-au" ? (

        // Donate Form for Australia
        <div ref={ref} className={`${styles.donationEmbededForm} section-region-aus`}
          onClick={() => {
            // Stop the video player.
            setPlaying(false);
          }}>
          <a className="contentForm" href="#XYJRQAGB" ></a>
        </div>

      ) : pathName === "/dollar-a-day-uk" ? (

        // Donate Form for UK
        <div ref={ref} className={`${styles.donationEmbededForm} section-region-uk`}
          onClick={() => {
            setPlaying(false);
          }}>
          <a className="contentForm" href="#XHKDKLRT" ></a>
        </div>

      ) : pathName === "/dollar-a-day-eu" ? (

        // Donate Form for Europe
        <div ref={ref} className={`${styles.donationEmbededForm} section-region-eu`}
          onClick={() => {
            setPlaying(false);
          }}>
          <a className="contentForm" href="XMLZGDSP" ></a>
        </div>
      ) : pathName === "/dollar-a-day-ch" ? (

        // Donate Form for Switzerland
        <div ref={ref} className={`${styles.donationEmbededForm} section-region-ch`}
          onClick={() => {
            setPlaying(false);
          }}>
          <a className="contentForm" href="#XLDVSSCZ" ></a>
        </div>

      ) : (
        //  Show FundRaiser form for US, MX, prisons
        <>
          <div ref={ref} className={`${styles.donationEmbededForm} section-region-us`}
            onClick={() => {
              // Stop the video player.
              setPlaying(false);
            }}>
            <a className="contentForm" href="#XHKAJKLB" ></a>
          </div>

        </>
      )}
    </>
  );
};

export default DonateDollarADayForm;
