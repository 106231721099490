/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styles from "./SearchResult.module.scss";
import Container from "react-bootstrap/Container";
import { fetchCompleteSearchResult } from "../../actions/menuAction";
import { connect } from "react-redux";
import { Link, useParams, useLocation } from "react-router-dom";
import { useSeasonal } from "../../logic/Seasonal";
import { Spinner } from "react-bootstrap";

const SearchResult = ({
  fetchCompleteSearchResult,
  searchArea,
  searchData,
  searchResultTotal,
  searchType,
  status,
}) => {
  const [tabActive, SetTabActive] = useState("all");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false); //Pagination loader

  let { searchTerm, } = useParams();
  let location = useLocation();

  const { classes } = useSeasonal();

  useEffect(() => {
    if (location?.state?.filters?.type) {
      SetTabActive(location.state.filters.type)
    }
  }, [location]);

  useEffect(() => {
    setPage(1);
    fetchCompleteSearchResult(searchTerm, tabActive, 1);

  }, [searchTerm, tabActive]);

  /*Pagination*/
  useEffect(() => {
    if (page > 1) {
      fetchCompleteSearchResult(searchTerm, tabActive, page);
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [searchTerm, tabActive, page]);

  return (
    <Container>
      <div className={styles.searchResult}>
        <h4
          className={styles.searchResultHeading}
          style={{ color: "white", marginTop: "200px" }}
        >
          {searchArea.total} Results for "{searchTerm}"
        </h4>
        <ul className={styles.tabs}>
          <li
            className={tabActive === "all" || searchType === "all" ? `${styles.active} ${classes?.seasonalClass} ${classes?.txtColor}`
              : null
            }
            onClick={() => {
              SetTabActive("all");
            }}
          >
            ALL
          </li>

          <li
            className={
              tabActive === "programs" || searchType === "programs"
                ? `${styles.active} ${classes?.seasonalClass} ${classes?.txtColor}`
                : null
            }
            onClick={() => {
              SetTabActive("programs");
            }}
          >
            SHOWS
          </li>
          <li
            className={
              tabActive === "episodes" || searchType === "episodes"
                ? `${styles.active} ${classes?.seasonalClass} ${classes?.txtColor}`
                : null
            }
            onClick={() => {
              SetTabActive("episodes");
            }}
          >
            EPISODES
          </li>
          <li
            className={
              tabActive === "blog" || searchType === "blog"
                ? `${styles.active} ${classes?.seasonalClass} ${classes?.txtColor}`
                : null
            }
            onClick={() => {
              SetTabActive("blog");
            }}
          >
            NEWS
          </li>
          <li
            className={
              tabActive === "podcasts" || searchType === "podcasts"
                ? `${styles.active} ${classes?.seasonalClass} ${classes?.txtColor}`
                : null
            }
            onClick={() => {
              SetTabActive("podcasts");
            }}
          >
            PODCAST
          </li>
        </ul>



        <div className={styles.searchResultWrapper}>
          {searchData &&
            searchData.map((value) => {
              return (
                <div className={styles.searchResultListWrp}>
                  {value.type === "blog" && (
                    <Link
                      to={"/news/" + value.blogUrl}
                      style={{ color: "white" }}
                    >
                      <div className={styles.searchResultList}>
                        <figure>
                          <img src={value.blogImage} alt="" />
                        </figure>
                        <div className={`${styles.searchResultListDesc} `}>
                          <h3 className={`${classes?.txtColor}`}>
                            In {value.type === "blog" ? "news" : ""}
                          </h3>
                          <h4>{value.blogTitle}</h4>
                          <h5>{value.airedAt}</h5>
                        </div>
                      </div>
                    </Link>
                  )}

                  {(value.type === "episodes" || value.type === "programs") && (
                    <Link
                      to={
                        value.type === "episodes"
                          ? "/episode/" + value.url
                          : "/show/" + value.url
                      }
                      style={{ color: "white" }}
                    >
                      <div className={styles.searchResultList}>
                        <figure>
                          <img src={value.image} alt="" />
                        </figure>
                        <div className={styles.searchResultListDesc}>
                          <h3 className={`${classes?.txtColor}`}>
                            In {value.type === "programs" ? "shows" : "episode"}
                          </h3>
                          <h4>{value.title}</h4>
                          <div className={styles.searchResultBtmDesc}>
                            <p>
                              {" "}
                              {value.type === "episodes"
                                ? `Episode ${value.episode} | ${value.program}`
                                : ""}
                            </p>
                            <h5>{value.airedAt}</h5>
                          </div>
                        </div>
                      </div>
                    </Link>
                  )}

                  {(value.type === "podcastepisodes" ||
                    value.type === "podcasts") && (
                      <Link
                        to={
                          value.type === "podcastepisodes"
                            ? "/podcast/" + value.programSlug + "/" + value.url
                            : "/podcast/" + value.url
                        }
                        style={{ color: "white" }}
                      >
                        <div className={styles.searchResultList}>
                          <figure>
                            <img src={value.thumbnail} alt="" />
                          </figure>
                          <div className={styles.searchResultListDesc}>
                            <h3 className={`${classes?.txtColor}`}>
                              In{" "}
                              {value.type === "podcastepisodes"
                                ? "podcast episode"
                                : "podcast"}
                            </h3>
                            <h4>{value.title}</h4>

                            {value.type === "podcastepisodes" && (
                              <div className={styles.searchResultBtmDesc}>
                                <p>Podcast {value?.programTitle}</p>
                                <h5>{value.airedAt}</h5>
                              </div>
                            )}

                            {value.type === "podcasts" && (
                              <div
                                className={`${styles.searchResultBtmDesc} ${styles.podcastDescription}`}
                              >
                                <article
                                  dangerouslySetInnerHTML={{
                                    __html: value?.description,
                                  }}
                                ></article>

                                <h5>{value.airedAt}</h5>
                              </div>
                            )}
                          </div>
                        </div>
                      </Link>
                    )}
                </div>
              );
            })}


          {status !== 'loading' &&
            <>
              {searchResultTotal === 0 && tabActive === "all" && <p>No results found. </p>}
              {searchResultTotal === 0 && tabActive === "programs" && (
                <p>No results found for Shows. </p>
              )}
              {searchResultTotal === 0 && tabActive === "episodes" && (
                <p>No results found for Episodes. </p>
              )}
              {searchResultTotal === 0 && tabActive === "blog" && (
                <p>No results found for News. </p>
              )}
              {searchResultTotal === 0 && tabActive === "podcasts" && (
                <p>No results found for Podcast. </p>
              )}

            </>
          }
          <div className={styles.spinner_wrap}>
            {/* {loading ? (
                      <LoadMoreLoader />

                    ) : null} */}

            {page < searchArea.pages && (
              <button
                className={`${styles.btn_load_more} ${classes.fullSchduleBtn} ${styles.uploading}`}
                onClick={() => setPage(page + 1)}
              >
                {loading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"

                  />
                )}
                Load More{" "}
              </button>
            )}

            <>
              {status === 'loading' && (
                <Spinner
                  as="span"
                  animation="border"
                  size="md"
                  role="status"
                  aria-hidden="true"
                  className={`${styles.result_loader} ${classes?.loaderColor}`}
                />
              )}
            </>

          </div>
        </div>

      </div>
    </Container >
  );
};

const mapStateToProps = (state) => {
  return {
    searchArea: state.commonSearch.searchArea,
    searchData: state.commonSearch.searchData,
    searchResultTotal: state.commonSearch.total,
    searchType: state.commonSearch.type,
    status: state.commonSearch.status,
  };
};

export default connect(mapStateToProps, {
  fetchCompleteSearchResult,
})(SearchResult);
