import { FETCH_PODCASTEPISODE, UPDATE_PODCASTEPISODE } from '../actions/types';

const INTIAL_STATE = {
    data: [],
    totalPages: 0,
    currentPage: 1,

};
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_PODCASTEPISODE:
            return action.payload
        case UPDATE_PODCASTEPISODE:
            return action.payload
        default:
            return state;
    }
}


