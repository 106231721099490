import {POST_MEDIASIGN} from '../actions/types.js';

const INTIAL_STATE = {userId:"",
userName:"",
userType:"",
accesstoken:"",
expiresIn:"",
tokenType:"",
scope:"",
requestToken:"",
emailVerified:""};

export default (state = INTIAL_STATE,action) => {
    switch(action.type){
        case POST_MEDIASIGN:
            return action.payload;
        default:
            return state;
    }
}