import React from "react";
import styles from './MobPodcastListShimmer.module.scss';

const AllEpisodeShimmer = () => {
  return (
    <div className={`${styles.mobPodcastListShimmerWrapper} col-lg-4 col-md-6 col-xs-12`}>
      <div className={`${styles.mobPodcastListShimmer} d-flex `}>
        <div className={`${styles.shimmerImg} Shimmer `}></div>
        <div className={`${styles.shimmerTtl} Shimmer `}></div>
      </div>
    </div>
  );
};

export default AllEpisodeShimmer;
