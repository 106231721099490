import React, { useState } from 'react';
import styles from './CountrySelectBox.module.scss';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Au from '../../../assets/img/flags/au.png'
import Ca from '../../../assets/img/flags/ca.png'
import Eu from '../../../assets/img/flags/eu.png'
import Ie from '../../../assets/img/flags/ie.png'
import Uk from '../../../assets/img/flags/uk.png'
import Us from '../../../assets/img/flags/us.png'
import _ from 'lodash';
import { useSeasonal } from '../../../logic/Seasonal';


const CountrySelectBox = ({ handleChangeCountry, countries, countrySelectionText, countrySelectionText1 }) => {
  // const [countryName, setCountryName] = React.useState([]);
  const [selectedCountry, setSelectedCountry] = useState({ country_name: "Select Country" });
  const { seasonal, classes } = useSeasonal();


  return (
    <div className={styles.chooseCountryWrap}>
      <div className={styles.cc_left_wrap}><h5 className={classes?.txtColor}>{countrySelectionText}</h5><p>{countrySelectionText1}</p></div>
      <div className={styles.cc_right_wrap}>

        <Dropdown className={styles.selectButton}
          onSelect={(eventKey, e) => {
            // const { code, title } = countries.find(({ code }) => eventKey === code);

            let x = _.filter(countries, function (o) {
              return o.country_code == eventKey
            });
            setSelectedCountry(x[0])
            handleChangeCountry(eventKey)
            // setSelectedCountry(eventKey);
            // setToggleContents(<><FlagIcon code={code}/> {title}</>);
          }}
        >
          <Dropdown.Toggle id="dropdown-flags" className={styles.dropdownCountry} >
            {selectedCountry.country_flag && <img src={selectedCountry.country_flag} />}{selectedCountry.country_name}
          </Dropdown.Toggle>
          <Dropdown.Menu className={styles.dropDownList}>
            {countries.length > 0 ? (

              countries.map((country) => {
                return (
                  <Dropdown.Item eventKey={country.country_code}><img src={country.country_flag} width="50" /> {country.country_name}</Dropdown.Item>
                )
              })
            ) : null}
          </Dropdown.Menu>
        </Dropdown>

        {/* <Select
         inputProps={{ 'aria-label': 'Without label' }}
         className={styles.choose_season__select}
        displayEmpty
        value={countryName}
        onChange={handleChangeCountry}
        style={{'color':'white','margin': '0'}}
      >
        {/* <MenuItem value='us'><img src={Us} />USA</MenuItem>
        <MenuItem value='eu'><img src={Uk} />UK</MenuItem>
        <MenuItem value='ca'><img src={Ca} />CAN</MenuItem>
        <MenuItem value='au'><img src={Au} />AUS</MenuItem>
        <MenuItem value='ir'><img src={Ie} />IRE</MenuItem> */}
        {/* <MenuItem disabled value="">
            <em>Placeholder</em>
          </MenuItem> */}
        {/*<MenuItem key='us' value='us'>USA</MenuItem>
      </Select> */}
        {/* <div id="selected_flag" className="input_country_flag">
                <img src="" /></div><div className="choose_country choose_country--select">
                    <input type="hidden" autocomplete="on" name="Country" value="in" />
                        <input autocomplete="on" className="choose_country__search" type="search" placeholder="Select Country" value="" />
                            <div className="choose_country__select"><ul className="choose_country__options">
                         <li role="menuitem" className="choose_country__option choose_country__row" data-value="us">
                      <figure><img src="https://ark.shalomworld.org/wp-content/uploads/2021/06/us.png" width="10px" />
                      <figcaption>USA</figcaption>
                           </figure></li>
           <li role="menuitem" className="choose_country__option choose_country__row" data-value="gb">
                           <figure>
     <img src="https://ark.shalomworld.org/wp-content/uploads/2021/06/uk.png" width="10px" />
         <figcaption>UK</figcaption> 
         </figure></li><li role="menuitem" className="choose_country__option choose_country__row" data-value="ca"><figure>
             <img src="https://ark.shalomworld.org/wp-content/uploads/2021/06/canada.png" width="10px" />
                 <figcaption>Canada</figcaption> </figure></li>
 <li role="menuitem" className="choose_country__option choose_country__row" data-value="others">
     <figure><img src="https://ark.shalomworld.org/wp-content/uploads/2021/07/globe-white.png" width="10px" />
         <figcaption>Other</figcaption> 
</figure></li></ul></div></div> */}
      </div>
    </div>
  )
}

export default CountrySelectBox;