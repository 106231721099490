/* eslint-disable import/no-anonymous-default-export */
import {
    ADD_TOPLAYLIST,
    APPEND_TOPLAYLIST,
    UPDATE_PLAYLIST,
    PURGE_TOPLAYLIST,
    TRIGGER_PLAY,
    TRIGGER_PROGRESS,
    TRIGGER_ACTIVEID,
    FETCH_PODCAST,
    FETCH_MORE_PODCAST,
    FETCH_PODCAST_EPISODES,
    FETCH_PODCAST_EPISODESPLAY,
    UPDATE_PODCAST,
} from "../actions/types";

const INTIAL_STATE = {
    playLists: {
        data: [],
        id: null,
    },
    podCastData: {},

    play: false,
    activeId: null,
    progress: { percentage: "0", remain: "" },
};
export default (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case ADD_TOPLAYLIST:
            return {
                ...state,
                playLists: {
                    data: state.playLists.concat(action.payload),
                    id: "",
                    seasonId: "",
                },
            };

        case APPEND_TOPLAYLIST:
            let arr =
                action.payload[
                    action.indexFound ? action.indexFound : 0
                ]?.duration.split(":"); // splitting the string by colon
            arr[0] = arr[0] !== "" ? `${arr[0]} hr` : "";
            arr[1] = arr[1] !== "" ? `${arr[1]} min` : "";
            arr[2] = arr[2] !== "" ? `${arr[2]} Sec` : "";
            let newVal = arr.join(" ");
            return {
                ...state,
                playLists: {
                    data: action.payload,
                    id: action.podCastDataId,
                    seasonId: action.seasonId,
                },
                progress: {
                    remain: newVal.replace("00 hr", ""),
                    percentage: 0,
                },
            };

        case UPDATE_PLAYLIST:
            let arry =
                action.payload[
                    action.indexFound ? action.indexFound : 0
                ]?.duration.split(":"); // splitting the string by colon
            arry[0] = arry[0] !== "" ? `${arry[0]} hr` : "";
            arry[1] = arry[1] !== "" ? `${arry[1]} min` : "";
            arry[2] = arry[2] !== "" ? `${arry[2]} Sec` : "";
            let newValy = arry.join(" ");
            return {
                ...state,
                playLists: {
                    data: action.payload,
                },
                progress: {
                    remain: newValy.replace("00 hr", ""),
                    percentage: 0,
                },
            };

        case PURGE_TOPLAYLIST:
            return {
                ...state,
                playLists: {
                    data: [],
                },
                activeId: null,
            };

        case TRIGGER_PLAY:
            return {
                ...state,
                play: action.payload,
            };

        case TRIGGER_ACTIVEID:
            return {
                ...state,
                activeId: action.payload,
            };

        case TRIGGER_PROGRESS:
            return {
                ...state,
                progress: action.payload,
            };

        /**/
        case FETCH_PODCAST:
            return {
                ...state,
                podCastData: action.payload,
            };
        case UPDATE_PODCAST:
            return {
                ...state,
                podCastData: action.payload?.data,
            };

        case FETCH_MORE_PODCAST:
            let index = state.podCastData.seasons.findIndex(
                (x) => x.seasonNumber === action.payload.seasonNumber
            );
            let todosPodcast = { ...state.podCastData };
            let todosPlaylist = { ...state.playLists };
            let episodes = todosPodcast.seasons[index].episodes.concat(
                action.payload.episodes
            );
            let page = action.payload.currentPage;
            todosPodcast.seasons[index].page = page;
            todosPodcast.seasons[index].episodes = episodes;

            //Append same to playlist based on Conditions ONLY
            //If Podcast ID is same as Playlist ID
            //If Season ID is available, Podcast Season ID is same as Playlist season ID
            let append = false;
            if (todosPodcast.id === todosPlaylist.id) {
                append = true;
                if (todosPodcast.seasons[index].id === todosPlaylist.seasonId) {
                    append = true;
                } else {
                    append = false;
                }
            }

            if (append) {
                let episodes = todosPlaylist.data.concat(action.payload.episodes);
                let page = action.payload.currentPage;
                todosPlaylist.page = page;
                todosPlaylist.data = episodes;
            }

            return {
                ...state,
                podCastData: todosPodcast,
                playLists: todosPlaylist,
            };

        case FETCH_PODCAST_EPISODES:
            let position = state.podCastData.seasons.findIndex(
                (x) => x.seasonNumber === action.payload.seasonNumber
            );
            //Append to podcast data
            let todosPodcastData = { ...state.podCastData };
            todosPodcastData.seasons[position].episodes = todosPodcastData.seasons[
                position
            ].episodes.concat(
                action.payload.episodes.filter(
                    (o1) =>
                        !todosPodcastData.seasons[position].episodes.some(
                            (o2) => o2.id === o1.id
                        )
                )
            );
            todosPodcastData.seasons[position].page = action.payload.pages;
            todosPodcastData.seasons[position].pages = action.payload.pages;
            //Append to podcast playlist data
            let todosPlaylistData = { ...state.playLists };
            todosPlaylistData.data = state.playLists.data.concat(
                action.payload.episodes.filter(
                    (o1) => !state.playLists.data.some((o2) => o2.id === o1.id)
                )
            );

            return {
                ...state,
                play: true,
                playLists: todosPlaylistData,
                podCastData: todosPodcastData,
            };

        case FETCH_PODCAST_EPISODESPLAY:
            let positionx = state.podCastData.seasons.findIndex(
                (x) => x.seasonNumber === action.payload.seasonNumber
            );
            //Append to podcast data
            let todosPodcastDatax = { ...state.podCastData };
            todosPodcastDatax.seasons[positionx].episodes = todosPodcastDatax.seasons[
                positionx
            ].episodes.concat(
                action.payload.episodes.filter(
                    (o1) =>
                        !todosPodcastDatax.seasons[positionx].episodes.some(
                            (o2) => o2.id === o1.id
                        )
                )
            );
            todosPodcastDatax.seasons[positionx].page = action.payload.pages;
            todosPodcastDatax.seasons[positionx].pages = action.payload.pages;
            //Append to podcast playlist data
            let todosPlaylistDatax = { ...state.playLists };
            todosPlaylistDatax.data = state.playLists.data.concat(action.payload.episodes.filter((o1) => !state.playLists.data.some((o2) => o2.id === o1.id)
            )
            );

            return {
                ...state,
                playLists: todosPlaylistDatax,
                podCastData: todosPodcastDatax,
            };


        default:
            return state;
    }
};
