import React, { useRef } from "react";
import styles from "./PodcastEpisodeList.module.scss";
import PodcastEpisodeThumb from "../PodcastEpisodeThumb";
import InfiniteScroll from "react-infinite-scroller";
import LoadMoreLoader from "../../components/Shimmers/LoadMoreLoader"

const PodcastEpisodes = ({
  handlePlay,
  data,
  podcastSlug,
  handleUpdatePlaylist,
  handleAddToPlaylist,
  activeId,
  progress,
  play,
  podCastPlayListData,
  handleClickUrl,
  seasonId,
  loadMoreEpisodes

}) => {
  const scrollerRef = useRef();

  let episodes = data?.seasons[seasonId].episodes;

  return (
    <>
      <div className={styles.PodcastEpisodeLists} ref={scrollerRef}>

        {/* <span style={{ color: 'red' }}> # {parseInt(data?.seasons[seasonId].page) + 1}  <br /></span>

        <span style={{ color: 'red' }}> # {data?.seasons[seasonId].pages} <br /> </span>

        <span style={{ color: 'red' }}> # {parseInt(data.seasons[seasonId].page) + 1 >= data?.seasons[seasonId].pages ? 'STOPPED' : 'PAGINATE'} <br /> </span> */}

        <InfiniteScroll
          pageStart={0}
          loadMore={() => loadMoreEpisodes(parseInt(parseInt(data?.seasons[seasonId].page)) + 1)}
          hasMore={parseInt(data.seasons[seasonId].page) >= data?.seasons[seasonId].pages ? false : true}
          loader={
            <div className="loader" key={0} >
              <LoadMoreLoader />
            </div>

          }
          useWindow={true}
          getScrollParent={() => scrollerRef.current}
        >

          {episodes &&
            episodes.length >= 1 &&
            episodes.map((value, index) => {
              return (
                <PodcastEpisodeThumb
                  key={index}
                  episode={value}
                  podcastSlug={podcastSlug}
                  handleAddToPlaylist={handleAddToPlaylist}
                  handleUpdatePlaylist={handleUpdatePlaylist}
                  activeId={activeId}
                  progress={progress}
                  play={play}
                  podCastPlayListData={podCastPlayListData}
                  handlePlay={handlePlay}
                  handleClickUrl={handleClickUrl}
                />
              );
            })}
        </InfiniteScroll>

      </div>
    </>
  );
};

export default PodcastEpisodes;
