import { useEffect } from "react";

export const useProgramList = (props) => {
    const {
        fetchPrograms,
        programes,
        category,
        path,
        fetchCategoryPrograms,
        pagination,
    } = props;
    let perPage = 15;

    useEffect(() => {

        if (pagination?.page >= 1) {

            if (path === "/") {
                if (programes?.length === 0 || programes.bgImage) {
                    fetchPrograms("featured");
                }
            } else {
                let cat = category;
                if (path === "/shows") {
                    cat = "popular";
                }
                if (programes[cat] === undefined) {
                    fetchCategoryPrograms(cat, 1, perPage);
                } else if (pagination.page && programes[cat].page !== pagination.page) {
                    fetchCategoryPrograms(cat, pagination.page, perPage);
                }
            }
        }

        if ((pagination === undefined || pagination?.page === 1) && (path === "/") && (programes?.length === 0 || programes.bgImage)) {
            fetchPrograms("featured");
            fetchCategoryPrograms('popular', 1, perPage);
        }

    }, [path]);


};
