import { FETCH_OTHER_DETAILS, FETCH_SOCIALLINK } from "./types";
import salomapi from "../apis";
import BrowserPersistence from "../util/simplePersistance";

const storage = new BrowserPersistence();
export const fetchOtherdetails = () => async (dispatch) => {
    /* */

    async function fetchFromServer() {
        try {
            const response = await salomapi.get("/otherdetails");
            await saveOtherDetails(response.data);
            dispatch({ type: FETCH_OTHER_DETAILS, payload: response.data });
        } catch (error) {
            dispatch({
                type: FETCH_OTHER_DETAILS,
                payload: {},
            });
            throw error;
        }
    }

    const localOtherDetailsData = await retrieveOtherDetails();
    if (localOtherDetailsData) {
        dispatch({ type: FETCH_OTHER_DETAILS, payload: localOtherDetailsData });
    } else {
        await fetchFromServer();
    }

    async function retrieveOtherDetails() {
        return storage.getItem("otherDetailsList");
    }

    async function saveOtherDetails(other) {
        return storage.setItem("otherDetailsList", other, 86400);
    }
};

/* */

export const fetchSocialLink = () => async (dispatch) => {
    async function fetchFromServer() {
        try {
            const response = await salomapi.get("/sociallinks");
            await saveSocialLink(response.data);
            dispatch({ type: FETCH_SOCIALLINK, payload: response.data });
        } catch (error) {
            dispatch({
                type: FETCH_SOCIALLINK,
                payload: {},
            });
            throw error;
        }
    }

    const localSocialData = await retrieveSocialDetails();
    if (localSocialData) {
        dispatch({ type: FETCH_SOCIALLINK, payload: localSocialData });
    } else {
        await fetchFromServer();
    }

    async function retrieveSocialDetails() {
        storage.removeItem("socialLinkList");
        return storage.getItem("socialLinksList");
    }

    async function saveSocialLink(social) {
        return storage.setItem("socialLinksList", social, 86400);
    }
};
