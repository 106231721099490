import React from "react";
import CommonLayout from "../components/Layouts/CommonLayout";
import { TitleComponent } from "../components/TitleComponent";
import PodcastDetail from "../components/PodcastDetail";
import SocialMediaWrap from '../components/SocailMediaIcons';
import { useDimensions } from '../logic/Dimentions';
import { useSeasonal } from '../logic/Seasonal'

const PodcastDetailPage = () => {
    const { width } = useDimensions();
    const { classes } = useSeasonal()

    return (
        <CommonLayout>
            <TitleComponent title="Shalom world " />
            {width >= 992 && <SocialMediaWrap classes={classes} />}
            <PodcastDetail />
        </CommonLayout>
    );
};

export default PodcastDetailPage;
