import { FETCH_DONATION } from "../actions/types";

const INTIAL_STATE = { data: [] };
export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_DONATION:
      return { ...state, data: action.payload };
    default:
      return state;
  }
};
