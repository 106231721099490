/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import _ from "lodash";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import InfiniteScroll from "react-infinite-scroller";
import VideoPlayer from "../VideoPlayer";
import { FiX } from "react-icons/fi";
import {
  fetchProgramDetails,
  fetchLatestVideo,
  fetchRelatedVideo,
} from "../../actions/programeAction";
import styles from "./ShowDetails.module.scss";
import Container from "react-bootstrap/Container";
import ProgramThumb from "../ProgramThumb";
import Episodethumb from "../EpisodeThumb";
import EpisodeListShimmer from "../Shimmers/EpisodeListShimmer/EpisodeListShimmer";
import { useDimensions } from "../../logic/Dimentions";
import { useSeasonal } from "../../logic/Seasonal";
import SocialMediaWrap from "../SocailMediaIcons";
import LoadMoreLoader from "../Shimmers/LoadMoreLoader";
import Paginate from "../../util/Paginate";

const ShowDetails = ({
  fetchProgramDetails, // Function to fetch program details
  programes, // List of programs
  fetchLatestVideo, // Function to fetch latest videos. This is to be used for Related Episodes.
  fetchRelatedVideo, // Function to fetch related videos. This is to be used for Latest Episodes
}) => {
  // Reference to the listing element
  const listingRef = useRef(null)
  const innerTabRef = useRef(null);
  // Getting the showId from the URL parameters
  let { showId, speakerId, categoryId } = useParams();

  // Access to the browser history object
  let history = useHistory();

  // Access to the current location object
  const { hash } = useLocation();

  // Access to the width dimension of the window
  const { width } = useDimensions();

  // Custom hook for seasonal styling
  const { classes } = useSeasonal();

  // State variables
  const [page, setPage] = useState({ latest: 1, related: 1 }); // Pagination page numbers
  const [watchPromo, setWatchPromo] = useState(false); // Flag to watch promo video
  const [seasonSeleted, setSeasonSeleted] = useState();
  const [buttomTab, setButtomTab] = useState(4);
  const [programDetails, setProgramDetails] = useState([]);
  const [stickyMenu1, setStickyMenu1] = useState(false); // Flag for sticky menu
  const [nonStickyMenu1, setNonStickyMenu1] = useState(false); // Flag for Non sticky menu
  const [showDescription, setShowDescription] = useState(false); // Flag to show program description
  const [hasMoreLatestEpisode, setHasMoreLatestEpisode] = useState(true);
  const [hasMoreRelatedEdpisode, setHasMoreRelatedEdpisode] = useState(true);
  const [hasMoreRelatedShow, setHasMoreRelatedShow] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isShimmer, setisShimmer] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [toggleScreen, setToggleScreen] = useState(false);
  const [showControls, setShowControls] = useState(0);
  const [play, setPlay] = useState(false);

  const [speaker, setSpeaker] = useState({ value: "Speakers", ky: "" }); // Selected speaker-To be used for filter dropdown
  const [topic, setTopic] = useState({ value: "Categories", ky: "" }); // Selected top To be used for filter dropdown
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 9,
    perPage: 9,
  });


  // Load items based on the pagination state
  useEffect(() => {
    if (pagination && pagination.page !== 0 && programDetails?.id) {
      loadItems();
    }
  }, [pagination]);

  // Excecutes pagination scroll to Top and increment page number
  const onPagination = (pageNumber) => {
    executeScroll();
    setPagination({
      ...pagination,
      page: pageNumber,
    });
  };
  const scrollToTab = () => {
    if (innerTabRef.current) {
      const offsetTop = innerTabRef.current.offsetTop;
      window.scrollTo({
        top: offsetTop - 60,
        behavior: 'smooth', // Optional: Smooth scrolling effect
      });

    }

  };
  // Add scroll event listener
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  // Redirect to the selected season if the URL has changed
  useEffect(() => {
    if (seasonSeleted?.url && seasonSeleted.url !== showId) {
      history.push("/show/" + seasonSeleted.url);
    }
  }, [seasonSeleted]);

  // Check if the URL hash is "#promo" and set the watchPromo state accordingly. Opens Modals and start playing Promo
  useEffect(() => {
    if (hash && hash === "#promo") {
      if (programDetails?.promoVideo?.length > 0) {
        setWatchPromo(true);
      }
    }
  }, [showId, programDetails]);

  // Fetch program details when the showId changes
  useEffect(() => {
    setisShimmer(true);

    fetchProgramDetails(showId, speakerId, categoryId)
      .then((value) => {
        if (value.status === "show_deleted") {
          history.push(`/show-not-found`);
        } else {
          setisShimmer(false);
          setProgramDetails(value);
          if (value.seasonlist && value.seasonlist.length > 0) {
            _.find(value.seasonlist, (o) => {
              if (o.url === showId) {
                setSeasonSeleted(o);
                return o;
              }
            });
          }
          if (value.childPrograms?.length >= 1) {
            setButtomTab(1); // Go to Related Shows tab
          } else {
            setButtomTab(4); // Go to Latest Episode
          }

          //Set Speaker and SetTopic
          setSpeaker({
            value: value.speakers[speakerId] || "Speakers",
            ky: speakerId,
          });
          setTopic({
            value: value.topics[categoryId] || "Categories",
            ky: categoryId,
          });

        }
      })
      .catch(function (error) {
        // log and rethrow
      });
  }, [showId]);

  useEffect(() => {
    let flag = 0;
    _.map(programes, (a) => {
      // eslint-disable-next-line eqeqeq
      if (flag == 0) {
        _.find(a?.data, (o) => {
          // eslint-disable-next-line eqeqeq
          if (o.url == showId) {
            flag = 1;
            return o;
          }
        });
      }
    });
  }, [showId]);

  // Set active tab 'Related Shows' if series title is '9 PM Series' . Not included in naive app
  useEffect(() => {
    if (programDetails.title === "9 PM Series") {
      setButtomTab(1);
    }

    if (
      programDetails?.latestEpisodes?.status &&
      programDetails.latestEpisodes.status === "error"
    ) {
      setHasMoreLatestEpisode(0);
    }
  }, [programDetails]);

  const handleRouting = (speaker, topic) => {

    const newPath = `/show/${showId}/${speaker?.ky || "all"}/${topic?.ky || "all"
      }`;
    history.push(newPath);
  };

  // Filter latest episodes based on Speaker && Topic dropdown
  const handleFilterLatestEpisodes = (speaker, topic) => {
    let pageNo = pagination.page;
    let tags = [];
    if (programDetails.tagsIds != null) {
      tags = programDetails.tagsIds + "";
    }
    setisShimmer(true);

    fetchLatestVideo(
      programDetails.id,
      programDetails.programId,
      programDetails.tagsIds != null ? tags : "",
      pageNo,
      pagination.limit,
      speaker,
      topic
    ).then(async (res) => {
      if (res.status !== "error") {
        setTimeout(() => {
          setisShimmer(false);
        }, 1000);
        await setProgramDetails({
          ...programDetails,
          latestEpisodes: {
            ...programDetails.latestEpisodes,
            currentPage: pageNo,
            episodes: res.episodes,
            total: res?.total,
            pages: res?.pages,
            relatedPages: res?.relatedPages,
            relatedTotal: res?.relatedTotal,
          },
        });
      }
    });
  };

  // Load more items based on active tab.

  /**
   * This denotes which tab to be set as active and pagination
   *
   * @ignore
   * @visibleName setButtomTab
   * #setButtomTab(1)  | Related Show    | programDetails.childPrograms
   * #setButtomTab(2)  | Related Episode | programDetails.relatedEpisodes
   * #setButtomTab(3)  | About           | programDetails.description
   * #setButtomTab(4)  | Latest Episode  | programDetails.latestEpisodes
   */

  const loadItems = () => {
    if (buttomTab === 4) {
      let pageNo = pagination.page;
      let tags = [];
      if (programDetails.tagsIds != null) {
        tags = programDetails.tagsIds + "";
      }
      setisShimmer(true);
      fetchLatestVideo(
        programDetails.id,
        programDetails.programId,
        programDetails.tagsIds != null ? tags : "",
        pageNo,
        pagination.limit
      ).then(async (res) => {
        if (res.status !== "error") {
          setTimeout(() => {
            setisShimmer(false);
          }, 1000);
          await setProgramDetails({
            ...programDetails,
            latestEpisodes: {
              ...programDetails.latestEpisodes,
              currentPage: pageNo,
              episodes: res.episodes,
            },
          });
        }
      });
    } else if (buttomTab === 2) {
      if (programDetails.relatedEpisodes.pages >= page.related) {
        let pageNo = page.related + 1;
        let tags = [];

        if (!isLoading && pageNo !== 1) {
          setIsLoading(true);
          if (programDetails.tagsIds != null) {
            tags = programDetails.tagsIds + "";
          }

          fetchRelatedVideo(
            programDetails.id,
            programDetails.programId,
            programDetails.tagsIds != null ? tags : "",
            pageNo
          ).then(async (res) => {
            if (res.status !== "error") {
              setTimeout(() => {
                setIsLoading(false);
                setHasMoreRelatedEdpisode(true);
              }, 1000);
              if (res.episodes.length >= 1) {
                await setProgramDetails({
                  ...programDetails,
                  relatedEpisodes: {
                    ...programDetails.relatedEpisodes,
                    episodes: [
                      ...programDetails.relatedEpisodes.episodes,
                      ...res.episodes
                        .filter(function (cv, index) {
                          return !programDetails.relatedEpisodes.episodes.find(
                            function (e) {
                              return e.id === cv.id;
                            }
                          );
                        })
                        .slice(0, 6),
                    ],
                    currentPage: pageNo,
                  },
                });
                setPage({ ...page, related: pageNo });
              }
            } else {
              setIsLoading(false);
              setHasMoreRelatedEdpisode(false);
            }
          });
        }
      }
    } else if (buttomTab === 1) {
      if (programDetails?.childPrograms?.pages >= page.related) {
        let pageNo = page.related + 1;

        if (!isLoading && pageNo !== 1) {
          setIsLoading(true);
          let tags = [];

          if (programDetails.tagsIds != null) {
            tags = programDetails.tagsIds + "";
          }

          fetchRelatedVideo(
            programDetails.id,
            programDetails.programId,
            programDetails.tagsIds != null ? tags : "",
            pageNo
          ).then(async (res) => {
            if (res.status !== "error") {
              setTimeout(() => {
                setIsLoading(false);
                setHasMoreRelatedShow(true);
              }, 1000);

              await setProgramDetails({
                ...programDetails,
                childPrograms: {
                  ...programDetails.childPrograms,
                  episodes: [
                    ...programDetails.childPrograms.episodes,
                    ...res.episodes,
                  ],
                  currentPage: pageNo,
                },
              });
              setPage({ ...page, related: pageNo });
            } else {
              setHasMoreRelatedShow(false);
              setIsLoading(false);
            }
          });
        } else {
        }
      }
    }
  };

  // Handle scroll event
  const handleScroll = (e) => {
    var element = document.getElementById("innerTab");
    if (element) {
      var topPos = element.getBoundingClientRect().top + window.scrollY;
      if (window.pageYOffset > topPos) {
        setStickyMenu1(true);
      } else {
        setStickyMenu1(false);
      }
      if (window.pageYOffset < topPos) setNonStickyMenu1(true);
      else setNonStickyMenu1(false);
    }
  };

  // Redirect to support page
  const support = () => {
    history.push(`/donate`);
    //window.location.href = `https://shalomworld.my-shalom.org/sw/donation?CauseName=Shalom%20World%27s%20program%20production/${showId}`;
    // window.location.href = `https://sit-shalomworld.my-shalom.org/sw/donation?CauseName=Shalom%20World%27s%20program%20production/${showId}`;
  };

  const seasonSelect = (value) => {
    setSeasonSeleted(value);
    if (seasonSeleted.url !== value.url) setProgramDetails(value);
  };

  const executeScroll = () =>
    listingRef.current.scrollIntoView({ behavior: "smooth", block: "start" });

  const commonloader = (
    <>
      {isLoading && (
        <div className={styles.loader}>
          <LoadMoreLoader />
        </div>
      )}
    </>
  );

  // Render component
  return (
    <section className={styles.showDetails}>
      <figure className={styles.showImg}>
        <img
          alt=""
          src={
            programDetails && programDetails.bannerImg
              ? programDetails.bannerImg
              : programDetails.bannerImg
          }
          className={styles.headerImg}
          style={{ position: width < 992 ? "relative" : "fixed" }}
        />
        <div className={styles.BottomDark}></div>
      </figure>

      <div className={styles.showsInner}>
        {/* Render the banner title and description for larger screens */}
        {width >= 992 ? (
          <Container>
            <div className={styles.bannerTitleSection}>
              <div className={styles.bannerTitleSectionWrap}>
                {programDetails && programDetails.title ? (
                  <h2>{programDetails?.title}</h2>
                ) : (
                  <h2>{programDetails && programDetails.title}</h2>
                )}
                <p>
                  {programDetails.description && (
                    <>
                      {showDescription
                        ? programDetails?.description
                        : programDetails?.description?.substring(0, 120) +
                        "..."}{" "}
                      <span
                        onClick={() => setShowDescription(!showDescription)}
                        className={classes?.txtColor}
                      >
                        {showDescription ? `Read less` : `Read more`}
                      </span>
                    </>
                  )}
                </p>
                <div className={styles.schedule} ref={listingRef}>
                  {programDetails &&
                    programDetails.id &&
                    programDetails.schedule && (
                      <>
                        <p>Schedule:</p>
                        <p className={classes?.txtColor}>
                          {programDetails.schedule}
                        </p>
                      </>
                    )}
                </div>{" "}
                {/* Render the "Watch Promo Video" button if a promo video is available */}
                {programDetails &&
                  programDetails.id &&
                  programDetails.promoVideo !== null && (
                    <button
                      className={`${styles.web_btn} ${classes?.fullSchduleBtn}`}
                      onClick={() => {
                        setWatchPromo(true);
                      }}
                    >
                      Watch Promo Video
                    </button>
                  )}
              </div>
            </div>
          </Container>
        ) : null}
        <Container ref={innerTabRef}>
          <div className={`${styles.tab_filter_wrap} d-flex align-items-center justify-content-between`}>
            <nav className={stickyMenu1 ? styles.stickMenu : null}>
              <div
                className={`${styles.nromalNav} ${classes.ddlColor} ${classes.dropDownItem}`}
              >
                {/* 
                *
                * The only true button.
                *
                *  hasMoreRelatedEdpisode   {hasMoreRelatedEdpisode ? "True" : "False"} <br />
                    hasMoreLatestEpisode     {hasMoreLatestEpisode ? "True" : "False"} <br />
                    page.latest              {page.latest}
                    page.related             {page.related}
                  *
                */}

                {!stickyMenu1 && programDetails.seasonlist?.length > 1 && (
                  <DropdownButton
                    id="dropdown-basic-button"
                    title={
                      seasonSeleted?.name
                        ? seasonSeleted.name
                        : programDetails.seasonlist[0].name
                    }
                    className={`${styles.seasonButton}`}
                  >
                    {programDetails.seasonlist?.map((value) => {
                      return (
                        <Dropdown.Item
                          onClick={() => {
                            seasonSelect(value);
                            setHasMoreRelatedShow(true);
                            setPage({ latest: 1, related: 1 });
                          }}
                        >
                          {value.name}
                        </Dropdown.Item>
                      );
                    })}
                  </DropdownButton>
                )}

                <ul
                  id="innerTab"
                  className={`${nonStickyMenu1 ? styles.submenu : styles.submenu}`}

                >
                  {programDetails.childPrograms?.length > 0 ? (
                    <li
                      className={
                        buttomTab === 1 &&
                        `${styles.active} ${classes?.txtColor}`
                      }
                      onClick={() => {

                        setButtomTab(1);
                        setPagination({
                          ...pagination,
                          page: 1,
                        });
                      }}
                    >
                      Related Show
                    </li>
                  ) : null}

                  {programDetails.title !== "9 PM Series" ? (
                    <li
                      className={
                        buttomTab === 4 &&
                        `${styles.active} ${classes?.txtColor}`
                      }
                      onClick={() => {
                        setButtomTab(4);
                        setPagination({
                          ...pagination,
                          page: 1,
                        });
                      }}
                    >
                      Latest Episode
                    </li>
                  ) : null}

                  {/* )} */}

                  {programDetails.relatedEpisodes?.episodes?.length > 0 && (
                    <li
                      className={
                        buttomTab === 2
                          ? `${styles.active} ${classes?.txtColor}`
                          : ""
                      }
                      onClick={() => {
                        setButtomTab(2);
                        setPagination({
                          ...pagination,
                          page: 1,
                        });
                      }}
                    >
                      Related Episode
                    </li>
                  )}
                  <li
                    className={
                      buttomTab === 3
                        ? `${styles.active} ${classes?.txtColor}`
                        : ""
                    }
                    onClick={() => {
                      setButtomTab(3);
                      setPagination({
                        ...pagination,
                        page: 1,
                      });
                    }}
                  >
                    About
                  </li>
                  {programDetails.testimonials?.length > 0 && (
                    <li
                      className={
                        buttomTab === 5
                          ? `${styles.active} ${classes?.txtColor}`
                          : ""
                      }
                      onClick={() => setButtomTab(5)}
                    >
                      Testimonial
                    </li>
                  )}
                </ul>
              </div>

              <div className={`${styles.navFixed}  ${classes.ddlBorderColor}`}>
                <Container>

                  <div className="fixedFilterWrap d-flex align-items-center justify-content-between">
                    <div className="fixedTabWrap">
                      {!stickyMenu1 &&
                        programDetails.seasonlist?.length > 1 && (
                          <DropdownButton
                            id="dropdown-basic-button"
                            title={
                              seasonSeleted?.name
                                ? seasonSeleted.name
                                : programDetails.seasonlist[0].name
                            }
                            className={styles.seasonButton}
                          >
                            {programDetails.seasonlist?.map((value) => {
                              return (
                                <Dropdown.Item
                                  href={value.slug}
                                  onClick={() => {
                                    setSeasonSeleted(value);
                                    setProgramDetails();
                                  }}
                                >
                                  {value.name}
                                </Dropdown.Item>
                              );
                            })}
                          </DropdownButton>
                        )}

                      {/* Tab list items */}
                      <ul
                        className={`${nonStickyMenu1 ? styles.submenu : styles.submenu
                          }`}
                      >
                        {programDetails.childPrograms?.length > 0 ? (
                          <li
                            className={
                              buttomTab === 1 &&
                              `${styles.active} ${classes?.txtColor}`
                            }

                            onClick={() => {
                              setButtomTab(1);
                              scrollToTab();
                            }}
                          >
                            Related Show
                          </li>
                        ) : null}

                        {programDetails.latestEpisodes?.episodes?.length > 0 && (
                          <li
                            className={
                              buttomTab === 4 && `${styles.active} ${classes?.txtColor}`
                            }
                            onClick={() => {
                              setButtomTab(4);
                              scrollToTab();
                            }}
                          >
                            Latest Episode
                          </li>
                        )}

                        {programDetails.relatedEpisodes?.episodes?.length > 0 && (
                          <li
                            className={
                              buttomTab === 2
                                ? `${styles.active} ${classes?.txtColor}`
                                : ""
                            }
                            onClick={() => {
                              setButtomTab(2);
                              scrollToTab();
                            }}
                          >
                            Related Episode
                          </li>
                        )}

                        <li
                          className={
                            buttomTab === 3
                              ? `${styles.active} ${classes?.txtColor}`
                              : ""
                          }
                          onClick={() => {
                            setButtomTab(2);
                            scrollToTab();
                          }}
                        >
                          About
                        </li>
                        {programDetails.testimonials?.length > 0 && (
                          <li
                            className={
                              buttomTab === 5
                                ? `${styles.active} ${classes?.txtColor}`
                                : ""
                            }
                            onClick={() => {
                              setButtomTab(5);
                              scrollToTab();
                            }}
                          >
                            Testimonial
                          </li>
                        )}
                      </ul>
                    </div>
                    {buttomTab === 4 && (
                      <div
                        className={`${styles.ddlFilterWrap} filter_wrap d-flex align-items-center justify-content-between`}
                      >
                        {/* Sticky Speaker && Topic dropdown starts here*/}

                        {programDetails?.speakers &&
                          Object.entries(programDetails?.speakers).length && (
                            <>
                              {/* Speaker Dropdown */}
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="success"
                                  id="dropdown-basic"
                                >
                                  {speaker?.value}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setSpeaker({ value: "Speakers", ky: "" });
                                      handleFilterLatestEpisodes(
                                        { value: "Speakers", ky: "" },
                                        topic
                                      );
                                      handleRouting(
                                        { value: "All", ky: "" },
                                        topic
                                      );
                                    }}
                                  >
                                    Speakers{" "}
                                  </Dropdown.Item>

                                  {programDetails?.speakers !== undefined &&
                                    Object.entries(
                                      programDetails?.speakers
                                    )?.map(([ky, speaker]) => {
                                      return (
                                        <>
                                          <Dropdown.Item
                                            onClick={() => {
                                              setSpeaker({
                                                value: speaker,
                                                ky: ky,
                                              });
                                              handleFilterLatestEpisodes(
                                                { value: speaker, ky: ky },
                                                topic
                                              );
                                              handleRouting(
                                                { value: speaker, ky: ky },
                                                topic
                                              );
                                            }}
                                          >
                                            {speaker}
                                          </Dropdown.Item>
                                        </>
                                      );
                                    })}
                                </Dropdown.Menu>
                              </Dropdown>
                            </>
                          )}

                        {programDetails?.topics &&
                          Object.entries(programDetails?.topics).length && (
                            <>
                              {/* Topic Dropdown */}
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="success"
                                  id="dropdown-basic"
                                >
                                  {topic?.value}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setTopic({ value: "All", ky: "" });
                                      handleFilterLatestEpisodes(speaker, "");
                                      handleRouting(speaker, "");
                                    }}
                                  >
                                    All{" "}
                                  </Dropdown.Item>

                                  {programDetails?.topics !== undefined &&
                                    Object.entries(programDetails?.topics)?.map(
                                      ([ky, topic]) => {
                                        return (
                                          <>
                                            <Dropdown.Item
                                              onClick={() => {
                                                setTopic({
                                                  value: topic,
                                                  ky: ky,
                                                });
                                                handleFilterLatestEpisodes(
                                                  speaker,
                                                  { value: speaker, ky: ky }
                                                );
                                                handleRouting(speaker, {
                                                  value: speaker,
                                                  ky: ky,
                                                });
                                              }}
                                            >
                                              {topic}
                                            </Dropdown.Item>
                                          </>
                                        );
                                      }
                                    )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </>
                          )}
                      </div>
                    )}
                    {/* Sticky Speaker && Topic dropdown ends here*/}
                  </div>
                </Container>
              </div>
            </nav>

            {/* 
              //setButtomTab(1)  | Related Show    | programDetails.childPrograms
              //setButtomTab(2)  | Related Episode | programDetails.relatedEpisodes
              //setButtomTab(3)  | About           | programDetails.description
              //setButtomTab(4)  | Latest Episode  | programDetails.latestEpisodes
              // setButtomTab
            */}

            {/* Normal dropdown desktop -Non Sticky*/}
            {buttomTab === 4 && (
              <div
                className={`${styles.ddlFilterWrap} filter_wrap d-flex align-items-center justify-content-between`}
              >
                {programDetails?.speakers &&
                  Object.entries(programDetails?.speakers).length && (
                    <>
                      {/* Speaker Dropdown */}
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          {speaker?.value}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              setSpeaker({ value: "All", ky: "" });
                              handleFilterLatestEpisodes(
                                { value: "All", ky: "" },
                                topic
                              );
                              handleRouting({ value: "All", ky: "" }, topic);
                            }}
                          >
                            All{" "}
                          </Dropdown.Item>

                          {programDetails?.speakers !== undefined &&
                            Object.entries(programDetails?.speakers)?.map(
                              ([ky, speaker]) => {
                                return (
                                  <>
                                    <Dropdown.Item
                                      onClick={() => {
                                        setSpeaker({ value: speaker, ky: ky });
                                        handleFilterLatestEpisodes(
                                          { value: speaker, ky: ky },
                                          topic
                                        );
                                        handleRouting(
                                          { value: speaker, ky: ky },
                                          topic
                                        );
                                      }}
                                    >
                                      {speaker}
                                    </Dropdown.Item>
                                  </>
                                );
                              }
                            )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </>
                  )}

                {programDetails?.topics &&
                  Object.entries(programDetails?.topics).length && (
                    <>
                      {/* Topic Dropdown */}
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          {topic?.value}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              setTopic({ value: "All", ky: "" });
                              handleFilterLatestEpisodes(speaker, "");
                              handleRouting(speaker, "");
                            }}
                          >
                            All{" "}
                          </Dropdown.Item>

                          {programDetails?.topics !== undefined &&
                            Object.entries(programDetails?.topics)?.map(
                              ([ky, topic]) => {
                                return (
                                  <>
                                    <Dropdown.Item
                                      onClick={() => {
                                        setTopic({ value: topic, ky: ky });
                                        handleFilterLatestEpisodes(speaker, {
                                          value: speaker,
                                          ky: ky,
                                        });
                                        handleRouting(speaker, {
                                          value: speaker,
                                          ky: ky,
                                        });
                                      }}
                                    >
                                      {topic}
                                    </Dropdown.Item>
                                  </>
                                );
                              }
                            )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </>
                  )}
              </div>
            )}
          </div>
        </Container>
        {/* Tab Contents */}
        <Container className={styles.showsThumbRow}>
          {/* Related Show */}
          {buttomTab === 1 && programDetails.childPrograms ? (
            <InfiniteScroll
              pageStart={1}
              loadMore={loadItems}
              loader={commonloader}
              hasMore={hasMoreRelatedShow}
            >
              <Row>
                {programDetails?.childPrograms?.map((value) => {
                  return <ProgramThumb value={value} />;
                })}
              </Row>
            </InfiniteScroll>
          ) : null}

          {buttomTab === 4 &&
            (programDetails.latestEpisodes ? (
              <Row>
                <>
                  {programDetails.latestEpisodes?.episodes?.length > 0 ? (
                    <>
                      {isShimmer ? (
                        <>
                          {_.times(9, (i) => (
                            <EpisodeListShimmer key={i} />
                          ))}
                        </>
                      ) : (
                        <>
                          {programDetails?.latestEpisodes?.episodes?.map(
                            (value) => {
                              return <Episodethumb value={value} />;
                            }
                          )}
                        </>
                      )}
                      <Paginate
                        totalPages={programDetails?.latestEpisodes.pages}
                        onPagination={onPagination}
                        currentPage={pagination.page ? pagination.page : 1}
                        pageNumberLimit={5}
                      />
                    </>
                  ) : (
                    <Col>
                      {" "}
                      <h4>Episodes Coming Soon</h4>
                    </Col>
                  )}
                </>
              </Row>
            ) : (
              <Row>
                {_.times(9, (i) => (
                  <EpisodeListShimmer key={i} />
                ))}
              </Row>
            ))}

          {buttomTab === 2 &&
            (programDetails.relatedEpisodes ? (
              <InfiniteScroll
                pageStart={1}
                loadMore={loadItems}
                loader={commonloader}
                hasMore={hasMoreRelatedEdpisode}
              >
                <Row>
                  {programDetails?.relatedEpisodes?.episodes.map((value) => {
                    return <Episodethumb value={value} />;
                  })}
                </Row>
              </InfiniteScroll>
            ) : (
              <Row>
                {_.times(6, (i) => (
                  <EpisodeListShimmer key={i} />
                ))}
              </Row>
            ))}

          {buttomTab === 3 && programDetails ? (
            <div className={styles.aboutSection}>
              <h1 className={classes?.seasonalClass}>
                About {programDetails.title}
              </h1>
              <p>{programDetails.description}</p>
              <button
                className={`${styles.web_btn} ${classes?.fullSchduleBtn}`}
                onClick={support}
              >
                Support
              </button>
            </div>
          ) : null}

          {buttomTab === 5 && programDetails ? (
            <div className={styles.testimonialSection}>
              {programDetails.testimonials?.length > 0 &&
                programDetails.testimonials?.map((value) => {
                  return (
                    <div className={styles.testimonial_list_each}>
                      <div className={styles.testimonial_list_desc}>
                        <p>{value.testimonial}</p>
                        <div className={styles.testimonial_list_auth_details}>
                          <h5>{value.name} </h5>
                          <h6>{value.country} </h6>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : null}
        </Container>
      </div>

      <Modal
        className={`watchPromoPop ${styles.watchPromoModal}`}
        show={watchPromo}
        onHide={() => {
          setWatchPromo(false);
          document.body.classList.remove("isplaying");
        }}
      >
        <Modal.Body>
          <div
            className={`${styles.closeMplayerBtn} ${classes.videoCloseClass}`}
            onClick={() => {
              setWatchPromo(false);
              document.body.classList.remove("isplaying");
            }}
          >
            <FiX />
          </div>
          {programDetails?.promoVideo?.length > 0 ? (
            <div
              className={`${styles.socialShareBtn} ${classes.hoverColor}  socialShareBtns`}
            >
              <span className={classes.hoverColor}>
                <i className="icon-share-episode"></i> Share
              </span>
              <div
                className={
                  window.location.pathname.includes("show")
                    ? `${styles.shareIcons} ${classes.socialMediaColor}`
                    : ``
                }
              >
                <SocialMediaWrap classes={classes} urlParams={"#promo"} />
              </div>
            </div>
          ) : null}
          {programDetails?.promoVideo?.length > 0 ? (
            <>
              <div className={`${styles.promoVideoWrap} promo_video_wrap`}>
                <VideoPlayer
                  url={programDetails.promoVideo[0].video}
                  fullscreen={fullscreen}
                  setFullscreen={setFullscreen}
                  toggleScreen={toggleScreen}
                  setToggleScreen={setToggleScreen}
                  showControls={showControls}
                  setShowControls={setShowControls}
                  play={play}
                  setPlay={setPlay}
                  allTracks={programDetails?.tracks}
                />
              </div>
            </>
          ) : null}
        </Modal.Body>
      </Modal>
    </section>
  );
};

const mapStateToProps = (state) => {
  return { programes: state.programes };
};

export default connect(mapStateToProps, {
  fetchProgramDetails,
  fetchLatestVideo,
  fetchRelatedVideo,
})(ShowDetails);
