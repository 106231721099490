import React from "react";
import styles from "./PodcastAbout.module.scss";
import { Link } from "react-router-dom";
import { FiChevronLeft } from "react-icons/fi";
import { useSeasonal } from "../../../logic/Seasonal";

const PodcastAbout = ({ readmore, setReadMore, data }) => {
    const { classes } = useSeasonal();
    return (
        <div className={`${styles.mobPodcastAbout} ${readmore ? styles.show : ""}`}>
            <div className={styles.mobPodcastAboutHeader}>
                <Link
                    onClick={() => {
                        setReadMore(false);
                    }}
                >
                    <i>
                        <FiChevronLeft />
                    </i>
                </Link>
                <p>{data?.title}</p>

            </div>
            <div className="scroll_wrap">
                <div className={styles.AboutPodcastContent}>

                    {data?.podcastDescription && (
                        <div className={styles.AboutDescriptionWrapper}>
                            <h2 className={'d-none'}>Description</h2>
                            <article
                                className={`editorTextArea ${classes?.contentLinkHoverColor}`}
                                dangerouslySetInnerHTML={{
                                    __html: data?.podcastDescription,
                                }}
                            ></article>
                        </div>
                    )}

                    <div className={styles.AboutPodcast}>
                        <div className={styles.host_guest_wrap}>
                            {data?.host && data?.host.length <= 1 && data?.host[0] && data?.host[0].name !== false && <h2>About Host</h2>}

                            {data?.host &&
                                data?.host.map((value, index) => {
                                    return (
                                        <div className={styles.host_guest_list} key={index}>
                                            <div className={styles.profile}>
                                                <figure>
                                                    <img src={value?.image} alt="Host" />
                                                </figure>

                                                <div className={styles.profile_dtls}>
                                                    {value?.name !== false && (
                                                        <>
                                                            {" "}
                                                            <strong>{value?.name} </strong>
                                                            <span>Host</span>
                                                        </>
                                                    )}
                                                </div>
                                            </div>

                                            {value?.description !== false && (
                                                <article
                                                    dangerouslySetInnerHTML={{
                                                        __html: data?.host?.[0]?.description,
                                                    }}
                                                ></article>
                                            )}
                                        </div>
                                    );
                                })}

                            {data?.guest &&
                                data?.guest.map((value, index) => {
                                    return (
                                        <>
                                            <div className={styles.host_guest_list} key={index}>
                                                <div className={styles.profile}>
                                                    <figure>
                                                        <img src={value?.image} alt="Guest" />
                                                    </figure>
                                                    <div className={styles.profile_dtls}>
                                                        <strong> {value?.name}</strong>
                                                        <span>Guest</span>
                                                    </div>
                                                </div>
                                                <article
                                                    dangerouslySetInnerHTML={{
                                                        __html: value?.description,
                                                    }}
                                                ></article>
                                            </div>
                                        </>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PodcastAbout;
