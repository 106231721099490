/* eslint-disable */

import React, { useState, useEffect, useCallback, useRef } from 'react'
import styles from './ContactUs.module.scss'
import { connect } from 'react-redux'
import { fetchContactus } from '../../actions/contactusAction'
import {
  fetchCountryList,
  fetchStateFromCountry,
} from '../../actions/swprayerAction'
import Container from 'react-bootstrap/Container'
import { UseContact } from '../../logic/ContactUs/useContact'
import classNames from 'classnames'
import { useFormik } from 'formik'
import Row from 'react-bootstrap/Row'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import GoogleRecaptcha from '../../components/Recaptcha'
import ContactAddress from '../ContactAddress'
import salomapi from '../../apis'
import { useSeasonal } from '../../logic/Seasonal'

import { useDimensions } from '../../logic/Dimentions'
const useStyles = makeStyles((theme) => ({
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      paddingLeft: 26,
      color: 'black',
    },
  },
}))
const ContactPage = ({
  fetchContactus,
  contactUs,
  fetchCountryList,
  fetchStateFromCountry,
  countryList,
  stateList,
}) => {
  UseContact({ fetchContactus, contactUs })
  const [captchaReset, setCaptchaReset] = useState(false)

  const [captchaValid, isCaptchaValid] = useState(false)
  const [validUser, isValidUser] = useState(false)
  const captcha = useRef(null)
  const [countryName, setCountryName] = useState('')
  const [countryInputName, setCountryInputName] = useState('')
  const [state, setState] = useState('')
  const [isFormSuccess, setFormSuccess] = useState(false)
  const inputClass = classNames(styles.input_fields)
  const divClass = classNames(styles.div_fields, 'col-sm-6')
  const seasonal = useSeasonal()

  useEffect(() => {
    if (countryList.length == 0) fetchCountryList()
  }, [])
  const top100Films = [
    { title: 'The Shawshank Redemption', year: 1994 },
    { title: 'The Godfather', year: 1972 },
    { title: 'The Godfather: Part II', year: 1974 },
    { title: 'The Dark Knight', year: 2008 },
    { title: '12 Angry Men', year: 1957 },
    { title: "Schindler's List", year: 1993 },
    { title: 'Pulp Fiction', year: 1994 },
    { title: 'The Lord of the Rings: The Return of the King', year: 2003 },
    { title: 'The Good, the Bad and the Ugly', year: 1966 },
    { title: 'Fight Club', year: 1999 },
  ]
  const classes = useStyles()
  const validate = (values) => {
    const errors = {}
    if (!values.firstName) {
      errors.firstName = 'Required'
    } else if (values.firstName.length > 15) {
      errors.firstName = 'Must be 15 characters or less'
    }

    if (!values.lastName) {
      errors.lastName = 'Required'
    } else if (values.lastName.length > 20) {
      errors.lastName = 'Must be 20 characters or less'
    }

    if (!values.email) {
      errors.email = 'Required'
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = 'Invalid email address'
    }
    if (countryInputName == '') {
      errors.country = 'Required'
    }
    if (!state) {
      errors.state = 'Required'
    }
    if (!values.contactDesc) {
      errors.contactDesc = 'Required'
    }
    if (!captchaValid) {
      errors.captchaValid = 'Please valid the Captcha'
    }
    if (!values.phone) {
      errors.phone = ''
    } else if (
      values.phone.length < 7 ||
      values.phone.length > 15 ||
      isNaN(values.phone)
    )
      errors.phone = 'Enter valid phone number'

    return errors
  }
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      contactDesc: '',
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      const postData = {
        firstname: values.firstName,
        lastname: values.lastName,
        email: values.email,
        phone: values.phone,
        state: state,
        country: countryInputName,
        message: values.contactDesc,
        category: 'contactus',
      }
      if (captchaValid) {
        salomapi.post('prayer/request', postData).then((response) => {
          if (response.data.status == 'success') setFormSuccess(true)
          resetForm({})
          setCaptchaReset(true)
          isCaptchaValid(false)
          setState('')
          setCountryInputName('')

          setTimeout(() => {
            setFormSuccess(false)
            setCaptchaReset(false)
          }, 4000)
        })
      }
      if (captcha.current.getValue()) {
        isValidUser(true)
        isCaptchaValid(true)
      } else {
        isValidUser(false)
        isCaptchaValid(false)
      }
      //  alert(JSON.stringify(values, null, 2));
    },
  })
  const CountryInputChange = useCallback((event, newInputValue) => {
    setCountryInputName(newInputValue)
  }, [])
  const StateChange = (event, newValue) => {
    setState(newValue)
    // setValue(newValue);
  }
  const CountryChange = useCallback((event, newValue) => {
    if (newValue) {
      fetchStateFromCountry(newValue.id)
      setCountryName(newValue.country_name)
    }
  }, [])
  const contactDecsonChange = (val) => {
    // let len = formik.values.massPetitionDesc.length
    if (val.target.value.length <= 250) formik.handleChange(val)

    // setCharCount(val.target.value.length)
  }
  const handleOnChange = () => {
    isCaptchaValid(true)
  }

  return (
    <>
      <div
        className="contact-wrp"
        style={{
          background: `url("https://ark.shalomworld.org/wp-content/uploads/2018/12/contact-banner-1919x985.jpg")`,
        }}
      >
        <div
          className={`${styles.headingAlign} mob-app-pad-0 mob-app-mar-0`}
          style={{
            backgroundImage: contactUs.background,
          }}
        >
          <Container>
            <h1 className={seasonal?.classes?.seasonalClass}>Contact Us</h1>

            <form className={styles.form_align} onSubmit={formik.handleSubmit}>
              <Row className="col-lg-12">
                <div className={divClass}>
                  <input
                    type="text"
                    onChange={formik.handleChange}
                    name="firstName"
                    className={
                      !formik.errors.firstName || !formik.submitCount
                        ? inputClass
                        : inputClass + ' ' + styles.formErrReact
                    }
                    value={formik.values.firstName}
                    placeholder="First Name"
                  ></input>
                </div>
                <div className={divClass}>
                  <input
                    // className={inputClass}
                    onChange={formik.handleChange}
                    className={
                      !formik.errors.lastName || !formik.submitCount
                        ? inputClass
                        : inputClass + ' ' + styles.formErrReact
                    }
                    value={formik.values.lastName}
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                  ></input>
                </div>
              </Row>
              <Row className="col-lg-12">
                <div className={divClass}>
                  <input
                    // className={inputClass}
                    onChange={formik.handleChange}
                    className={
                      !formik.errors.phone || !formik.submitCount
                        ? inputClass
                        : inputClass + ' ' + styles.formErrReact
                    }
                    value={formik.values.phone}
                    type="text"
                    name="phone"
                    placeholder="Phone"
                  ></input>
                </div>
                <div className={divClass}>
                  <input
                    // className={inputClass}
                    onChange={formik.handleChange}
                    className={
                      !formik.errors.email || !formik.submitCount
                        ? inputClass
                        : inputClass + ' ' + styles.formErrReact
                    }
                    value={formik.values.email}
                    type="text"
                    name="email"
                    placeholder="Email"
                  ></input>
                </div>
              </Row>
              <Row className="col-lg-12">
                <div className={divClass}>
                  {/* <Autocomplete
                        id="combo-box-demo"
                        options={top100Films}
                        getOptionLabel={(option) => option.title}

                        renderInput={(params) => <TextField {...params} className={styles.color} label="Combo box" variant="outlined" />}
                        /> */}
                  <Autocomplete
                    id="combo-box-demo"
                    classes={classes}
                    options={countryList}
                    getOptionLabel={(option) => option.country_name}
                    renderOption={(option) => (
                      <div style={{ color: 'white' }}>
                        {option.country_name}
                      </div>
                    )}
                    style={{ width: 404 }}
                    inputValue={countryInputName}
                    onInputChange={CountryInputChange}
                    // value={countryName}
                    onChange={CountryChange}
                    renderInput={(params) => (
                      <TextField
                        className={
                          !formik.errors.country || !formik.submitCount
                            ? inputClass
                            : inputClass + ' ' + styles.formErrReact
                        }
                        {...params}
                        placeholder="Select Country"
                        name="country"
                        variant="outlined"
                      />
                    )}
                  />
                  {/* <input className={inputClass} type="search" autocomplete="on" name="SelectCountry" placeholder="Select Country"></input> */}
                </div>
                <div className={divClass}>
                  <Autocomplete
                    id="combo-box-demo"
                    classes={classes}
                    options={stateList.length > 0 ? stateList : countryList}
                    getOptionLabel={(option) =>
                      stateList.length > 0 ? option.name : option.country_name
                    }
                    renderOption={(option) => (
                      <div style={{ color: 'white' }}>
                        {stateList.length > 0
                          ? option.name
                          : option.country_name}
                      </div>
                    )}
                    style={{ width: 404 }}
                    inputValue={state}
                    onInputChange={StateChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={
                          !formik.errors.state || !formik.submitCount
                            ? inputClass
                            : inputClass + ' ' + styles.formErrReact
                        }
                        placeholder="Select State"
                        variant="outlined"
                      />
                    )}
                  />
                  {/* <input className={inputClass} type="text" name="SelectState" placeholder="Select State"></input> */}
                </div>
              </Row>
              <Row className={`col-lg-12 ${styles.div_fields}`}>
                <textarea
                  onChange={contactDecsonChange}
                  className={
                    !formik.errors.contactDesc || !formik.submitCount
                      ? styles.textClass
                      : styles.textClass + ' ' + styles.formErrReact
                  }
                  value={formik.values.contactDesc}
                  name="contactDesc"
                  placeholder="Describe your message, program feedback or prayer request here"
                ></textarea>
              </Row>
              <Row className={`col-lg-12 ${styles.div_fields}`}>
                <div className={styles.captchaWrap}>
                  <GoogleRecaptcha
                    onChange={handleOnChange}
                    isCaptchaReset={captchaReset}
                  />
                </div>
              </Row>
              {Object.keys(formik.errors).length != 0 && formik.submitCount ? (
                <div className="form-group col-sm-12">
                  <span className={styles.formtotNullfieldErr}>
                    Please fill all required fields.
                  </span>
                </div>
              ) : null}
              {formik.errors.phone ? (
                <div className="form-group col-sm-12">
                  <span className={styles.formtotNullfieldErr}>
                    {formik.errors.phone}
                  </span>
                </div>
              ) : null}
              {formik.errors.email && formik.submitCount ? (
                <div className="form-group col-sm-12">
                  <span className={styles.formtotNullfieldErr}>
                    Please Enter a valid Email Id
                  </span>
                </div>
              ) : null}
              {isFormSuccess ? (
                <div className="form-group col-sm-12">
                  <span className={styles.resonseSuccessMsg}>
                    Your message sent successfully !!!
                  </span>
                </div>
              ) : null}
              <Row className={`col-lg-12 ${styles.div_fields}`}>
                <button
                  className={` ${styles.web_btn}   ${seasonal?.classes?.fullSchduleBtn}
                 `}
                  type="submit"
                >

                  SUBMIT
                </button>
              </Row>
            </form>
          </Container>
        </div>
        <div className={styles.contact_us_wrap}>
          <h2
            className={`${seasonal?.classes?.seasonalClass} ${styles.is_title}`}
          >
            Get in touch with us
          </h2>
          <ContactAddress data={contactUs.address ? contactUs.address : null} />
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    contactUs: state.contactUs,
    countryList: state.swprayer.countryList,
    stateList: state.swprayer.stateList,
  }
}

export default connect(mapStateToProps, {
  fetchContactus,
  fetchCountryList,
  fetchStateFromCountry,
})(ContactPage)
