import React from 'react';
import WatchOnVideo from '../components/WatchOnVideo';
import CommonLayout from '../components/Layouts/CommonLayout';
import {fetchOtherdetails} from '../actions/otherdetailsAction';
import { useHome } from '../logic/Home/useHome';
import {connect} from 'react-redux';
import { TitleComponent } from '../components/TitleComponent';
import { useSeo } from '../logic/Seo/useSeo';
import { useSeasonal } from '../logic/Seasonal';
import {useDimensions} from '../logic/Dimentions';
import SocialMediaWrap from '../components/SocailMediaIcons'

const WatchOn = ({fetchOtherdetails,otherdetails}) => {

    useHome({fetchOtherdetails,otherdetails});
    useSeo();
    const {classes} = useSeasonal();
    const { width } = useDimensions();

    return (
        <CommonLayout>
             {width >= 992 && <SocialMediaWrap />}
             <TitleComponent title="Shalom world "/>
        <WatchOnVideo classes={classes}/>
        </CommonLayout>
    )
}
const mapStateToProps = (state) => {
    return ({otherdetails:state.otherdetails.data})
  }
export default connect(mapStateToProps,{fetchOtherdetails})(WatchOn);