import React from 'react'
import CommonLayout from '../components/Layouts/CommonLayout'
import { useHome } from '../logic/Home/useHome'
import PeaceFellowship from '../components/PeaceFellowship'
import { connect } from 'react-redux'
import { fetchOtherdetails } from '../actions/otherdetailsAction'
import { TitleComponent } from '../components/TitleComponent'
import { useSeo } from '../logic/Seo/useSeo'

import SocialMediaWrap from "../components/SocailMediaIcons";
import { useDimensions } from "../logic/Dimentions";
import { useSeasonal } from '../logic/Seasonal'

const Fellowship = ({ fetchOtherdetails, otherdetails }) => {
  useHome({ fetchOtherdetails, otherdetails })
  useSeo()
  const {  width } = useDimensions();
  const { classes } = useSeasonal()

  return (
    <CommonLayout>
      <TitleComponent title="Shalom world " />
      {width>=992&&<SocialMediaWrap classes={classes}/>}
      <PeaceFellowship />
    </CommonLayout>
  )
}
const mapStateToProps = (state) => {
  return { otherdetails: state.otherdetails.data }
}
export default connect(mapStateToProps, { fetchOtherdetails })(Fellowship)
