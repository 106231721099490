/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Style from "./Promotion.module.scss";
import { Row, Col, Container } from "react-bootstrap";
// import imgBanner from "../../assets/img/imgDonationBanner.jpg";
// import imgPromo from "../../assets/img/imgDonateChannel_1.png";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { fetchPromotion } from "../../actions/promotionAction";
import { useSeasonal } from "../../logic/Seasonal";
import { FiDownload } from "react-icons/fi";
import CommonLoader from "../Shimmers/CommonLoader";

const Promotion = ({ fetchPromotion, promotionData }) => {
  const { classes } = useSeasonal();
  let { promotionId } = useParams();

  useEffect(() => {
    fetchPromotion({ promotionId: promotionId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={Style.PromotionWrapper}>
        {promotionData?.banner ? (
          <>
            <div className={`figImg ${Style.promotionBanner}`}>
              <img src={promotionData?.banner} alt="Banner" />
            </div>
            <div className={Style.promotionWrapper}>
              <Container>
                {promotionData?.full?.length > 0 && (
                  <div className={Style.gridWrap}>
                    <h2>Full Page</h2>
                    <Row>
                      {promotionData &&
                        promotionData?.full &&
                        promotionData?.full?.length > 0
                        ? promotionData?.full.map((item, index) => {
                          return (
                            <>
                              <Col md={4} lg={3} className={Style.promo_grid}>
                                {item.type === "pdf" ? (
                                  <div className={Style.promo_thumb}>
                                    <a
                                      rel="noreferrer"
                                      href={item.pdf}
                                      className="full_link"
                                      target="_blank"
                                      download
                                    ></a>
                                    <figure className="figImg">
                                      <img
                                        src={item.pdf_thumbnail}
                                        alt={item.title}
                                      />
                                      <figcaption
                                        className={classes.backgroundColr}
                                      >
                                        {item.type}
                                      </figcaption>
                                    </figure>
                                    <span>
                                      {item.title} <FiDownload />
                                    </span>
                                  </div>
                                ) : (
                                  <div
                                    className={Style.promo_thumb}
                                  //onClick={() => onButtonClick(item.image)}
                                  >
                                    <a
                                      rel="noreferrer"
                                      href={item.image}
                                      className="full_link"
                                      target="_blank"
                                      download
                                    ></a>

                                    <figure className="figImg">
                                      <img
                                        src={item.image_thumbnail}
                                        alt={item.title}
                                      />
                                      <figcaption
                                        className={classes.backgroundColr}
                                      >
                                        {item.type}
                                      </figcaption>
                                    </figure>
                                    <span>
                                      {item.title} <FiDownload />
                                    </span>
                                  </div>
                                )}
                              </Col>
                            </>
                          );
                        })
                        : null}
                    </Row>
                  </div>
                )}
                {promotionData?.half?.length > 0 && (
                  <div className={Style.gridWrap}>
                    <h2>Half Page</h2>
                    <Row>
                      {promotionData &&
                        promotionData?.half &&
                        promotionData?.half?.length > 0
                        ? promotionData?.half.map((item, index) => {
                          return (
                            <>
                              <Col md={4} lg={3} className={Style.promo_grid}>
                                {item.type === "pdf" ? (
                                  <div className={Style.promo_thumb}>
                                    <a
                                      rel="noreferrer"
                                      href={item.pdf}
                                      className="full_link"
                                      target="_blank"
                                      download
                                    ></a>
                                    <figure className="figImg">
                                      <img
                                        src={item.pdf_thumbnail}
                                        alt={item.title}
                                      />
                                      <figcaption
                                        className={classes.backgroundColr}
                                      >
                                        {item.type}
                                      </figcaption>
                                    </figure>
                                    <span>
                                      {item.title} <FiDownload />
                                    </span>
                                  </div>
                                ) : (
                                  <div className={Style.promo_thumb}>
                                    <a
                                      rel="noreferrer"
                                      href={item.image}
                                      className="full_link"
                                      target="_blank"
                                      download
                                    ></a>
                                    <figure className="figImg">
                                      <img
                                        src={item.image_thumbnail}
                                        alt={item.title}
                                      />
                                      <figcaption
                                        className={classes.backgroundColr}
                                      >
                                        {item.type}
                                      </figcaption>
                                    </figure>
                                    <span>
                                      {item.title} <FiDownload />
                                    </span>
                                  </div>
                                )}
                              </Col>
                            </>
                          );
                        })
                        : null}
                    </Row>
                  </div>
                )}
                {promotionData?.quarter?.length > 0 && (
                  <div className={Style.gridWrap}>
                    <h2>Quarter Page</h2>
                    <Row>
                      {promotionData &&
                        promotionData?.quarter &&
                        promotionData?.quarter?.length > 0
                        ? promotionData?.quarter.map((item, index) => {
                          return (
                            <>
                              <Col md={4} lg={3} className={Style.promo_grid}>
                                {item.type === "pdf" ? (
                                  <div className={Style.promo_thumb}>
                                    <a
                                      rel="noreferrer"
                                      href={item.pdf}
                                      className="full_link"
                                      target="_blank"
                                      download
                                    ></a>
                                    <figure className="figImg">
                                      <img
                                        src={item.pdf_thumbnail}
                                        alt={item.title}
                                      />
                                      <figcaption
                                        className={classes.backgroundColr}
                                      >
                                        {item.type}
                                      </figcaption>
                                    </figure>
                                    <span>
                                      {item.title} <FiDownload />
                                    </span>
                                  </div>
                                ) : (
                                  <div className={Style.promo_thumb}>
                                    <a
                                      rel="noreferrer"
                                      href={item.image}
                                      className="full_link"
                                      target="_blank"
                                      download
                                    ></a>
                                    <figure className="figImg">
                                      <img
                                        src={item.image_thumbnail}
                                        alt={item.title}
                                      />
                                      <figcaption
                                        className={classes.backgroundColr}
                                      >
                                        {item.type}
                                      </figcaption>
                                    </figure>
                                    <span>
                                      {item.title} <FiDownload />
                                    </span>
                                  </div>
                                )}
                              </Col>
                            </>
                          );
                        })
                        : null}
                    </Row>
                  </div>
                )}
              </Container>
            </div>
          </>
        ) : (
          <div className={Style.loader}>
            <CommonLoader />
          </div>
        )}
        ;
      </div>
    </>
  );
};

const mapPropsToState = (state) => {
  return { promotionData: state.promotion?.promotion };
};
export default connect(mapPropsToState, { fetchPromotion })(Promotion);
