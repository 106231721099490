/* eslint-disable import/no-anonymous-default-export */
import { act } from 'react-dom/cjs/react-dom-test-utils.production.min';
import { FETCH_PROGRAMES, UPDATE_PROGRAMES, REMOVE_PROGRAMES } from '../actions/types';

const INTIAL_STATE = [];

export default (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_PROGRAMES:
            let data = action.payload;
            return { ...state, ...data, bgImage: action.bgImage };
        case UPDATE_PROGRAMES:
            return {
                ...state,
                [action.payload.category]: {
                    ...state[action.payload.category],
                    page: action.payload.page,
                    totalPage: state[action.payload.category].totalPage,
                    data: action.payload[action.payload.category],
                },
            };
        case REMOVE_PROGRAMES:
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    data: [],
                },
            };

        default:
            return state;
    }
};
