import React from "react";
import styles from "./ThanksforStorySubmission.module.scss";

const ThanksforSubmission = ({ alertClose, successMsg }) => {
  return (
    <div className={styles.ThanksforStorySubmission}>
      <div className={styles.alertBotton} id="successalert">
        <p className={styles.alertP}> {successMsg}</p>
        {/* <div className={`col-lg-2 ${styles.closeAlert}`}>
            <button type="button" className={styles.close} onClick={alertClose}>×</button>
          </div> */}
      </div>
    </div>
  );
};
export default ThanksforSubmission;
