import React from "react";
import styles from "./DonateDollarADayContent.module.scss";
import imgOPADLogo from "../../assets/img/imgOPADLogo.png";
const DollarADayContentCA = () => {
	return (
		<div className={styles.dad_content}>
			<article>
				<p>
					Shalom World has faithfully served the Catholic community these past 9 years — delivering high quality{" "}
					<strong>
						Catholic entertainment that heals and transforms lives.
					</strong>
				</p>
				<p>
				We have entertained and deepened the faith of millions of Catholics all over the world through our TV programming and print publications...and we are preparing for the next 10 years!
				</p>
				<h3>But we need your support to do so</h3>
				<p className="mb-4">
					Would you prayerfully consider using your change to change the world?{" "}
					<strong>
						{" "}
						Donate a One £ -A-Day and help Shalom World reach millions more
					</strong>{" "}
					with the soul-saving message of Christ! {" "}
				</p>
				<p>
					{" "}
					For every One £ you give we can reach more people, produce more shows, and — as an answer to God’s call 9 years ago — share the peace of Christ with the world.
				</p>

				<h4>
					I Want To Support Shalom World <br />
					With A One £ -A-Day!
				</h4>
				<figure className={styles.dadLogo}>
					<img src={imgOPADLogo} alt=" One £ -A-Day" />
				</figure>

			</article>
		</div>
	);
};



export default DollarADayContentCA;