import {FETCH_NEWS_COMMENTS} from './types';
import {salomapi4} from '../apis';


export const fetchBlogComments  = (blogId) => async dispach => {
    const response = await salomapi4.get(`comments?post=${blogId}`);
    dispach({type:FETCH_NEWS_COMMENTS,payload:response.data})
}


