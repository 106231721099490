import {
  FETCH_MEDIA_GALLERY,
  POST_MEDIASIGN,
  FETCH_MEDIA_USER,
  RESET_MEDIA_USER,
} from "./types";
import salomapi, { salomapi3 } from "../apis";
import BrowserPersistence from "../util/simplePersistance";

const storage = new BrowserPersistence();
export const fetchMediaGallery = () => async (dispatch) => {
  const response = await salomapi.get("/mediagallery");
  dispatch({ type: FETCH_MEDIA_GALLERY, payload: response.data });
};

export const postSign = (postData) => async (dispatch) => {

  const response = await salomapi3.post("/medialogin", postData);
  if (response.data.code === "success") {
    storage.setItem("mediauser", response.data.message, 86400);
    dispatch({ type: POST_MEDIASIGN, payload: response.data.message });
    return response;
  }
  else{
    return response;
  }
};
export const emailVerify = (postData) => async (dispatch) => {
  const response = await salomapi3.post("/media/verify", postData);

  return response;
};
export const getProfile = (user) => async (dispatch) => {
  let timestamp = new Date().toLocaleString("en-US", {
    timeZone: "Asia/Kolkata",
  });
  timestamp = new Date(timestamp);
  let user1 = storage.getItem("mediauser");
  const response = await salomapi3.post("/media/profile", {
    accessToken: user1.accesstoken,
    timestamp: timestamp.getTime(),
    userId: user1.userId,
  });
  dispatch({ type: FETCH_MEDIA_USER, payload: response.data.message });
  return response.data.message;
};

export const logout = () => async (dispatch) => {
  dispatch({ type: RESET_MEDIA_USER });
};
