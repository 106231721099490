import {FETCH_ABOUTUS,FETCH_MORE_ENDORSEMENTS} from '../actions/types.js';

const INTIAL_STATE = {
    aboutUs:[],
    moreEndoresments:[],
    maxPage:1
};

export default (state = INTIAL_STATE,action) => {
    switch(action.type){
        case FETCH_ABOUTUS:
            return {
                ...state,
                aboutUs : action.payload
            } 
            case FETCH_MORE_ENDORSEMENTS:
            return {
                    ...state,
                    aboutUs:{...state.aboutUs,endorsement:{...state.aboutUs.endorsement,endorsements:{...state.aboutUs.endorsement.endorsements,[action.region]:{...state.aboutUs.endorsement.endorsements[action.region],endorsement:[...state.aboutUs.endorsement.endorsements[action.region].endorsement,...action.payload[action.region]]}}}},
                    maxPage: action.maxPage,
                    page:action.page
                }
                // return {
                //     ...state,
                //     aboutUs: 
                // }
            // return {...state,[action.payload.category]:{...state[action.payload.category],page:action.payload.page,totalPage:state[action.payload.category].totalPage,data:[...state[action.payload.category].data,...action.payload[action.payload.category]]}}

                    // return{
                    //     ...state,
                    //     moreEndoresments: state.moreEndoresments.concat(action.payload),
                    //     maxPage: action.maxPage,
                    //     page:action.page
                    // }

        default:
            return state;
    }
}