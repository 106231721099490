import React from 'react'
import Container from 'react-bootstrap/Container'
import styles from './WaysToGive.module.scss'
import { Link } from 'react-router-dom'

const WaysToGive = ({ moreWays, classes }) => {
  return (
    <section className={styles.more_ways}>
      <Container>
        <h3 className={styles.is_title}>More Ways To Give</h3>
        <div className="row">
          {moreWays && moreWays.length > 0
            ? moreWays.map((item, key) => {
                return (
                  <div
                    key={item.readMoreUrl}
                    className={`col-md-4 col-xs-6${styles.image_wrapper}`}
                  >
                    <div>
                      <img src={item.heading} alt=""/>
                    </div>
                    <div className={styles.text_wrap}>
                      <div>
                        <h4>{item.title}</h4>
                        <br />
                        <p>{item.description}</p>
                      </div>

                      {item.readMoreUrl === 'amazonsmile' ||
                      item.readMoreUrl === 'cardonation' ||
                      item.readMoreUrl === 'employermatching' ? (
                        <span>
                          <Link to={item.readMoreUrl}>{item.readMoreText}</Link>{' '}
                        </span>
                      ) : null}

                      {/* <a href="" className={styles.full_link}></a> */}
                    </div>

                    {item.readMoreUrl === 'amazonsmile' ||
                    item.readMoreUrl === 'cardonation' ||
                    item.readMoreUrl === 'employermatching' ? (
                      <Link to={item.readMoreUrl} className={`full_link ${classes?.txtColor}`}></Link>


                    ) : null}
                  </div>
                )
              })
            : null}
        </div>
      </Container>
    </section>
  )
}

export default WaysToGive
