import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSeasonal } from "../../actions/seasonAction";
import { createUseStyles } from "react-jss";

export const useSeasonal = () => {
  const seasonal = useSelector((state) => state.seasonal);
  const dispatch = useDispatch();

  useEffect(() => {
    if (seasonal.status == "idle") {
      dispatch(fetchSeasonal());
    }
  }, []);

  const useStyles = createUseStyles({
    tabColor: {
      "@global .css-1f3wfo0-MuiButtonBase-root-MuiTab-root.Mui-selected": {
        color: seasonal?.color + " !important",
        borderBottom: "3px solid " + seasonal?.color + " !important",
      },
      "@global .css-8je8zh-MuiTouchRipple-root": {
        color: seasonal?.color + " !important",
      },

      "@global .MuiButtonBase-root.Mui-selected span": {
        color: seasonal?.color + " !important",
      },

    },

    menuActive: {
      "a p": {
        color: seasonal?.color + " !important",
      },
      "a h5": {
        color: seasonal?.color + " !important",
      },
    },

    hamburgerHover: {
      "&:hover span": {
        backgroundColor: seasonal?.color + " !important",
      },
    },
    textDecororationNone: {
      textDecoration: "none !important",
    },
    colorWhite: {
      color: "#fff",
    },
    borderTopOnly: {
      "&:after": {
        borderTopColor: seasonal?.color + " !important",
      },
    },
    activeColor: {
      "&.active": {
        color: seasonal?.color + " !important",
      },
    },
    calenderActiveColor: {
      "@global": {
        span: {
          color: seasonal?.color + " !important",
        },
      }
    },
    paginateActive: {
      "@global": {
        a: {
          color: "#fff !important",
          backgroundColor: seasonal?.color + " !important",
          borderColor: seasonal?.color + " !important",
        },
      }
    },
    soundbarColor: {
      "@global": {
        ".bar": {
          backgroundColor: seasonal?.color + " !important",
        },
      }
    },
    hoverClass: {
      "&:hover span": {
        color: seasonal?.color + " !important",
      },
      "&:hover": {
        color: seasonal?.color + " !important",
      },
      "&:hover @global .MuiListItemText-root": {
        color: seasonal?.color + " !important",
      },
      "&:hover @global .MuiTypography-root": {
        color: seasonal?.color + " !important",
      },
      "&:hover @global .MuiListItemText-root .MuiTypography-root": {
        color: seasonal?.color + " !important",
      },
    },
    hoverColor: {
      "&:hover": {
        color: seasonal?.color + " !important",
      }
    },
    afterHoverClass: {
      "&:hover&:after": {
        background: seasonal?.color + " !important",
      },
    },
    beforeHoverClass: {
      textDecoration: "none",
      "&:hover&:before": {
        background: seasonal?.color + " !important",
      },
    },
    beforeHoverColorClass: {
      textDecoration: "none",
      "&:hover&:before": {
        color: seasonal?.color + " !important",
      },
      "&:hover .icon-play_video:before": {
        color: seasonal?.color + " !important",
      },
      "@global &:hover .icon-play_video:before": {
        color: seasonal?.color + " !important",
      },
    },
    aboutVideoPlayCircleClass: {
      "&:hover *:before": {
        color: seasonal?.color + " !important",
      },
    },
    seasonalAfterColorClass: {
      "&:after": {
        color: seasonal?.color + " !important",
      },
    },
    seasonalBeforeColorClass: {
      "&:before": {
        color: seasonal?.color + " !important",
      },
    },
    loaderColor: {
      borderColor: seasonal?.color + " !important",
      borderRightColor: "transparent !important",
      "@global": {
        ".spinner-border": {
          borderColor: seasonal?.color + " !important",
          borderRightColor: "transparent !important",
        },
      }
    },
    progressClass: {
      ".progress-bar .progress:after": {
        backgroundColor: seasonal?.color + " !important",
      },
    },
    videoCloseClass: {
      backgroundColor: seasonal?.color + " !important",
      "&:hover": {
        boxShadow: "0 0 0 3px " + seasonal?.color + " !important",
      },
    },
    podcastPlayClass: {
      "@global": {
        'svg *': {
          fill: seasonal?.color + " !important",
        },
        svg: {
          fill: seasonal?.color + " !important",
        },
      },
      "&:after": {
        borderLeftColor: seasonal?.color + " !important",
      },
      "&.pause": {
        backgroundColor: seasonal?.color + " !important",
        borderColor: seasonal?.color + " !important",
        "&:after": {
          borderLeftColor: "#000 !important",
        },
        "@global": {
          'svg *': {
            fill: "#fff !important",
          },
          svg: {
            fill: "#fff !important",
          },
        },
      },
    },
    tabDropdownCustomResp: {
      "@global .dropdown-toggle": {
        color: seasonal?.color + " !important",
      },
      "@global.dropdown-toggle > span": {
        color: seasonal?.color + " !important",
      },
    },
    seasonalClass: {
      "&:after": {
        backgroundColor: seasonal?.color + " !important",
      },
    },
    seasonalLeftBorder: {
      "&:after": {
        borderLeftColor: seasonal?.color + " !important",
      },
    },
    seasonalClass2: {
      "&:before": {
        background: seasonal?.color + " !important",
      },
    },
    playClass: {
      "&:after": {
        borderLeftColor: seasonal?.color + " !important",
      },
    },
    pauseClass: {
      borderColor: seasonal?.color + " !important",
      backgroundColor: seasonal?.color + " !important",
    },
    fullSchduleBtn: {
      borderColor: seasonal?.color + " !important",
      color: '#fff',
      "&:hover": {
        background: seasonal?.color + " !important",
        color: "#fff !important",
      },
    },
    watchLivebtn: {
      color: "#fff",
      "&:hover": {
        backgroundColor: seasonal?.color + " !important",
        color: "#fff !important",
      },
    },
    bgBtnClass: {
      borderColor: seasonal?.color + " !important",
      background: seasonal?.color + " !important",
      color: "#fff !important",
      "&:hover": {
        background: "transparent !important",
        color: "#fff !important",
      },
    },
    categoryList: {
      "&:hover": {
        color: seasonal?.color + " !important",
      },
    },
    contentLinkColor: {
      "@global": {
        a: {
          color: seasonal?.color + " !important",
        },
      }
    },
    contentLinkHoverColor: {
      "@global": {
        a: {
          "&:hover": {
            color: seasonal?.color + " !important",
          },
        },
      }
    },
    txtColor: {
      color: seasonal?.color + " !important",
    },
    backgroundColr: {
      backgroundColor: seasonal?.color + " !important",
      color: "#fff !important",
    },
    paginationActive: {
      backgroundColor: seasonal?.color + " !important",
      color: "#fff !important",
      borderColor: seasonal?.color + " !important",
      a: {
        backgroundColor: seasonal?.color + " !important",
        color: "red !important",
        borderColor: seasonal?.color + " !important",
      },

    },
    menuHover: {
      "&:hover p": {
        color: seasonal?.color + " !important",
      },
      "&:hover h5": {
        color: seasonal?.color + " !important",
      },
      "&:hover": {
        p: {
          color: seasonal?.color + " !important",
        },
        h5: {
          color: seasonal?.color + " !important",
        },
      },
    },
    cookiePolicy: {
      "@global .rodal-dialog": {
        borderTopColor: seasonal?.color + " !important",
      },
      "@global .rodal-dialog p a": {
        color: seasonal?.color + " !important",
      },
    },
    getAppModal: {
      "@global .modal-content": {
        borderColor: seasonal?.color + " !important",
      },
    },
    newsletter: {
      "@media (max-width: 767px)": {
        "@global .rodal-dialog": {
          backgroundColor: seasonal?.color + " !important",
        },
      },
      "@media (min-width: 768px)": {
        "@global .rodal-dialog": {
          border: "1px solid " + seasonal?.color + " !important",
          backgroundColor: "#101010 !important",
        },
      },
    },
    borderOnly: {
      borderColor: seasonal?.color + " !important",
    },
    borderBtmOnly: {
      borderBottomColor: seasonal?.color + " !important",
    },
    borderTop: {
      borderTopColor: seasonal?.color + " !important",
    },
    borderBoxShadow: {
      boxShadow: "0 0 0 3px " + seasonal?.color + " !important" 
    },
    videoModalClose: {
      boxShadow: "0 0 0 0 " + seasonal?.color + " !important" ,
      "&:hover": {
        boxShadow: "0 0 0 3px " + seasonal?.color + " !important"
      }
    },
    ddlBorderColor: {
      "@global .dropdown .dropdown-toggle": {
        borderColor: seasonal?.color + " !important",

      }
    },
    
    ddlColor: {
      "@global .dropdown .dropdown-toggle": {
        borderColor: seasonal?.color + " !important",
        backgroundColor: seasonal?.color + " !important",
        "&:hover": {
          background: "transparent !important",
          color: "#fff !important",
        },
      }
    },
    bannerDots: {
      "@global .react-multi-carousel-list": {
        "@global .react-multi-carousel-dot--active": {
          "@global": {
            button: {
              backgroundColor: seasonal?.color + " !important",
            },

          },
        },
      },
    },

    swtab: {
      "@global .MuiButtonBase-root.Mui-selected": {
        "@global": {
          span: {
            color: seasonal?.color + " !important",
          },
        },
      },
    },
    countryDDL: {
      " @global .css-4ssu7w-MuiButtonBase-root-MuiListItem-root-MuiMenuItem-root.Mui-selected": {
        color: seasonal?.color + " !important"
      },
      " @global .MuiPaper-root .MuiMenu-list li:hover": {
        color: seasonal?.color + " !important"
      }
    },
    socialMediaColor: {

      "@global .socialShare ul": {
        borderBottomColor: seasonal?.color + " !important"
      },

    },
    svgClass: {
      "&:hover svg": {
        fill: seasonal?.color + " !important",
      },
      color: seasonal?.color + " !important",
      "svg *": {
        fill: seasonal?.color + " !important",
      },
    },
    radioBtnColor: {
      " @global .css-1di6j4h-MuiButtonBase-root-MuiIconButton-root-MuiRadio-root.Mui-checked": {
        color: seasonal?.color + " !important"
      },
      "@global .css-1di6j4h-MuiButtonBase-root-MuiIconButton-root-MuiRadio-root.Mui-checked  span": {
        color: seasonal?.color + " !important"
      },

    },
    svgHoverClass: {

      "&:hover": {
        color: seasonal?.color + " !important",
      },
      "&:hover svg": {
        fill: seasonal?.color + " !important",
        color: seasonal?.color + " !important",
      },
      "&:hover svg *": {
        fill: seasonal?.color + " !important",
        color: seasonal?.color + " !important",
      },
      "&:hover a": {
        color: seasonal?.color + " !important",
      },
    },
    hoverBtnColor: {
      "&:hover": {
        backgroundColor: seasonal?.color + " !important",
        color: "#fff !important",
        borderColor: seasonal?.color + " !important",
      },
    },
    sideMenu: {
      "@global .MuiListItem-root": {
        "@global": {
          a: {
            "&:hover": {
              color: seasonal?.color + " !important",
              "@global": {
                span: {
                  color: seasonal?.color + " !important",
                },
              },
            },
          },
        },
      },
    },
    seekbarColor: {
      '@global .MuiSlider-root': {
        color: seasonal?.color + ' !important',
      }
    },
    hoverColor: {
      "&:hover": {
        color: seasonal?.color + " !important",
      },
      "&:hover span": {
        color: seasonal?.color + " !important",
      }
    },
    aboutPlayBtn: {
      "@global .react-player__play-icon": {
        borderLeftColor: seasonal?.color + " !important",
      },
      "@global .react-player__shadow:hover": {
        borderColor: seasonal?.color + " !important",
      }
    },
    watchLiveDDL: {
      "@global .dropdown.show": {
        color: seasonal?.color + " !important",
      },
      "@global .dropdown.show .dropdown-toggle ": {
        color: seasonal?.color + " !important",
      },
      "@global .dropdown.show .dropdown-toggle::after ": {
        borderTopColor: seasonal?.color + " !important",
      }
    },
    themeColor: {
      "&.bodyWhite .socialShare .SocialMediaShareButton a": {
        color: seasonal?.color + " !important",
        borderColor: seasonal?.color + " !important",
      },
      "@global .sidemenu .sideMenuWrap .MuiListItem-root": {
        "@global": {
          a: {
            "&:hover": {
              color: seasonal?.color + " !important",
              "@global": {
                div: {
                  color: seasonal?.color + " !important",
                },
                span: {
                  color: seasonal?.color + " !important",
                },
              },
            },
          },
        },
      },
    },
    dropDownItem: {
      "@global .dropdown-item.active, .dropdown-item:active": {
        color: "#fff !important",
        backgroundColor: seasonal?.color + " !important",
      },
    },
    dropDownItemHover: {
      "@global .dropdown-item:hover, .dropdown-item:active": {
        color: seasonal?.color + " !important",
      },
    },
    contentAnchorColor: {
      "@global": {
        a: {
          color: seasonal?.color + " !important",
        },
      },
    },
    swiperSliderClass: {
      "@global .swiper-button-prev,.swiper-button-next": {
        "&:hover": {
          "&:after": {
            color: seasonal?.color + " !important",
          }
        }

      },
    },

  });

  const classes = useStyles();

  return { seasonal, classes };
};
