import React, { useState } from 'react';
import { IoIosCopy, IoLogoWhatsapp } from "react-icons/io";
import {
  FacebookShareButton, TwitterShareButton, WhatsappShareButton
} from 'react-share';
import { IoMdShare } from "react-icons/io";
import { FaFacebookF, FaXTwitter } from "react-icons/fa6";

const EventVideoShare = ({ type, slug, elemType}) => {

  const [showCopiedMessage, setShowCopiedMessage] = useState(false);

  // Function to copy text to the clipboard
  // Function to copy text to the clipboard
  const copyTextToClipboard = (url) => {
    const copyText = url;

    // Check if the Clipboard API is supported
    if (navigator.clipboard) {
      // Use the Clipboard API to write the text to the clipboard
      navigator.clipboard.writeText(copyText)
        .then(() => {
          // Successful copy
          setShowCopiedMessage(true);
          setTimeout(() => {
            setShowCopiedMessage(false);
          }, 1000);
        })
        .catch((error) => {
          // Error occurred while copying
          console.error('Failed to copy text: ', error);
        });
    } else {
      // Fallback for browsers without Clipboard API support

      // Create a temporary textarea element to hold the text
      const el = document.createElement('textarea');
      el.value = copyText;
      el.style.opacity = 0;

      // Append the textarea to the body, select its contents, and execute the copy command
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

      // Show the "Copied" message and hide it after a certain duration
      setShowCopiedMessage(true);
      setTimeout(() => {
        setShowCopiedMessage(false);
      }, 1000);
    }
  };


  return (
    <>
      <div className={`share_video ${elemType === 'modal' ? 'top_left' : ''} `} >
        {elemType === 'modal' ?
          <span><IoMdShare /></span>
        :
          <span className="share_title">Share</span>
        }
        
        <ul >
          {/* Facebook share button */}
          <li>
            <FacebookShareButton url={`${window.location.protocol}//${window.location.host}${window.location.pathname}?${type}=${slug}`}

            >
              <FaFacebookF />
            </FacebookShareButton>
          </li>
          {/* Twitter share button */}
          <li>
            <TwitterShareButton url={`${window.location.protocol}//${window.location.host}${window.location.pathname}?${type}=${slug}`}

            >
              <FaXTwitter />
            </TwitterShareButton>
          </li>
          {/* Whatsapp share button */}
          <li>
            <WhatsappShareButton url={`${window.location.protocol}//${window.location.host}${window.location.pathname}?${type}=${slug}`}

            >
              <IoLogoWhatsapp />
            </WhatsappShareButton>
          </li>
          {/* Copy to clipboard button */}
          <li>
            <a onClick={() => copyTextToClipboard(`${window.location.protocol}//${window.location.host}${window.location.pathname}?${type}=${slug}`)}>
              <IoIosCopy />
            </a>
            {/* Show "Copied" message */}
            {showCopiedMessage && <span className="copy_message">Copied</span>}
          </li>
        </ul>

      </div>
    </>
  );
};

export default EventVideoShare;
