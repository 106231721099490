/* eslint-disable */
import { FETCH_LATEST_EPISODES, FETCH_LATEST_EPISODES_START, UPDATE_LATEST_EPISODES, REMOVE_LATEST_RELATED_VIDEOS, UPDATE_LATEST_VIDEO, UPDATE_RELATED_VIDEO, RESET_LATEST_EPISODES } from './types';
import salomapi from '../apis';
import BrowserPersistence from '../util/simplePersistance';

const storage = new BrowserPersistence();
export const fetchLatestEpisode = (region) => async dispatch => {
    async function fetchFromServer() {
        try {
            const response = await salomapi.get('/latest/episodes');

            let data = { ['latestEpisodes']: { data: response.data.episodes, page: 1, totalPage: 1 } }

            dispatch({ type: FETCH_LATEST_EPISODES, payload: data })
            await saveLatestEpisodes(data);
        }
        catch (error) {
            dispatch({ type: FETCH_LATEST_EPISODES, payload: error });
            throw error;
        }
    }
    const localHeaderData = await retrieveLatestEpisodes();
    if (localHeaderData)
        dispatch({ type: FETCH_LATEST_EPISODES, payload: localHeaderData })
    else
        await fetchFromServer();

    async function retrieveLatestEpisodes() {
        return storage.getItem('LatestEpisodes');
    }
    async function saveLatestEpisodes(data) {
        return storage.setItem('LatestEpisodes', data, 86400);
    }
}

export const fetchCategoryEpisode = (category, page, region) => async dispatch => {

    const response = await salomapi.get(`category/episodes?region=${region}&category=${category ? category : ''}&page=${page}&perpage=9`);

    if (page > 1) {
        let episodeData = { [category]: response.data.episodes, bgImage: response.data.bgImage, totalPage: response.data.pages }
        let pages =
            dispatch({ type: UPDATE_LATEST_EPISODES, payload: { episodeData, page, category, } })

    }
    else {
        let data = { [category]: { page: page, data: response.data.episodes, totalPage: response.data.pages, bgImage: response.data.bgImage } }
        dispatch({ type: FETCH_LATEST_EPISODES, payload: data })
    }

}

export const fetchCategoryEpisodeStart = () => async dispatch => {
    dispatch({ type: FETCH_LATEST_EPISODES_START })
}


export const fetchLatestVideo = (id, zypeId, page, episodeId, region) => async dispatch => {
    let resp = []
    const response = await salomapi.get('/related/episodes?id=' + id + '&zypeId=' + zypeId + '&page=' + page + '&posts_per_page=6&episodeId=' + episodeId);
    if (response.data.status != 'error') {
        await dispatch({ type: UPDATE_LATEST_VIDEO, payload: response.data.episodes, page: page })
    }
}

export const fetchRelatedVideo = (zypeId, tags, page, id, region) => async dispatch => {
    let resp = []
    const response = await salomapi.get('/tag/episodes?region=' + region + '&zypeId=' + zypeId + '&tags=' + tags + '&page=' + page + '&perpage=9&postId=' + id);
    if (response.data.status != 'error') {
        await dispatch({ type: UPDATE_RELATED_VIDEO, payload: response.data.episodes, page: page })

    }

}
export const removeLatestRelatedVideo = () => async dispatch => {
    dispatch({ type: REMOVE_LATEST_RELATED_VIDEOS })
}


export const resetLatestData = () => async dispatch => {
    dispatch({ type: RESET_LATEST_EPISODES })
}


export const fetchLatestEpisodes = (id, zypeId, page, episodeId, region) => async dispatch => {
    let resp = []
    const response = await salomapi.get('/latestepisodes?id=' + id + '&zypeId=' + zypeId + '&page=' + page + '&perpage=9&episodeId=' + episodeId);
    if (response.data.status != 'error') {
        await dispatch({ type: UPDATE_LATEST_VIDEO, payload: response.data.episodes, page: page })
    }
}