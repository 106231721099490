import React from 'react'
import CommonLayout from '../components/Layouts/CommonLayout'
import ShortFilmContest from '../components/ShortFilmContest'
import SocialMediaWrap from '../components/SocailMediaIcons'
import { useDimensions } from '../logic/Dimentions'
import { useSeasonal } from '../logic/Seasonal'

const ShortFilmContestPage = () => {
  const { width } = useDimensions()
  const { classes } = useSeasonal()
  return (
    <CommonLayout>
      {width >= 992 && <SocialMediaWrap classes={classes} />}
      <ShortFilmContest />
    </CommonLayout>
  )
}

export default ShortFilmContestPage
