/* eslint-disable import/no-anonymous-default-export */
import {
    FETCH_COMMON_SEARCH,
    FETCH_COMPLETE_SEARCH_START,
    FETCH_COMPLETE_SEARCH_SUCCESS,
    UPDATE_COMPLETE_SEARCH,
} from "../actions/types.js";

const INTIAL_STATE = {
    searchResult: [],
    searchArea: {},
    searchData: [],
    total: null,
    status: null, //Loading, success, error
};

export default (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_COMMON_SEARCH:
            return {
                ...state,
                searchResult: action.payload.result,
                searchType: action.payload.type ? action.payload.type : "all",
                total: action.payload.result ? action.payload.total : 0,
                status: "success",
            };

        case FETCH_COMPLETE_SEARCH_START:
            return {
                ...state,
                searchData: [],
                searchArea: {},
                status: "loading",
            };

        case FETCH_COMPLETE_SEARCH_SUCCESS:
            return {
                ...state,
                searchArea: action.payload,
                searchData: action.payload.result,
                searchType: action.payload.type ? action.payload.type : "all",
                total: action.payload.result ? action.payload.total : 0,
                status: "success",
            };

        case UPDATE_COMPLETE_SEARCH:
            return {
                ...state,
                searchData: [...state.searchData, ...action.payload],
            };

        default:
            return state;
    }
};
