import React,{lazy} from 'react';
import PrivacyStatements from '../components/PrivacyStatements';
import CommonLayout from "../components/Layouts/CommonLayout";
import SocialMediaWrap from "../components/SocailMediaIcons";
import { useDimensions } from "../logic/Dimentions";
import { useSeasonal } from '../logic/Seasonal'

const Menu = lazy(()=>import('../components/Menu'));
const Subscription = lazy(()=>import('../components/Subscription'));
const Footer = lazy(()=>import('../components/Footer'));

const Statements = () => {
    const {  width } = useDimensions();
  const { classes } = useSeasonal()

    return (
        <>
        {/* <Menu/> */}
        <CommonLayout>
        {width>=992&&<SocialMediaWrap classes={classes}/>}
        <PrivacyStatements/>
        </CommonLayout>
        {/* <Subscription/>
        <Footer/> */}
        </>
    )
}
export default Statements;