import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { FiChevronLeft } from "react-icons/fi";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./PodcastEpisodeDetail.module.scss";
import { useViewportAnimation } from "../../logic/ViewportAnimation/useViewportAnimation";
import { usePodCastEpisode } from "../../logic/PodCastEpisode/usePodCastEpisode";
import {
  addToPlaylist,
  appendToPlaylist,
  purgePlaylist,
  triggerPlay,
  setActiveId,
  fetchPodcastEpisode,
  fetchAllPodcastEpisode,
} from "../../actions/podCastPlayListAction";
import { useSeo } from "../../logic/Seo/useSeo";
import PodcastEpisodeBanner from "../PodcastEpisodeBanner";
import PodcastEpisodeCarousel from "../PodcastEpisodeCarousel";
import { useSeasonal } from '../../logic/Seasonal'
import AboutPodcast from "../AboutPodcast";
import { updatePodcast } from "../../actions/podCastsAction";

const PodcastDetail = ({
  fetchPodcastEpisode,
  fetchAllPodcastEpisode,
  podCastData,
  podCastEpisode,
  triggerPlay,
  setActiveId,
  progress,
  play,
  activeId,
  appendToPlaylist,
  updatePodcast
}) => {
  useViewportAnimation();
  usePodCastEpisode({ fetchPodcastEpisode });
  useSeo();
  const { classes } = useSeasonal()

  /* Add all episodes to playlist and set ID as active one*/

  const handleUpdatePlaylist = (id) => {
    let indexFound = 0;
    let podCastDataId = podCastData?.id;

    podCastEpisode?.otherEpisodes?.episodes.forEach((element, index) => {
      if (id === element.id) {
        podCastEpisode.otherEpisodes.episodes[index].active = true;
        setActiveId(podCastEpisode.otherEpisodes.episodes[index].id);
        indexFound = index;
      } else {
        podCastEpisode.otherEpisodes.episodes[index].active = false;
      }
    });
    if (podCastEpisode?.otherEpisodes?.episodes) {
      appendToPlaylist(podCastEpisode.otherEpisodes.episodes, indexFound, podCastDataId);
    }

    const timer = setTimeout(() => {

      handlePlay(true);
    }, 100);

    return () => clearTimeout(timer);
  };

  const handlePlay = (status) => {
    // fetchAllPodcastEpisode({ fetchPodcast, podcastId, seasonId, seasonNumber });

    triggerPlay(status);
  };

  /*Update Redux before API hits*/
  const handleClick = (item) => {
    updatePodcast(item);
  };

  return (
    <>
      <PodcastEpisodeBanner
        data={podCastEpisode}
        handlePlay={handlePlay}
        play={play}
        activeId={activeId}
        handleUpdatePlaylist={handleUpdatePlaylist}
        progress={progress}
        handleClick={handleClick}
      />

      <div className={styles.PodcastEpisodeDetailWrapper}>
        <Container>
          <Row>
            <Col lg={8} className={`${styles.PodcastEpisodeDetail} PodcastEpisodeDetail`}>
              {podCastEpisode?.description && (
                <h4>
                  <Link to={`/podcast/${podCastEpisode?.podcastSlug}`} onClick={(e) => {
                    handleClick(podCastEpisode);
                  }}>
                    <i>
                      <FiChevronLeft />
                    </i>
                  </Link>
                  Show Notes

                </h4>
              )}<h5>{podCastEpisode?.title}</h5>

              <article className={`editorTextArea ${classes?.contentLinkHoverColor}`}
                dangerouslySetInnerHTML={{
                  __html: podCastEpisode?.description,
                }}
              ></article>

            </Col>
            <Col lg={4} className={styles.aboutPodcastSidebar}>
              {podCastEpisode?.title && <AboutPodcast data={podCastEpisode} />}
            </Col>
          </Row>

          <PodcastEpisodeCarousel
            data={podCastEpisode?.otherEpisodes}
            handleUpdatePlaylist={handleUpdatePlaylist}
            handlePlay={handlePlay}
            play={play}
            activeId={activeId}
            copyLinkText={
              window.location.origin +
              "/podcast/" +
              podCastEpisode?.podcastSlug
            }
            type="desktop"
            currentPid={podCastEpisode?.id}
          />
        </Container>
      </div>


    </>
  );
};

const mapStateToProps = (state) => {
  return {
    podCastData: state.podCast,
    podCastEpisode: state.podCastEpisode,
    podCastPlayListData: state.podCastPlayList?.playLists,
    play: state.podCastPlayList?.play,
    activeId: state.podCastPlayList?.activeId,
    progress: state.podCastPlayList?.progress,
  };
};

export default connect(mapStateToProps, {
  fetchPodcastEpisode,
  fetchAllPodcastEpisode,
  appendToPlaylist,
  addToPlaylist,
  triggerPlay,
  setActiveId,
  purgePlaylist,
  updatePodcast
})(PodcastDetail);
