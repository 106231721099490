import {FETCH_AMAZON_SMILE} from '../actions/types.js';

const INTIAL_STATE = {
    amazonSmile:{},
};
export default (state = INTIAL_STATE,action) => {
    switch(action.type){ 
        case FETCH_AMAZON_SMILE: 
        return {
            ...state,
            amazonSmile:action.payload
        }
        default:
            return state;
    }
}