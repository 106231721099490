import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from './EpisodeThumb.module.scss';
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateEpisodeVideo } from "../../../actions/episodeVideoAction";


const EpisodeThumb = ({ value }) => {
    const dispatch = useDispatch();
    const handleClick = () => {
        dispatch(updateEpisodeVideo(value));
    };

    return (
        <div className={styles.episodeThumb}>
            <Link to={`/episode/${value.url}`} onClick={handleClick}>
                <Row className={styles.colrow}>
                    <div className={styles.colleft}>
                        <figure>
                            <img src={value.thumbnail} alt={value.title} />
                        </figure>

                    </div>
                    <div className={styles.colright}>
                        <h5>{value.title}</h5>
                        <p>EP - {value.episode} {value.program}</p>
                    </div>
                </Row>
            </Link>
        </div>
    )
}

export default EpisodeThumb;