/* eslint-disable import/no-anonymous-default-export */
import {
    FETCH_VIDEO,
    UPDATE_VIDEO,
    REMOVE_VIDEO,
    UPDATE_LATEST_VIDEO,
    UPDATE_RELATED_VIDEO,
    REMOVE_LATEST_RELATED_VIDEOS,
} from "../actions/types";

const INTIAL_STATE = {
    episodeVideos: {
        id: null,
        latestEpisodes: [],
        relatedEpisodes: [],
    },
    maxLatestPages: null,
    maxRelatedPages: null,
};

export default (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_VIDEO:
            return {
                ...state,
                episodeVideos: action.payload,
                maxLatestPages: action.maxLatestPage,
                maxRelatedPages: action.maxRelatedPage,
            };
        case UPDATE_LATEST_VIDEO:
            return {
                ...state,
                episodeVideos: {
                    ...state.episodeVideos,
                    latestEpisodes: [...action.payload],
                },
                maxLatestPages: action.page,
            };
        case UPDATE_RELATED_VIDEO:
            return {
                ...state,
                episodeVideos: {
                    ...state.episodeVideos,
                    relatedEpisodes: [...action.payload],
                },
                maxRelatedPages: action.page,
            };
        case REMOVE_LATEST_RELATED_VIDEOS:
            return {
                ...state,
                episodeVideos: {
                    ...state.episodeVideos,
                    latestEpisodes: [],
                    relatedEpisodes: [],
                },
                maxRelatedPages: action.page,
            };
        case REMOVE_VIDEO:
            return {
                ...state,
                episodeVideos: action.payload,
                maxLatestPages: action.maxLatestPage,
                maxRelatedPages: action.maxRelatedPage,
            };
        case UPDATE_VIDEO:
            return {
                ...state,
                episodeVideos: {
                    ...action.payload,
                    latestStatus: 'loading',
                    relatedStatus: 'loading',
                },
                maxLatestPages: action.maxLatestPage,
                maxRelatedPages: action.maxRelatedPage,

            };

        default:
            return state;
    }
};
