import React from "react";
import styles from "./NewSwPluseBanner.module.scss";
import imgBanner from "../../assets/img/imgSWPlusBanner.jpg";
function SwpluseBanner({ data }) {
  return (
    <section className={`${styles.swplusBanner} mediaCenter`}>
      <img src={data.banner} alt="SW+ Banner" />
    </section>
  );
}

export default SwpluseBanner;
