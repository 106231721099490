import React from 'react';
import { useDimensions } from '../logic/Dimentions'
import { useSeasonal } from '../logic/Seasonal'
import CommonLayout from '../components/Layouts/CommonLayout';
import SocialMediaWrap from '../components/SocailMediaIcons'
import AllEventRelatedVideos from '../components/AllEventRelatedVideos';

const AllEventRelatedVideosPage = () => {
    const { width } = useDimensions()
    const { classes } = useSeasonal()

    return (
        <CommonLayout>
            <AllEventRelatedVideos />
            {width >= 992 && <SocialMediaWrap classes={classes} />}
        </CommonLayout>

    )
}
export default AllEventRelatedVideosPage;