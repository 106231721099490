import React, { useEffect, useRef } from "react";
import styles from "./NewSwPluseSignup.module.scss";
import { Container } from "react-bootstrap";
import SignUpTab from "../SignUpTab";
import SignInTab from "../SignInTab";
import { useSeasonal } from "../../logic/Seasonal";

function SwPluseSignUp({
  data,
  countryList,
  SetTabActive,
  tabActive,
  token,
  verifyError,
  verifyMsg,
  pathName,
}) {
  const { classes } = useSeasonal();
  const watchSheduleRef = useRef();

  useEffect(() => {
    // code to run after render goes here
    if (watchSheduleRef.current && token && data.signup_bg) {
      watchSheduleRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  });

  return (
    <div
      id="Signup/Signin"
      className={styles.sign_in_up_wrap}
      style={{ backgroundImage: `url(${data.signup_bg})` }}
    >
      <>
        <Container>
          <div className={styles.swplus_tabWrap} ref={watchSheduleRef}>
            <ul className={styles.tabs}>
              <li
                className={

                  tabActive == 1
                    ? `${styles.active}  ${classes?.seasonalClass} ${classes.afterHoverClass}`
                    : `${classes.afterHoverClass}`
                }
                onClick={() => {
                  SetTabActive(1);
                }}
              >
                Sign up
              </li>
              <li
                className={
                  tabActive == 2
                    ? `${styles.active}  ${classes?.seasonalClass} ${classes.afterHoverClass}`
                    : `${classes.afterHoverClass}`
                }
                onClick={() => {
                  SetTabActive(2);
                }}
              >
                Sign in
              </li>
            </ul>
            <div className={styles.tab_content_wrap}>
              {tabActive === 1 ? (
                <SignUpTab mediaGallery={data} countryList={countryList} />
              ) : null}
              {tabActive === 2 ? (
                <SignInTab
                  token={token}
                  SetTabActive={SetTabActive}
                  verifyError={verifyError}
                  verifyMsg={verifyMsg}
                  pathName={pathName}
                />
              ) : null}
            </div>
          </div>
        </Container>
      </>
    </div>
  );
}

export default SwPluseSignUp;
