import { useEffect } from 'react';

export const usePodCasts = props => {
    const { fetchPodcasts, page, setPage } = props

    useEffect(() => {
        setPage(1)
        fetchPodcasts(page)
    }, [])
}
