/* eslint-disable import/no-anonymous-default-export */
import { FETCH_SEASONAL } from '../actions/types';

const INTIAL_STATE = {
    color: "#ffb600",
    comingUpEventsImage: "",
    donateLeftImage: "",
    donateRightImage: "",
    generalButtonImage: "",
    isActive: true,
    navigationImage: "",
    otherSitesImage: "",
    watchLiveImage: "",
    status: "idle"

};

export default (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_SEASONAL:
            return action.payload;
        default:
            return state;
    }
}