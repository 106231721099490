/* eslint-disable */
import React from 'react';
import Container from 'react-bootstrap/Container';
import styles from './CareerSections.module.scss';
import { useSeasonal } from '../../logic/Seasonal';


const CareerSection = ({ data }) => {
    const { seasonal, classes } = useSeasonal();

    return (
        <section className={styles.careerSection}>
            <Container>
                <div className="row">
                    {data ? data.map((value) => {
                        return (
                            <div className="col-md-4">
                                <>
                                    <i className={`${value.icon} ${classes?.txtColor}`}></i>
                                    <h3 className={styles.heading}><span className={styles.spanStyling}>{value.superHeading}</span>{value.heading}</h3>
                                </>
                            </div>
                        )
                    }) : null}

                </div>
            </Container>
        </section>
    )
}

export default CareerSection;