/* eslint-disable */

import styles from "./ApplyNow.module.scss";
import React, { useEffect, useState, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { fetchCareerDetails } from "../../actions/careersAction";
import { connect } from "react-redux";
import { useFormik } from "formik";
import classNames from "classnames";
import salomapi from "../../apis";
import BrowserPersistence from "../../util/simplePersistance";
import InnerHTML from "dangerously-set-html-content";
import { Transition } from "react-transition-group";
import GoogleRecaptcha from "../../components/Recaptcha";
import { useTransition } from "../../logic/PageTransition/useTransition";
import { useSeasonal } from "../../logic/Seasonal";

const storage = new BrowserPersistence();
const ApplyNow = ({ fetchCareerDetails, careerDetails }) => {
  const [captchaReset, setCaptchaReset] = useState(false);

  const [captchaValid, isCaptchaValid] = useState(false);
  const [validUser, isValidUser] = useState(false);
  const captcha = useRef(null);
  const [isFormSuccess, setFormSuccess] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [resume, setResume] = useState("");
  const divClass = classNames(styles.div_fields, "col-sm-6");
  const inputClass = classNames(styles.input_fields);
  const { duration, defaultStyle, transitionStyles } = useTransition();
  const { seasonal, classes } = useSeasonal();

  useEffect(() => {
    var pathArray = window.location.pathname.split("/");
    var url = pathArray[2];

    // if(news.length===0)
    fetchCareerDetails(url);
  }, []);
  const validate = (values) => {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = "Required";
    } else if (values.firstName.length > 15) {
      errors.firstName = "Must be 15 characters or less";
    }

    if (!values.lastName) {
      errors.lastName = "Required";
    } else if (values.lastName.length > 20) {
      errors.lastName = "Must be 20 characters or less";
    }

    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }
    if (!values.phone) {
      errors.phone = "Required";
    }
    // if(!captchaValid) {
    //   errors.captchaValid = 'Please valid the Captcha'
    // }
    if (!values.aboutprofile) {
      errors.aboutprofile = "Required";
    }
    if (!resume) errors.resume = "Required";
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      phone: "",
      firstName: "",
      lastName: "",
      aboutprofile: "",
      resume: "",
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      let dataNew = new FormData();
      dataNew.append("jobId", careerDetails.jobID);
      dataNew.append("firstname", values.firstName);
      dataNew.append("lastname", values.lastName);
      dataNew.append("phone", values.phone);
      dataNew.append("email", values.email);
      dataNew.append("message", values.aboutprofile);
      dataNew.append("resume", resume);
      dataNew.append("region", storage.getItem("CountryCode"));
      if (captchaValid) {
        salomapi.post("/career/post", dataNew).then((response) => {
          setFormSuccess(true);
          resetForm({});
          setCaptchaReset(true);
          isCaptchaValid(false);

          setTimeout(() => {
            setFormSuccess(false);
            setCaptchaReset(false);
          }, 4000);
        });
      }
      else {
      }
      if (captcha.current.getValue()) {
        isValidUser(true);
        isCaptchaValid(true);
      } else {
        isValidUser(false);
        isCaptchaValid(false);
      }
      //alert(JSON.stringify(values, null, 2));
    },
  });
  const selectResume = (event) => {
    let selectedFile = event.target.files[0];
    let splitarr = selectedFile.name.split(".");
    if (splitarr.includes("pdf")) {
      let selectedResume = event.target.value;
      selectedResume = selectedResume.replace(/C:\\fakepath\\/i, "");
      setSelectedFileName(selectedResume);
      setResume(selectedFile);
      //   this.setState({ resume: selectedFile, selectedFileName: selectedResume,isinvalidResumeFormat:false })
    } else {
      setSelectedFileName("");
      setResume("");
      //   this.setState({ resume: "", selectedFileName: "",isinvalidResumeFormat:true })
    }
  };
  const handleOnChange = () => {
    isCaptchaValid(true);
  };

  return (
    <>
      {/* <Transition in={careerDetails?.banner} timeout={duration}>
        {state => (
          <>
          <div style={{
          ...defaultStyle,
          ...transitionStyles[state]
        }}> */}
      {careerDetails ? (
        <div className={styles.apply_heading}>
          <div className="applynow-banner">
            <img src={careerDetails.banner}></img>
          </div>
          <section className={styles.description_section}>
            <Container>
              <h1>{careerDetails.jobTitle}</h1>
              <h3 className={classes?.txtColor}>Job Description</h3>
              <p>
                <InnerHTML html={careerDetails.description} />
              </p>
            </Container>
          </section>
          <section className={styles.skills_section}>
            <Container>
              <h3 className={classes?.txtColor}>Skills</h3>
              <ul>
                {careerDetails.skills
                  ? careerDetails.skills.map((skill) => {
                    return (
                      <li className={classes?.seasonalClass2}>{skill}</li>
                    );
                  })
                  : null}
              </ul>
            </Container>
          </section>
          <section className={styles.career_details}>
            <Container>
              <h3 className={classes?.txtColor}>Job Details</h3>
              <ul>
                <li>
                  Job Location: <span>{careerDetails.location}</span>
                </li>
                <li>
                  Career Level: <span>{careerDetails.careerLevel}</span>
                </li>
                <li>
                  Employment Status:{" "}
                  <span>{careerDetails.employmentStatus}</span>
                </li>
                <li>
                  Experience:
                  <span>{careerDetails?.yearsOfExperience}</span>
                </li>
                <li>
                  Residence Location:
                  <span>{careerDetails.residenceLocation}</span>
                </li>
              </ul>
            </Container>
          </section>
          <section className={styles.career_apply_section}>
            <Container>
              <div className={styles.CareerFormwrap}>
                <h1 className={classes?.seasonalClass}>Apply Now</h1>

                <form
                  className={styles.career_apply_form}
                  onSubmit={formik.handleSubmit}
                >
                  <Row className={styles.div_align}>
                    <div className={divClass}>
                      <input
                        className={
                          !formik.errors.firstName || !formik.submitCount
                            ? inputClass
                            : inputClass + " " + styles.formErrReact
                        }
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.firstName}
                        name="firstName"
                        placeholder="First Name"
                      ></input>
                    </div>
                    <div className={divClass}>
                      <input
                        className={inputClass}
                        type="text"
                        value={careerDetails.jobTitle}
                        name="CareerFor"
                        placeholder="Document Manager"
                        disabled
                      ></input>
                    </div>
                  </Row>
                  <Row className={styles.div_align}>
                    <div className={divClass}>
                      <input
                        className={
                          !formik.errors.lastName || !formik.submitCount
                            ? inputClass
                            : inputClass + " " + styles.formErrReact
                        }
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.lastName}
                        name="lastName"
                        placeholder="Last Name"
                      ></input>
                      <input
                        className={
                          !formik.errors.email || !formik.submitCount
                            ? inputClass
                            : inputClass + " " + styles.formErrReact
                        }
                        type="email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        name="email"
                        placeholder="Email"
                      ></input>
                    </div>
                    <div className={divClass}>
                      <textarea
                        className={
                          !formik.errors.aboutprofile || !formik.submitCount
                            ? inputClass
                            : inputClass + " " + styles.formErrReact
                        }
                        onChange={formik.handleChange}
                        value={formik.values.aboutprofile}
                        name="aboutprofile"
                        placeholder="A brief about your profile ( max-limit : 300 Characters )"
                      ></textarea>
                    </div>
                  </Row>
                  <Row className={styles.div_align}>
                    <div className={divClass}>
                      <input
                        className={
                          !formik.errors.phone || !formik.submitCount
                            ? inputClass
                            : inputClass + " " + styles.formErrReact
                        }
                        type="number"
                        onChange={formik.handleChange}
                        value={formik.values.phone}
                        name="phone"
                        placeholder="Phone"
                      ></input>
                    </div>
                    <div className={divClass}>
                      <input
                        type="file"
                        name="resume"
                        accept=".pdf"
                        id="file-upload"
                        onChange={selectResume}
                      />
                      <label
                        for="file-upload"
                      // htmlFor='apply-resume'
                      // className={
                      //   (this.state.resume === '' ||
                      //     this.state.resume === undefined) &&
                      //   this.state.isSubmitted && !this.state.isFormReset
                      //     ? 'formErrReact'
                      //     : ''
                      // }
                      >
                        Upload resume
                        {selectedFileName}
                      </label>
                      <span
                        className={`${styles.allowedResumFmtext} ${classes?.txtColor}`}
                      >
                        Allowed document format " PDF " only
                      </span>
                    </div>
                  </Row>
                  <Row className={styles.div_align}>
                    <div className="captchaWrap">
                      <GoogleRecaptcha
                        onChange={handleOnChange}
                        isCaptchaReset={captchaReset}
                      />
                    </div>
                  </Row>
                  {Object.keys(formik.errors).length != 0 &&
                    formik.submitCount ? (
                    <div className="form-group col-sm-12">
                      <span className={styles.formtotNullfieldErr}>
                        Please fill all required fields.
                      </span>
                    </div>
                  ) : null}
                  {isFormSuccess ? (
                    <div className="form-group col-sm-12">
                      <span className="resonseSuccessMsg">
                        Your career form has been successfully submitted!!!
                      </span>
                    </div>
                  ) : null}
                  <button
                    className={`${styles.apply_btn} ${classes?.fullSchduleBtn}`}
                    disabled={formik.isSubmitting}
                    type="submit"
                  >
                    SUBMIT
                  </button>
                </form>
              </div>
            </Container>
          </section>
        </div>
      ) : null}
      {/* </div>
            </>
            )}
        </Transition> */}
    </>
  );
};
const mapStateToProps = (state) => {
  return { careerDetails: state.careers.careerDetails };
};
export default connect(mapStateToProps, { fetchCareerDetails })(ApplyNow);
