import {FETCH_EMPLOYEE_MATCHING} from '../actions/types.js';

const INTIAL_STATE = {
    employeeMatching:{},
};
export default (state = INTIAL_STATE,action) => {
    switch(action.type){ 
        case FETCH_EMPLOYEE_MATCHING: 
        return {
            ...state,
            employeeMatching:action.payload
        }
        default:
            return state;
    }
}