/* eslint-disable import/no-anonymous-default-export */
import { FETCH_PROMOTION } from '../actions/types.js';

const INTIAL_STATE = {
    promotion:{
        banner: null,
        full:[],
        half:[],
        quarter:[],
    }
};

export default (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_PROMOTION:
            return {
                ...state,
                promotion: {
                    banner: action.payload.banner,
                    full: action.payload.promotions.filter(promotion => promotion.size === 'full'),
                    half: action.payload.promotions.filter(promotion => promotion.size === 'half'),
                    quarter: action.payload.promotions.filter(promotion => promotion.size === 'quarter'),
                }
            }
        default:
            return state;
    }
}