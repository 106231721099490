import {FETCH_SEO} from '../actions/types';

const INTIAL_STATE = [];

export default (state = INTIAL_STATE,action) => {
    switch(action.type){
        case FETCH_SEO:
            return [...state,action.payload];
        default:
            return state;
    }
}