import {FETCH_LIVE_SCHEDULE2} from '../actions/types.js';

const INTIAL_STATE = [];

export default (state = INTIAL_STATE,action) => {
    switch(action.type){
        case FETCH_LIVE_SCHEDULE2:
            return action.payload;
        default:
            return state;
    }
}