/* eslint-disable import/no-anonymous-default-export */
import { FETCH_WEBSITES } from '../actions/types';

const INTIAL_STATE = [];

export default (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_WEBSITES:
            return action.payload;

        default:
            return state;
    }
}