import React, { forwardRef } from "react";
import styles from "./ListenOn.module.scss";
import Container from "react-bootstrap/Container";
import app1 from "../../assets/img/app_apple.svg";
import app2 from "../../assets/img/app_spotify.svg";
import app3 from "../../assets/img/app_google.svg";
import app4 from "../../assets/img/app_amazon.svg";
import app5 from "../../assets/img/app_stitcher.svg";
import app6 from "../../assets/img/app_tune_in.svg";
import app7 from "../../assets/img/app_deezer.svg";
import app8 from "../../assets/img/app_jio_saavan.svg";
import app9 from "../../assets/img/app_listen_notes.svg";
import app10 from "../../assets/img/app_pocket_casts.svg";
import app11 from "../../assets/img/app_podchaser.svg";

const ListenOn = forwardRef(({ data }, ref) => {

  return (
    <>
      <div className={styles.ListenOnWrap} >
        <Container>
          {data && <h4>Listen On</h4>}
          <div className={styles.listen_app_list} ref={ref} >

            <ul>
              {data?.apple_url && (
                <li>
                  <a href={data?.apple_url} target="_blank" rel="noreferrer">
                    <figure>
                      <img src={app1} alt="" />
                    </figure>
                    <span>Apple Podcasts</span>
                  </a>
                </li>
              )}

              {data?.spotify_url && (
                <li>
                  <a href={data?.spotify_url} target="_blank" rel="noreferrer">
                    <figure>
                      <img src={app2} alt="" />
                    </figure>
                    <span>Spotify</span>
                  </a>
                </li>
              )}

              {data?.google_url && (
                <li>
                  <a href={data?.google_url} target="_blank" rel="noreferrer">
                    <figure>
                      <img src={app3} alt="" />
                    </figure>
                    <span>Google Podcasts</span>
                  </a>
                </li>
              )}

              {data?.amazon_music_url && (
                <li>
                  <a href={data?.amazon_music_url} target="_blank" rel="noreferrer">
                    <figure>
                      <img src={app4} alt="" />
                    </figure>
                    <span>Amazon Music</span>
                  </a>
                </li>
              )}

              {data?.stitcher_url && (
                <li>
                  <a href={data?.stitcher_url} target="_blank" rel="noreferrer">
                    <figure>
                      <img src={app5} alt="" />
                    </figure>
                    <span>Stitcher</span>
                  </a>
                </li>
              )}

              {data?.tunein_url && (

                <li>
                  <a href={data?.tunein_url} target="_blank" rel="noreferrer">
                    <figure>
                      <img src={app6} alt="" />
                    </figure>
                    <span>TuneIn</span>
                  </a>
                </li>
              )}

              {data?.deezer_url && (

                <li>
                  <a href={data?.deezer_url} target="_blank" rel="noreferrer">
                    <figure>
                      <img src={app7} alt="" />
                    </figure>
                    <span>Deezer</span>
                  </a>
                </li>
              )}

              {data?.jiosaavan_url && (
                <li>
                  <a href={data?.jiosaavan_url} target="_blank" rel="noreferrer">
                    <figure>
                      <img src={app8} alt="" />
                    </figure>
                    <span>JioSaavan</span>
                  </a>
                </li>
              )}

              {data?.listennotes_url && (
                <li>
                  <a href={data?.listennotes_url} target="_blank" rel="noreferrer">
                    <figure>
                      <img src={app9} alt="" />
                    </figure>
                    <span>Listen Notes</span>
                  </a>
                </li>
              )}

              {data?.pocketcasts_url && (
                <li>
                  <a href={data?.pocketcasts_url} target="_blank" rel="noreferrer">
                    <figure>
                      <img src={app10} alt="" />
                    </figure>
                    <span>Pocket casts</span>
                  </a>
                </li>
              )}

              {data?.podchaser_url && (
                <li>
                  <a href={data?.podchaser_url} target="_blank" rel="noreferrer">
                    <figure>
                      <img src={app11} alt="" />
                    </figure>
                    <span>Podchaser</span>
                  </a>
                </li>
              )}
            </ul>
          </div>
        </Container>
      </div>
    </>
  );
});

export default ListenOn;
