import React from 'react'
import styles from './ContentSection.module.scss'
import { useSeasonal } from '../../../logic/Seasonal'
const ContentSection = (props) => {
  const { heading, description, donationTxt, donationUrl, target } = props
  const { classes } = useSeasonal()

  return (
    <div className="main_content">
      <div className={styles.content_wrap}>
        {heading && <h1 className={classes?.seasonalClass}>{heading}</h1>}
        <p> {description}</p>
      </div>
      {donationTxt ? (
        <a
          className={`${styles.amazon_btn} ${classes?.fullSchduleBtn}`}
          href={donationUrl}
          target={target}
        >
          {donationTxt}
        </a>
      ) : null}
    </div>
  )
}
export default ContentSection
