import {
    FETCH_LIVE_SCHEDULE,
    FETCH_FULL_SCHEDULE,
    FETCH_LIVE_SCHEDULE2,
    FETCH_UPCOMING_SCHEDULE,
} from "./types";
import salomapi, { salomapi2 } from "../apis";
import BrowserPersistence from "../util/simplePersistance";

const storage = new BrowserPersistence();

export const fetchLiveSchedule = () => async (dispatch) => {
    const response = await salomapi.get("live/schedules2", {
        params: {
            region: storage.getItem("CountryCode"),
            isoCountryCode: storage.getItem("IsoCountryCode"),
            timeZone: storage.getItem("TimeZoneCode"),
        },
    });
    if (response.data.schedules) {
        dispatch({ type: FETCH_LIVE_SCHEDULE, payload: response.data.schedules });
    }
};

export const fetchLiveScheduleMobile = (params) => async (dispatch) => {
    const response = await salomapi2.get("live/schedules", { params });
    if (response.data) {
        dispatch({ type: FETCH_LIVE_SCHEDULE2, payload: response.data });
    }
};

export const fetchLiveSchedule2 = (region) => async (dispatch) => {
    const response = await salomapi.get("full/schedules", {
        params: {
            region: storage.getItem("CountryCode"),
            isoCountryCode: storage.getItem("IsoCountryCode"),
            timeZone: storage.getItem("TimeZoneCode"),
        }
    });
    if (response.data.region) {
        let region = response.data.region;
        storage.setItem("CountryCode", region.toLowerCase(), 10);
    }
    if (response.data.schedules) {
        dispatch({ type: FETCH_FULL_SCHEDULE, payload: response.data.schedules });
    }

};

export const fetchCurrentSchedule = (region) => async (dispatch) => {
    const response = await salomapi2.get("current/schedule", {
        params: { region },
    });
    return response.data;
};
export const fetchUpcomingmassSchedule = (region) => async (dispatch) => {
    const response = await salomapi.get(
        "upcomingmass/schedules?region=" + region,
        { params: { region } }
    );
    if (response?.data?.schedules) {
        dispatch({
            type: FETCH_UPCOMING_SCHEDULE,
            payload: response.data.schedules,
        });
    }
    //dispatch({type:FETCH_UPCOMING_SCHEDULE,payload:response.data.schedules})
    // return response.data;
};
