import React, { useState } from 'react'
import { Modal } from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import styles from './ShareModal.module.scss'
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share'
import { FaFacebookF, FaWhatsapp, FaXTwitter } from "react-icons/fa6";

const ShareModal = ({ onCloseModal, open }) => {
  return (
    <div>
      <div>
        <Modal
          open={open}
          onClose={onCloseModal}
          classNames={{
            modal: styles.shareModal_wrap,
          }}
          // classNames={styles.shareModal_wrap}
        >
          <div className="shareModal">
            <h4>Share</h4>
            <span>

              <FacebookShareButton url={window.location.href}>
                <a href={() => false} 
                //className="icon-social_fb"
                >
                  <FaFacebookF/>
                </a>
              </FacebookShareButton>

              <TwitterShareButton url={window.location.href}>
                <a href={() => false} 
                //className="icon-twitter"
                >
                  <FaXTwitter/>
                </a>
              </TwitterShareButton>

              <WhatsappShareButton url={window.location.href}>
                <a href={() => false} 
                //className="icon-social_whatsapp"
                >
                  <FaWhatsapp/>
                </a>
              </WhatsappShareButton>
            </span>
          </div>
        </Modal>
      </div>
    </div>
  )
}
export default ShareModal
