import React, { useEffect, useState, useRef } from "react";

import styles from "./WatchOnSection.module.scss";
import Container from "react-bootstrap/Container";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ConnectedTvTab from "../ConectedTVTab";
import ChannelFinderTab from "../ChannelFinderTab";
import { useDimensions } from "../../logic/Dimentions";
import { useHistory, useParams, useLocation } from "react-router-dom";

const WatchOnSection = ({ watchonDetails, classes }) => {
  const [value, setValue] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0)
  const [activeName, setActiveName] = useState(0)
  const location = useLocation();

  const { width } = useDimensions();
  let history = useHistory();
  let { tab, platform } = useParams();
  const scrollDiv = useRef();
  const watchPlatformRef = useRef();

  useEffect(() => {
    if (tab === "apps") {
      setValue(1);
    } else if (tab === "channelfinder") {
      setValue(2);
    } else if (tab === "connectedtv") {
      setValue(0);
    } else {
      setValue(0);
    }
  }, [tab]);

  useEffect(() => { }, [value]);

  const handleChange = (event, newValue) => {
    setActiveIndex(-1)

    if (newValue === 0) {
      history.push({
        // no need
        pathname: `/watchon/connectedtv`,
        state: { to: "connectedtv" },
      });
    } else if (newValue === 1) {
      history.push({
        // no need
        pathname: `/watchon/apps`,
        state: { to: "apps" },
      });
    } else if (newValue === 2) {
      history.push({
        // no need
        pathname: `/watchon/channelfinder`,
        state: { to: "channelfinder" },
      });
    }

  };

  const onSelectHandler = (e) => {
    setValue(parseInt(e.target.value));
  };

  useEffect(() => {
    if (platform && watchPlatformRef.current) {
      watchPlatformRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [platform]);

  useEffect(() => {
    if (location?.state?.scrollId) {
      if (scrollDiv.current) {
        scrollDiv.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location, platform, watchonDetails]);

  return (
    <>
      <section
        className={`${styles.watchon_wrapper} ${navigator.userAgent === "shalom_world_app_mobile" ? 'mob-app-pad-30' : 'no-mob-app'}`} ref={scrollDiv}
      >
        {width >= 992 ? (
          <Container>
            <h2
              className={`${styles.ttlHome} ${classes?.seasonalClass}`}
              ref={watchPlatformRef}
            >
              Watch On
            </h2>
            {watchonDetails?.banner && <>

              <div className={`row ${styles.watchon_tabs}`} >

                <Tabs
                  aria-label="simple tabs example"
                  onChange={handleChange}
                  value={value}
                  className={`${styles.tabContainer} ${classes?.tabColor}`}
                >
                  <Tab
                    label={
                      <a
                        href={() => false}
                        className={`${classes?.menuHover} ${classes?.textDecororationNone} ${classes?.colorWhite}`}
                      >
                        <span
                          className={`icon-connect_tv ${styles.tv_icon}`}
                        ></span>
                        <p
                          className={`${styles.letters_hover} ${value === 0 && classes?.txtColor
                            }`}
                        >
                          {watchonDetails.tab1
                            ? watchonDetails.tab1.tabTitle
                            : null}
                        </p>
                      </a>
                    }
                    className={`${styles.tab} `}
                  ></Tab>
                  <Tab
                    label={
                      <a
                        href={() => false}
                        className={`${classes?.menuHover} ${classes?.textDecororationNone} ${classes?.colorWhite}`}
                      >
                        <span
                          className={`icon-our_apps ${styles.tv_icon}`}
                        ></span>
                        <p
                          className={`${styles.letters_hover} ${value === 1 && classes?.txtColor
                            }`}
                        >
                          {watchonDetails.tab2
                            ? watchonDetails.tab2.tabTitle
                            : null}
                        </p>
                      </a>
                    }
                    className={`${styles.tab} `}
                  />
                  <Tab
                    label={
                      <a
                        href={() => false}
                        className={`${classes?.menuHover} ${classes?.textDecororationNone} ${classes?.colorWhite}`}
                      >
                        <span
                          className={`icon2-ch-finder-thin ${styles.tv_icon}`}
                        ></span>
                        <p
                          className={` ${styles.letters_hover} ${value === 2 && classes?.txtColor
                            }`}
                        >
                          {watchonDetails.tab4
                            ? watchonDetails.tab4.tabTitle
                            : null}
                        </p>
                      </a>
                    }
                    className={`${styles.tab}`}
                  />
                </Tabs>
              </div>
            </>}


          </Container>
        ) : (
          <div className={styles.selWrap}>
            <select
              className={`${styles.input} ${classes?.borderOnly}`}
              onChange={onSelectHandler}
            >
              <option value={0}>
                {watchonDetails.tab1 ? watchonDetails.tab1.tabTitle : null}
              </option>
              <option value={1}>
                {watchonDetails.tab2 ? watchonDetails.tab2.tabTitle : null}
              </option>
              <option value={2}>
                {watchonDetails.tab3 ? watchonDetails.tab3.tabTitle : null}
              </option>
            </select>
          </div>
        )}
        <Container>

          {value === 0 ? (
            <ConnectedTvTab
              activeName={activeName}
              setActiveName={setActiveName}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              tabType="connectedtv"
              tabContent={
                watchonDetails.tab1 ? watchonDetails.tab1.tabContent : null
              }
            />
          ) : value === 1 ? (
            <ConnectedTvTab
              activeName={activeName}
              setActiveName={setActiveName}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              tabType="apps"
              tabContent={
                watchonDetails.tab2 ? watchonDetails.tab2.tabContent : null
              }
            />
          ) : (
            <ChannelFinderTab
              tabContent={
                watchonDetails.tab4 ? watchonDetails.tab4.tabContent : null
              }
            />
          )}
        </Container>
      </section>
    </>
  );
};
export default WatchOnSection;
