import React from "react";
import { Container } from "react-bootstrap";
import { Accordion } from "react-bootstrap";
import CustomAccordion from "../Layouts/CustomAccordion";
import styles from "./NewSwPluseFAQ.module.scss";
function SwPluseFAQ({ data }) {
  return (
    <div className={styles.swplus_faq_wrap} id="faq">
      <Container>
        <h2>{data?.faq_title}</h2>
        <div className={styles.faq_accordion}>
          {data?.faq_content?.map(({ question, answer }) => {
            return <CustomAccordion heading={question} content={answer} />;
          })}
          <div> </div>
        </div>
      </Container>
    </div>
  );
}

export default SwPluseFAQ;
